import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as IconInfo } from '../../assets/icons/icon-info.svg';
import { ReactComponent as IconError } from '../../assets/icons/icon-n-error.svg';

const Attention = ({ children, type }) => {
  return (
    <div className={`flow-message ${type}`}>
      <span className='flow-message-icon'>
        {type === 'info' && <IconInfo />}

        {type === 'error' && <IconError />}
      </span>
      <span className='el-text-s flow-message-text'>{children}</span>
    </div>
  );
};

Attention.propTypes = {
  children: PropTypes.any.isRequired,
  type: PropTypes.string.isRequired,
};

export default Attention;
