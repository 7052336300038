import React from 'react';
import { Link } from 'react-router-dom';
import { truncateWords } from '../../client';
import { createStyles, Card, Image, Group, Text, Avatar } from '@mantine/core';
import getInitials from '../../utils/getInitials';
import PropTypes from 'prop-types';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    width: 400,
    marginBottom: 30,
    marginLeft: 30,
    [theme.fn.smallerThan('lg')]: {
      width: '100%',
      marginLeft: 0,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
}));

const ArticleCard = ({ author, datePublished, title, slug, image }) => {
  const { classes } = useStyles();

  return (
    <Card withBorder padding='lg' radius='md' className={`${classes.card} c-post-item`}>
      <Link to={`/post/${slug}`}>
        <Card.Section mb='sm'>
          <Image src={image} alt={title} height={180} />
        </Card.Section>

        <Text fw={700} className={classes.title} mt='xs'>
          {truncateWords(title, 14)}
        </Text>

        <Group mt='lg'>
          <Avatar color='orange' radius='sm'>
            {getInitials(author)}
          </Avatar>
          <div>
            <Text fw={500}>{author}</Text>
            <Text fz='xs' c='dimmed'>
              {new Date(datePublished).toLocaleDateString()}
            </Text>
          </div>
        </Group>
      </Link>
    </Card>
  );
};

ArticleCard.propTypes = {
  author: PropTypes.string,
  datePublished: PropTypes.string,
  title: PropTypes.string,
  slug: PropTypes.string,
  image: PropTypes.string,
};

export default ArticleCard;
