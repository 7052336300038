import React, { useState } from 'react';
import ArrowBack from '../../ArrowBack';
import { Button } from '@mui/material';
import PhoneField from '../../../components/PhoneField';
import closeFlow from '../../../utils/closeFlow';

const MPesaReceiver = ({ flowData, changeStep, setData }) => {
  const [searchPhone, setSearchPhone] = useState({
    phoneNumber: flowData?.phoneNumber || '',
    dialCode: '',
    isValid: false,
  });

  const { phoneNumber, isValid } = searchPhone;

  const handleSetNumber = ({ phoneNumber, isValid, dialCode }) =>
    setSearchPhone((prevState) => {
      return {
        ...prevState,
        phoneNumber,
        isValid,
        dialCode,
      };
    });

  const setReceiver = () => setData({ userReceiver: { name: '', phoneNumber: phoneNumber }, paymentSystem: 'm-pesa' });

  return (
    <>
      <ArrowBack handle={() => changeStep(-1)} />

      <div className='payment-title'>
        <h6 className='el-title-h6 med'>
          Enter the phone number to send via <span className='orange'>M-Pesa</span>
        </h6>
      </div>

      <div className='send-step-content'>
        <div className='sender-phone'>
          <PhoneField searchPhone={phoneNumber} setSearchPhone={handleSetNumber} submitFn={setReceiver} />
        </div>

        <div className='send-btns'>
          <div className='send-btns-continue'>
            <Button
              disabled={!isValid}
              className='el-button orange'
              variant='contained'
              onClick={setReceiver}
            >
              Continue
            </Button>
          </div>

          <div className='send-btns-cancel'>
            <span className='el-link-m el-button-link' variant='contained' onClick={closeFlow}>
              Cancel
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default MPesaReceiver;
