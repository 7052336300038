import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import animFail from '../../../assets/anims/videos/fullscreen/account-fail-loop.mp4';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { checkUserAccount, getUserProfile, signUpUser } from '../../../api';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccount, selectFirebaseJWT, setAccount, setAvatar } from '../../../redux/features/user/userSlice';
import Success from '../../../components/Success';

const UserNotFound = () => {
  const [localLoading, setLocalLoading] = useState(false);
  const inviteRFCode = useSelector(selectAccount)?.account?.inviteRFCode;
  const firebaseJWT = useSelector(selectFirebaseJWT);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const setUserData = async (token) => {
    const userData = await checkUserAccount(token);
    const response = await getUserProfile(token);
    const { account, ...newObj } = response;
    dispatch(setAccount({ ...newObj, ...response?.account, ...{ accInfo: userData } }));
    dispatch(setAvatar(response?.account?.avatar));
  };

  const createNewAccount = async () => {
    try {
      setLocalLoading(true);
      const data = await signUpUser(firebaseJWT, inviteRFCode);

      if (data.status === 200) {
        toast.success('Signup successfully');
        setUserData(firebaseJWT);
        navigate('/auth/trust-device', { state: { reg: true } });
      }
    } catch (error) {
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
    } finally {
      setLocalLoading(false);
    }
  };

  return (
    <Success
      video={animFail}
      title={'There is no account associated with this number'}
      subtitle={
        'You can create an account or try logging in again if you think there was an error.'
      }
      buttons={
        <>
          <LoadingButton
            disabled={localLoading}
            loading={localLoading}
            onClick={createNewAccount}
            className='el-button orange'
          >
            <span>{localLoading ? 'Processing' : 'Create an account'}</span>
          </LoadingButton>

          <Link disabled={localLoading} to={'/auth/login'} className='el-button'>
            Try to log in again
          </Link>
        </>
      }
    />
  );
};

export default UserNotFound;
