const transformNumbers = (value = 0, number = 3) => {
  const valueToNumber = parseFloat(value?.toString().replace(/,/g, ''));

  if (!isNaN(valueToNumber) && valueToNumber >= 0) {
    const formattedValue = valueToNumber.toLocaleString('en-US', {
      minimumFractionDigits: number,
      maximumFractionDigits: number,
    });
    return formattedValue;
  }
  return '';
};

export default transformNumbers;
