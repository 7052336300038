import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { Link } from 'react-router-dom';
import useBodyClass from '../../hooks/useBodyClass';
import usePictures from '../../hooks/usePictures';
import ResponsiveImage from '../../components/ResponsiveImage';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Container } from '@mui/material';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import useIsTablet from '../../hooks/useIsTablet';
gsap.registerPlugin(ScrollTrigger);

const Referral = () => {
  useBodyClass('p-referral');

  const [isTablet, setIsTablet] = useState(useIsTablet());

  const imgsGlobal = usePictures(
    require.context('../../assets/img/p-home', false, /\.(png|jpe?g|svg|webp)$/)
  );
  const imgs = usePictures(
    require.context('../../assets/img/p-referral', false, /\.(png|jpe?g|svg|webp)$/)
  );

  const phoneImgRef = useRef(null);
  const phoneAnchorRef = useRef(null);
  const screenWidth = window.innerWidth;
  let startValue, fixPhoneImg, endValue;

  if (screenWidth >= 1920) {
    (startValue = '+=20% 40%'), (endValue = () => `+=${phoneImgRef.current.offsetHeight / 5}`);
  } else if (screenWidth >= 1440) {
    (startValue = '+=20% 40%'), (endValue = () => `+=${phoneImgRef.current.offsetHeight / 2.75}`);
  } else if (screenWidth >= 1024) {
    (startValue = '+=0% 30%'), (endValue = () => `+=${phoneImgRef.current.offsetHeight / 2.5}`);
  }

  useEffect(() => {
    if (!isTablet) {
      fixPhoneImg = ScrollTrigger.create({
        trigger: phoneImgRef.current,
        scrub: true,
        start: startValue,
        end: endValue,
        markers: false,
        pin: true,
        toggleActions: 'play reverse none reverse',
      });
    }

    return () => {
      fixPhoneImg && fixPhoneImg.kill();
    };
  }, [isTablet]);

  return (
    <>
      <Header />
      <Container className='p-referral-container' disableGutters>
        <section className='s-hero'>
          <span className='s-hero-bg bg-parent'>
            <ResponsiveImage
              desktop={imgs['s-hero@desktop.jpg']}
              tablet={imgs['s-hero@tablet.jpg']}
              mobile={imgs['s-hero@mobile.jpg']}
              type={'jpg'}
              alt='section hero'
            />
          </span>
          <div className='s-hero-content'>
            <div className='s-hero-title'>
              <h1>
                Get more with Our <span className='orange'>Referral</span> Program!
              </h1>
              <h6 className='s-hero-title-desc'>
                Welcome to the PESA community, where your connections can lead to exciting rewards!
              </h6>
            </div>
            <div className='s-hero-bottom'>
              <div className='links'>
                <Link to={'/auth/signup'} className='links-btn el-button orange'>
                  sign up
                </Link>
                <a
                  href='https://play.google.com/store/apps/details?id=com.pesabase.app&hl=en'
                  target='_blank'
                  className='links-btn links-btn-app'
                  rel='noopener noreferrer'
                >
                  <img src={imgsGlobal['google.png']} alt='' />
                </a>

                <a
                  href='https://apps.apple.com/us/app/pesabase/id1557420579'
                  target='_blank'
                  className='links-btn links-btn-app '
                  rel='noopener noreferrer'
                >
                  <img src={imgsGlobal['apple.png']} alt='' />
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className='s-how'>
          <div className='s-how-left'>
            <h2>How It Works</h2>
            <div className='s-how-left-list'>
              <ul>
                <li>
                  <span className='li-count'>1</span>
                  <div className='li-content'>
                    <h6>Share Your Link</h6>
                    <p className='el-text-m'>
                      Spread the word and share your personalized referral link with friends,
                      family, and colleagues. The more you share, the more you earn!
                    </p>
                  </div>
                </li>
                <li>
                  <span className='li-count'>2</span>
                  <div className='li-content'>
                    <h6>Earn 50 PESA</h6>
                    <p className='el-text-m'>
                      {' '}
                      As soon as someone registers using your link and makes transactions totally
                      100 USD, you'll earn 50 PESA. It's that easy!
                    </p>
                  </div>
                </li>
                <li>
                  <span className='li-count'>3</span>
                  <div className='li-content'>
                    <h6>Watch Your PESA Balance Grow</h6>
                    <p className='el-text-m'>
                      Your earned referral bonuses will be seamlessly added to your PESA balance
                      after the transactions are processed. It's like watching your money grow with
                      every successful referral!
                    </p>
                  </div>
                </li>
                <li ref={phoneAnchorRef}>
                  <span className='li-count'>4</span>
                  <div className='li-content'>
                    <h6>Track Your Success</h6>
                    <p className='el-text-m'>
                      Stay in the loop by checking your Activity history, where you can easily
                      monitor and celebrate each referral bonus you've earned.{' '}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className='s-how-right'>
            <div className='s-how-right-wrap' ref={phoneImgRef}>
              <div className='phone-img'>
                <img src={imgs['phone-refer@2x.png']} alt='' />
              </div>
            </div>
          </div>
        </section>
        <section className='s-why'>
          <div className='s-why-wrap'>
            <h2>Why Refer?</h2>
            <div className='s-why-list'>
              <div className='item'>
                <div className='item-wrap'>
                  <div className='item-img'>
                    <img src={imgs['refer-1.png']} alt='' />
                  </div>
                  <div className='item-bottom'>
                    <h6>Instant Rewards</h6>
                    <p className='el-text-m'>
                      Earn 50 PESA when someone registers using your link and makes 100 USD
                      transactions.
                    </p>
                  </div>
                </div>
              </div>
              <div className='item'>
                <div className='item-wrap'>
                  <div className='item-img'>
                    <img src={imgs['refer-2.png']} alt='' />
                  </div>
                  <div className='item-bottom'>
                    <h6>Easy Tracking</h6>
                    <p className='el-text-m'>
                      Access and track your referral rewards effortlessly with our intuitive
                      Activity history.
                    </p>
                  </div>
                </div>
              </div>
              <div className='item'>
                <div className='item-wrap'>
                  <div className='item-img'>
                    <img src={imgs['refer-3.png']} alt='' />
                  </div>
                  <div className='item-bottom'>
                    <h6>Build Community</h6>
                    <p className='el-text-m'>
                      Share the benefits of PESA with your network and strengthen our growing
                      community.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='s-ready'>
          <div className='s-ready-wrap'>
            <div className='s-ready-content'>
              <div className='s-ready-title'>
                <h2>Ready to turn your connections into rewards?</h2>
                <h6 className='s-ready-title-desc'>
                  Join our Referral Program now and let the PESA perks roll in!
                </h6>
              </div>
              <div className='s-ready-bottom'>
                <div className='links'>
                  <Link to={'/auth/signup'} className='links-btn el-button black'>
                    sign up
                    <br /> on the web
                  </Link>
                  <a
                    href='https://play.google.com/store/apps/details?id=com.pesabase.app&hl=en'
                    target='_blank'
                    className='links-btn links-btn-app'
                    rel='noopener noreferrer'
                  >
                    <img src={imgsGlobal['google.png']} alt='' />
                  </a>

                  <a
                    href='https://apps.apple.com/us/app/pesabase/id1557420579'
                    target='_blank'
                    className='links-btn links-btn-app '
                    rel='noopener noreferrer'
                  >
                    <img src={imgsGlobal['apple.png']} alt='' />
                  </a>
                </div>
                <div className='s-ready-img'>
                  <div className='s-ready-img-block'>
                    <img src={imgs['refer-ready.png']} alt='' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Container>
      <Footer />
    </>
  );
};

export default Referral;
