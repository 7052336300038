import React, { useContext, useState, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectFirebaseJWT } from '../../../redux/features/user/userSlice';
import { allCountries } from 'country-region-data';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { ReactComponent as iconSelect } from '../../../assets/icons/icon-select.svg';
import { TextField } from '@mui/material';
import { getUserProfile } from '../../../api';
import UserContext from '../../../UserContext/UserContext';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

const KycLocation = ({ location, submitStepData, updateStepData }) => {
  const userToken = useSelector(selectFirebaseJWT);
  const { loading, setLoading } = useContext(UserContext);
  const neededCountries = ['AU', 'UG', 'SS', 'KE'];
  const defaultCountries = allCountries.filter((country) => neededCountries.includes(country[1]));
  const [error, setError] = useState(false);

  const [userLocation, setUserLocation] = useState({
    country: '',
    suburb: '',
    city: '',
  });

  const [disabledBtn, setDisabledBtn] = useState(true);
  const [allRegions, setAllRegions] = useState([]);

  const handleCountryChange = (event) => {
    setUserLocation({ ...userLocation, country: event.target.value, city: '', suburb: null });
    const selectedCountryData = defaultCountries.find(
      (country) => country[0].toLocaleLowerCase() === event.target.value
    );
    setAllRegions(selectedCountryData[2]);
  };

  const handleRegionChange = (event) =>
    setUserLocation({ ...userLocation, suburb: event.target.value });

  const checkDisabledBtn = () => {
    if (userLocation?.city?.length > 2) {
      setDisabledBtn(false);
    } else {
      setDisabledBtn(true);
    }
  };

  const onTextChange = (e) => {
    setUserLocation({ ...userLocation, [e.target.name]: e.target.value });
  };

  const submitForm = async (e) => {
    e.preventDefault();

    if (location) {
      updateStepData('location', { location: userLocation });
    } else {
      submitStepData({ location: userLocation });
    }
  };

  useEffect(() => {
    const selectedCountryRegions = defaultCountries.find((country) => {
      return country[0].toLocaleLowerCase() === userLocation?.country?.toLocaleLowerCase();
    });

    const initialRegions = selectedCountryRegions ? selectedCountryRegions[2] : [];
    setAllRegions(initialRegions);
  }, [userLocation.country]);

  useEffect(() => {
    checkDisabledBtn();
  }, [userLocation.city]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { location } = await getUserProfile(userToken);
        if (location) {
          setUserLocation(location);
        }
      } catch (error) {
        setError(error);
        toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <>
      {!loading && !error && (
        <form onSubmit={submitForm}>
          <div className='auth-title-block'>
            <h6 className='el-title-h6'>
              Fill in your location information to complete your profile:
            </h6>
          </div>

          <FormControl className='el-input-select'>
            <InputLabel id='select-label-1'>Select country</InputLabel>
            <Select
              labelId='select-label-1'
              id='select'
              value={userLocation?.country || ''}
              label='Select country'
              onChange={handleCountryChange}
              IconComponent={iconSelect}
            >
              {defaultCountries.map((country) => (
                <MenuItem className='capitalize' key={uuidv4()} value={country[0].toLocaleLowerCase()}>
                  {country[0]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className='el-input-select'>
            <InputLabel id='select-label-2'>Select region</InputLabel>
            <Select
              disabled={!userLocation?.country}
              labelId='select-label-2'
              id='select-region'
              value={userLocation?.suburb || ''}
              label='Select region'
              onChange={handleRegionChange}
              IconComponent={iconSelect}
            >
              {allRegions.length > 0 &&
                allRegions.map((region) => (
                  console.log(region),
                  <MenuItem className='capitalize' key={uuidv4()} value={region[0].toLocaleLowerCase()}>
                    {region[0]}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <TextField
            disabled={!userLocation?.suburb}
            className='el-input'
            label='City'
            type='text'
            name='city'
            variant='outlined'
            onChange={onTextChange}
            value={userLocation?.city || ''}
          />

          <LoadingButton
            disabled={disabledBtn}
            loading={loading}
            type='submit'
            className='el-button orange'
          >
            <span>{loading ? 'Processing' : 'Continue'}</span>
          </LoadingButton>
        </form>
      )}

      {!loading && error && <h6 className='el-title-error'>{error}</h6>}
    </>
  );
};

KycLocation.propTypes = {
  location: PropTypes.bool.isRequired,
  submitStepData: PropTypes.func.isRequired,
};

export default KycLocation;
