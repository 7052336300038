import React, { useEffect, useState } from 'react';
import Steps from '../../components/Steps';
import MPesaReceiver from '../../components/MPesa/MPesaReceiver';
import MPesaAmount from '../../components/MPesa/MPesaAmount';
import MPesaReview from '../../components/MPesa/MPesaReview';
import MPesaSend from '../../components/MPesa/MPesaSend';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useUserRole from '../../utils/useUserRole';
import successVideo from '../../assets/anims/videos/fullscreen/receipt-success-loop.mp4';
import Success from '../../components/Success';
import useHideBtn from '../../utils/useHideBtn';
import { useSelector } from 'react-redux';
import { selectTransferData } from '../../redux/features/user/userSlice';

/**
 * Manages the flow of a multi-step M-Pesa transaction process in a React component.
 * It maintains the current step and data entered by the user, rendering different components based on the current step.
 */
const MPesaFlow = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAgent = useUserRole('agent');
  const linkTransferData = location?.state?.transferData;
  const transferData = useSelector(selectTransferData);
  const [flowData, setFlowData] = useState(linkTransferData || transferData);
  const [steps, setSteps] = useState({
    current: flowData?.userReview?.sendValue ? (location.state?.jumpToNextStep ? 2 : 1) : 0,
    total: [{ name: 'Receiver' }, { name: 'Amount' }, { name: 'Review' }, { name: 'Send' }],
  });
  const { current } = steps;

  useHideBtn(current, 4);

  const changeStep = (step) => {
    if (current === 0 && step === -1) {
      navigate(-1);
    }
    setSteps((prevSteps) => ({ ...prevSteps, current: prevSteps.current + step }));
  };

  const setData = (data) => {
    setFlowData((prevFlowData) => ({ ...prevFlowData, ...data }));
    changeStep(1);
  };

  return (
    <div className='send'>
      <Steps steps={steps} className='steps' />

      {current === 0 && (
        <MPesaReceiver flowData={flowData} setData={setData} changeStep={changeStep} />
      )}

      {current === 1 && (
        <MPesaAmount flowData={flowData} setData={setData} changeStep={changeStep} />
      )}

      {current === 2 && (
        <MPesaReview flowData={flowData} setData={setData} changeStep={changeStep} />
      )}

      {current === 3 && <MPesaSend flowData={flowData} changeStep={changeStep} />}

      {current === 4 && (
        <Success
          video={successVideo}
          title={'Your send request has been placed'}
          subtitle={
            isAgent
              ? 'You can check the details in <a class="el-button-link" href="/agent/activity"><span>Activity</span></a>'
              : 'You can check the details in<a class="el-button-link" href="/user/transactions"><span>Activity</span></a>'
          }
          buttons={
            <Link
              className='el-button orange'
              state={{
                transferData: flowData,
              }}
              onClick={() => {
                changeStep(-3);
              }}
            >
              Send more
            </Link>
          }
        />
      )}
    </div>
  );
};

export default MPesaFlow;
