import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Success from '../../../components/Success';
import Steps from '../../../components/Steps';
import {
  selectFirebaseJWT,
  selectJwtToken,
  selectTransferData,
} from '../../../redux/features/user/userSlice';
import { useSelector } from 'react-redux';
import successVideo from '../../../assets/anims/videos/fullscreen/withdraw_success_ff.mp4';
import errorVideo from '../../../assets/anims/videos/fullscreen/withdraw_fail_ff.mp4';
import { toast } from 'react-toastify';
import WithdrawReceiver from '../../components/Withdraw/WithdrawReceiver';
import WithdrawAmount from '../../components/Withdraw/WithdrawAmount';
import WithdrawReview from '../../components/Withdraw/WithdrawReview';
import WithdrawOTP from '../../components/Withdraw/WithdrawOTP';
import { withdrawCurrency } from '../../../api';
import useHideBtn from '../../../utils/useHideBtn';

const WithdrawFlow = () => {
  const location = useLocation();
  const userToken = useSelector(selectFirebaseJWT);
  const userDeviceToken = useSelector(selectJwtToken);
  const transferData = useSelector(selectTransferData);
  const linkTransferData = location?.state?.transferData;
  const [localLoading, setLocalLoading] = useState(false);
  const [error, setError] = useState(false);

  const [flowData, setTransferData] = useState(
    linkTransferData || transferData || { paymentSystem: 'pesabase-withdraw' }
  );

  const [steps, setSteps] = useState({
    current: flowData?.userReview?.sendValue ? (location.state?.jumpToNextStep ? 2 : 1) : 0,
    total: [{ name: 'Receiver' }, { name: 'Amount' }, { name: 'Review' }, { name: 'Withdraw' }],
  });

  const { current } = steps;

  const changeStep = (step) => setSteps({ ...steps, current: current + step });

  const setData = (data) => {
    setTransferData({ ...flowData, ...data });
    changeStep(1);
  };

  const submitSend = async (otp) => {
    try {
      setLocalLoading(true);
      const { userReceiver, userReview } = flowData;

      const data = {
        amount: userReview.sendValue,
        wallet: userReceiver?.address,
        channel: 'CRYPTO',
        comment: userReview.comment,
        code: otp,
      };

      const response = await withdrawCurrency(
        data,
        userToken,
        userDeviceToken,
        userReview.currency
      );

      if (response.status === 200) {
        changeStep(1);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      setError(true);
    } finally {
      setLocalLoading(false);
    }
  };

  useHideBtn(current, 4);

  return (
    <div className='send'>
      <Steps steps={steps} />

      {current === 0 && (
        <WithdrawReceiver changeStep={changeStep} setData={setData} flowData={flowData} />
      )}

      {current === 1 && (
        <WithdrawAmount changeStep={changeStep} flowData={flowData} setData={setData} />
      )}

      {current === 2 && (
        <WithdrawReview changeStep={changeStep} flowData={flowData} userToken={userToken} />
      )}

      {current === 3 && (
        <WithdrawOTP
          loading={localLoading}
          changeStep={changeStep}
          flowData={flowData}
          submitSend={submitSend}
          flow={true}
          error={error}
          setError={setError}
        />
      )}

      {current === 4 && (
        <Success
          error={error}
          video={error ? errorVideo : successVideo}
          title={'Your withdrawal request has been placed'}
          subtitle={
            'You can check the details in <a class="el-button-link" href="/user/transactions"><span>Activity</span></a>'
          }
          buttons={
            <>
              <Link
                className='el-button orange'
                to='/user/withdraw'
                state={{
                  transferData: flowData,
                }}
                onClick={() => changeStep(-3)}
              >
                Send more
              </Link>
            </>
          }
        />
      )}
    </div>
  );
};

export default WithdrawFlow;
