import React, { Fragment } from 'react';
import useBodyClass from '../../hooks/useBodyClass';
import { Container } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Icon from '../../components/Icon';
import Grid from '@mui/material/Unstable_Grid2';
import useSWR from 'swr';
import { getPostBySlug } from '../../client';
import SanityBlockContent from '@sanity/block-content-to-react';
import RecentPosts from '../../components/RecentPosts/RecentPosts';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Loader, Center } from '@mantine/core';
import { Helmet } from 'react-helmet-async';

const PostPage = () => {
  useBodyClass('p-post');
  const navigate = useNavigate();

  // getting the slug identifier
  const { slug } = useParams();
  console.log('slug', slug);
  // fetching post
  const { data, isLoading } = useSWR(['post', slug], () => getPostBySlug(slug));
  React.useEffect(() => {
    if (slug === 'investing-in-south-sudan-opportunities-in-a-developing-economy') {
      document.title = 'Investing in South Sudan: Explore New Opportunities';

      //     const metaDescription = document.createElement('meta');
      //     metaDescription.name = 'description';
      //     metaDescription.content = 'This is an awesome React app2'
      //     document.head.appendChild(metaDescription)
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          'content',
          'Investing in South Sudan offers unique opportunities. Discover the key sectors and insights that can help you make informed decisions.'
        );
      document
        .querySelector('meta[name="title"]')
        .setAttribute('content', 'Investing in South Sudan: Explore New Opportunities');
    }
  }, [slug]);

  return (
    <Fragment>
      <Header />
      {isLoading ? (
        <Center>
          <Loader color='orange' style={{ marginTop: 200 }} />
        </Center>
      ) : (
        <>
          <section className='s-post'>
            <Container disableGutters>
              <div className='s-post-wrap'>
                <aside className='s-post-aside'>
                  <div className='s-post-aside-top-image'>
                    <img src={data[0]?.mainImage?.asset?.url} alt='' />
                  </div>

                  <div className='s-post-aside-top'>
                    <div className='s-post-aside-bottom'>
                      <Link className='s-post-aside-top-back' onClick={() => navigate(-1)}>
                        <Icon name='arrow-slider' />
                        back to blog
                      </Link>

                      <div className='bottom-row'>
                        <span className='bottom--title'>Follow us:</span>

                        <ul>
                          <li>
                            <a href='google.com' rel='noreferrer noopener' target={'_blank'}>
                              <Icon name='facebook' />
                            </a>
                          </li>

                          <li>
                            <a href='google.com' rel='noreferrer noopener' target={'_blank'}>
                              <Icon name='twitter' />
                            </a>
                          </li>

                          <li>
                            <a href='google.com' rel='noreferrer noopener' target={'_blank'}>
                              <Icon name='linkedin' />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </aside>

                <article className='s-post-article'>
                  <h3>{data[0]?.title}</h3>

                  <span className='s-post-article--date'>{`${data[0]?.authorName} • ${new Date(
                    data[0]?._createdAt
                  ).toLocaleDateString()}`}</span>
                  <div>
                    <SanityBlockContent
                      blocks={data[0]?.body}
                      projectId='286u5fwe'
                      dataset='production'
                    />
                  </div>
                  <div className='bottom-row'>
                    <span className='bottom--title'>Follow us:</span>

                    <ul>
                      <li>
                        <a href='google.com' rel='noreferrer noopener' target={'_blank'}>
                          <Icon name='facebook' />
                        </a>
                      </li>

                      <li>
                        <a href='google.com' rel='noreferrer noopener' target={'_blank'}>
                          <Icon name='twitter' />
                        </a>
                      </li>

                      <li>
                        <a href='google.com' rel='noreferrer noopener' target={'_blank'}>
                          <Icon name='linkedin' />
                        </a>
                      </li>
                    </ul>
                  </div>
                </article>
              </div>
            </Container>
          </section>

          <section className='s-recent'>
            <Container disableGutters>
              <h2>Recent posts</h2>

              <Grid
                container
                className='s-recent-list'
                columnSpacing={{ mobile: 2.5 }}
                rowSpacing={{ mobile: 3, tablet: 5 }}
              >
                <RecentPosts />
              </Grid>
            </Container>
          </section>
        </>
      )}
      <Footer />
    </Fragment>
  );
};

export default PostPage;
