import React, { useContext, useState } from 'react';
import ArrowBack from '../../../components/ArrowBack';
import VerifyOption from '../../../components/VerifyOption';
import iconAgent from '../../../assets/icons/icon-agent.png';
import iconPool from '../../../assets/icons/icon-pool.png';
import { toast } from 'react-toastify';
import { addRemitToPool } from '../../../api';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetCurrentRemit,
  selectOrder,
  setRemitDetails,
  setRemitReceiver,
  setRemitSender,
  updatecurrentOrder,
} from '../../../redux/features/transaction/remittanceSlice';
import { selectFirebaseJWT, selectJwtToken } from '../../../redux/features/user/userSlice';
import UserContext from '../../../UserContext/UserContext';
import { useNavigate } from 'react-router-dom';
import Popup from '../../../components/Popup';
import { Button } from '@mui/material';
import ReactPlayer from 'react-player';
import Success from '../../../components/Success';
import sendSuccessVideo from '../../../assets/anims/videos/fullscreen/send_success_ff.mp4';
import sendFailVideo from '../../../assets/anims/videos/fullscreen/send_fail_ff.mp4';
import findOrderById from '../../../utils/findOrderById';
import { selectOrders } from '../../../redux/features/sockets/socketslice';
import sendWarnVideo from '../../../assets/anims/videos/popups/send_warn_ff.mp4';

const AgentReassign = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const order = useSelector(selectOrder);
  const token = useSelector(selectFirebaseJWT);
  const deviceJwt = useSelector(selectJwtToken);
  const { setLoading } = useContext(UserContext);
  const { pool, incoming, outgoing } = useSelector(selectOrders);
  const [reassignState, setReassignState] = useState({
    dialog: false,
    error: false,
    successScreen: false,
  });

  const { dialog, error, successScreen } = reassignState;

  /**
   * Handles the opening and closing of the dialog.
   * This function toggles the 'dialog' state in the 'reassignState' object.
   *
   * @returns {void}
   */
  const handleDialog = () => {
    setReassignState((prev) => ({ ...prev, dialog: !prev.dialog }));
  };

  /**
   * Function to add the transaction to the pool.
   * This function sends a request to the server to add the transaction to the pool.
   * It handles the loading state, success and error scenarios.
   *
   * @returns {void}
   */
  const addToPool = async () => {
    try {
      // Set loading state to true
      setLoading(true);

      // Send a request to the server to add the transaction to the pool
      const response = await addRemitToPool(order.txId, token, deviceJwt);

      // Check the response status
      if (response.status === 200) {
        // If the transaction is added to the pool successfully, update the state
        setReassignState((prev) => ({ ...prev, successScreen: true }));
      } else {
        // If the transaction is not added to the pool, throw an error
        throw new Error(response.message);
      }
    } catch (error) {
      // Log the error to the console
      console.log(error);

      // Display an error toast message
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
    } finally {
      // Set loading state to false, regardless of the outcome
      setLoading(false);
    }
  };

  const navigateToTheChat = () => {
    const currentOrder = findOrderById({ pool, incoming, outgoing }, order.txId);

    if (currentOrder) {
      dispatch(updatecurrentOrder(currentOrder));
      dispatch(resetCurrentRemit());
      navigate(`/agent/chat`);
    }
  };

  return (
    <>
      {!successScreen && (
        <>
          <ArrowBack handle={() => navigate(-1)} />

          <div className='payment-title'>
            <h6 className='el-title-h6 med'>Choose the destination of the transaction</h6>

            <span className='el-text-m payment-title--subtitle'>
              Select the way the transaction will be processed
            </span>
          </div>

          <div className='send-step-content'>
            <div className='c-option-verify'>
              <div onClick={() => navigate('/agent/chat/reassign/search-agent')}>
                <VerifyOption
                  image={iconAgent}
                  title={'Send to a specific agent'}
                  subtitle={
                    'You choose the agent to receive the transaction. They can confirm or decline your request.'
                  }
                />
              </div>

              <div onClick={addToPool}>
                <VerifyOption
                  image={iconPool}
                  title={'Add to transactions pool'}
                  subtitle={
                    'Other agents will be able to choose and process your transaction if they’re available.'
                  }
                />
              </div>
            </div>
          </div>

          <div className='send-btns'>
            <div className='send-btns-cancel'>
              <span className='el-link-m el-button-link' variant='contained' onClick={handleDialog}>
                Cancel
              </span>
            </div>
          </div>
        </>
      )}

      {successScreen && (
        <Success
          error={error}
          video={error ? sendFailVideo : sendSuccessVideo}
          title={error ? 'Error' : 'Your send request has been added to pool'}
          subtitle={
            error
              ? 'Error'
              : `You can find the transaction in <a class="el-button-link" href="/agent/outgoing-orders"><span>Outgoing orders</span></a>`
          }
          buttons={
            error ? (
              <Link to={'/agent/dashboard'} className='el-button orange'>
                Go to Dashboard
              </Link>
            ) : (
              <Button onClick={navigateToTheChat} className='el-button orange'>
                View transaction details
              </Button>
            )
          }
        />
      )}

      <Popup open={dialog} togglePopUp={handleDialog}>
        <div className='modal-content'>
          <div className='modal-img'>
            {/* <img src={imgs['send-cancel.png']} alt='' /> */}
            <ReactPlayer
              className='player'
              controls={false}
              playing={true}
              loop={true}
              muted={true}
              playsinline={true}
              url={sendWarnVideo}
              width='100%'
              height='100%'
            />
          </div>
          <div className='modal-desc'>
            <div className='modal-desc-title'>Are you sure want to cancel destination change?</div>

            <div className='modal-desc-text'>
              Please be aware that all entered data will be lost.
            </div>
          </div>

          <div className='modal-btns'>
            <Button onClick={() => navigate(-1)} className='el-button'>
              Yes, I want to cancel
            </Button>

            <Button className='el-button orange' onClick={handleDialog}>
              No, I want to continue
            </Button>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default AgentReassign;
