import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import iconStar from '../../assets/icons/icon-star.svg';
import useUserRole from '../../utils/useUserRole';
import Avatar from '../Avatar';

const DashboardContact = ({ contact }) => {
  const userRole = useUserRole();

  return (
    <Link
      className='contact'
      to={`/${userRole}/send/system/${userRole}`}
      state={{
        transferData: {
          paymentSystem: 'pesabase',
          userReceiver: contact,
        },
      }}
    >
      {contact?.favourite && (
        <span className='contact-icon'>
          <img src={iconStar} alt='star icon' />
        </span>
      )}

      <div className='contact-avatar'>
        <Avatar data={contact} />
      </div>

      {contact?.name && contact.name !== ' ' && (
        <span className='contact-name'>{contact.name}</span>
      )}

      {!contact?.name ||
        (contact.name === ' ' && <span className='contact-name'>Pesabase user</span>)}
    </Link>
  );
};

DashboardContact.propTypes = {
  contact: PropTypes.object.isRequired,
};

export default DashboardContact;
