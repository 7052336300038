const findOrderById = (orders, txId) => {
  for (const category of ['pool', 'incoming', 'outgoing']) {
    const data = orders[category] || {};
    for (const [key, value] of Object.entries(data)) {
      const foundOrder = value.find(order => order.txId === txId);
      if (foundOrder) {
        return foundOrder;
      }
    }
  }
  return null;
};

export default findOrderById;