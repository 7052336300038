import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import useDebounce from '../../hooks/useDebounce';
import { getAgentsSearch, getContactsSearch, getTXlist, getUsers } from '../../api';
import { toast } from 'react-toastify';

const SearchField = ({
  className,
  token,
  onSearch,
  onClear,
  onSearchTabChange,
  placeholderText,
  flow = false,
  tx = false,
  period = 3,
  toggleLoading,
  agents,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [prevDebouncedSearchValue, setPrevDebouncedSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 700);

  const handleSearchChange = (event) => {
    toggleLoading(true);
    const value = event.target.value;

    if (value.trim() === '' || value === '') {
      handleClearClick();
    }

    setSearchValue(value);
    onSearchTabChange && onSearchTabChange('all');
  };

  const handleClearClick = () => {
    setSearchValue('');
    onClear();
  };

  useEffect(() => {
    const searchContacts = async () => {
      if (searchValue.trim() !== '' && prevDebouncedSearchValue !== debouncedSearchValue) {
        try {
          let response;

          if (flow) {
            response = await getUsers(token, 1, 5, debouncedSearchValue);
          } else if (tx) {
            response = await getTXlist(token, debouncedSearchValue, period);
          } else if (agents) {
            response = await getAgentsSearch(token, debouncedSearchValue);
          } else {
            response = await getContactsSearch(token, 1, 5, 'all', debouncedSearchValue);
          }

          onSearch(response, debouncedSearchValue);
          toggleLoading(false);
        } catch (error) {
          toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
          console.error('searchContacts', error);
        }
      }
    };

    searchContacts();
  }, [
    debouncedSearchValue,
    onSearch,
    prevDebouncedSearchValue,
    flow,
    token,
    tx,
    period,
    getContactsSearch,
    toggleLoading,
  ]);

  useEffect(() => {
    setPrevDebouncedSearchValue(debouncedSearchValue);
  }, [debouncedSearchValue]);

  return (
    <div className={className}>
      <TextField
        placeholder={placeholderText || 'Search...'}
        variant='outlined'
        value={searchValue}
        onChange={handleSearchChange}
        fullWidth
        className='el-input-search'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
              {searchValue && (
                <ClearIcon onClick={handleClearClick} style={{ cursor: 'pointer' }} />
              )}
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

SearchField.propTypes = {
  className: PropTypes.string,
  token: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  toggleLoading: PropTypes.func.isRequired,
  placeholderText: PropTypes.string,
  onClear: PropTypes.func.isRequired,
  onSearchTabChange: PropTypes.func,
  flow: PropTypes.bool,
  tx: PropTypes.bool,
  period: PropTypes.any,
};

export default SearchField;
