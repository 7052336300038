import React from 'react';
import Success from '../../../components/Success';
// import animVideo from '../../../assets/anims/videos/fullscreen/account-success-loop.mp4';
import sendSuccessVideo from '../../../assets/anims/videos/fullscreen/send_success_ff.mp4';
import { Link } from 'react-router-dom';

const AgentRemitComplete = () => {
  return (
    <Success
      error={false}
      video={sendSuccessVideo}
      title={'Transaction was completed'}
      subtitle={`You can find it in <a class="el-button-link" href="/agent/activity"><span>Activity history</span></a>`}
      buttons={
        <Link to={'/agent/dashboard'} className='el-button orange'>
          Go to Dashboard
        </Link>
      }
    />
  );
};

export default AgentRemitComplete;
