import { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';

import { auth } from '../../firebase';
import { setFirebaseJWT } from '../../redux/features/user/userSlice';

const useAuthentication = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribeFromAuthStatusChanged = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        let token = await currentUser.getIdToken(true);
        setFirebaseJWT(token);
        setUser(currentUser);
        
      } else {
        setFirebaseJWT('');
      }
    });

    return () => {
      unsubscribeFromAuthStatusChanged();
    };
  }, []);

  return user;
};

export default useAuthentication;
