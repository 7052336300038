import React, { useContext, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { LoadingButton } from '@mui/lab';
import closeFlow from '../../utils/closeFlow';
import { useSelector } from 'react-redux';
import { selectFirebaseJWT } from '../../redux/features/user/userSlice';
import { deleteAccount } from '../../api';
import { toast } from 'react-toastify';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import Success from '../../components/Success';
import animVideo from '../../assets/anims/videos/fullscreen/account-success-loop.mp4';
import { Link } from 'react-router-dom';
import UserContext from '../../UserContext/UserContext';

const AccountDelete = () => {
  const userToken = useSelector(selectFirebaseJWT);
  const { logout } = useContext(UserContext);
  const [deleteACC, setDeleteACC] = useState({
    localLoading: false,
    reason: '',
    showTextArea: false,
    success: false,
  });

  const { localLoading, reason, showTextArea, success } = deleteACC;

  const setReason = (e) => {
    const isTextArea = e.target.nodeName === 'TEXTAREA';

    setDeleteACC((prevState) => ({
      ...prevState,
      reason: e.target.value,
      showTextArea: isTextArea,
    }));
  };

  const submitForm = async (e) => {
    e.preventDefault();

    try {
      setDeleteACC((prevState) => ({
        ...prevState,
        localLoading: true,
      }));

      const response = await deleteAccount(userToken, reason);
      console.log('deleteAccount', response);

      if (response.status === 200) {
        setDeleteACC({ success: true });
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.log('deleteAccount', error);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
    } finally {
      setDeleteACC((prevState) => ({
        ...prevState,
        localLoading: false,
      }));
    }
  };

  return (
    <>
      {!success && (
        <form className='account-edits' onSubmit={submitForm}>
          <h6 className='el-title-h6 med'>Before you go, tell us why you’re leaving:</h6>

          <div className='account-edits-variants'>
            <FormControl>
              <RadioGroup defaultValue='female' name='radio-buttons-group'>
                <FormControlLabel
                  className='label'
                  value='Fees are too high'
                  control={<Radio />}
                  label='Fees are too high'
                  onClick={setReason}
                />

                <FormControlLabel
                  className='label'
                  value='Problems with my transfers'
                  control={<Radio />}
                  label='Problems with my transfers'
                  onClick={setReason}
                />

                <FormControlLabel
                  className='label'
                  value='I’m not using my account anymore'
                  control={<Radio />}
                  label='I’m not using my account anymore'
                  onClick={setReason}
                />

                <FormControlLabel
                  className='label'
                  value={'other'}
                  control={<Radio />}
                  label='Other reasons'
                  onClick={() =>
                    setDeleteACC((prevState) => ({ ...prevState, showTextArea: true, reason: '' }))
                  }
                />
              </RadioGroup>

              {showTextArea && (
                <BaseTextareaAutosize
                  onChange={setReason}
                  minRows={3}
                  maxRows={6}
                  placeholder='Please share with us the reasons for your leave so that we can improve the service'
                />
              )}
            </FormControl>
          </div>

          <div className='account-edits-form-btns'>
            <LoadingButton
              disabled={reason?.length < 6}
              loading={localLoading}
              type='submit'
              className='el-button orange'
            >
              <span>{localLoading ? 'Processing' : 'Delete account'}</span>
            </LoadingButton>

            <span className='el-link-m' onClick={closeFlow}>
              Cancel
            </span>
          </div>
        </form>
      )}

      {success && (
        <Success
          video={animVideo}
          title={'Your request to delete account has been sent'}
          subtitle={'Sorry to see you go'}
          buttons={
            <Link onClick={logout} className='el-button orange'>
              Go to Homepage
            </Link>
          }
        />
      )}
    </>
  );
};

export default AccountDelete;
