import { configureStore } from '@reduxjs/toolkit';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import logger from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import { thunk } from 'redux-thunk';

import rootReducer from './reducers';
import socketMiddleware from './socketMiddleware';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['socket'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

let middleware = (getDefaultMiddleware) =>
  getDefaultMiddleware({
    thunk,
    serializableCheck: {
      ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat([socketMiddleware, logger]);

if (!process.env.NODE_ENV === 'development') {
  middleware = middleware.filter((m) => m !== logger);
}

export const store = configureStore({
  reducer: persistedReducer,
  middleware,
});

export const persistor = persistStore(store);
