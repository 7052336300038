import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import UserContext from '../../UserContext/UserContext';
import Popup from '../../components/Popup';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAccount,
  selectAvatar,
  selectFirebaseJWT,
  setAvatar,
} from '../../redux/features/user/userSlice';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { deleteAvatar, getUserProfile, updateAvatar } from '../../api';
import copyToclipboard from '../../utils/copyToclipboard';
import getInitials from '../../utils/getInitials';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2';
import AvatarEditor from 'react-avatar-editor';
import Slider from '@mui/material/Slider';
import { ReactComponent as ScaleMin } from '../../assets/icons/icon_scale_min.svg';
import { ReactComponent as ScaleMax } from '../../assets/icons/icon_scale_max.svg';
import { ReactComponent as Rotate } from '../../assets/icons/icon_rotate.svg';
import { LoadingButton } from '@mui/lab';
import editImg from '../../assets/anims/videos/popups/account-edit-loop.mp4';
import ReactPlayer from 'react-player';
import { allCountries } from 'country-region-data';
import useUserRole from '../../utils/useUserRole';
import transformString from '../../utils/transformString';

const Account = () => {
  const dispatch = useDispatch();
  const addressRef = useRef(null);
  const userToken = useSelector(selectFirebaseJWT);
  const { loading } = useContext(UserContext);
  const userProfile = useSelector(selectAccount);
  const userRole = useUserRole();
  let editor = '';

  const currentCountry = allCountries.filter(
    (country) => transformString(country[0]) === transformString(userProfile?.location?.country)
  );

  const currentSuburb =
    currentCountry?.[2]?.filter(
      (suburb) => transformString(suburb)[0] === transformString(response.location.suburb)
    )[0][0] || null;

  const [userData, setUserData] = useState({
    localLoading: false,
    account: userProfile,
    location: {
      country: userProfile?.location?.country || null,
      suburb: currentSuburb || null,
      city: userProfile?.location?.city || null,
    },
    personalInfo: userProfile?.personalInfo,
    error: false,
    deleteReason: '',
    phoneNumber: userProfile?.phoneNumber,
    avatarModal: false,
    closeFlowModal: false,
    ava: useSelector(selectAvatar) || null,
    avatarEditor: {
      image: null,
      scale: 1,
      rotate: 0,
    },
  });

  const {
    localLoading,
    account,
    location,
    personalInfo,
    error,
    phoneNumber,
    avatarModal,
    closeFlowModal,
    ava,
    avatarEditor,
  } = userData;

  const setEditorRef = (ed) => (editor = ed);

  const updateState = (name, value) => {
    setUserData((prevState) => {
      if (name === 'avatarModal' && value === false) {
        return {
          ...prevState,
          avatarModal: false,
          avatarEditor: {
            image: null,
            scale: 1,
            rotate: 0,
          },
        };
      }

      return { ...prevState, [name]: value };
    });
  };

  const handleUpload = async (base64String) => {
    try {
      updateState('localLoading', true);
      const response = await updateAvatar({ image: `${base64String}` }, userToken);

      if (response.status === 200) {
        const { account } = await getUserProfile(userToken);

        if (account?.avatar) {
          dispatch(setAvatar(account.avatar));
          setUserData((prevState) => ({
            ...prevState,
            ava: account.avatar,
            avatarModal: false,
            avatarEditor: {
              image: null,
              scale: 1,
              rotate: 0,
            },
          }));
        }
      } else {
        throw new Error(response.message);
      }
      toast.success('Avatar has been updated successfully');
    } catch (error) {
      console.log(error);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
    } finally {
      updateState('localLoading', false);
    }
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64String = e.target.result;

          setUserData((prevState) => ({
            ...prevState,
            avatarEditor: {
              ...prevState.avatarEditor,
              image: base64String,
            },
          }));
        };
        reader.readAsDataURL(file);
      }
    },
    [handleUpload]
  );

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    maxSize: 5242880,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg', '.webp', '.svg', '.png'],
    },
    onDrop,
  });

  const transformImage = (e, rotate) => {
    setUserData((prevState) => {
      const newScale =
        (typeof e === 'number' && 1 <= prevState.avatarEditor.scale + e <= 3
          ? prevState.avatarEditor.scale + e
          : e?.target?.value) || prevState.avatarEditor.scale;

      return {
        ...prevState,
        avatarEditor: {
          ...prevState.avatarEditor,
          rotate: prevState.avatarEditor.rotate + rotate,
          scale: Math.min(3, Math.max(1, newScale)),
        },
      };
    });
  };

  const cropImage = () => {
    if (setEditorRef) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      handleUpload(croppedImg);
    }
  };

  const removeAvatar = async () => {
    try {
      updateState('localLoading', true);
      const response = await deleteAvatar(userToken);

      if (response?.status === 200) {
        toast.success('The avatar has been successfully removed');
        dispatch(setAvatar(null));
        setUserData((prevState) => ({
          ...prevState,
          ava: null,
          avatarModal: false,
          avatarEditor: {
            image: null,
            scale: 1,
            rotate: 0,
          },
        }));
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      console.log(error);
    } finally {
      updateState('localLoading', false);
    }
  };

  useEffect(() => {
    const error = fileRejections[0]?.errors[0];
    if (error) {
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
    }
  }, [fileRejections]);

  return (
    <>
      {userData.account && !loading && !error && (
        <div className='acc'>
          <div className='acc-avatar'>
            <div className='acc-avatar-wrap'>
              <div className='acc-avatar-item'>
                <div className='acc-avatar-block'>
                  <Avatar className={`acc-avatar-icon ${ava ? '' : 'empty'}`}>
                    {ava ? (
                      <img src={ava} alt='' />
                    ) : personalInfo?.firstName && personalInfo?.lastName ? (
                      getInitials(`${personalInfo?.firstName} ${personalInfo?.lastName}`)
                    ) : (
                      ''
                    )}
                  </Avatar>
                </div>
                <div className='acc-avatar-btn'>
                  <Button
                    className='el-button-link'
                    variant='contained'
                    onClick={() => updateState('avatarModal', true)}
                  >
                    <span className='el-link-m semi acc-avatar-btn-link'>Change avatar</span>
                  </Button>

                  <Popup
                    open={avatarModal}
                    className='avatar-modal'
                    togglePopUp={() =>
                      avatarEditor.image
                        ? updateState('closeFlowModal', true)
                        : updateState('avatarModal', !avatarModal)
                    }
                  >
                    <form className='modal-content'>
                      <input {...getInputProps()} />

                      <h6 className='med'>Avatar photo change</h6>

                      {!avatarEditor.image && (
                        <div className='modal-content-btns'>
                          <Button className='el-button orange' {...getRootProps()}>
                            Upload a photo
                          </Button>

                          {ava && (
                            <LoadingButton
                              loading={localLoading}
                              onClick={removeAvatar}
                              className='el-button'
                            >
                              <span>{localLoading ? 'Processing' : 'Remove current photo'}</span>
                            </LoadingButton>
                          )}

                          <span
                            className='el-link-m'
                            onClick={() =>
                              avatarEditor.image
                                ? updateState('closeFlowModal', true)
                                : updateState('avatarModal', !avatarModal)
                            }
                          >
                            Cancel
                          </span>
                        </div>
                      )}

                      {avatarEditor.image && (
                        <>
                          <div className='modal-content-upload'>
                            <div className='editor'>
                              <AvatarEditor
                                onError={(error) => console.log(error)}
                                ref={setEditorRef}
                                width={200}
                                height={200}
                                borderRadius={100}
                                border={0}
                                scale={avatarEditor.scale}
                                rotate={avatarEditor.rotate}
                                image={avatarEditor.image}
                              />
                            </div>

                            <div className='slider'>
                              <div className='slider-box'>
                                <span
                                  className='slider-box-scale'
                                  onClick={() => transformImage(-0.1, 0)}
                                >
                                  <ScaleMin />
                                </span>

                                <Slider
                                  aria-label='Scale'
                                  min={1}
                                  max={3}
                                  step={0.05}
                                  value={avatarEditor.scale}
                                  onChange={(value) => transformImage(value, null)}
                                />

                                <span
                                  className='slider-box-scale'
                                  onClick={() => transformImage(0.1, 0)}
                                >
                                  <ScaleMax />
                                </span>
                              </div>

                              <span
                                className='slider-box-scale rotate'
                                onClick={() => transformImage(null, 90)}
                              >
                                <Rotate />
                              </span>
                            </div>
                          </div>

                          <div className='modal-content-btns'>
                            <LoadingButton
                              loading={localLoading}
                              className='el-button orange'
                              onClick={() => cropImage()}
                            >
                              <span>{loading ? 'Processing' : 'Apply and save'}</span>
                            </LoadingButton>

                            <span
                              className='el-link-m'
                              onClick={() => updateState('closeFlowModal', true)}
                            >
                              Cancel
                            </span>
                          </div>
                        </>
                      )}
                    </form>
                  </Popup>

                  <Popup
                    open={closeFlowModal}
                    togglePopUp={() => updateState('closeFlowModal', !closeFlowModal)}
                  >
                    <div className='modal-content'>
                      <div className='modal-img'>
                        <ReactPlayer
                          className='player'
                          controls={false}
                          playing={true}
                          loop={true}
                          muted={true}
                          playsinline={true}
                          url={editImg}
                          width='100%'
                          height='100%'
                        />
                      </div>

                      <div className='modal-desc'>
                        <div className='modal-desc-title'>
                          Are you sure you want to discard your changes?
                        </div>

                        <div className='el-text-m modal-desc-text'>
                          Please be aware that all changes will be lost.
                        </div>
                      </div>

                      <div className='modal-btns'>
                        <Button
                          className='el-button el-button-btn'
                          variant='contained'
                          onClick={() => {
                            updateState('closeFlowModal', false);
                            updateState('avatarModal', false);
                          }}
                        >
                          Yes, I want to cancel
                        </Button>
                        <Button
                          className='el-button orange el-button-continue'
                          variant='contained'
                          onClick={() => {
                            updateState('closeFlowModal', false);
                          }}
                        >
                          No, I want to continue
                        </Button>
                      </div>
                    </div>
                  </Popup>
                </div>
              </div>

              <div className='acc-avatar-copy'>
                <div className='acc-avatar-copy--title el-text-m semi'>Crypto wallet address:</div>
                <div className='acc-avatar-copy-block'>
                  {account?.address && (
                    <div className='acc-avatar-copy-ref' ref={addressRef}>
                      {account.address}
                    </div>
                  )}
                  <Button
                    className='acc-avatar-copy-btn el-button orange'
                    variant='contained'
                    onClick={() => copyToclipboard(account.address)}
                  >
                    Copy
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className='acc-block acc-profile'>
            <div className='acc-block-wrap'>
              <div className='acc-block-top'>
                <h6 className='acc-block-title el-title-h6 med'>Profile:</h6>
              </div>

              <div className='acc-block-content'>
                {personalInfo?.email && (
                  <div className='acc-block-item'>
                    <div className='acc-block-label el-text-s'>Email</div>
                    <div className='acc-block-text el-text-m'>{personalInfo.email}</div>
                  </div>
                )}
                <div className='acc-block-item'>
                  <div className='acc-block-label el-text-s'>Phone number</div>
                  <div className='acc-block-text el-text-m'>
                    {account.phoneNumber && (
                      <PhoneInput value={phoneNumber} disabled disableDropdown />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='acc-block acc-info'>
            <div className='acc-block-wrap'>
              <div className='acc-block-top'>
                <h6 className='acc-block-title el-title-h6 med'>Personal information:</h6>

                <div className='acc-block-edit'>
                  <Link
                    to={`/${userRole}/account/edits/information`}
                    className='el-button el-button-edit'
                    variant='contained'
                  >
                    <span className='el-button-icon'>
                      <svg
                        width='100%'
                        height='100%'
                        viewBox='0 0 28 28'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M5.79809 22.172H7.40675L17.3173 12.2615L15.7086 10.6528L5.79809 20.5633V22.172ZM22.2294 10.5954L17.346 5.76936L18.9547 4.1607C19.3951 3.72023 19.9363 3.5 20.5783 3.5C21.2202 3.5 21.761 3.72023 22.2007 4.1607L23.8094 5.76936C24.2498 6.20983 24.4797 6.74146 24.4988 7.36424C24.518 7.98702 24.3073 8.51826 23.8668 8.95797L22.2294 10.5954ZM20.5633 12.2902L8.38344 24.4701H3.5V19.5866L15.6799 7.40675L20.5633 12.2902ZM16.5129 11.4571L15.7086 10.6528L17.3173 12.2615L16.5129 11.4571Z' />
                      </svg>
                    </span>
                    <span>Edit details</span>
                  </Link>
                </div>
              </div>
              {personalInfo?.firstName ||
              personalInfo?.middleName ||
              personalInfo?.lastName ||
              personalInfo?.gender ||
              personalInfo?.dateOfBirth ? (
                <>
                  <div className='acc-block-content'>
                    {personalInfo?.firstName && (
                      <div className='acc-block-item'>
                        <div className='acc-block-label el-text-s'>First name</div>
                        <div className='acc-block-text el-text-m name'>
                          {' '}
                          {personalInfo.firstName}
                        </div>
                      </div>
                    )}

                    {personalInfo?.middleName && (
                      <div className='acc-block-item'>
                        <div className='acc-block-label el-text-s'>Middle name</div>
                        <div className='acc-block-text el-text-m name'>
                          {' '}
                          {personalInfo.middleName}{' '}
                        </div>
                      </div>
                    )}

                    {personalInfo?.lastName && (
                      <div className='acc-block-item'>
                        <div className='acc-block-label el-text-s'>Last name</div>
                        <div className='acc-block-text el-text-m name'>
                          {' '}
                          {personalInfo.lastName}
                        </div>
                      </div>
                    )}

                    {personalInfo?.gender && (
                      <div className='acc-block-item'>
                        <div className='acc-block-label el-text-s'>Gender</div>
                        <div className='acc-block-text el-text-m name'>{personalInfo.gender}</div>
                      </div>
                    )}

                    {personalInfo?.dateOfBirth && (
                      <div className='acc-block-item'>
                        <div className='acc-block-label el-text-s'>Date of birth</div>
                        <div className='acc-block-text el-text-m'>
                          {dayjs(new Date(parseInt(personalInfo.dateOfBirth))).format('DD.MM.YYYY')}
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <div className='acc-block-content'>
                  <div className='acc-block-item'>
                    <span className='el-text-m acc-block-wrap--empty'>
                      👤 No personal information yet.
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='acc-block acc-location'>
            <div className='acc-block-wrap'>
              <div className='acc-block-top'>
                <h6 className='acc-block-title el-title-h6 med'>Location:</h6>
                <div className='acc-block-edit'>
                  <Link
                    className='el-button el-button-edit'
                    variant='contained'
                    to={`/${userRole}/account/edits/location`}
                  >
                    <span className='el-button-icon'>
                      <svg
                        width='100%'
                        height='100%'
                        viewBox='0 0 28 28'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M5.79809 22.172H7.40675L17.3173 12.2615L15.7086 10.6528L5.79809 20.5633V22.172ZM22.2294 10.5954L17.346 5.76936L18.9547 4.1607C19.3951 3.72023 19.9363 3.5 20.5783 3.5C21.2202 3.5 21.761 3.72023 22.2007 4.1607L23.8094 5.76936C24.2498 6.20983 24.4797 6.74146 24.4988 7.36424C24.518 7.98702 24.3073 8.51826 23.8668 8.95797L22.2294 10.5954ZM20.5633 12.2902L8.38344 24.4701H3.5V19.5866L15.6799 7.40675L20.5633 12.2902ZM16.5129 11.4571L15.7086 10.6528L17.3173 12.2615L16.5129 11.4571Z' />
                      </svg>
                    </span>
                    <span>Edit details</span>
                  </Link>
                </div>
              </div>
              {location.country || location?.suburb || location?.city ? (
                <div className='acc-block-content'>
                  {location?.country && (
                    <div className='acc-block-item'>
                      <div className='acc-block-label el-text-s'>Country</div>
                      <div className='acc-block-text el-text-m name'>{location.country}</div>
                    </div>
                  )}

                  {location?.suburb && (
                    <div className='acc-block-item'>
                      <div className='acc-block-label el-text-s'>Suburb</div>
                      <div className='acc-block-text el-text-m name'>{location.suburb}</div>
                    </div>
                  )}

                  {location?.city && (
                    <div className='acc-block-item'>
                      <div className='acc-block-label el-text-s'>City</div>
                      <div className='acc-block-text el-text-m name'>{location.city}</div>
                    </div>
                  )}
                </div>
              ) : (
                <div className='acc-block-content'>
                  <div className='acc-block-item'>
                    <span className='el-text-m acc-block-wrap--empty'>
                      👤 No location information yet.
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='acc-delete'>
            <Link
              className='el-button-link el-link-s'
              variant='contained'
              to={`/${userRole}/account/edits/delete`}
            >
              Delete account
            </Link>
          </div>
        </div>
      )}

      {!loading && error && <h6 className='el-title-error'>{error}</h6>}
    </>
  );
};

export default Account;
