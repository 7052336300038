import React, { useState, useEffect } from 'react';
import AgentTxItemSocket from '../../components/AgentTxItemSocket';
import AgentSearchField from '../../components/AgentSearchField';
import AgentFilter from '../../components/AgentFilter/AgentFilter';
import dayjs from 'dayjs';

const IncomingOrders = ({ incomingTxData }) => {
  const [selectedPeriod, setSelectedPeriod] = useState(-1);
  const [selectedStatus, setSelectedStatus] = useState('All statuses');
  const [selectedSender, setSelectedSender] = useState('All agent senders');
  const [searchValue, setSearchValue] = useState('');
  const [statusOptions, setStatusOptions] = useState(['All statuses']);
  const [senderOptions, setSenderOptions] = useState(['All agent senders']);
  const periodOptions = [7, 30, 90, 120, -1];

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
  };

  const handlePeriodChange = (period) => {
    setSelectedPeriod(period);
    setSelectedSender('All agent senders');
    setSelectedStatus('All statuses');
    if (period === -1) {
      setSelectedSender('All agent senders');
      setSelectedStatus('All statuses');
    }
  };

  const handleSenderChange = (sender) => {
    setSelectedSender(sender);
    setSelectedStatus('All statuses');
  };

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const handleClear = () => {
    setSearchValue('');
  };

  const filterBySearch = (transactions, search) => {
    const normalizedSearch = search.toLowerCase().trim();
    return transactions.filter((tx) => {
      const isValidSearch =
        normalizedSearch === '' ||
        (tx?.receiver?.phoneNumber && tx?.receiver?.phoneNumber.includes(normalizedSearch)) ||
        (tx?.sender?.phoneNumber && tx?.sender?.phoneNumber.includes(normalizedSearch)) ||
        (tx?.agentReceiver?.phoneNumber &&
          tx?.agentReceiver?.phoneNumber.includes(normalizedSearch)) ||
        (tx?.agentSender?.phoneNumber && tx?.agentSender?.phoneNumber.includes(normalizedSearch)) ||
        (tx?.receiver?.name && tx?.receiver?.name.toLowerCase().includes(normalizedSearch)) ||
        (tx?.sender?.name && tx?.sender?.name.toLowerCase().includes(normalizedSearch)) ||
        (tx?.agentReceiver?.name &&
          tx?.agentReceiver?.name.toLowerCase().includes(normalizedSearch)) ||
        (tx?.agentSender?.name && tx?.agentSender?.name.toLowerCase().includes(normalizedSearch));

      return isValidSearch;
    });
  };

  const filterByCriteria = (transactions, status, period, sender) => {
    const now = dayjs();
    return transactions.filter((tx) => {
      const isValidStatus = status === 'All statuses' || tx.status === status;
      const isValidPeriod =
        period === -1 || now.diff(dayjs(parseInt(tx.timestamp)), 'day') <= period;
      const isValidSender = sender === 'All agent senders' || tx.agentSender?.name === sender;
      return isValidStatus && isValidPeriod && isValidSender;
    });
  };

  useEffect(() => {
    const newStatuses = ['All statuses'];
    const newSenderOptions = ['All agent senders'];
    const allTxData = Object.values(incomingTxData || {}).flat();
    const now = dayjs();

    allTxData.forEach((tx) => {
      if (
        selectedPeriod === -1 ||
        now.diff(dayjs(parseInt(tx.timestamp)), 'day') <= selectedPeriod
      ) {
        if (selectedSender === 'All agent senders' || tx.agentSender?.name === selectedSender) {
          if (!newStatuses.includes(tx?.status) && tx?.status !== ' ' && tx?.status) {
            newStatuses.push(tx?.status);
          }
          if (tx?.agentSender?.name && !newSenderOptions.includes(tx.agentSender.name)) {
            newSenderOptions.push(tx.agentSender.name);
          }
        }
      }
    });

    setStatusOptions(newStatuses);
    setSenderOptions(newSenderOptions);
  }, [incomingTxData, selectedPeriod, selectedSender]);

  const getFilteredData = (data) => {
    const searchFilteredData = filterBySearch(data, searchValue);
    return filterByCriteria(searchFilteredData, selectedStatus, selectedPeriod, selectedSender);
  };

  const attentionTxData = Object.entries(incomingTxData || {}).flatMap(([key, value]) => {
    if (key === 'Needs your attention') {
      return value;
      // return value.filter(tx => tx.status !== 'success');
    }
    return [];
  });

  const filteredAttentionTxData = getFilteredData(attentionTxData);

  const txsByKey = Object.entries(incomingTxData).reduce((acc, [key, value]) => {
    if (key !== 'Needs your attention') {
      const filteredData = getFilteredData(value);
      // const filteredData = getFilteredData(value.filter(tx => tx.status !== 'success'));
      if (filteredData.length > 0) {
        acc[key] = filteredData;
      }
    }
    return acc;
  }, {});

  return (
    <div className='tx'>
      <div className='tx-search'>
        <div className='tx-search-wrap'>
          <h6 className='el-title-h6-med'>Browse your transactions:</h6>
          <AgentSearchField
            className='tx-search-input'
            onSearch={handleSearch}
            onClear={handleClear}
            toggleLoading={() => {}}
          />
        </div>
      </div>
      <AgentFilter
        onStatusChange={handleStatusChange}
        onPeriodChange={handlePeriodChange}
        onSenderChange={handleSenderChange}
        statusOptions={statusOptions}
        periodOptions={periodOptions}
        senderOptions={senderOptions}
        defaultStatus={selectedStatus}
        defaultPeriod={selectedPeriod}
        defaultSender={selectedSender}
      />
      {filteredAttentionTxData.length > 0 && (
        <div className='tx-attention'>
          <div className='tx-title el-text-s med'>Need your attention</div>
          <div className='tx-table'>
            {filteredAttentionTxData.map((tx, index) => (
              <AgentTxItemSocket
                tx={tx}
                key={index}
                txIncoming={true}
                txAttention={true}
                txChat={true}
              />
            ))}
          </div>
        </div>
      )}

      {txsByKey && Object.keys(txsByKey).length > 0 && (
        <div className='tx-list'>
          {Object.entries(txsByKey).map(([key, txs]) => (
            <div className='tx-block' key={key}>
              <div className='tx-date el-text-s med'>{key}</div>
              <div className='tx-table'>
                {txs.map((tx, index) => (
                  <AgentTxItemSocket tx={tx} key={index} txIncoming={true} txChat={true} />
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
      {filteredAttentionTxData.length === 0 && Object.keys(txsByKey).length === 0 && (
        <div className='tx-no-results el-text-m'>
          🔍 No transactions yet.
        </div>
      )}
    </div>
  );
};

export default IncomingOrders;
