import { toast } from 'react-toastify';

const copyToclipboard = (address, transaction = false) => {
  navigator.clipboard.writeText(address);
  if (transaction) {
    toast.success('Transaction copied to clipboard!');
  } else {
    toast.success('Address copied to clipboard!');
  }
};

export default copyToclipboard;
