import React from 'react';
import Icons from '../../assets/icons/Icons.svg';
import PropTypes from 'prop-types';

const Icon = ({ name, secondName = '' }) => (
  <svg className={`${secondName} icon-${name}`}>
    <use xlinkHref={`${Icons}#icon-${name}`} />
  </svg>
);

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  secondName: PropTypes.string,
};

export default Icon;
