import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Estimate from '../../../components/Estimate';
import Review from '../../../components/Review';
import { useSelector } from 'react-redux';
import {
  selectRemitReceiver,
  selectRemitDetails,
  selectRemitSender,
} from '../../../redux/features/transaction/remittanceSlice';
import ArrowBack from '../../../components/ArrowBack';
import closeFlow from '../../../utils/closeFlow';
import { getAgentOTP } from '../../../api';
import { selectFirebaseJWT, selectPhoneNumber } from '../../../redux/features/user/userSlice';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';

const RemitReview = ({ changeMainStep }) => {
  const remitDetails = useSelector(selectRemitDetails);
  const sender = useSelector(selectRemitSender);
  const receiver = useSelector(selectRemitReceiver);
  const userToken = useSelector(selectFirebaseJWT); 
  const userPhone = useSelector(selectPhoneNumber);
  const [localLoading, setLocalLoading] = useState();

  const remitData = { remitDetails, sender, receiver };

  const getOTP = async () => {
    try {
      setLocalLoading(true);
      await getAgentOTP(userToken, userPhone);
      changeMainStep(1);
    } catch (error) {
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      console.log(error);
    } finally {
      setLocalLoading(false);
    }
  };

  return (
    <div className='send-step'>
      <ArrowBack handle={() => changeMainStep(-1)} />

      <div className='payment-title'>
        <h6 className='el-title-h6 med'>Review details of your transfer</h6>
      </div>

      <div className='send-step-content'>
        <Review changeStep={changeMainStep} remitData={remitData} />

        <div className='c-agent-note send-step-content-rewards'>
          {remitData.sender.personalInfo?.createAccount && (
            <Estimate
              type={'large'}
              count={50}
              text={'Reward for a newly registered user'}
              registration='true'
            />
          )}
  
          {remitData.remitDetails?.transferRewardsInPesa && (
            <Estimate type={'large'} count={remitData?.transferRewardsInPesa} text={'Reward for transaction'} />
          )}
        </div>

        <div className='send-btns'>
          <div className='send-btns-continue'>
            <LoadingButton
              disabled={localLoading}
              loading={localLoading}
              onClick={getOTP}
              className='el-button orange'
            >
              <span>{localLoading ? 'Processing' : 'Continue'}</span>
            </LoadingButton>
          </div>

          <div className='send-btns-cancel'>
            <span className='el-link-m el-button-link' variant='contained' onClick={closeFlow}>
              Cancel
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

RemitReview.propTypes = {
  changeStep: PropTypes.func.isRequired,
};
export default RemitReview;
