import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as IconArrow } from '../../assets/icons/icon-arrow-right.svg';

const VerifyOption = ({ image, title, subtitle }) => {
  return (
    <>
      <div className='c-option-verify-btn'>
        <div className='icon'>{image && <img src={image} alt='' />}</div>

        <div className='description'>
          <span className='el-text-m med description--title'>{title}</span>

          <span className='el-text-xs description--subtitle'>{subtitle}</span>
        </div>

        <div className='arrow'>
          <IconArrow />
        </div>
      </div>
    </>
  );
};

VerifyOption.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default VerifyOption;
