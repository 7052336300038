import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { List, ListItem } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import UserContext from '../../UserContext/UserContext';
import SearchField from '../../components/SearchField';
import { selectFirebaseJWT } from '../../redux/features/user/userSlice';
import SingleContact from '../../components/SingleContact';
import { toast } from 'react-toastify';
import { getContacts, getContactsSearch } from '../../api';
import getDataLength from '../../utils/getDataLength';
import { v4 as uuidv4 } from 'uuid';
import NoResult from '../../components/NoResult';

const Contacts = () => {
  const token = useSelector(selectFirebaseJWT);
  const tabs = ['all', 'favourites', 'users', 'agents'];
  const { loading, setLoading } = useContext(UserContext);
  const [activeTab, setActiveTab] = useState('all');
  const [originalData, setOriginalData] = useState({});
  const [contactsData, setContactsData] = useState({});
  const [contactsDataLength, setContactsDataLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [localLoading, setLocalLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const limit = 5;

  const skeleton = (
    <div className='c-contact-box-el'>
      <div className='c-contact-box-el-symbol'></div>
      {[...new Array(5)].map(() => (
        <div className='contact-wrap' key={uuidv4()}>
          <SingleContact loading={true} />
        </div>
      ))}
    </div>
  );

  // helpers functions for below main funcs
  const toggleLoading = (value) => setLocalLoading(value);

  const mergeContacts = (contacts1, contacts2) => {
    const mergedContacts = {};

    // Merge contacts from the first object
    Object.keys(contacts1).forEach((letter) => {
      if (!mergedContacts[letter]) {
        mergedContacts[letter] = [];
      }
      mergedContacts[letter] = [...mergedContacts[letter], ...contacts1[letter]];
    });

    // Merge contacts from the second object
    Object.keys(contacts2).forEach((letter) => {
      if (!mergedContacts[letter]) {
        mergedContacts[letter] = [];
      }
      mergedContacts[letter] = [...mergedContacts[letter], ...contacts2[letter]];
    });

    return mergedContacts;
  };

  const checkHasMore = (data) => setHasMore(getDataLength(data) === limit);

  const handleSearch = async (response, searchValue) => {
    setSearchValue(searchValue);
    setCurrentPage(1);
    setContactsData(response?.data?.contact || {});
    checkHasMore(response?.data?.contact || {});
  };

  const handleTabClick = async (tab) => {
    toggleLoading(true);
    setActiveTab(tab);
    setCurrentPage(1);
    setHasMore(false);

    try {
      let response;

      if (searchValue) {
        response = await getContactsSearch(token, 1, limit, tab, searchValue);
      } else {
        response = await getContacts(token, 1, limit, tab);
      }
      if (response.status === 200) {
        const contacts = response?.data?.contact;

        setContactsData(contacts || {});
        checkHasMore(contacts || {});
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.error('Error fetching contacts:', error);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
    } finally {
      toggleLoading(false);
    }
  };

  const handleClearSearch = async () => {
    setSearchValue('');
    setActiveTab('all');
    setCurrentPage(1);
    setContactsData(originalData);
    checkHasMore(originalData);
    checkHasMore(originalData);
    setLocalLoading(false);
  };

  const fetchContactsData = async (page) => {
    try {
      const result = await (searchValue
        ? getContactsSearch(token, page, limit, activeTab, searchValue)
        : getContacts(token, page, limit, activeTab));

      return result;
    } catch (error) {
      console.error('Error fetching contacts:', error);
      return {};
    }
  };

  const loadMoreContacts = async () => {
    if (!hasMore) return;

    try {
      const result = await fetchContactsData(currentPage + 1);

      if (result.status === 200) {
        const contacts = result?.data?.contact;

        setContactsData((prevState) => mergeContacts(prevState, contacts));
        setCurrentPage((prevPage) => prevPage + 1);
        checkHasMore(contacts);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      console.log(error);
    }
  };

  const renderContactGroup = (data) => (
    <div className='c-contact-box-el' key={uuidv4()}>
      <div className='c-contact-box-el-symbol'>{data[0]}</div>
      {data[1].map((contact) => (
        <div className='contact-wrap' key={uuidv4()}>
          <SingleContact contact={contact} />
        </div>
      ))}
    </div>
  );

  useEffect(() => {
    setContactsDataLength(getDataLength(contactsData));
  }, [contactsData]);

  useEffect(() => {
    (async (clear = false) => {
      try {
        clear ? setLocalLoading(true) : setLoading(true);
        const result = await getContacts(token, 1, limit, 'all');

        if (result.status === 200) {
          const contacts = result?.data?.contact;

          setOriginalData(contacts);
          setContactsData((prevState) => ({ ...prevState, ...contacts }));
          checkHasMore(contacts);
        } else {
          throw new Error(result.message);
        }
      } catch (error) {
        console.log(error);
        toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      } finally {
        clear ? setLocalLoading(false) : setLoading(false);
      }
    })();
  }, [token]);

  return (
    <div className='contacts'>
      <div className='contacts-search'>
        <h6 className='el-title-h6 med'>Search your Pesabase contact:</h6>

        <SearchField
          className='contacts-search-field'
          token={token}
          page={currentPage}
          limit={limit}
          filter={activeTab}
          onSearch={(response, searchValue) => handleSearch(response, searchValue)}
          onClear={handleClearSearch}
          onSearchTabChange={(newTab) => setActiveTab(newTab)}
          placeholderText='Search users...'
          toggleLoading={toggleLoading}
        />
      </div>

      <div className='contacts-filters'>
        <List>
          {tabs.map((tab) => (
            <ListItem
              key={uuidv4()}
              className={`el-text-m ${activeTab === tab ? 'active' : ''}`}
              onClick={() => handleTabClick(tab)}
            >
              {tab}
            </ListItem>
          ))}
        </List>
      </div>

      <div className='c-contact-box'>
        {contactsData && (
          <InfiniteScroll
            dataLength={contactsDataLength}
            next={loadMoreContacts}
            hasMore={hasMore}
            loader={skeleton}
          >
            <>
              {localLoading && skeleton}

              {!localLoading && Object.entries(contactsData).length === 0 && searchValue === '' && (
                <div className='c-contact-box-el'>
                  <NoResult>{'No contacts yet.'}</NoResult>
                </div>
              )}

              {Object.entries(contactsData).length === 0 && searchValue !== '' && !localLoading && (
                <div className='c-contact-box-el'>
                  <NoResult>
                    {'No contact found. Try entering the email or mobile number.'}
                  </NoResult>
                </div>
              )}

              {!localLoading && !loading && Object.entries(contactsData).map(renderContactGroup)}
            </>
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};

export default Contacts;
