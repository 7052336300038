import React, { useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Dashboard from '../../pages/Dashboard';
import Layout from '../../components/Layout';
import Account from '../../pages/Account';
import AccountEdits from '../../pages/AccountEdits';
import AccountDelete from '../../pages/AccountDelete';
import Contacts from '../../pages/Contacts';
import Transactions from '../../pages/Transactions';
import Settings from '../../pages/Settings';
import BuyPesa from '../../user/pages/BuyPesa';
import TransactionDetail from '../../pages/TransactionDetail';
import DepositPesaBase from '../../user/pages/Deposit/DepositPesabase';
import DepositSystem from '../../user/pages/Deposit/DepositSystem';
import DepositToSSP from '../../user/pages/Deposit/DepositToSSP';
import UserPaymentSystem from '../pages/UserPaymentSystem';
import UserSendToUser from '../pages/UserSendToUser';
import WithdrawFlow from '../pages/WithdrawFlow';
import MPesaFlow from '../../pages/MPesaFlow';
import useUserRole from '../../utils/useUserRole';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccount } from '../../redux/features/user/userSlice';
import { selectSocket } from '../../redux/features/sockets/socketslice';
import { initializeSocketConnection } from '../../redux/socketEvents';
import useAuthentication from '../../components/Authentication/Authentication';

const UserRoutes = () => {
  const isUser = useUserRole('user');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const userProfile = useSelector(selectAccount);
  const socket = useSelector(selectSocket);
  const user = useAuthentication();

  useEffect(() => {
    if (user && !socket.isConnected) dispatch(initializeSocketConnection());
  }, [user]);

  useEffect(() => {
    !isUser && navigate('/not-found');
  }, []);

  useEffect(() => {
    if (!userProfile?.canTransact) {
      if (
        location.pathname.startsWith('/user/send') ||
        location.pathname.startsWith('/user/deposit') ||
        location.pathname.startsWith('/user/contacts') ||
        location.pathname.startsWith('/user/account') ||
        location.pathname.startsWith('/user/settings') ||
        location.pathname.startsWith('/user/transactions')
      ) {
        navigate('/user/dashboard');
      }
    }
  }, [location]);

  return (
    <Routes>
      <Route element={<Layout type={'acc'} agent={false} user={true} />}>
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='contacts' element={<Contacts />} />
        <Route path='account' element={<Account />} />
        <Route path='transactions' element={<Transactions />} />
        <Route path='settings' element={<Settings />} />
        {/* <Route path='charity' element={<Charity />} /> */}
        <Route path='*' element={<Navigate to='/not-found' />} />
      </Route>

      <Route element={<Layout type={'tx'} agent={false} user={true} />}>
        <Route path='deposit'>
          <Route path='system' element={<DepositSystem />} />
          <Route path='pesabase' element={<DepositPesaBase />} />
          <Route path='ssp' element={<DepositToSSP />} />
          <Route path='buy-pesa' element={<BuyPesa />} />
          <Route path='*' element={<Navigate to='/not-found' />} />
        </Route>

        <Route path='send'>
          <Route path='system'>
            <Route path='' element={<UserPaymentSystem />} />
            <Route path='user' element={<UserSendToUser />} />
            <Route path='mobile' element={<UserSendToUser />} />
            <Route path='mpesa' element={<MPesaFlow />} />
            <Route path='*' element={<Navigate to='/not-found' />} />
          </Route>

          <Route path='*' element={<Navigate to='/not-found' />} />
        </Route>

        <Route path='account/edits'>
          <Route path='information' element={<AccountEdits />} />
          <Route path='location' element={<AccountEdits />} />
          <Route path='delete' element={<AccountDelete />} />
          <Route path='*' element={<Navigate to='/not-found' />} />
        </Route>

        <Route path='withdraw' element={<WithdrawFlow />} />

        <Route path='*' element={<Navigate to='/not-found' />} />
      </Route>

      <Route element={<Layout type={'tx-single'} agent={false} user={true} />}>
        <Route path='transactions/:txId' element={<TransactionDetail />} />
      </Route>
    </Routes>
  );
};

export default UserRoutes;
