import React from 'react';
import pesaLogo from '../../../assets/img/p-dashboard/deposit-to-pesa.png';
import mpesaLogo from '../../../assets/img/p-dashboard/send-pesa.png';
import PaymentSystem from '../../../components/PaymentSystem';
import Attention from '../../../components/Attention/Attention';
import { useSelector } from 'react-redux';
import { selectAccount } from '../../../redux/features/user/userSlice';

const data = [
  {
    title: 'Send to a Pesabase user',
    icon: pesaLogo,
    link: '/user/send/system/user',
    system: 'pesabase',
  },
  {
    title: 'Send via M-PESA',
    icon: mpesaLogo,
    link: '/user/send/system/mpesa',
    system: 'm-pesa',
  },
];

const UserPaymentSystem = () => {
  const account = useSelector(selectAccount);

  return (
    <div className='deposit'>
      <PaymentSystem data={data} />

      {!account?.canTransact && (
        <div className='system-message'>
          <Attention type='info'>
            You can process 1 send transaction before verifying your identity.
          </Attention>
        </div>
      )}
    </div>
  );
};

export default UserPaymentSystem;
