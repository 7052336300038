import React, { useContext, useEffect, useState } from 'react';
import { FormControlLabel, Checkbox, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { ReactComponent as iconSelect } from '../../../assets/icons/icon-select.svg';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { selectFirebaseJWT } from '../../../redux/features/user/userSlice';
import { useSelector } from 'react-redux';
import { addEmail, getUserProfile } from '../../../api';
import UserContext from '../../../UserContext/UserContext';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import DOBPicker from '../../DOBPicker';

const KycInfo = ({ personalInfo, submitStepData, updateStepData }) => {
  const userToken = useSelector(selectFirebaseJWT);
  const [checkbox, setCheckbox] = useState(false);
  const [error, setError] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [userInfo, setUserInfo] = useState({
    title: null,
    firstName: '',
    lastName: '',
    middleName: '',
    gender: null,
    dateOfBirth: '',
    email: '',
  });

  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const { loading, setLoading } = useContext(UserContext);

  const setGender = (e) => {
    setUserInfo((userInfo) => ({ ...userInfo, gender: e.target.value }));
  };

  const setTitle = (e) => {
    setUserInfo((userInfo) => ({ ...userInfo, title: e.target.value }));
  };

  const setDoB = (e) => {
    setUserInfo((userInfo) => ({ ...userInfo, dateOfBirth: Date.parse(e) }));
  };

  const setTextFields = (e) => {
    setUserInfo((userInfo) => ({ ...userInfo, [e.target.name]: e.target.value }));
  };

  const checkEmailField = (email) => email !== '' && email?.length > 0 && email !== undefined;

  const isValidData = () => {
    const { email, middleName, ...newObj } = userInfo || {};
    const values = Object.values(newObj).map((field) => {
      if (typeof field === 'object') {
        return field !== null && field.$y > 0;
      } else {
        return field !== '' && field !== null;
      }
    });
    const result = values.every((value) => value === true) && Object.keys(newObj).length >= 5;
    return result;
  };

  const submitForm = async (e) => {
    e.preventDefault();
    const formatedDoB = { ...userInfo, dateOfBirth: `${userInfo.dateOfBirth}` };

    if (personalInfo) {
      updateStepData('personalInfo', { personalInfo: formatedDoB });
    } else {
      submitStepData({ personalInfo: formatedDoB });
    }

    if (userInfo.email && checkbox) {
      await addEmail(userInfo.email, userToken, checkbox);
    }
  };

  useEffect(() => {
    isValidData() ? setDisabledSubmit(false) : setDisabledSubmit(true);
  }, [userInfo]);

  useEffect(() => {
    setShowEmail(!checkEmailField(userInfo?.email));

    (async () => {
      if (personalInfo) {
        try {
          setLoading(true);
          const { personalInfo } = await getUserProfile(userToken);
          if (Object.values(personalInfo).length > 0) setUserInfo(personalInfo);
        } catch (error) {
          setError(error);
          toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, []);

  return (
    <>
      {!loading && !error && (
        <form onSubmit={submitForm}>
          <div className='auth-title-block'>
            <h6 className='el-title-h6'>
              Fill in your personal information to complete your profile:
            </h6>
          </div>
          <div className='auth-content-row'>
            <FormControl className='el-input-select'>
              <InputLabel id='select-label'>Title</InputLabel>
              <Select
                labelId='select-label'
                id='select'
                value={userInfo?.title || ''}
                label='Title'
                onChange={setTitle}
                IconComponent={iconSelect}
              >
                <MenuItem className='capitalize' value={'ms'}>Ms</MenuItem>
                <MenuItem className='capitalize' value={'mr'}>Mr</MenuItem>
                <MenuItem className='capitalize' value={'mx'}>Mx</MenuItem>
              </Select>
            </FormControl>

            <TextField
              className='el-input'
              label='First name'
              type='text'
              name='firstName'
              value={userInfo?.firstName || ''}
              variant='outlined'
              onChange={setTextFields}
            />
          </div>

          <TextField
            className='el-input'
            label='Middle name (optional)'
            name='middleName'
            type='text'
            variant='outlined'
            value={userInfo?.middleName || ''}
            onChange={setTextFields}
          />

          <TextField
            className='el-input'
            label='Last name'
            type='text'
            name='lastName'
            variant='outlined'
            onChange={setTextFields}
            value={userInfo?.lastName || ''}
          />

          <FormControl className='el-input-select'>
            <InputLabel id='select-label'>Gender</InputLabel>
            <Select
              labelId='select-label'
              id='select'
              value={userInfo?.gender || ''}
              label='Gender'
              onChange={setGender}
              IconComponent={iconSelect}
            >
              <MenuItem className='capitalize' value={'male'}>Male</MenuItem>
              <MenuItem className='capitalize' value={'female'}>Female</MenuItem>
              <MenuItem className='capitalize' value={'other'}>Other</MenuItem>
            </Select>
          </FormControl>

          <DOBPicker dob={userInfo.dateOfBirth || null} setDoB={setDoB} />

          {showEmail && (
            <div className='email-block'>
              <TextField
                className='el-input'
                label='Email (optional)'
                type='email'
                variant='outlined'
                name='email'
                inputProps={{ 
                  pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$',
                }}
                onChange={setTextFields}
                value={userInfo?.email || ''}
              />

              <FormControlLabel
                control={<Checkbox />}
                label='I want to receive news, feature updates, and offers from Pesabase.'
                onChange={(e) => setCheckbox(e.target.checked)}
              />
            </div>
          )}

          <div className='auth-content-confirm'>
            <LoadingButton
              disabled={disabledSubmit}
              loading={loading}
              type='submit'
              className='el-button orange'
            >
              <span>{loading ? 'Processing' : 'Continue'}</span>
            </LoadingButton>
          </div>
        </form>
      )}

      {!loading && error && <h6 className='el-title-error'>{error}</h6>}
    </>
  );
};

KycInfo.propTypes = {
  personalInfo: PropTypes.bool,
  submitStepData: PropTypes.func.isRequired,
  updateStepData: PropTypes.func.isRequired,
};

export default KycInfo;
