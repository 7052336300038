import React, { useState } from 'react';
import { Button } from '@mui/material';
import usePictures from '../../../hooks/usePictures';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/en'; // Import the English locale or the locale you need
import relativeTime from 'dayjs/plugin/relativeTime';
import { useDispatch } from 'react-redux';
import { updatecurrentOrder } from '../../../redux/features/transaction/remittanceSlice';
import AgentTxItemPopup from '../AgentTxItemPopup';
import Popup from '../../../components/Popup';
import ReactPlayer from 'react-player';
import rewardsbanner from '../../../assets/anims/videos/popups/rewards_ff.mp4';
import Avatar from '../../../components/Avatar';
dayjs.extend(relativeTime);

const AgentTxItemSocket = ({
  tx,
  txLink = false,
  txAttention = false,
  txChat = false,
  txPool = false,
  txOutgoing = false,
  txIncoming = false,
}) => {
  const [txDetails, setTxDetails] = useState(false);
  const [rewardsPopUp, setRewardsPopUp] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const icons = usePictures(
    require.context('../../../assets/icons', false, /\.(png|jpe?g|svg|webp)$/)
  );

  const imgs = usePictures(
    require.context('../../../assets/img/p-dashboard', false, /\.(png|jpe?g|svg|webp)$/)
  );

  const toggleTxDetails = () => setTxDetails(!txDetails);
  const toggleRewards = () => setRewardsPopUp(!rewardsPopUp);

  const handleRemittance = async () => {
    if (txIncoming) {
      dispatch(updatecurrentOrder({ ...tx }));
      navigate(`/agent/chat`);
    }

    if (txOutgoing) {
      dispatch(updatecurrentOrder({ ...tx }));
      navigate(`/agent/chat`);
    }
    if (txPool) {
      dispatch(updatecurrentOrder({ ...tx }));
      navigate(`/agent/transaction-pool-details`);
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber || phoneNumber.length < 11) return phoneNumber;
    return `${phoneNumber.slice(0, 4)}*****${phoneNumber.slice(-4)}`;
  };

  const txItemContent = (
    <>
      <div className='txagent-item-info'>
        <div className='txagent-item-info-left'>
          <div className='txagent-item-info-avatar'>
            {txAttention ? (
              <img src={imgs['avatar-attention@2x.png']} alt='' />
            ) : (
              <>
                <img src={imgs['avatar-pesa-agent@2x.png']} alt='' />
              </>
            )}
          </div>
        </div>
        <div className='txagent-item-info-desc'>
          {(tx?.sender?.phoneNumber || tx?.receiver?.phoneNumber) && (
            <div className='txagent-item-phone'>
              {tx?.sender?.phoneNumber && (
                <div className='txagent-item-phone-num'>
                  {formatPhoneNumber(tx?.sender.phoneNumber)}
                </div>
              )}
              <img src={icons['icon-arrow-trans.svg']} alt='' />
              {tx?.receiver?.phoneNumber && (
                <div className='txagent-item-phone-num'>
                  {formatPhoneNumber(tx?.receiver.phoneNumber)}
                </div>
              )}
            </div>
          )}
          <div className='txagent-item-info-bottom'>
            {tx?.actionState?.action === 'added pool' ? (
              <div className='txagent-item-info-ag'>
                <div className='avatar'>
                  <img src={icons['icon-added-pool.svg']} alt='' />
                </div>
                <div className='text'>Added to pool</div>
              </div>
            ) : tx?.actionState?.action === 'decline' ? (
              <div className='txagent-item-info-ag'>
                <div className='avatar'>
                  <img src={icons['icon-dec-cancel.svg']} alt='' />
                </div>
                <div className='text'>Declined by receiver</div>
              </div>
            ) : tx?.status === 'cancelled' ? (
              <div className='txagent-item-info-ag'>
                <div className='avatar'>
                  <img src={icons['icon-dec-cancel.svg']} alt='' />
                </div>
                <div className='text'>Canceled by sender</div>
              </div>
            ) : tx?.type === 'incoming' && tx?.agentSender ? (
              <div className='txagent-item-info-ag'>
                <Avatar data={tx?.agentSender} />

                <div className='name'>{tx?.agentSender?.name}</div>
              </div>
            ) : tx?.type === 'outgoing' && !txPool && tx?.agentReceiver ? (
              <div className='txagent-item-info-ag'>
                <Avatar data={tx?.agentReceiver} />

                <div className='name'>{tx?.agentReceiver?.name}</div>
              </div>
            ) : txPool && tx?.agentSender ? (
              <div className='txagent-item-info-ag'>
                <Avatar data={tx?.agentSender} />

                <div className='name'>{tx?.agentSender?.name}</div>
              </div>
            ) : (
              <></>
            )}

            {tx?.timestamp && (
              <div className='txagent-item-info-date'>
                {dayjs(parseInt(tx?.timestamp)).format('DD MMM, HH:mm')}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='txagent-item-quantity'>
        {tx?.type === 'outgoing' ? (
          <div className='txagent-item-quantity-wrap sent-quan'>
            <div className='icon'>
              <img src={icons['icon-sent.svg']} alt='' />
            </div>
            {tx?.amount && <div className='text'>${parseFloat(tx?.amount).toFixed(2)}</div>}
          </div>
        ) : tx?.type === 'incoming' ? (
          <div className='txagent-item-quantity-wrap received-quan'>
            <div className='icon'>
              <img src={icons['icon-received.svg']} alt='' />
            </div>
            {tx?.amount && <div className='text'>${parseFloat(tx?.amount).toFixed(2)}</div>}
          </div>
        ) : tx?.type === 'pool' ? (
          <div className='txagent-item-quantity-wrap received-quan'>
            <div className='icon'>
              <img src={icons['icon-received.svg']} alt='' />
            </div>
            {tx?.amount && <div className='text'>${parseFloat(tx?.amount).toFixed(2)}</div>}
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className='txagent-item-status'>
        {tx?.status === 'processing' ? (
          <div className='txagent-item-status-wrap pending'>
            <div className='icon'>
              <img src={icons['icon-time.svg']} alt='' />
            </div>
            <div className='text'>processing</div>
          </div>
        ) : tx?.status === 'pending' ? (
          <div className='txagent-item-status-wrap pending'>
            <div className='icon'>
              <img src={icons['icon-time.svg']} alt='' />
            </div>
            <div className='text'>pending</div>
          </div>
        ) : tx?.status === 'cancelled' ? (
          <div className='txagent-item-status-wrap cancelled'>
            <div className='icon'>
              <img src={icons['icon-failed.svg']} alt='' />
            </div>
            <div className='text'>cancelled</div>
          </div>
        ) : tx?.status === 'completed' ? (
          <div className='txagent-item-status-wrap completed'>
            <div className='icon'>
              <img src={icons['icon-success.svg']} alt='' />
            </div>
            <div className='text'>completed</div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className='txagent-item-details'>
        <span className='txagent-item-details-btn'>
          <img src={icons['icon-arrow-right.svg']} alt='' />
        </span>
      </div>
    </>
  );
  return (
    <div className='txagent-item'>
      {txAttention ? (
        <>
          <div
            className={`txagent-item-wrap  ${txAttention ? 'attention' : ''}`}
            onClick={handleRemittance}
          >
            {txItemContent}
          </div>
        </>
      ) : txChat ? (
        <>
          <div className={`txagent-item-wrap`} onClick={handleRemittance}>
            {txItemContent}
          </div>
        </>
      ) : txPool ? (
        <>
          <div className={`txagent-item-wrap`} onClick={handleRemittance}>
            {txItemContent}
          </div>
          {/* <Link className={`txagent-item-wrap`} to={`/agent/transaction-pool-details`}>
            {txItemContent}
          </Link> */}
        </>
      ) : txLink ? (
        <>
          <Link className={`txagent-item-wrap link`}>{txItemContent}</Link>
          <Popup open={rewardsPopUp} togglePopUp={toggleRewards}>
            <div className='modal-content'>
              <div className='modal-img'>
                <ReactPlayer
                  className='player'
                  controls={false}
                  playing={true}
                  loop={true}
                  muted={true}
                  playsinline={true}
                  url={rewardsbanner}
                  width='100%'
                  height='100%'
                />
              </div>

              <div className='modal-desc'>
                <div className='modal-desc-title'>Rewards for you!</div>

                <div className='el-text-m modal-desc-text'>
                  We pay you rewards each time you use our platform sending money to your friends
                  and family. Your reward will be added to your PESA balance after transaction is
                  processed. You can also find it in your Activity history.
                </div>
              </div>

              <div className='modal-btns'>
                <Button
                  className='el-button orange el-button-continue'
                  variant='contained'
                  onClick={toggleRewards}
                >
                  Ok, got it
                </Button>
              </div>
            </div>
          </Popup>
        </>
      ) : (
        <>
          <div className={`txagent-item-wrap`} onClick={toggleTxDetails}>
            {txItemContent}
          </div>
          <AgentTxItemPopup
            tx={tx}
            // userUid={userUid}
            open={txDetails}
            toggleTxDetails={toggleTxDetails}
            toggleRewards={toggleRewards}
          />
          <Popup open={rewardsPopUp} togglePopUp={toggleRewards}>
            <div className='modal-content'>
              <div className='modal-img'>
                <ReactPlayer
                  className='player'
                  controls={false}
                  playing={true}
                  loop={true}
                  muted={true}
                  playsinline={true}
                  url={rewardsbanner}
                  width='100%'
                  height='100%'
                />
              </div>

              <div className='modal-desc'>
                <div className='modal-desc-title'>Rewards for you!</div>

                <div className='el-text-m modal-desc-text'>
                  We pay you rewards each time you use our platform sending money to your friends
                  and family. Your reward will be added to your PESA balance after transaction is
                  processed. You can also find it in your Activity history.
                </div>
              </div>

              <div className='modal-btns'>
                <Button
                  className='el-button orange el-button-continue'
                  variant='contained'
                  onClick={toggleRewards}
                >
                  Ok, got it
                </Button>
              </div>
            </div>
          </Popup>
        </>
      )}
    </div>
  );
};

AgentTxItemSocket.propTypes = {
  tx: PropTypes.object,
};

export default AgentTxItemSocket;
