import React, { useContext, useEffect, useState } from 'react';
import ArrowBack from '../../../components/ArrowBack';
import { useSelector } from 'react-redux';
import { Button, List, ListItem } from '@mui/material';
import { selectFirebaseJWT, selectJwtToken } from '../../../redux/features/user/userSlice';
import SingleContact from '../../../components/SingleContact';
import { toast } from 'react-toastify';
import { assignAgent, getAgentsList, getAgentsSearch } from '../../../api';
import { v4 as uuidv4 } from 'uuid';
import SearchField from '../../../components/SearchField';
import { selectOrder } from '../../../redux/features/transaction/remittanceSlice';
import getDataLength from '../../../utils/getDataLength';
import NoResult from '../../../components/NoResult';
import { useNavigate } from 'react-router-dom';
import Success from '../../../components/Success';
import sendSuccessVideo from '../../../assets/anims/videos/fullscreen/send_success_ff.mp4';
import sendFailVideo from '../../../assets/anims/videos/fullscreen/send_fail_ff.mp4';
import UserContext from '../../../UserContext/UserContext';

const AgentReassignSearch = () => {
  const navigate = useNavigate();
  const order = useSelector(selectOrder);
  const userToken = useSelector(selectFirebaseJWT);
  const userDeviceJwt = useSelector(selectJwtToken);
  const tabs = ['all', 'available'];

  const [agent, setAgent] = useState(null);
  const [error, setError] = useState(false);
  const { setLoading } = useContext(UserContext);
  const [agentsData, setAgentsData] = useState({});
  const [activeTab, setActiveTab] = useState('all');
  const [searchValue, setSearchValue] = useState('');
  const [originalData, setOriginalData] = useState([]);
  const [localLoading, setLocalLoading] = useState(false);
  const [successScreen, setSuccessScreen] = useState(false);

  // for infinity scrolling pagination in the future
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const limit = 5;

  /**
   * Renders a skeleton UI for the contact list.
   *
   * @returns {JSX.Element} - The JSX element representing the skeleton UI.
   */
  const skeleton = () => {
    return (
      <div className='c-contact-box-el'>
        <div className='c-contact-box-el-symbol'></div>
        {[...new Array(5)].map(() => (
          <div className='contact-wrap' key={uuidv4()}>
            <SingleContact loading={true} />
          </div>
        ))}
      </div>
    );
  };

  /**
   * Sets the agent for the transaction and shows success screen.
   *
   * @param {Object} userReceiver - The user object of the selected agent.
   * @param {string} userReceiver.uid - The unique identifier of the agent.
   * @param {string} userReceiver.name - The name of the agent.
   * @param {string} userReceiver.email - The email of the agent.
   * @param {string} userReceiver.phone - The phone number of the agent.
   * @returns {void}
   */
  const setData = async ({ userReceiver }) => {
    try {
      setLoading(true);
      // Call the assignAgent API to assign the agent to the transaction
      const response = await assignAgent(userToken, userDeviceJwt, order.txId, userReceiver.uid);

      // Check if the API call was successful
      if (response.status === 200) {
        // Set the selected agent and show the success screen
        setAgent(userReceiver);
        setSuccessScreen(true);
      } else {
        // If the API call was not successful, throw an error
        throw new Error(response.message);
      }
    } catch (error) {
      // If an error occurred during the API call, handle it
      setError(true);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Checks if there are more data to fetch for pagination.
   *
   * @param {Array} data - The data array to check for more items.
   * @returns {void} - This function does not return anything, but it updates the 'hasMore' state.
   */
  const checkHasMore = (data) => setHasMore(getDataLength(data) === limit);

  /**
   * Handles the search functionality for agents.
   *
   * @param {Object} response - The API response object containing the search results.
   * @param {string} searchValue - The value entered in the search field.
   * @returns {void} - This function does not return anything, but it updates the component's state.
   */
  const handleSearch = async (response, searchValue) => {
    // Reset the current page to 1 when a new search is performed
    setCurrentPage(1);

    // Update the search value in the component's state
    setSearchValue(searchValue);

    // Extract the agents data from the API response and update the component's state
    setAgentsData(response?.data?.contact || {});

    // Check if there are more data to fetch for pagination
    checkHasMore(response?.data?.contact || {});
  };

  /**
   * Handles the tab click event for the agent search page.
   * Fetches the agents based on the selected tab and search value.
   *
   * @param {string} tab - The tab that was clicked.
   * @returns {void} - This function does not return anything.
   */
  const handleTabClick = async (tab) => {
    try {
      // Set loading state to true
      setLocalLoading(true);

      // Update the active tab
      setActiveTab(tab);

      // Reset the current page and hasMore state for pagination
      setCurrentPage(1);
      setHasMore(false);

      let response;

      // If the 'available' tab is clicked and there is a search value, fetch agents based on the search
      if (searchValue) {
        response = await getAgentsSearch(
          userToken,
          searchValue,
          tab === 'all' ? false : true,
          1,
          limit
        );
      } else if (tab === 'available') {
        // If the 'available' tab is clicked or there is no search value, fetch available agents
        response = await getAgentsList(userToken, true);
      } else {
        // If the 'available' tab is not clicked or there is no search value, fetch all agents
        response = await getAgentsList(userToken, false);
      }
      // Check if the API call was successful
      if (response.status === 200) {
        // Extract the agents data from the API response and update the component's state
        const agents = response?.data?.contact || response?.data;
        setAgentsData(agents || {});

        // Check if there are more data to fetch for pagination
        checkHasMore(agents || {});
      } else {
        // If the API call was not successful, throw an error
        throw new Error(response.message);
      }
    } catch (error) {
      // If an error occurred during the API call, handle it
      console.error('Error fetching agents:', error);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
    } finally {
      // Set loading state to false regardless of the outcome of the API call
      setLocalLoading(false);
    }
  };

  /**
   * Handles the clear search functionality for agents.
   * Resets the search value, active tab, current page, agents data, and loading state.
   *
   * @returns {void} - This function does not return anything, but it updates the component's state.
   */
  const handleClearSearch = async () => {
    setSearchValue(''); // Reset the search value
    setActiveTab('all'); // Set the active tab to 'all'
    setCurrentPage(1); // Reset the current page to 1
    setAgentsData({}); // Clear the agents data
    setAgentsData(originalData); // Set the agents data to the original data
    checkHasMore(originalData); // Check if there are more data to fetch for pagination
    setLocalLoading(false); // Set the loading state to false
  };

  /**
   * Renders a group of contacts with the same initial letter.
   *
   * @param {Array} data - An array containing the initial letter and an array of contacts.
   * @returns {JSX.Element} - The JSX element representing the contact group.
   */
  const renderContactGroup = (data) => {
    if (data) {
      return (
        <div className='c-contact-box-el' key={uuidv4()}>
          <div className='c-contact-box-el-symbol'>{data[0]}</div>
          {data[1].map((contact) => (
            <div className='contact-wrap' key={uuidv4()}>
              <SingleContact contact={contact} setData={setData} flow={true} agentSearch={true} />
            </div>
          ))}
        </div>
      );
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLocalLoading(true);
        const response = await getAgentsList(userToken);

        if (response.status === 200) {
          const agents = response?.data;
          setOriginalData(agents);
          setAgentsData(agents);
        } else {
          throw new Error();
        }
      } catch (error) {
        console.log(error);
        toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      } finally {
        setLocalLoading(false);
      }
    })();
  }, [userToken]);

  return (
    <>
      {!successScreen && (
        <>
          <ArrowBack handle={() => navigate(-1)} />

          <div className='payment-title'>
            <h6 className='el-title-h6 med'>
              Choose an <span className='orange'>Agent</span> to send
            </h6>
          </div>

          <div className='send-step-content'>
            <SearchField
              className='agents-search-field'
              contactType='contact'
              token={userToken}
              onClear={handleClearSearch}
              onSearch={(response, searchValue) => handleSearch(response, searchValue)}
              placeholderText='Enter name, address, or phone number'
              toggleLoading={setLocalLoading}
              agents={true}
            />

            <div className='contacts-filters agents-flow'>
              <List>
                {tabs.map((tab) => (
                  <ListItem
                    key={uuidv4()}
                    className={`el-text-m ${activeTab === tab ? 'active' : ''}`}
                    onClick={() => {
                      handleTabClick(tab);
                    }}
                  >
                    {tab}
                  </ListItem>
                ))}
              </List>
            </div>

            <div className='c-contact-box'>
              {localLoading && skeleton()}

              {!localLoading &&
                Object.entries(agentsData).length > 0 &&
                Object.entries(agentsData).map(renderContactGroup)}

              {Object.entries(agentsData).length === 0 && searchValue !== '' && !localLoading && (
                <div className='c-contact-box-el'>
                  <NoResult>{'No agents found. Try entering the email or mobile number.'}</NoResult>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {successScreen && (
        <Success
          error={error}
          video={error ? sendFailVideo : sendSuccessVideo}
          title={error ? 'Error' : `Your send request has been sent to ${agent?.name}`}
          subtitle={
            error
              ? 'Error'
              : `You can find the transaction in <a class="el-button-link" href="/agent/outgoing-orders"><span>Outgoing orders</span></a>`
          }
          buttons={
            error ? (
              <Link to={'/agent/dashboard'} className='el-button orange'>
                Go to Dashboard
              </Link>
            ) : (
              <Button onClick={() => navigate(`/agent/chat`)} className='el-button orange'>
                View transaction details
              </Button>
            )
          }
        />
      )}
    </>
  );
};

export default AgentReassignSearch;
