const usePictures = (arr) => {
  const images = {};

  arr.keys().map((item) => {
    return (images[item.replace('./', '')] = arr(item));
  });
  return images;
};

export default usePictures;
