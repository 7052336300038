import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import usePictures from '../../../hooks/usePictures';
import useLowerCase from '../../../hooks/useLowerCase';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import transformNumbers from '../../../utils/transformNumbers';
import iconEyeHidden from '../../../assets/icons/icon-textfield-eye.svg';
import iconEye from '../../../assets/icons/icon-textfield-eye-hidden.svg';
import ReactPlayer from 'react-player';
import { Drawer } from '@mui/material';
import Avatar from '../../../components/Avatar';
import { Link } from 'react-router-dom';
import transformString from '../../../utils/transformString';
import Popup from '../../../components/Popup';
import rewardsbanner from '../../../assets/anims/videos/popups/rewards_ff.mp4';
import ShortenedAddress from '../../../components/ShortenedAddress';
import copyToclipboard from '../../../utils/copyToclipboard';

const AgentTxItemPopup = ({ tx, userUid, open, toggleTxDetails }) => {
  const [popup, setPopup] = useState(false);
  const [registration, setRegistration] = useState( false);

  const icons = usePictures(
    require.context('../../../assets/icons', false, /\.(png|jpe?g|svg|webp)$/)
  );

  const imgs = usePictures(
    require.context('../../../assets/img/p-dashboard', false, /\.(png|jpe?g|svg|webp)$/)
  );
  const [visible, setVisible] = useState(false);

  const togglePopup = () => {
    setPopup(!popup);
  };

  const setRegistrationState = (reg) => {
    setRegistration(reg);
  };

  const handleClick = (reg) => {
    setRegistrationState(reg);
    togglePopup();
  };

  const toggleVisibleVerification = () => setVisible(!visible);
  const txPaymentSystem = tx?.paymentSystem !== undefined ? useLowerCase(tx?.paymentSystem) : '';
  const txOperation = tx && tx?.operation !== undefined ? useLowerCase(tx?.operation) : '';


  const showRepeatBtnRule =
    tx && tx?.sender?.uid === userUid && (txOperation === 'top up' || txOperation === 'withdrawal');

  return (
    <Drawer className='modal-right agent' anchor={'right'} open={open} onClose={toggleTxDetails}>
      <div className='modal-top'>
        <h6 className='el-title-h6 med'>Transaction details:</h6>

        <DialogActions>
          <Button onClick={toggleTxDetails} className='modal-close' color='primary'>
            <svg
              width='100%'
              height='100%'
              viewBox='0 0 40 40'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g id='icons/X'>
                <path
                  id='Union'
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M8.68645 8.68547C8.29593 9.076 8.29593 9.70916 8.68645 10.0997L18.586 19.9993L8.68645 29.8988C8.29592 30.2894 8.29592 30.9225 8.68645 31.313C9.07697 31.7036 9.71014 31.7036 10.1007 31.313L20.0002 21.4135L29.8997 31.3129C30.2902 31.7034 30.9233 31.7034 31.3139 31.3129C31.7044 30.9224 31.7044 30.2892 31.3139 29.8987L21.4144 19.9993L31.3139 10.0998C31.7044 9.70931 31.7044 9.07615 31.3139 8.68562C30.9233 8.2951 30.2902 8.2951 29.8997 8.68562L20.0002 18.585L10.1007 8.68547C9.71014 8.29495 9.07698 8.29495 8.68645 8.68547Z'
                />
              </g>
            </svg>
          </Button>
        </DialogActions>
      </div>

      <DialogContent>
        <div className='modal-wrap'>
          <div className='activity-modal'>
            <div className='item'>
              <div className='item-wrap'>
                <div className='item-info'>
                  <div className='item-info-avatar'>
                    <>
                    {txOperation === 'crypto deposit' ? (
                        <>
                          <img src={imgs['avatar-cripto@2x.png']} alt='' />
                        </>
                      ) :txOperation === 'remit' ? (
                        <img src={imgs['avatar-pesa-agent@2x.png']} alt='' />
                      ) : txOperation === 'mpesa' || txOperation === 'withdrawal' ? (
                        <img src={imgs['avatar-mpesa-agent@2x.png']} alt='' />
                      ) : txOperation === 'top up' ? (
                        <Avatar data={tx?.sender?.uid === userUid ? tx?.receiver : tx?.sender} />
                      ) : txOperation === 'referral reward' || txOperation === 'signup reward' ? (
                        <>
                          <img src={imgs['avatar-pesa-ref@2x.png']} alt='' />
                        </>
                      ) : (
                        <Avatar data={tx?.sender?.uid !== userUid ? tx?.sender : tx?.receiver} />
                      )}
                    </>
                  </div>
                  <div className='item-info-desc'>
                  {txOperation === 'crypto deposit' ? (
                  <div className='txagent-item-name'>Crypto deposit</div>
                  ):(txPaymentSystem === 'm-pesa' && txOperation === 'withdrawal')  ? (
                      <>
                        {(tx?.receiver?.name && transformString(tx?.receiver?.name) !== ' ') ? (
                            <div className='txagent-item-phone-num'>{tx?.receiver?.name}</div>
                          ):(
                            <div className='txagent-item-phone-num'>{tx?.receiver?.phoneNumber}</div>
                        )}
                      </>
                    )  : tx?.agentSender || tx?.agentReceiver ? (
                      <>
                        {tx?.sender?.phoneNumber && (
                          <div className='txagent-item-phone'>
                            <div className='txagent-item-phone-num'>
                              {tx?.sender?.phoneNumber}
                            </div>
                            <img src={icons['icon-arrow-trans.svg']} alt='' />
                            {tx?.receiver?.phoneNumber && (
                              <div className='txagent-item-phone-num'>
                                {tx?.receiver?.phoneNumber}
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    ) : tx?.sender?.uid === userUid && tx?.receiver?.name ? (
                      <>
                        <div className='txagent-item-name'>{tx?.receiver?.name}</div>
                      </>
                    ) : tx?.receiver?.uid === userUid && tx?.sender?.name ? (
                      <>
                        <div className='txagent-item-name'>{tx?.sender?.name}</div>
                      </>
                    ) : (
                      <></>
                    )}

                    <div className='txagent-item-info-bottom'>
                      {txOperation === 'referral reward' ? (
                        <div className='txagent-item-info-ag'>
                          {tx?.agentSender.name}. has registered using your referral link
                        </div>
                      ) : tx?.status === 'cancel' ? (
                        <div className='txagent-item-info-ag'>
                          <div className='avatar'>
                            <img src={icons['icon-dec-cancel.svg']} alt='' />
                          </div>
                          <div className='text'>Canceled by sender</div>
                        </div>
                      ) : txOperation === 'remit' ? (
                        <div className='txagent-item-info-ag'>
                          <Avatar
                            data={
                              userUid === tx?.agentSender?.uid ? tx?.agentReceiver : tx?.agentSender
                            }
                          />
                        </div>
                      ) : (
                        <></>
                      )}

                      {tx?.timestamp && (
                        <div className='item-info-date'>
                          {dayjs(parseInt(tx?.timestamp)).format('DD MMM, HH:mm')}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* todo conditions */}
                <div className='item-right'>
                  <div className='item-quantity'>
                    <div
                      className={`item-quantity-wrap ${
                        txOperation === 'crypto deposit'
                         ? 'wallet-quan'
                        : (tx?.agentSender?.uid === userUid ||
                        userUid === tx?.sender?.uid ||
                        tx?.type === 'outgoing')
                          ? 'sent-quan'
                          : 'received-quan'
                      }`}
                    >
                      <div className='icon'>
                        {txOperation === 'crypto deposit' ? (
                          <img src={icons['icon-wallet.svg']} alt='' />
                        ) : (tx?.agentSender?.uid === userUid ||
                        userUid === tx?.sender?.uid ||
                        tx?.type === 'outgoing') ? (
                          <img src={icons['icon-sent.svg']} alt='' />
                        ) : (
                          <img src={icons['icon-received.svg']} alt='' />
                        )}
                      </div>

                      <div className='text'>
                        {transformNumbers(Number(tx?.amount), 2)} {tx?.currency}
                      </div>
                    </div>
                  </div>
                  <div className='item-status'>
                    {tx?.status === 'completed' ? (
                      <div className='item-status-wrap success'>
                        <div className='icon'>
                          <img src={icons['icon-success.svg']} alt='' />
                        </div>
                      </div>
                    ) : tx?.status === 'failed' || tx?.status === 'cancelled' ? (
                      <div className='item-status-wrap failed'>
                        <div className='icon'>
                          <img src={icons['icon-failed.svg']} alt='' />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='modal-desc'>
            <div className='modal-desc-list'>
              {txOperation !== 'referral reward' ? (
                <>
                  {/* txId for all transaction */}
                  {(tx?.txId || tx?.paymentSystem || tx?.timestamp) && (
                    <div className='modal-desc-wrap'>
                      {tx?.txId && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Transaction ID</div>
                          <div className='el-text-m modal-desc-text'>{tx?.txId}</div>
                        </div>
                      )}
                      {/* paymentSystem operation transfer  */}
                      {(tx?.paymentSystem && txOperation !== 'crypto deposit') && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Payment system</div>
                          <div className='el-text-m modal-desc-text name'>
                            {tx?.paymentSystem.toLowerCase()}
                          </div>
                        </div>
                      )}
                      {/* date for all transaction */}
                      {tx?.timestamp && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Initiation date</div>
                          <div className='el-text-m modal-desc-text'>
                            {dayjs(parseInt(tx?.timestamp)).format('MM/DD/YYYY HH:mm:ss')}
                          </div>
                        </div>
                      )}
                      {/* address for crypto deposit  */}
                        {txOperation === 'crypto deposit' ? (
                          <>
                            {tx?.sender?.address && (
                              <div className='modal-desc-item'>
                                <div className='el-text-m modal-desc-label'>
                                  <>Sender wallet address</>
                                </div>
                                <div className='el-text-m modal-desc-text name'>
                                  <span
                                    className='address'
                                    onClick={() => copyToclipboard(tx?.sender?.address)}
                                  >
                                    <ShortenedAddress address={tx?.sender?.address} />
                                    <img className='address-copy' src={icons['icon-copy.svg']} alt='' />
                                  </span>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      {/* {(txPaymentSystem === 'm-pesa' || txOperation === 'mpesa' || txOperation === 'mpesa-agent') ? (
                        <>
                          {tx?.receiver?.phoneNumber && (
                            <div className='modal-desc-item'>
                              <div className='el-text-m modal-desc-label'>Receiver</div>
                              <div className='el-text-m modal-desc-text'>
                                {tx?.receiver?.phoneNumber}
                              </div>
                            </div>
                          )}
                        </>
                      ) : tx?.receiver?.uid === userUid ? (
                        <>
                          {tx?.sender?.name && (
                            <div className='modal-desc-item'>
                              <div className='el-text-m modal-desc-label'>Sender</div>
                              <div className='el-text-m modal-desc-text'>{tx?.sender?.name}</div>
                            </div>
                          )}
                        </>
                      ) : tx?.sender?.uid === userUid ? (
                        <>
                          {tx?.receiver?.name && (
                            <div className='modal-desc-item'>
                              <div className='el-text-m modal-desc-label'>Receiver</div>
                              <div className='el-text-m modal-desc-text'>{tx?.receiver?.name}</div>
                            </div>
                          )}
                        </>
                      ) : (
                        <></>
                      )} */}
                      {tx?.receiver?.uid === userUid ? (
                        <>
                          {tx?.sender?.name && (
                            <div className='modal-desc-item'>
                              <div className='el-text-m modal-desc-label'>Sender</div>
                              <div className='el-text-m modal-desc-text'>{tx?.sender?.name}</div>
                            </div>
                          )}
                        </>
                      ) : tx?.sender?.uid === userUid ? (
                        <>
                          {tx?.receiver?.name && (
                            <div className='modal-desc-item'>
                              <div className='el-text-m modal-desc-label'>Receiver</div>
                              <div className='el-text-m modal-desc-text'>{tx?.receiver?.name}</div>
                            </div>
                          )}
                        </>
                      ) : (txOperation === 'withdrawal'|| txOperation === 'm-pesa' || txOperation === 'mpesa-agent') ? (
                        <>
                        {txPaymentSystem === 'm-pesa' ? (
                              <>
                              <div className='modal-desc-item'>
                                <div className='el-text-m modal-desc-label'>Receiver</div>
                                <div className='el-text-m modal-desc-text'>{(tx?.receiver?.name && transformString(tx?.receiver?.name) !== ' ') ? tx?.receiver.name : tx?.receiver?.phoneNumber}</div>
                              </div>
                              </>
                          ):(
                          <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {/* referral reward */}
                  {tx?.txId && (
                    <div className='modal-desc-wrap'>
                      {tx?.txId && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Transaction ID</div>
                          <div className='el-text-m modal-desc-text'>{tx?.txId}</div>
                        </div>
                      )}
                    </div>
                  )}
                  {(tx?.timestamp || tx?.comment || tx?.amount) && (
                    <div className='modal-desc-wrap'>
                      {tx?.amount && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Transaction amount</div>
                          <div className='el-text-m semi modal-desc-text'>
                          {transformNumbers(tx?.amount, 2)} {tx?.currency}
                          </div>
                        </div>
                      )}
                      {/* date for all transaction */}

                      {tx?.timestamp && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Initiation date</div>
                          <div className='el-text-m modal-desc-text'>
                            {dayjs(parseInt(tx?.timestamp)).format('MM/DD/YYYY HH:mm:ss')}
                          </div>
                        </div>
                      )}
                      {tx?.comment && tx?.comment.length > 0 && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Comment</div>
                          <div className='el-text-m modal-desc-text'>{tx?.comment}</div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}

              {/* Agent sender */}
              {userUid === tx?.agentSender?.uid || tx?.type === 'outgoing' ? (
                <>
                  {/* amount */}
                  {(tx?.amount ||
                    tx?.fee ||
                    tx?.totalAmount ||
                    tx?.comment ||
                    tx?.verification) && (
                    <div className='modal-desc-wrap'>
                      {tx?.amount && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Transaction amount</div>
                          <div className='el-text-l semi modal-desc-text'>
                          {transformNumbers(tx?.amount, 2)} {tx?.currency}
                          </div>
                        </div>
                      )}
                      {/* fee */}
                      {( tx?.fee && tx?.fee !== '0') && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Transaction fee</div>
                          <div className='el-text-m modal-desc-text'>
                            {transformNumbers(Number(tx.fee), 4)} {tx?.feeCurrency}
                          </div>
                        </div>
                      )}
                      {/* total for all transaction */}
                      {tx?.totalAmount && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Total</div>
                          <div className='el-text-l semi modal-desc-text'>
                            {transformNumbers(Number(tx?.totalAmount), 4)} {tx?.currency}
                          </div>
                        </div>
                      )}
                      {tx?.comment && tx?.comment.length > 0 && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Comment</div>
                          <div className='el-text-m modal-desc-text'>{tx?.comment}</div>
                        </div>
                      )}
                      {tx?.verification && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Verification</div>
                          <div className='el-text-m modal-desc-text verification'>
                            {' '}
                            {visible ? tx?.verification : '****'}
                            <span className='verification-icon' onClick={toggleVisibleVerification}>
                              <img
                                src={visible ? iconEye : iconEyeHidden}
                                alt='Toggle visibility'
                              />
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {/* sender */}
                  {(tx?.sender?.name || tx?.sender?.phoneNumber || tx?.sender?.location) && (
                    <div className='modal-desc-wrap'>
                      {tx?.sender?.name && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Sender</div>
                          <div className='el-text-m modal-desc-text'>{tx?.sender?.name}</div>
                        </div>
                      )}

                      {tx?.sender?.phoneNumber && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Sender’s phone</div>
                          <div className='el-text-m modal-desc-text name'>
                            {tx?.sender?.phoneNumber}
                          </div>
                        </div>
                      )}

                      {tx?.sender?.location && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Sender’s location</div>
                          <div className='el-text-m modal-desc-text'>
                            {tx?.sender?.location?.country}, {tx?.sender?.location?.city}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {(tx?.agentReceiver?.name ||
                    tx?.receiver?.name ||
                    tx?.receiver?.phoneNumber ||
                    tx?.receiver?.location) && (
                    <div className='modal-desc-wrap'>
                      {tx?.pickupLocation && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>
                            Receiver’s cash pickup location
                          </div>
                          <div className='el-text-m modal-desc-text'>
                            {tx?.pickupLocation?.country}, {tx?.pickupLocation?.suburb}
                          </div>
                        </div>
                      )}
                      {tx?.agentReceiver?.name && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Receiving Agent</div>
                          <div className='el-text-m modal-desc-text'>{tx?.agentReceiver?.name}</div>
                        </div>
                      )}
                      {tx?.receiver?.name && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Receiver</div>
                          <div className='el-text-m modal-desc-text'>{tx?.receiver?.name}</div>
                        </div>
                      )}
                      {tx?.receiver?.phoneNumber && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Receiver’s phone</div>
                          <div className='el-text-m modal-desc-text name'>
                            {tx?.receiver?.phoneNumber}
                          </div>
                        </div>
                      )}
                      {tx?.receiver?.location && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Receiver’s location</div>
                          <div className='el-text-m modal-desc-text'>
                            {tx?.receiver?.location?.country}, {tx?.receiver?.location?.city}
                          </div>
                        </div>
                      )}
                      {/* Date of birth */}
                      {tx?.receiver?.dateOfBirth && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Date of birth</div>
                          <div className='el-text-m modal-desc-text'>
                            {tx?.receiver?.dateOfBirth}
                          </div>
                        </div>
                      )}
                      {/* Gender */}
                      {tx?.receiver?.gender && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Gender</div>
                          <div className='el-text-m modal-desc-text'>{tx?.receiver?.gender}</div>
                        </div>
                      )}
                    </div>
                  )}
                  {tx?.status === 'cancelled' && (
                    <div className='modal-desc-wrap'>
                      {tx?.cancelReason && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Reason to cancel</div>
                          <div className='el-text-m modal-desc-text'>{tx?.cancelReason}</div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : userUid === tx?.agentReceiver?.uid || tx?.type === 'incoming' ? (
                <>
                  {/* Agent receiver */}
                  {/* amount */}
                  {(tx?.amount || tx?.comment || tx?.verification) && (
                    <div className='modal-desc-wrap'>
                      {tx?.amount && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Transaction amount</div>
                          <div className='el-text-m semi modal-desc-text'>
                          {transformNumbers(tx?.amount, 2)} {tx?.currency}
                          </div>
                        </div>
                      )}
                      {tx?.comment && tx?.comment.length > 0 && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Comment</div>
                          <div className='el-text-m modal-desc-text'>{tx?.comment}</div>
                        </div>
                      )}
                      {tx?.verification && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Verification</div>
                          <div className='el-text-m modal-desc-text verification'>
                            {' '}
                            {visible ? tx?.verification : '****'}
                            <span className='verification-icon' onClick={toggleVisibleVerification}>
                              <img
                                src={visible ? iconEye : iconEyeHidden}
                                alt='Toggle visibility'
                              />
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {/* sender */}
                  {(tx?.agentSender?.name ||
                    tx?.sender?.name ||
                    tx?.sender?.phoneNumber ||
                    tx?.sender?.location) && (
                    <div className='modal-desc-wrap'>
                      {tx?.agentSender?.name && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Sending Agent</div>
                          <div className='el-text-m modal-desc-text'>{tx?.agentSender?.name}</div>
                        </div>
                      )}
                      {tx?.sender?.name && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Sender</div>
                          <div className='el-text-m modal-desc-text'>{tx?.sender?.name}</div>
                        </div>
                      )}

                      {tx?.sender?.phoneNumber && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Sender’s phone</div>
                          <div className='el-text-m modal-desc-text name'>
                            {tx?.sender?.phoneNumber}
                          </div>
                        </div>
                      )}

                      {tx?.sender?.location && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Sender’s location</div>
                          <div className='el-text-m modal-desc-text'>
                            {tx?.sender?.location?.country}, {tx?.sender?.location?.city}
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {(tx?.receiver?.name || tx?.receiver?.phoneNumber || tx?.receiver?.location) && (
                    <div className='modal-desc-wrap'>
                      {/* Receiver’s cash pickup location */}
                      {tx?.pickupLocation && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>
                            Receiver’s cash pickup location
                          </div>
                          <div className='el-text-m modal-desc-text'>
                            {tx?.pickupLocation?.country}, {tx?.pickupLocation?.suburb}
                          </div>
                        </div>
                      )}
                      {tx?.receiver?.name && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Receiver</div>
                          <div className='el-text-m modal-desc-text'>{tx?.receiver?.name}</div>
                        </div>
                      )}
                      {tx?.receiver?.phoneNumber && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Receiver’s phone</div>
                          <div className='el-text-m modal-desc-text name'>
                            {tx?.receiver?.phoneNumber}
                          </div>
                        </div>
                      )}
                      {tx?.receiver?.location && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Receiver’s location</div>
                          <div className='el-text-m modal-desc-text'>
                            {tx?.receiver?.location?.country}, {tx?.receiver?.location?.city}
                          </div>
                        </div>
                      )}
                      {/* Date of birth */}
                      {tx?.receiver?.dateOfBirth && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Date of birth</div>
                          <div className='el-text-m modal-desc-text'>
                            {tx?.receiver?.dateOfBirth}
                          </div>
                        </div>
                      )}
                      {/* Gender */}
                      {tx?.receiver?.gender && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Gender</div>
                          <div className='el-text-m modal-desc-text'>{tx?.receiver?.gender}</div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}

              {!tx?.agentSender?.uid && tx?.sender?.uid === userUid ? (
                <>
                  {/* send to agent */}
                  {(tx?.amount ||
                    tx?.fee ||
                    tx?.totalAmount ||
                    tx?.comment ||
                    tx?.verification) && (
                    <div className='modal-desc-wrap'>
                      {tx?.amount && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>You sent exactly</div>
                          <div className='el-text-l semi modal-desc-text'>
                            {transformNumbers(tx?.amount, 2)} {tx?.currency}
                          </div>
                        </div>
                      )}
                      {/* fee */}
                      {( tx?.fee && tx?.fee !== '0') && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Transaction fee</div>
                          <div className='el-text-m modal-desc-text'>
                          {transformNumbers(tx?.fee, 4)} {tx?.feeCurrency}
                          </div>
                        </div>
                      )}
                      {/* feeInUSD */}
                      {tx?.feeInUSD && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Transaction fee in USD</div>
                          <div className='el-text-m modal-desc-text'>
                          {transformNumbers(tx?.feeInUSD, 4)} USD
                          </div>
                        </div>
                      )}
                      {/* total for all transaction */}
                      {tx?.totalAmount && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Total</div>
                          <div className='el-text-l semi modal-desc-text'>
                            {transformNumbers(tx?.totalAmount, 2)} {tx?.currency}
                          </div>
                        </div>
                      )}
                      {tx?.txAmountInUSD && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>USD Equivalent</div>
                          <div className='el-text-m modal-desc-text'>
                            {transformNumbers(tx?.txAmountInUSD, 2)} USD
                          </div>
                        </div>
                      )}
                      {tx?.comment && tx?.comment.length > 0 && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Comment</div>
                          <div className='el-text-m modal-desc-text'>{tx?.comment}</div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : !tx?.agentReceiver?.uid && tx?.receiver?.uid === userUid ? (
                <>
                  {/* receive from agent */}
                  {(tx?.amount || tx?.comment) && (
                    <div className='modal-desc-wrap'>

                      {tx?.amount && (
                        <div className='modal-desc-item'>
                          { txOperation === 'crypto deposit' ? (
                          <div className='el-text-m modal-desc-label'>You deposited</div>
                           )  : (
                            <div className='el-text-m modal-desc-label'>You received</div>
                           )}
                          <div className='el-text-l semi modal-desc-text'>
                            {transformNumbers(tx?.amount, 2)} {tx?.currency}
                          </div>
                        </div>
                      )}

                      {tx?.comment && tx?.comment.length > 0 && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Comment</div>
                          <div className='el-text-m modal-desc-text'>{tx?.comment}</div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}

              {/* send to M-Pesa */}
              {(txPaymentSystem === 'mpesa' || txOperation === 'mpesa' || txOperation === 'mpesa-agent') && (
                <>
                  {(tx?.amount ||
                    tx?.fee ||
                    tx?.totalAmount ||
                    tx?.comment ||
                    tx?.verification) && (
                    <div className='modal-desc-wrap'>
                      {tx?.amount && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>You sent exactly</div>
                          <div className='el-text-m modal-desc-text'>
                          {transformNumbers(tx?.amount, 2)} {tx?.currency}
                          </div>
                        </div>
                      )}
                      {/* fee */}
                      {( tx?.fee && tx?.fee !== '0') && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Transaction fee</div>
                          <div className='el-text-m modal-desc-text'>
                          {transformNumbers(tx?.fee, 4)} {tx?.feeCurrency}
                          </div>
                        </div>
                      )}
                      {/* total for all transaction */}
                      {tx?.totalAmount && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Total</div>
                          <div className='el-text-m modal-desc-text'>
                            {tx?.totalAmount} {tx?.currency}
                          </div>
                        </div>
                      )}
                      {tx?.txAmountInUSD && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>USD Equivalent</div>
                          <div className='el-text-m modal-desc-text'>
                            {transformNumbers(tx?.txAmountInUSDNumber, 2)} USD
                          </div>
                        </div>
                      )}
                      {tx?.comment && tx?.comment.length > 0 && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Comment</div>
                          <div className='el-text-m modal-desc-text'>{tx?.comment}</div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          {((tx?.type === 'outgoing' && tx?.status !== 'cancelled') ||
          tx?.type === 'incoming'||
          txPaymentSystem === 'mpesa' ||
            txOperation === 'mpesa' ||
            txOperation === 'mpesa-agent' ||
            txOperation === 'top up') && (
            <>
              {/* register user reward */}
              {tx?.registerUserRewards && Number(tx?.registerUserRewards) !== 0 ? (
                <>
                  <div className='modal-reward'>
                    <div className='modal-reward-wrap'>
                      <div className='modal-reward-left'>
                        <div className='modal-reward-left-img'>
                          <img src={imgs['icon-reward-no-bg.png']} alt='reward image' />
                        </div>
                        <div className='modal-reward-label'>
                          {Number(tx?.registerUserRewards).toFixed(6)} PESA
                        </div>
                      </div>
                      <div className='modal-reward-text'>
                      Your reward for a newly registered user
                        <span className='modal-reward-text-icon' onClick={() => handleClick(false)}>
                          <svg
                            width='16'
                            height='16'
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 0.999999 8 0.999999C11.866 1 15 4.13401 15 8Z'
                              stroke='#6D757D'
                              strokeWidth='2'
                            />
                            <path
                              d='M9 11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55228 10 9 10.4477 9 11Z'
                              fill='#6D757D'
                            />
                            <path
                              d='M6 6.5V6.5C6 5.67157 6.67157 5 7.5 5H8.76393C9.44659 5 10 5.55341 10 6.23607V6.23607C10 6.70426 9.73548 7.13226 9.31672 7.34164L8.44721 7.77639C8.17313 7.91343 8 8.19357 8 8.5V8.5'
                              stroke='#6D757D'
                              strokeWidth='1.75'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {/* Your reward banner */}
              {tx?.rewardInPesa && Number(tx?.rewardInPesa) !== 0 && (
                <>
                  <div className='modal-reward'>
                    <div className='modal-reward-wrap'>
                      <div className='modal-reward-left'>
                        <div className='modal-reward-left-img'>
                          <img src={imgs['icon-reward-no-bg.png']} alt='reward image' />
                        </div>
                        <div className='modal-reward-label'>
                          {Number(tx?.rewardInPesa).toFixed(6)} PESA
                        </div>
                      </div>
                      <div className='modal-reward-text'>
                        Your reward
                        <span className='modal-reward-text-icon' onClick={() => handleClick(false)}>
                          <svg
                            width='16'
                            height='16'
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 0.999999 8 0.999999C11.866 1 15 4.13401 15 8Z'
                              stroke='#6D757D'
                              strokeWidth='2'
                            />
                            <path
                              d='M9 11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55228 10 9 10.4477 9 11Z'
                              fill='#6D757D'
                            />
                            <path
                              d='M6 6.5V6.5C6 5.67157 6.67157 5 7.5 5H8.76393C9.44659 5 10 5.55341 10 6.23607V6.23607C10 6.70426 9.73548 7.13226 9.31672 7.34164L8.44721 7.77639C8.17313 7.91343 8 8.19357 8 8.5V8.5'
                              stroke='#6D757D'
                              strokeWidth='1.75'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}

          {showRepeatBtnRule && (
            <div className='modal-btn'>
              <Link
                variant='outlined'
                to={
                  transformString(tx?.paymentSystem) === 'pesabase'
                    ? '/agent/send/system/agent'
                    : '/agent/send/system/mpesa'
                }
                className='el-button orange'
                state={{
                  transferData: {
                    paymentSystem:
                      transformString(tx?.paymentSystem) === 'PESABASE' ? 'pesabase' : 'm-pesa',
                    userReceiver: tx?.receiver,
                    userReview: {
                      sendValue: tx?.amount || tx?.totalAmount,
                      currency: tx?.currency.toLowerCase(),
                    },
                  },
                }}
              >
                Repeat transaction
              </Link>
            </div>
          )}
        </div>
      </DialogContent>

      <Popup open={popup}  togglePopUp={togglePopup}>
        <div className='modal-content'>
          <div className='modal-img'>
            <ReactPlayer
              className='player'
              controls={false}
              playing={true}
              loop={true}
              muted={true}
              playsinline={true}
              url={rewardsbanner}
              width='100%'
              height='100%'
            />
          </div>

          <div className='modal-desc'>
            <div className='modal-desc-title'>
            {registration
                ? 'Register new users and receive bonus to your balance!'
                : 'Rewards for you!'}
              </div>

            <div className='el-text-m modal-desc-text'>
              {registration ? (
                <>
                  {`
                  We pay you ${tx?.registerUserRewards} PESA each time you register a new user and they make 100 USD of transactions.
                  Your referral bonus will be added to your PESA balance after the transaction is processed.
                  You can also find it in your Activity history.
                  `}
                  <Link target='_blank' to={'/referral'} className='el-button-link'>
                    More details here
                  </Link>
                </>
              ) : (
                <>
                  {`
                  You will receive up to ${tx?.rewardInPesa} PESA for this transaction.
                  We reward you each time you use our platform to send money.
                  Your reward will be added to your PESA balance after the transaction is processed.
                  You can find it in the transaction details in your Activity history.
                  `}
                  <Link target='_blank' to={'/rewards'} className='el-button-link'>
                    More details here
                  </Link>
                </>
              )}
            </div>
          </div>

          <div className='modal-btns'>
            <Button
              className='el-button orange el-button-continue'
              variant='contained'
              onClick={togglePopup}
            >
              Ok, got it
            </Button>
          </div>
        </div>
      </Popup>
    </Drawer>
  );
};

AgentTxItemPopup.propTypes = {
  tx: PropTypes.object,
  toggleTxDetails: PropTypes.func,
};

export default AgentTxItemPopup;
