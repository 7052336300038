import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import UserTxItem from '../../user/components/UserTxItem';
import AgentTxItem from '../../agent/components/AgentTxItem';
import Filter from '../../components/Filter';
import { getTXlist } from '../../api';
import { useSelector } from 'react-redux';
import { selectFirebaseJWT } from '../../redux/features/user/userSlice';
import { toast } from 'react-toastify';
import UserContext from '../../UserContext/UserContext';
import { selectAccount } from '../../redux/features/user/userSlice';
import ActivitySearchField from '../../components/ActivitySearchField/ActivitySearchField';
import { v4 as uuidv4 } from 'uuid';
import useUpperCase from '../../hooks/useUpperCase';
import AgentTxItemPopup from '../../agent/components/AgentTxItemPopup';
import UserTxItemPopup from '../../user/components/UserTxItemPopup';
import useUserRole from '../../utils/useUserRole';

const Transactions = () => {
  const userToken = useSelector(selectFirebaseJWT);
  const { loading, setLoading } = useContext(UserContext);
  const [localLoading, setLocalLoading] = useState(false);
  const userUid = useSelector(selectAccount).uid;
  const isAgent = useUserRole('agent');
  const [originalTxs, setOriginalTxs] = useState([]);
  const [filteredTxs, setFilteredTxs] = useState([]);
  const [filteredTypeTxs, setFilteredTypeTxs] = useState([]);
  const [filteredPersonsTxs, setFilteredPersonsTxs] = useState([]);
  const [filteredStatusTxs, setFilteredStatusTxs] = useState([]);
  const [searchTxs, setSearchTxs] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(30);
  const [selectedType, setSelectedType] = useState('All types');
  const [selectedPersons, setSelectedPersons] = useState('All people');
  const [selectedStatus, setSelectedStatus] = useState('All statuses');
  const periodOptions = [7, 30, 90, 180, 365];
  const [searchFound, setSearchFound] = useState(false);
  const [error, setError] = useState(false);
  const [popup, setPopup] = useState({
    agentPopup: false,
    userPopup: false,
  });
  const { userPopup, agentPopup } = popup;

  const [currentTX, setCurrentTX] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [clearSearch, setClearSearch] = useState(false);


  const togglePopup = (tx) => {
    if (isAgent) {
      setPopup({ ...popup, agentPopup: !agentPopup });
    } else {
      setPopup({ ...popup, userPopup: !userPopup });
    }
    setCurrentTX(tx);
  };

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const handleClear = () => {
    setSearchValue('');
    setClearSearch(true);
  };

  const typeOptions = useMemo(() => {
    const options = ['All types'];
    const allTxData = Object.values(searchTxs || {}).flat();
    allTxData.forEach((tx) => {
      if (tx?.operation && !options.includes(useUpperCase(tx.operation))) {
        options.push(useUpperCase(tx.operation));
      }
    });
    return options;
  }, [searchTxs]);

  const personsOptions = useMemo(() => {
    const options = ['All people'];
    const allTxData = Object.values( filteredTypeTxs || {}).flat();
    allTxData.forEach((tx) => {
      if (tx?.sender?.name && !options.includes(tx.sender.name)) {
        options.push(tx.sender.name);
      }
      if (tx?.agentSender?.name && !options.includes(tx.agentSender.name)) {
        options.push(tx.agentSender.name);
      }
      if (tx?.receiver?.name && !options.includes(tx.receiver.name)) {
        options.push(tx.receiver.name);
      }
      if (tx?.agentReceiver?.name && !options.includes(tx.agentReceiver.name)) {
        options.push(tx.agentReceiver.name);
      }
    });
    return options;
  }, [filteredTypeTxs]);

  const statusOptions = useMemo(() => {
    const options = ['All statuses'];
    const allTxData = Object.values(filteredPersonsTxs || {}).flat();
    allTxData.forEach((tx) => {
      if (tx?.status && !options.includes(tx.status)) {
        options.push(tx.status);
      }
    });
    return options;
  }, [filteredPersonsTxs]);

  const handlePeriodChange = async (period) => {
    setSearchValue('');
    setSelectedPeriod(period);
    setSelectedType('All types');
    setSelectedPersons('All people');
    setSelectedStatus('All statuses');
    setClearSearch(true);
    await fetchData(period);
  };

  const handleTypeChange = (type) => {
    setSelectedType(type);
    setSelectedPersons('All people');
    setSelectedStatus('All statuses');
    filterTransactions('first',searchTxs,type, 'All people', 'All statuses');
  };

  const handlePersonsChange = (persons) => {
    setSelectedPersons(persons);
    setSelectedStatus('All statuses');
    filterTransactions('second',searchTxs,selectedType, persons, 'All statuses');
  };

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    filterTransactions('third',searchTxs,selectedType, selectedPersons, status);
  };


  const filterTransactions = (filter,transactions,type, persons, status) => {
    const filtered = {};
    for (const [key, txs] of Object.entries(transactions)) {
      const filteredTxsForKey = txs.filter((tx) => {
        const isValidType = type === 'All types' || useUpperCase(tx?.operation) === type;
        const isValidPersons =
          persons === 'All people' ||
          [
            tx?.sender?.name,
            tx?.agentSender?.name,
            tx?.receiver?.name,
            tx?.agentReceiver?.name,
          ].includes(persons);
        const isValidStatus = status === 'All statuses' || tx?.status === status;
        return isValidType && isValidPersons && isValidStatus;
      });
      if (filteredTxsForKey.length > 0) {
        filtered[key] = filteredTxsForKey;
      }
    }
    if (filter ==='first'){
      setFilteredTypeTxs(filtered);
    } else if (filter ==='second'){
      setFilteredPersonsTxs(filtered);
    } else{
      setFilteredStatusTxs(filtered);
    }
    setFilteredTxs(filtered);
  };


  const toggleLoading = (value) => setLocalLoading(value);
  // Function to fetch transaction data from API
  const fetchData = async (selectedPeriod) => {
    try {
      setLocalLoading(true);
      const data = await getTXlist(userToken, selectedPeriod);
      const newTxs = data?.data?.activity;
      setOriginalTxs(newTxs);
      setFilteredTxs(newTxs);
      setSearchTxs(newTxs);
      setFilteredTypeTxs(newTxs);
      setFilteredPersonsTxs(newTxs);
      setFilteredStatusTxs(newTxs);
      setSearchFound(false);
    } catch (error) {
      console.log('getTXlist error:', error);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      setError(true);
    } finally {
      setLocalLoading(false);
      setLoading(false);
      setClearSearch(false);
    }
  };

  const filterBySearch = (transactions,searchValue) => {
    const normalizedSearch = searchValue.toLowerCase().trim();
    const filtered = {};

    for (const [key, txs] of Object.entries(transactions)) {
      const filteredTxsForKey = txs.filter((tx) => {
        return (
          tx?.receiver?.phoneNumber?.toLowerCase().includes(normalizedSearch) ||
          tx?.sender?.phoneNumber?.toLowerCase().includes(normalizedSearch) ||
          tx?.agentReceiver?.phoneNumber?.toLowerCase().includes(normalizedSearch) ||
          tx?.agentSender?.phoneNumber?.toLowerCase().includes(normalizedSearch) ||
          tx?.receiver?.name?.toLowerCase().includes(normalizedSearch) ||
          tx?.sender?.name?.toLowerCase().includes(normalizedSearch) ||
          tx?.agentReceiver?.name?.toLowerCase().includes(normalizedSearch) ||
          tx?.agentSender?.name?.toLowerCase().includes(normalizedSearch)
        );
      });

      if (filteredTxsForKey.length > 0) {
        filtered[key] = filteredTxsForKey;
      }
    }
    if (Object.keys(filtered).length === 0) {
      setFilteredTxs(transactions);
      setSearchTxs(transactions);
      setSearchFound(true);
    } else {
      setFilteredTxs(filtered);
      setSearchTxs(filtered);
      setSearchFound(false);
    }

  };



  useEffect(() => {
    if (searchValue !== '') {
      setSelectedType('All types');
      setSelectedPersons('All people');
      setSelectedStatus('All statuses');
      filterBySearch(originalTxs,searchValue);
    } else {
      setSelectedType('All types');
      setSelectedPersons('All people');
      setSelectedStatus('All statuses');
      fetchData(selectedPeriod);
    }

  }, [searchValue]);

  useEffect(() => {
    fetchData(selectedPeriod);
  }, [selectedPeriod]);


  return (
    <>
      {!loading && !error && (
        <div className='tx'>
          <div className='tx-search'>
            <div className='tx-search-wrap'>
              <h6 className='el-title-h6 med'>Browse your transactions:</h6>
              <ActivitySearchField
                onSearch={handleSearch}
                onClear={handleClear}
                placeholderText='Search'
                clearSearch={clearSearch}
                toggleLoading={() => {}}
              />
            </div>
          </div>

          <Filter
            onPeriodChange={handlePeriodChange}
            onTypeChange={handleTypeChange}
            onPersonsChange={handlePersonsChange}
            onStatusChange={handleStatusChange}
            periodOptions={periodOptions}
            typeOptions={typeOptions}
            personsOptions={personsOptions}
            statusOptions={statusOptions}
            defaultPeriod={selectedPeriod}
            defaultType={selectedType}
            defaultPersons={selectedPersons}
            defaultStatus={selectedStatus}
          />

          <div className='tx-list'>

            {(Object.entries(filteredTxs).length > 0 && !searchFound) &&
              Object.entries(filteredTxs).map(
                ([key, txs]) =>
                  txs.length > 0 && (
                    <div className='tx-block' key={key}>
                      {!localLoading && <div className='el-text-s med activity-date'>{key}</div>}

                      <div className='tx-table'>
                        {!localLoading &&
                          txs.map((tx) => (
                            <div className='tx-item-inner' key={uuidv4()}>
                              {isAgent ? (
                                <div onClick={() => togglePopup(tx)}>
                                  <AgentTxItem tx={tx} userUid={userUid} />
                                </div>
                              ) : (
                                <div onClick={() => togglePopup(tx)}>
                                  <UserTxItem tx={tx} userUid={userUid} />
                                </div>
                              )}
                            </div>
                          ))}
                      </div>
                    </div>
                  )
              )}

            {localLoading && (
              <div className='tx-table'>
                {[...new Array(5)].map(() => (
                  <UserTxItem loading={localLoading} key={uuidv4()} />
                ))}
              </div>
            )}

            {Object.entries(filteredTxs).length === 0 && !localLoading && !loading && !searchFound && (
              <div className='dashboard-contacts-empty el-text-m'>
                <span className='icon'>💵</span>
                No recent transactions yet. Please,
                <Link className='el-link-m' to={'/user/send/system'}>
                  {' '}
                  send some money{' '}
                </Link>
                to see your recent contacts here.
              </div>
            )}

            {!localLoading && !loading && searchFound && (
              <div className='tx-no-results el-text-m' >
              🔍 No transactions found.
              </div>
            )}
          </div>
        </div>
      )}
      {!loading && error && <h6 className='el-title-error'>Error</h6>}

      <AgentTxItemPopup
        tx={currentTX}
        userUid={userUid}
        open={agentPopup}
        toggleTxDetails={togglePopup}
      />

      <UserTxItemPopup
        tx={currentTX}
        userUid={userUid}
        open={userPopup}
        toggleTxDetails={togglePopup}
      />
    </>
  );
};

export default Transactions;
