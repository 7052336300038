import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Review from '../../components/Review';
import ArrowBack from '..//ArrowBack';
import { useSelector } from 'react-redux';
import { selectFirebaseJWT } from '../../redux/features/user/userSlice';
import { getOTP , getWhatsAppOTP} from '../../api';
import closeFlow from '../../utils/closeFlow';
import { LoadingButton } from '@mui/lab';

const BuyReview = ({ changeStep, currencyValue }) => {
  const userToken = useSelector(selectFirebaseJWT);
  const [localLoading, setLocalLoading] = useState(false);

  const goToTheNextStep = async () => {
    try {
      setLocalLoading(true);
      await getOTP(userToken);
      changeStep(1);
    } catch (error) {
      console.log(error);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
    } finally {
      setLocalLoading(false);
    }
  };

  return (
    <div className='buy-step buy-step-2'>
      <ArrowBack handle={() => changeStep(-1)} />

      <div className='payment-title'>
        <h6 className='el-title-h6 med'>Review details of your transfer</h6>
      </div>

      <div className='buy-step-wrap'>
        <div className='buy-step-content'>
          <Review
            changeStep={changeStep}
            buyData={{
              title: 'Review details of your transfer',
              youPay: currencyValue.usd,
              youBuy: currencyValue.pesa,
            }}
          />
        </div>
      </div>

      <div className='buy-btns'>
        <div className='buy-btns-continue'>
          <LoadingButton
            loading={localLoading}
            onClick={goToTheNextStep}
            className='el-button orange'
          >
            <span>{localLoading ? 'Processing' : 'Continue'}</span>
          </LoadingButton>
        </div>

        <div className='buy-btns-cancel'>
          <div className='buy-btns-cancel-wrap'>
            <span className='el-link-m el-button-link' onClick={closeFlow} variant='contained'>
              Cancel
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

BuyReview.propTypes = {
  changeStep: PropTypes.func.isRequired,
  // onDialogOpen: PropTypes.func.isRequired,
  currencyValue: PropTypes.object.isRequired,
  setNumberOTP: PropTypes.func.isRequired,
};
export default BuyReview;
