import React from 'react';
import PropTypes from 'prop-types';
import ArrowBack from '../../../../../components/ArrowBack';
import VerifyOption from '../../../../../components/VerifyOption';
import iconPassport from '../../../../../assets/icons/icon-passport.png';
import iconID from '../../../../../assets/icons/icon-id.png';
import iconLicence from '../../../../../assets/icons/icon-licence.png';
import { v4 as uuidv4 } from 'uuid';

const NewUserVerify = ({ changeDocType, changeStep }) => {
  const docs = [
    {
      image: iconPassport,
      title: 'Passport',
      subtitle: 'Face photo page',
    },
    {
      image: iconID,
      title: 'National ID',
      subtitle: 'Front and back',
    },
    {
      image: iconLicence,
      title: 'Driver licence',
      subtitle: 'Front and back',
    },
  ];

  return (
    <div className='send-step'>
      <ArrowBack handle={() => changeStep('newUserSteps', -1)} />

      <div className='payment-title'>
        <h6 className='el-title-h6 med'>
          Verify <span className='orange'>Sender’s</span> identity
        </h6>

        <span className='el-text-s payment-title--subtitle'>
          Select the type of document you would like to upload
        </span>
      </div>

      <div className='send-step-content'>
        <div className='c-option-verify'>
          {docs.map(({ image, title, subtitle }, index) => {
            return (
              <div key={uuidv4()} onClick={() => changeDocType(index)}>
                <VerifyOption image={image} title={title} subtitle={subtitle} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

NewUserVerify.propTypes = {
  changeStep: PropTypes.func.isRequired,
};

export default NewUserVerify;
