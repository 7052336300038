import React, { useState } from 'react';
import PropTypes from 'prop-types';
import closeIcon from '../../assets/icons/icon-close-gray.svg';
import Skeleton from '@mui/material/Skeleton';
import Popup from '../Popup';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setBanners } from '../../redux/features/user/userSlice';
import ReactPlayer from 'react-player';
import rewardsbanner from '../../assets/anims/videos/popups/rewards_ff.mp4';
import { Link } from 'react-router-dom';

const Reward = ({ type, data, linkText, loading = false }) => {
  const dispatch = useDispatch();
  const { image, title, text, summary, info, textEmail } = data || {};
  const [popup, setPopup] = useState(false);
  const togglePopup = () => setPopup(!popup);

  const hideBanner = () => {
    type === 'rewards' && dispatch(setBanners({ rewards: false }));
    type === 'email' && dispatch(setBanners({ email: false }));
  };

  return (
    <div className='c-reward'>
      {loading ? (
        <Skeleton animation='wave' variant='rectangular' />
      ) : (
        <div className='c-reward-wrap'>
          {image && (
            <div className='c-reward-img'>
              <img src={image} alt='' />
            </div>
          )}

          <div className='c-reward-content'>
            {title && <div className='el-text-l med title'>{title}</div>}

            {type === 'rewards' && (
              <div className='el-text-s text'>
                Pay transaction fee in PESA and return 5 PESA to your balance.
              </div>
            )}

            {text && type === 'email' && (
              <div className='el-text-s text'>
                {text} <b>{textEmail}</b>
              </div>
            )}

            {type === 'rewards' && (
              <span className='link' onClick={togglePopup}>
                <svg
                  className='link-icon'
                  width='16'
                  height='17'
                  viewBox='0 0 16 17'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M15 8.5C15 12.366 11.866 15.5 8 15.5C4.13401 15.5 1 12.366 1 8.5C1 4.63401 4.13401 1.5 8 1.5C11.866 1.5 15 4.63401 15 8.5Z'
                    stroke='#6D757D'
                    strokeWidth='2'
                  />
                  <path
                    d='M9 11.5C9 12.0523 8.55228 12.5 8 12.5C7.44772 12.5 7 12.0523 7 11.5C7 10.9477 7.44772 10.5 8 10.5C8.55228 10.5 9 10.9477 9 11.5Z'
                    fill='#6D757D'
                  />
                  <path
                    d='M6 7V7C6 6.17157 6.67157 5.5 7.5 5.5H8.76393C9.44659 5.5 10 6.05341 10 6.73607V6.73607C10 7.20426 9.73548 7.63226 9.31672 7.84164L8.44721 8.27639C8.17313 8.41343 8 8.69357 8 9V9'
                    stroke='#6D757D'
                    strokeWidth='1.75'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>

                <span className='el-link-s semi link-content'>{linkText}</span>
              </span>
            )}

            {type === 'email' && info && (
              <Link to={info} className='link' onClick={togglePopup}>
                <span className='el-link-s semi link-content'>{linkText}</span>
              </Link>
            )}
          </div>

          <span className='c-reward-close' onClick={hideBanner}>
            <img src={closeIcon} />
          </span>
        </div>
      )}

      <Popup open={popup} togglePopUp={togglePopup}>
        <div className='modal-content'>
          <div className='modal-img'>
            <ReactPlayer
              className='player'
              controls={false}
              playing={true}
              loop={true}
              muted={true}
              playsinline={true}
              url={rewardsbanner}
              width='100%'
              height='100%'
            />
          </div>

          <div className='modal-desc'>
            <div className='modal-desc-title'>{summary}</div>

            {text && (
              <div
                className='el-text-m modal-desc-text'
                dangerouslySetInnerHTML={{ __html: text }}
              />
            )}
          </div>

          <div className='modal-btns'>
            <Button
              className='el-button orange el-button-continue'
              variant='contained'
              onClick={togglePopup}
            >
              Ok, got it
            </Button>
          </div>
        </div>
      </Popup>
    </div>
  );
};

Reward.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  linkText: PropTypes.string,
  loading: PropTypes.bool,
};

export default Reward;
