import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Link} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrder, updatecurrentOrder } from '../../../redux/features/transaction/remittanceSlice';
import {
  ChatMSG,
  ChatLogMSG,
  ChatSideBar,
  ChatSenderMSG,
  ChatReceiverMSG,
} from '../../components/AgentChat';
import { selectAccount } from '../../../redux/features/user/userSlice';
import { send_message, update_current_order } from '../../../redux/features/sockets/socketslice';
import dayjs from 'dayjs';

const AgentChat = ({ messages }) => {
  const order = useSelector(selectOrder);
  const userUID = useSelector(selectAccount).uid;
  const dispatch = useDispatch();
  //   const [messages, setMessages] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const chatEndRef = useRef(null);

  const chatUnixTimestamp = Math.floor(Number(order?.timestamp) / 1000);
  const date = dayjs.unix(chatUnixTimestamp);
  const isAgentReceiver = order?.agentReceiver?.uid === userUID;

  const isMyMessageReceiver = order?.agentReceiver?.uid === userUID ? true : false;
  const isMyMessageSender = order?.agentSender?.uid === userUID ? true : false;

  const isDeclined = order?.agentReceiver?.name ? false : true;

  const handleSendMessage = (comment) => {
    console.log(`send message object via socket`);
    dispatch(send_message({ tx: order.txId, message: comment }));
  };

  useEffect(() => {
      dispatch(updatecurrentOrder(order));
  }, [order]);

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className='chat'>
      <ChatSideBar order={order} />

      <div className='chat-box'>
        <div className='chat-box-inner'>
          <div className='chat-dialogue'>
            <div className='chat-dialogue-wrap'>
              <div className='chat-initiated'>Chat initiated</div>

              {/* when pending and declined hide this */}
              {Array.isArray(messages) &&
                messages?.map((msg, index) => {
                  //Ordinary messages
                  if ((msg.type === 'message')||(msg.type === 'button')) {
                    //agent sender message
                    if (msg.from.uid === order?.agentSender?.uid) {
                      return (
                        <ChatSenderMSG
                          {...msg}
                          key={index}
                          isMyMessageSender={isMyMessageSender}
                          senderAvatar={order?.agentSender}
                          orderTxId={order?.txId}
                        />
                      );
                    } else {
                      //agent receiver message
                      return (
                        <ChatReceiverMSG
                          {...msg}
                          key={index}
                          isMyMessageReceiver={isMyMessageReceiver}
                          receiverAvatar={order?.agentReceiver}
                        />
                      );
                    }

                  }
                 //log messages
                  if (msg.type === 'log') {
                    return <ChatLogMSG {...msg} logType={msg.logType} key={index} />;
                  }
                })}

              {!isAgentReceiver && isDeclined ? (
                <div className='chat-agent-links'>
                  Please,{' '}
                  <Link className='el-button-link' to='/agent/chat/reassign'>
                    click here
                  </Link>{' '}
                  to change the destination of current transaction
                </div>
              ) : null}
              <div className='chat-dialogue-wrap-anchor' ref={chatEndRef}>
                {' '}
              </div>
            </div>
          </div>

          <ChatMSG sendMessage={handleSendMessage} />
        </div>
      </div>
    </div>
  );
};

export default AgentChat;
