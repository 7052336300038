import React, { useEffect, useState, useRef  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer } from '@mui/material';
import { ReactComponent as IconNotifications } from '../../assets/icons/icon-notifications.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from '@mui/material/Skeleton';
import getDataLength from '../../utils/getDataLength';
import { v4 as uuidv4 } from 'uuid';
import { get_notifictaions, selectNotifications } from '../../redux/features/sockets/socketslice';
import NotificationMenu from './NotificationMenu';
import { updateNotification, deleteNotification } from '../../api';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { selectFirebaseJWT } from '../../redux/features/user/userSlice';

const Notifications = () => {
  const dispatch = useDispatch();
  const userToken = useSelector(selectFirebaseJWT);
  const { page, limit, unreadCount, messages, hasMore } = useSelector(selectNotifications);

  const [notifications, setNotifications] = useState({
    popupState: false,
    loadedMessages: {},
  });
  const [notifyClass, setNotifyClass] = useState('null-notify');
  const prevUnreadCount = useRef(0);

  useEffect(() => {
    if (unreadCount > prevUnreadCount.current) {
      // console.log('prevUnreadCount.current ', prevUnreadCount.current);
      if (prevUnreadCount.current === 0) {
        setNotifyClass('first-notify');
      } else {
        setNotifyClass('alarm-notify');
      }
    } else if (unreadCount === 0) {
      setNotifyClass('null-notify');
    }

    prevUnreadCount.current = unreadCount;
    // console.log('unreadCount ', unreadCount);
    if (unreadCount > 0) {
      const timer = setTimeout(() => {
        setNotifyClass('');
      }, 3000);
      return () => clearTimeout(timer);
    }

  }, [unreadCount]);

  const { popupState, loadedMessages } = notifications;

  const updateNotificationStatus = async (id, read, promt = true) => {
    try {
      const response = await updateNotification(
        userToken,
        id,
        read === 'false' || !read ? true : false
      );

      if (response.status === 200) {
        setNotifications((prevState) => {
          const updatedMessages = { ...prevState.loadedMessages };

          for (const month in updatedMessages) {
            const messagesInMonth = updatedMessages[month];

            const updatedMonthMessages = messagesInMonth.map((message) => {
              if (message.id === id) {
                console.log(message);
                return { ...message, read: read === 'false' || !read ? true : false };
              }
              return message;
            });

            updatedMessages[month] = updatedMonthMessages;
          }

          return {
            ...prevState,
            loadedMessages: updatedMessages,
          };
        });

        promt && toast.success('Notification updated');
        console.log('notification updated');
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      console.log('Notifications --> updateNotificationStatus', error);
    }
  };

  const delNotification = async (notificationId) => {
    try {
      const response = await deleteNotification(userToken, notificationId);

      if (response.status === 200) {
        setNotifications((prevState) => {
          const updatedMessages = { ...prevState.loadedMessages };

          for (const month in updatedMessages) {
            const messagesInMonth = updatedMessages[month];

            const updatedMonthMessages = messagesInMonth.filter((message) => {
              return message.id !== notificationId;
            });

            updatedMessages[month] = updatedMonthMessages;
          }

          return {
            ...prevState,
            loadedMessages: updatedMessages,
          };
        });
        toast.success('Notification deleted');
      } else {
        throw new Error('Something went wrong...');
      }
    } catch (error) {
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      console.log('Notifications --> deleteNotification', error);
    }
  };

  const getFirstNotification = () => {
    dispatch(get_notifictaions({ currentPage: 1, limit: `${limit}` }));
  };

  const togglePopUp = (popupState) => {
    if (!popupState) {
      getFirstNotification();
    }

    setNotifications((prevState) => ({
      ...prevState,
      popupState: popupState,
    }));
  };

  const loadMoreMessages = () => {
    if (!hasMore) return;
    dispatch(get_notifictaions({ currentPage: page + 1, limit: `${limit}` }));
  };

  const mergeObjects = (obj1, obj2) => {
    const result = {};

    const allKeys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

    allKeys.forEach((key) => {
      const itemMap = new Map();
      const array1 = obj1[key] || [];
      const array2 = obj2[key] || [];
      const combinedArray = [...array1, ...array2];

      combinedArray.forEach((item) => {
        if (itemMap.has(item.id)) {
          itemMap.set(item.id, { ...itemMap.get(item.id), ...item });
        } else {
          itemMap.set(item.id, item);
        }
      });

      result[key] = Array.from(itemMap.values());
    });

    return result;
  };

  const skeleton = (
    <div className='c-notifications-content-block loading'>
      {[...new Array(10)].map(() => (
        <div className='el loading' key={uuidv4()}>
          <Skeleton animation='wave' variant='rectangular' />
        </div>
      ))}
    </div>
  );

  const parseTime = (timestamp) => {
    const date = dayjs(timestamp).format('DD/MM/YY');
    const time = dayjs(timestamp).format('HH:mm:ss');

    return (
      <div className='el-text-s med el--date'>
        {date}
        <span className='el--time'>{time}</span>
      </div>
    );
  };

  useEffect(() => {
    if (messages) {
      setNotifications((prevState) => {
        return {
          ...prevState,
          loadedMessages: popupState ? mergeObjects(prevState.loadedMessages, messages) : messages,
        };
      });
    }
  }, [messages]);

  useEffect(() => {
    getFirstNotification();
  }, []);

  return (
    <div className='user-box-icon'>
      <div className={`user-box-icon-ring ${notifyClass}`}>
        <IconNotifications onClick={() => togglePopUp(true)} />
        <span className='user-box-icon-counter'>{unreadCount <= 9 ? unreadCount : '9+'}</span>
      </div>

      <Drawer
        className='c-notifications'
        anchor={'right'}
        open={popupState}
        onClose={() => togglePopUp(false)}
      >
        <div className='c-notifications-top'>
          <span className='el-title-h6 med c-notifications-top-title'>Notifications:</span>

          <span className='c-notifications-top-close' onClick={() => togglePopUp(false)}>
            <svg
              width='100%'
              height='100%'
              viewBox='0 0 40 40'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g id='icons/X'>
                <path
                  id='Union'
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M8.68645 8.68547C8.29593 9.076 8.29593 9.70916 8.68645 10.0997L18.586 19.9993L8.68645 29.8988C8.29592 30.2894 8.29592 30.9225 8.68645 31.313C9.07697 31.7036 9.71014 31.7036 10.1007 31.313L20.0002 21.4135L29.8997 31.3129C30.2902 31.7034 30.9233 31.7034 31.3139 31.3129C31.7044 30.9224 31.7044 30.2892 31.3139 29.8987L21.4144 19.9993L31.3139 10.0998C31.7044 9.70931 31.7044 9.07615 31.3139 8.68562C30.9233 8.2951 30.2902 8.2951 29.8997 8.68562L20.0002 18.585L10.1007 8.68547C9.71014 8.29495 9.07698 8.29495 8.68645 8.68547Z'
                />
              </g>
            </svg>
          </span>
        </div>

        <div className='c-notifications-content' id='scrollableDiv'>
          <InfiniteScroll
            dataLength={getDataLength(loadedMessages)}
            next={loadMoreMessages}
            hasMore={hasMore}
            loader={skeleton}
            scrollableTarget='scrollableDiv'
          >
            {Object.entries(loadedMessages).map((block) => (
              <div className='c-notifications-content-block-wrap' key={uuidv4()}>
                <div className='c-notifications-content-block'>
                  <span className='el-text-s med c-notifications-content-month'>{block[0]}</span>

                  {block[1].map((el) => (
                    <div className='el-wrap' key={uuidv4()}>
                      <NotificationMenu
                        data={el}
                        togglePopUp={togglePopUp}
                        parseTime={parseTime}
                        updateNotificationStatus={updateNotificationStatus}
                        delNotification={delNotification}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </Drawer>
    </div>
  );
};

export default Notifications;
