import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

/**
 * Initialize firebase instance with config settings
 */

const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
// firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

/**
 * Production variables
 */
// const reactAppApi = process.env.REACT_APP_API_URL;
// export const rootCollection = process.env.REACT_APP_ROOT_COLLECTION;

/**
 * Exported firebase apis
 */
export const auth = getAuth(app);
auth.useDeviceLanguage();
// export const firestore = firebase.firestore();
