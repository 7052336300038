import React, { useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import iconUpload from '../../../assets/icons/icon-upload.svg';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { checkUserAccount, getUserProfile, submitKYC } from '../../../api';
import LoadingButton from '@mui/lab/LoadingButton';
import UserContext from '../../../UserContext/UserContext';
import { selectFirebaseJWT, setAccount } from '../../../redux/features/user/userSlice';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import DOBPicker from '../../DOBPicker';

const KycIndetify = ({ docType }) => {
  const [filesFront, setFilesFront] = useState([]);
  const [filesBack, setFilesBack] = useState([]);
  const [filesFace, setFilesFace] = useState([]);
  const { loading, setLoading } = useContext(UserContext);
  const [userData, setUserData] = useState({});
  const userToken = useSelector(selectFirebaseJWT);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [expirationDate, setExpirationDate] = useState(null);
  const docTypeMapping = {
    0: 'passport',
    1: 'id',
    2: 'driver-licence',
  };

  const setDate = (newValue) => setExpirationDate(Date.parse(newValue));

  const handleDrop = (acceptedFiles, setter) => {
    const file = acceptedFiles[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64String = e.target.result;

        setter(
          acceptedFiles.map((file) => ({
            ...file,
            ...file,
            preview: URL.createObjectURL(file),
            raw: base64String,
          }))
        );
      };
      reader.readAsDataURL(file);
    }
  };

  const {
    getRootProps: getRootPropsFront,
    getInputProps: getInputPropsFront,
    open: openFront,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => handleDrop(acceptedFiles, setFilesFront),
    maxFiles: 1,
    maxSize: 5242880,
    accept: {
      'image/*': [],
    },
  });

  const {
    getRootProps: getRootPropsBack,
    getInputProps: getInputPropsBack,
    open: openBack,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => handleDrop(acceptedFiles, setFilesBack),
    maxFiles: 1,
    maxSize: 5242880,
    accept: {
      'image/*': [],
    },
  });

  const {
    getRootProps: getRootPropsFace,
    getInputProps: getInputPropsFace,
    open: openFace,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => handleDrop(acceptedFiles, setFilesFace),
    maxFiles: 1,
    maxSize: 5242880,
    accept: {
      'image/*': [],
    },
  });

  const submitDocs = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const docs = {
        document: {
          type: docTypeMapping[docType] || null,
          front: filesFace[0]?.raw || filesFront[0]?.raw || null,
          back: filesBack[0]?.raw || null,
          expirationDate: `${expirationDate}` || null,
        },
      };
      const userDataResponse = await getUserProfile(userToken);

      const userData = {
        personalInfo: userDataResponse.personalInfo,
        location: userDataResponse.location,
      };

      const response = await submitKYC({ ...userData, ...docs }, userToken);

      if (response.status === 200) {
        const userDataResponse = await checkUserAccount(userToken);
        const response = await getUserProfile(userToken);
        const { account, ...newObj } = response;
        console.log('submitDocs', {
          ...newObj,
          ...response?.account,
          ...{ accInfo: userDataResponse },
        });
        dispatch(setAccount({ ...newObj, ...response?.account, ...{ accInfo: userDataResponse } }));

        setLoading(false);
        navigate('/auth/thanks/kyc', { state: { kyc: true } });
      } else {
        setLoading(false);
        throw new Error(response.message);
      }
    } catch (error) {
      setLoading(false);
      console.log('submitDocs', error);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
    }
  };

  useEffect(() => {
    return () => {
      filesFront.forEach((file) => URL.revokeObjectURL(file.preview));
      filesBack.forEach((file) => URL.revokeObjectURL(file.preview));
      filesFace.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [filesFront, filesBack, filesFace]);

  return (
    <form>
      <div className='auth-title-block'>
        <h6 className='el-title-h6 med'>Identity verification</h6>
      </div>
      <div className='auth-content-indetify'>
        <div className='auth-content-indetify-box'>
          {docType === 0 && (
            <>
              <div {...getRootPropsFace({ className: 'dropzone' })}>
                {filesFace.length === 0 && (
                  <div className='dropzone-img'>
                    <img src={iconUpload} alt='dropzone img' />
                  </div>
                )}

                <div className='dropzone-description'>
                  <input {...getInputPropsFace()} />

                  {filesFace.length === 0 && (
                    <>
                      <span className='el-text-m dropzone-description--title'>
                        Face photo page of you passport
                      </span>

                      <span className='el-text-xs dropzone-description--subtitle'>
                        Upload a photo or scan
                      </span>

                      <ul>
                        <li className='el-text-xs'>Supported formats: JPG; PNG; WEBP</li>
                        <li className='el-text-xs'>Max file weight 5 MB</li>
                      </ul>

                      <Button onClick={openFace} className='el-button orange' variant='outlined'>
                        Choose a file
                      </Button>
                    </>
                  )}

                  {filesFace.length > 0 && (
                    <>
                      <span className='el-text-m dropzone-description--title'>
                        Front side of your document
                      </span>

                      {filesFace.map((file) => (
                        <div className='dropzone-preview' key={uuidv4()}>
                          <img src={file.preview} onLoad={() => file.preview} />
                        </div>
                      ))}

                      <span className='el-text-xs dropzone-preview-name'>{filesFace[0].path}</span>

                      <a className='el-link-s dropzone-description--upload' onClick={openFace}>
                        Upload another file
                      </a>
                    </>
                  )}
                </div>
              </div>
            </>
          )}

          {docType !== 0 && (
            <>
              <div {...getRootPropsFront({ className: 'dropzone' })}>
                {filesFront.length === 0 && (
                  <div className='dropzone-img'>
                    <img src={iconUpload} alt='dropzone img' />
                  </div>
                )}

                <div className='dropzone-description'>
                  <input {...getInputPropsFront()} />

                  {filesFront.length === 0 && (
                    <>
                      <span className='el-text-m dropzone-description--title'>
                        {docType === 1 && 'Front side of your ID card'}
                        {docType === 2 && 'Front side of your driving license'}
                      </span>

                      <span className='el-text-xs dropzone-description--subtitle'>
                        Upload a photo or scan
                      </span>

                      <ul>
                        <li className='el-text-xs'>Supported formats: JPEG; JPG; PNG</li>
                        <li className='el-text-xs'>Max file weight 5 MB</li>
                      </ul>

                      <Button onClick={openFront} className='el-button orange' variant='outlined'>
                        Choose a file
                      </Button>
                    </>
                  )}

                  {filesFront.length > 0 && (
                    <>
                      <span className='el-text-m dropzone-description--title'>
                        {docType === 1 && 'Front side of your ID card'}
                        {docType === 2 && 'Front side of your driving license'}
                      </span>

                      {filesFront.map((file, index) => (
                        <div className='dropzone-preview' key={`file-${index}`}>
                          <img src={file.preview} onLoad={() => file.preview} />
                        </div>
                      ))}

                      <span className='el-text-xs dropzone-preview-name'>{filesFront[0].path}</span>

                      <a className='el-link-s dropzone-description--upload' onClick={openFront}>
                        Upload another file
                      </a>
                    </>
                  )}
                </div>
              </div>

              <div {...getRootPropsBack({ className: 'dropzone' })}>
                {filesBack.length === 0 && (
                  <div className='dropzone-img'>
                    <img src={iconUpload} alt='dropzone img' />
                  </div>
                )}

                <div className='dropzone-description'>
                  <input {...getInputPropsBack()} />

                  {filesBack.length === 0 && (
                    <>
                      <span className='el-text-m dropzone-description--title'>
                        {docType === 1 && 'Back side of your ID card'}
                        {docType === 2 && 'Back side of your driving license'}
                      </span>

                      <span className='el-text-xs dropzone-description--subtitle'>
                        Upload a photo or scan
                      </span>

                      <ul>
                        <li className='el-text-xs'>Supported formats: JPEG; JPG; PNG</li>
                        <li className='el-text-xs'>Max file weight 5 MB</li>
                      </ul>

                      <Button onClick={openBack} className='el-button orange' variant='outlined'>
                        Choose a file
                      </Button>
                    </>
                  )}

                  {filesBack.length > 0 && (
                    <>
                      <span className='el-text-m dropzone-description--title'>
                        {docType === 1 && 'Back side of your ID card'}
                        {docType === 2 && 'Back side of your driving license'}
                      </span>

                      {filesBack.map((file, index) => (
                        <div className='dropzone-preview' key={`file-${index}`}>
                          <img src={file.preview} onLoad={() => file.preview} />
                        </div>
                      ))}

                      <span className='el-text-xs dropzone-preview-name'>{filesBack[0].path}</span>

                      <a className='el-link-s dropzone-description--upload' onClick={openBack}>
                        Upload another file
                      </a>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        <div className='auth-content-indetify-date'>
          <DOBPicker dob={null || expirationDate} setDoB={setDate} expDate={true} />
        </div>

        <div className='send-btns'>
          <LoadingButton
            disabled={
              (docType === 0 && filesFace.length === 0) ||
              (docType === 1 && filesFront.length === 0) ||
              (docType === 2 && filesBack.length === 0) ||
              !expirationDate
            }
            loading={loading}
            className='el-button orange dropzone-submit'
            onClick={(e) => submitDocs(e)}
          >
            <span>{loading ? 'Processing' : 'Continue'}</span>
          </LoadingButton>
        </div>
      </div>
    </form>
  );
};

KycIndetify.propTypes = {
  docType: PropTypes.number.isRequired,
};

export default KycIndetify;
