import React from 'react';
import PropTypes from 'prop-types';
import Amount from '../../../components/Amount';
import ArrowBack from '../../../components/ArrowBack';

const AgentAmount = ({ setData, data, changeStep }) => {
  return (
    <div className='send-step'>
      <ArrowBack handle={() => changeStep(-1)} />

      <div className='payment-title'>
        <h6 className='el-title-h6 med'>
          Enter the amount to <span className='orange'>send</span> to
        </h6>
      </div>

      <Amount
        changeStep={changeStep}
        setData={setData}
        transferData={{
          userReview: data?.userReview,
          userReceiver: data?.userReceiver,
          paymentSystem: 'pesabase',
        }}
      />
    </div>
  );
};

AgentAmount.propTypes = {
  setData: PropTypes.func.isRequired,
};

export default AgentAmount;
