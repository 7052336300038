import React from 'react';
import pesaLogo from '../../../../assets/img/p-dashboard/deposit-to-pesa.png';
import mtnLogo from '../../../../assets/img/p-dashboard/deposit-to-ssp.png';
import PaymentSystem from '../../../../components/PaymentSystem';

const data = [
  {
    title: 'Deposit crypto via Pesabase',
    icon: pesaLogo,
    link: '/user/deposit/pesabase',
  },
  {
    title: 'Deposit SSP',
    icon: mtnLogo,
    link: '/user/deposit/ssp',
  },
];

const Deposit = () => {
  return (
    <div className='deposit'>
      <PaymentSystem data={data} />
    </div>
  );
};

export default Deposit;
