import React from 'react';
import PropTypes from 'prop-types';
import Amount from '../../../components/Amount';
import ArrowBack from '../../../components/ArrowBack';

const MPesaAmount = ({ setData, flowData, changeStep }) => {
  return (
    <div className='send-step'>
      <ArrowBack handle={() => changeStep(-1)} />

      <div className='payment-title'>
        <h6 className='el-title-h6 med'>
          Enter the amount to send via <span className='orange'>M-Pesa</span> to
        </h6>
      </div>

      <Amount
        changeStep={changeStep}
        setData={setData}
        transferData={flowData}
      />
    </div>
  );
};

MPesaAmount.propTypes = {
  setData: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  changeStep: PropTypes.func.isRequired,
};

export default MPesaAmount;
