import React, { useContext, useRef, useState } from 'react';
import { FormControlLabel, Checkbox, TextField } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSelector } from 'react-redux';
import { selectFirebaseJWT } from '../../../redux/features/user/userSlice';
import { addEmail } from '../../../api';
import UserContext from '../../../UserContext/UserContext';
import { toast } from 'react-toastify';

const AddEmail = () => {
  const navigate = useNavigate();
  const userToken = useSelector(selectFirebaseJWT);
  const [emailState, setEmailState] = useState({
    email: '',
    checkbox: false,
  });
  const { loading, setLoading } = useContext(UserContext);
  const emailRule = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const { email, checkbox } = emailState;

  const handleEmailSend = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      const responseEmail = await addEmail(email, userToken, checkbox);

      if (responseEmail.status === 200) {
        navigate('/auth/thanks/email', {
          state: { email: true, userEmail: email },
        });
      } else {
        throw new Error(responseEmail.message);
      }
    } catch (error) {
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      console.error('handleEmailSend', error);

      navigate('/auth/thanks/email', {
        state: { email: true, userEmail: email, error: true },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleEmailSend}>
      <h4>Add email address</h4>

      <div className='el-text-m auth-content--title'>
        It lets you get notified about transactions and service updates. Also, it makes your account
        more secure.
      </div>

      <div className='form-inputs'>
        <TextField
          className='el-input'
          label='Email'
          type='email'
          inputProps={{ 
            pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$',
          }}
          variant='outlined'
          value={email}
          onChange={(e) => setEmailState((prev) => ({ ...prev, email: e.target.value }))}
        />

        <FormControlLabel
          control={<Checkbox />}
          label='I want to recieve updates about products and services (optional)'
          onChange={(e) => setEmailState((prev) => ({ ...prev, checkbox: e.target.checked }))}
        />
      </div>

      <div className='form-btns'>
        <LoadingButton
          disabled={!emailRule.test(email)}
          loading={loading}
          type='submit'
          className='el-button orange'
        >
          <span>{loading ? 'Processing' : 'Add email'}</span>
        </LoadingButton>

        <Link
          className='el-link-m cancel'
          to={'/auth/thanks/registration'}
          state={{ registration: true }}
        >
          Cancel
        </Link>
      </div>
    </form>
  );
};

export default AddEmail;
