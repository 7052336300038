import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  phoneNumber: '',
  account: null,
  firebaseJWt: '',
  jwtToken: '',
  verificationId: '',
  balance: '',
  transferData: null,
  avatar: null,
  whatsAppCode: null,
  banners: {
    rewards: true,
    email: true,
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    setFirebaseJWT: (state, action) => {
      state.firebaseJWt = action.payload;
    },
    setJwtToken: (state, action) => {
      state.jwtToken = action.payload;
    },
    setVerificationId: (state, action) => {
      state.verificationId = action.payload;
    },
    setAccount: (state, action) => {
      state.account = { ...state.account, ...action.payload};
    },
    setAvatar: (state, action) => {
      state.avatar = action.payload;
    },
    setBalance: (state, action) => {
      state.balance = action.payload;
    },
    setTransferData: (state, action) => {
      state.transferData = action.payload;
    },
    setBanners: (state, action) => {
      state.banners = { ...state.banners, ...action.payload };
    },
    setWhatsAppCode: (state, action) => {
      state.whatsAppCode = action.payload;
    },
    reset: () => initialState,
  },
});

export const {
  setPhoneNumber,
  setAccount,
  setFirebaseJWT,
  setJwtToken,
  setVerificationId,
  setBalance,
  setTransferData,
  setAvatar,
  setBanners,
  setWhatsAppCode,
  reset,
} = userSlice.actions;

export const selectPhoneNumber = (state) => state.user.phoneNumber;
export const selectAccount = (state) => state.user.account;
export const selectFirebaseJWT = (state) => state.user.firebaseJWt;
export const selectJwtToken = (state) => state.user.jwtToken;
export const selectVerificationId = (state) => state.user.verificationId;
export const selectBalance = (state) => state.user.balance;
export const selectTransferData = (state) => state.user.transferData;
export const selectAvatar = (state) => state.user.avatar;
export const selectBanners = (state) => state.user.banners;
export const selectWhatsAppCode = (state) => state.user.whatsAppCode;

export default userSlice.reducer;
