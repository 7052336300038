import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as IconArrowBack } from '../../assets/icons/icon-arrow-back.svg';

const ArrowBack = ({ handle }) => {
  const handleDefault = () => history.go(-1);

  return (
    <div className='c-arrow-back' onClick={() => (handle ? handle() : handleDefault())}>
      <span className='c-arrow-back-icon'>
        <IconArrowBack />
      </span>
      Back
    </div>
  );
};

ArrowBack.propTypes = {
  handle: PropTypes.any,
};

export default ArrowBack;
