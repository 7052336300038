import { useEffect } from 'react';
import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText.js';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import useIsMobile from '../../hooks/useIsMobile';

gsap.registerPlugin(ScrollTrigger, SplitText);

const AboutAnims = (heroSection, teamSection, coloredText) => {
  const isMobile = useIsMobile();

  useEffect(() => {
    const typingTextWords = ['instantly', 'secure', 'carefully'];
    const mySplitText = new SplitText(coloredText.current, { type: 'words' });
    const words = mySplitText.words;
    const teamSectionText = teamSection.current.querySelector('.s-team-desc');
    const anims = [];
    let pinnedImg;

    const mainTL = gsap.timeline({ repeat: -1 });

    typingTextWords.forEach((word) => {
      const wordTL = gsap.timeline({ repeat: 1, yoyo: true });
      wordTL.to(heroSection.current.querySelector('i'), 1, { text: word });
      mainTL.add(wordTL);
    });

    const coloredTextAnim = ScrollTrigger.create({
      trigger: coloredText.current,
      scrub: true,
      start: 'top 90%',
      end: 'top 30%',
      animation: gsap.timeline({}).to(words, { duration: 0, color: '#ED7E41', stagger: 0.25 }),
      markers: false,
    });

    if (!isMobile) {
      pinnedImg = ScrollTrigger.create({
        trigger: teamSection.current,
        pin: true,
        scrub: true,
        start: 'bottom bottom',
        end: 'bottom 50%',
        animation: gsap
          .timeline({})
          .to(teamSectionText, { duration: 1, yPercent: -50, autoAlpha: 1 }),
        markers: false,
      });
    }

    anims.push(mainTL, coloredTextAnim, pinnedImg);

    return () => anims.forEach((anim) => anim?.kill(true));
  }, [coloredText, heroSection, teamSection, isMobile]);
};

export default AboutAnims;
