import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import useDebounce from '../../../hooks/useDebounce';

const AgentSearchField = ({
  className,
  onSearch,
  onClear,
  placeholderText,
  toggleLoading,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 300);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    toggleLoading(true);
  };

  const handleClearClick = () => {
    setSearchValue('');
    onClear();
  };

  useEffect(() => {
    if (debouncedSearchValue.trim()) {
      onSearch(debouncedSearchValue);
    } else {
      onClear();
    }
  }, [debouncedSearchValue, onSearch, onClear]);

  return (
    <div className={className}>
      <TextField
        placeholder={placeholderText || 'Search by the name or phone number of an agent or client'}
        variant='outlined'
        value={searchValue}
        onChange={handleSearchChange}
        fullWidth
        className='el-input-search'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
              {searchValue && (
                <ClearIcon onClick={handleClearClick} style={{ cursor: 'pointer' }} />
              )}
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

AgentSearchField.propTypes = {
  className: PropTypes.string,
  onSearch: PropTypes.func,
  onClear: PropTypes.func,
  placeholderText: PropTypes.string,
  toggleLoading: PropTypes.func,
};

export default AgentSearchField;
