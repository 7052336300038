import React, { useEffect, useState } from 'react';
import Success from '../../../components/Success';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectFirebaseJWT } from '../../../redux/features/user/userSlice';
import sendSuccessVideo from '../../../assets/anims/videos/fullscreen/send_success_ff.mp4';
import sendFailVideo from '../../../assets/anims/videos/fullscreen/send_fail_ff.mp4';
import { toast } from 'react-toastify';
import { acceptRemit } from '../../../api';

const AgentRemitAccept = () => {
  const userToken = useSelector(selectFirebaseJWT);
  const { state } = useLocation();
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const navigateToTX = async () => navigate(`/agent/chat`);

  // TO DO change logic to navigate to transaction details and click on btn for accept method
  useEffect(() => {
    const handleAccepted = async () => {
      try {
        const code = await acceptRemit(state.txId, userToken);

        if (code === 200) {
          console.log('Confirmed');
        } else {
          throw new Error(code.message);
        }
      } catch (error) {
        toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
        console.log(error);
        setError(true);
      }
    };
    handleAccepted();
  }, []);

  return (
    <Success
      error={error}
      video={error ? sendFailVideo : sendSuccessVideo}
      title={error ? 'Error' : 'You have been assigned as a transaction receiver'}
      subtitle={
        error
          ? 'Error'
          : `You can check the details in <a class="el-button-link" href="/agent/incoming-orders"><span>Incoming orders</span></a>`
      }
      buttons={
        error ? (
          <Link to={'/agent/dashboard'} className='el-button orange'>
            Go to Dashboard
          </Link>
        ) : (
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigateToTX();
            }}
            className='el-button orange'
          >
            View transaction details
          </Button>
        )
      }
    />
  );
};

export default AgentRemitAccept;
