import React, { useState } from 'react';
import useBodyClass from '../../hooks/useBodyClass';
import AccordionFaq from '../../components/AccordionFaq/AccordionFaq';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ReactComponent as MyIndicatorIcon } from './../../assets/icons/icon-left-arrow.svg';
import { Container } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

const faqs = [
  {
    category: 'Account and Registration',
    faqList: [
      {
        title: 'Account and Registration1',
        content: 'Content for Account and Registration FAQ1.',
      },
      {
        title: 'Sending Money1',
        content: 'Content for Sending Money FAQ1.',
      },
      {
        title: 'Receiving Money1',
        content: 'Content for Receiving Money FAQ1.',
      },
      {
        title: 'Fees and Charges1',
        content: 'Content for Fees and Charges FAQ1.',
      },
    ],
  },
  {
    category: 'Sending Money',
    faqList: [
      {
        title: 'Account and Registration2',
        content: 'Content for Account and Registration FAQ2.',
      },
      {
        title: 'Sending Money2',
        content: 'Content for Sending Money FAQ2.',
      },
      {
        title: 'Receiving Money2',
        content: 'Content for Receiving Money FAQ2.',
      },
      {
        title: 'Fees and Charges2',
        content: 'Content for Fees and Charges FAQ2.',
      },
    ],
  },
  {
    category: 'Receiving Money',
    faqList: [
      {
        title: 'Account and Registration3',
        content: 'Content for Account and Registration FAQ3.',
      },
      {
        title: 'Sending Money3',
        content: 'Content for Sending Money FAQ3.',
      },
      {
        title: 'Receiving Money3',
        content: 'Content for Receiving Money FAQ3.',
      },
      {
        title: 'Fees and Charges3',
        content: 'Content for Fees and Charges FAQ3.',
      },
    ],
  },
];

const FaqTabs = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  useBodyClass('p-faq');
  // getting faq data from api
  // const { data } = useSWR('vacancys', () => getVacancy());
  // console.log(data);
  return (
    <main>
      <Header />
      <section className='s-hero'>
        <Container disableGutters>
          <div className='s-hero-top'>
            <div className='s-hero-top-wrap'>
              <div className='s-hero-top-tabs'>
                <div className='s-hero-top-title'>
                  <h1>Faq</h1>
                </div>
                <AppBar position='static'>
                  <Tabs
                    orientation='vertical'
                    value={activeTab}
                    onChange={handleTabChange}
                    TabIndicatorProps={{
                      children: <MyIndicatorIcon />,
                    }}
                  >
                    {faqs.map((category, index) => (
                      <Tab key={uuidv4()} label={category.category} index={index} />
                    ))}
                  </Tabs>
                </AppBar>
              </div>
              <div className='s-hero-top-accordion'>
                {faqs.map((category, index) => (
                  <div key={uuidv4()}>
                    {activeTab === index &&
                      category.faqList.map((faq, faqIndex) => (
                        <AccordionFaq key={uuidv4()} faq={faq} faqIndex={faqIndex} />
                      ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Container>
      </section>
      <Footer />
    </main>
  );
};

export default FaqTabs;
