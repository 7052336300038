import {
  send_message,
  tx_messages,
  get_notifictaions,
  get_whatsapp_verification_status,
} from './features/sockets/socketslice';
import SocketFactory from '../socketFactory';

const socketMiddleware = (storeAPI) => (next) => (action) => {
  if (typeof action === 'function') {
    return action(storeAPI.dispatch, storeAPI.getState);
  }

  if (send_message.match(action)) {
    const jwt = storeAPI.getState().user.firebaseJWt;
    const socket = SocketFactory.create(jwt);
    // socket.socketOrders.connect()
    // socket.socketNotifications.connect()
    // console.log('here sending messge', jwt, action);
    const { tx, message } = action.payload;

    socket.socketOrders.emit('message', { tx, message });
  }

  if (tx_messages.match(action)) {
    const jwt = storeAPI.getState().user.firebaseJWt;
    const socket = SocketFactory.create(jwt);
    // socket.socketOrders.connect()
    // socket.socketNotifications.connect()
    // console.log('here subsribing messges && tx',jwt,action)
    const tx = action.payload;

    socket.socketOrders.emit('transaction', tx);
  }

  if (get_notifictaions.match(action)) {
    const jwt = storeAPI.getState().user.firebaseJWt;
    const socket = SocketFactory.create(jwt);
    // socket.socketOrders.connect()
    // socket.socketNotifications.connect()

    socket.socketNotifications.emit(
      'getNotifications',
      `${action.payload.currentPage}`,
      `${action.payload.limit}`
    );
  }

  if (get_whatsapp_verification_status.match(action)) {
    console.log('it matches allaaaa!');
    const jwt = storeAPI.getState().user.firebaseJWt;
    const socket = SocketFactory.create(jwt);

    socket.socketVerification.emit('getWhatsAppVerificationStatus');
  }

  return next(action);
};
export default socketMiddleware;
