import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Attention from '../Attention/Attention';
import DashboardContact from '../DashboardContact';
import AgentTxItem from '../../agent/components/AgentTxItem';
import copyToclipboard from '../../utils/copyToclipboard';
import ReactPlayer from 'react-player';
import inviteImg from '../../assets/anims/videos/popups/referral-loop.mp4';
import { v4 as uuidv4 } from 'uuid';
import Reward from '../Reward/Reward';
import usePictures from '../../hooks/usePictures';
import iconEmail from '../../assets/icons/icon_email.svg';
import { Button } from '@mui/material';
import Popup from '../Popup';
import UserTxItem from '../../user/components/UserTxItem';
import { ReactComponent as IconError } from '../../assets/icons/icon-rejected.svg';

const DashboardUser = ({
  isAgent,
  account,
  canTransact,
  localLoading,
  loading,
  userContacts,
  userTransactions,
  userReferralCode,
  userBanner,
  userBanners,
  userEmail,
  userEmailVerified,
}) => {
  const [invitePopup, setInvitePopup] = useState(false);
  const { accInfo } = account || {};
  // get array of images for dashboard
  const imgs = usePictures(
    require.context('../../assets/img/p-dashboard', false, /\.(png|jpe?g|svg|webp)$/)
  );

  const referralLink = `https://pesabase.com/auth/signup?invite=${userReferralCode}`;

  const toggleInvitePopup = () => setInvitePopup(!invitePopup);

  return (
    <>
      {accInfo?.verificationState === 'rejected' && (
        <div className='dashboard-rejected'>
          <div className='dashboard-rejected-icon'>
            <IconError />
          </div>

          <div className='dashboard-rejected-info'>
            <h6 className='med'>Your identity verification was rejected.</h6>

            <div className='el-text-m dashboard-rejected-info--text'>
              Please contact support by email <b>help@pesabase.com</b>
            </div>
          </div>
        </div>
      )}

      {userBanners.rewards && (
        <Reward
          type={'rewards'}
          loading={loading.bannerLoading}
          data={userBanner}
          linkText={'Learn more'}
        />
      )}

      {userBanners.email && !userEmail && (
        <Reward
          type='email'
          data={{
            image: iconEmail,
            title: 'Complete the profile by adding an email address!',
            text: 'It lets you get notified about transactions and service updates. Also, it makes your account more secure.',
            info: '/auth/add-email',
          }}
          linkText={'Add email address'}
        />
      )}

      {userBanners.email && userEmail && !userEmailVerified && (
        <Reward
          type='email'
          data={{
            image: iconEmail,
            title: 'Verify your email address to get notified about transactions and updates!',
            text: `We have sent you a verification link on your email`,
            textEmail: `${userEmail}`,
          }}
        />
      )}

      {accInfo?.verificationState !== 'verified' && accInfo?.verificationState !== 'rejected' && (
        <div className='dashboard-verify'>
          <span className='dashboard-verify--bg'>
            <img src={imgs['bg_banner_indentify.jpg']} alt='' />
          </span>

          <div className='dashboard-verify-inner'>
            {(accInfo?.verificationState === 'uninitiated' ||
              !accInfo?.verificationState ||
              accInfo?.verificationState === 'incomplete') && (
              <h6>Verify your identity to use all the features of the Pesabase!</h6>
            )}

            {accInfo?.verificationState === 'pending' && (
              <h6>Identity verification is under review. We will notify you shortly.</h6>
            )}

            {(accInfo?.verificationState === 'uninitiated' ||
              !accInfo?.verificationState ||
              accInfo?.verificationState === 'incomplete') && (
              <span className='dashboard-verify--subtitle'>
                Prior to the verification, you can:
              </span>
            )}

            {accInfo?.verificationState === 'pending' && (
              <span className='dashboard-verify--subtitle'>Before that is possible to:</span>
            )}

            <ul>
              <li>deposit USD, SSP and PESA to your account</li>
              <li>send 1 transaction</li>
            </ul>

            {(accInfo?.verificationState === 'uninitiated' ||
              !accInfo?.verificationState ||
              accInfo?.verificationState === 'incomplete') && (
              <Link className='el-button orange' to={'/auth/kyc'}>
                Verify identity
              </Link>
            )}

            <span className='dashboard-verify-icon'>
              <img src={imgs['verify_icon.png']} alt='' />
            </span>
          </div>
        </div>
      )}

      <div className='dashboard-contacts'>
        <h6 className='el-title-h6 med'>Send again:</h6>

        {!canTransact && (
          <Attention type='error'>
            You cannot send any more transactions without verifying your identity. You can contact
            us if you need our help with this.
          </Attention>
        )}

        {userContacts.length > 0 && !localLoading.contactsLoading && canTransact && (
          <div className='dashboard-contacts-content'>
            {userContacts.map((contact) => {
              if (!contact) return;
              return <DashboardContact key={uuidv4()} contact={contact} />;
            })}

            <Link to={'/user/contacts'} className='contact contact-search'>
              <div className='contact-avatar'>
                <svg
                  className='contact-search-icon'
                  width='100%'
                  height='100%'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M21.75 20.0625L15.9338 14.2453C16.8711 12.9572 17.3753 11.4049 17.3738 9.81188C17.3738 5.64234 13.9814 2.25 9.81188 2.25C5.64234 2.25 2.25 5.64234 2.25 9.81188C2.25 13.9814 5.64234 17.3738 9.81188 17.3738C11.4049 17.3753 12.9572 16.8711 14.2453 15.9338L20.0625 21.75L21.75 20.0625ZM9.81188 14.9855C8.7885 14.9856 7.78809 14.6822 6.93714 14.1137C6.0862 13.5452 5.42296 12.7371 5.03129 11.7917C4.63961 10.8462 4.53711 9.80583 4.73674 8.80211C4.93637 7.7984 5.42916 6.87643 6.15279 6.15279C6.87643 5.42916 7.7984 4.93637 8.80211 4.73674C9.80583 4.53711 10.8462 4.63961 11.7917 5.03129C12.7371 5.42296 13.5452 6.0862 14.1137 6.93714C14.6822 7.78809 14.9856 8.7885 14.9855 9.81188C14.9839 11.1835 14.4383 12.4985 13.4684 13.4684C12.4985 14.4383 11.1835 14.9839 9.81188 14.9855Z' />
                </svg>
              </div>

              <span className='contact-name'>Search</span>
            </Link>
          </div>
        )}

        {userContacts.length === 0 && !localLoading.contactsLoading && (
          <div className='dashboard-contacts-empty el-text-m'>
            <span className='icon'>👤</span>
            No recent contacts yet. Please,
            <Link to={'/user/send/system'} className='el-link-m'>
              {' '}
              send some money{' '}
            </Link>
            to see your recent contacts here.
          </div>
        )}
      </div>

      <div className='dashboard-transactions'>
        <div className='dashboard-transactions-top'>
          <h6 className='el-title-h6 med'>Latest Transactions:</h6>

          <Link className='dashboard-transactions-top-link el-text-s med' to='/user/transactions'>
            <span className='link-icon'>
              <svg
                width='100%'
                height='100%'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M9 21L5 17M5 17L9 13M5 17L19 17M15 3L19 7M19 7L15 11M19 7L5 7'
                  stroke='#E98000'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </span>
            All transactions
          </Link>
        </div>

        {userTransactions.length > 0 && !localLoading.txLoading && (
          <div className='dashboard-transactions-content'>
            <div className='tx-block'>
              <div className='tx-table'>
                {userTransactions.map((tx) => (
                  <div className='tx-item-inner' key={uuidv4()}>
                    <>
                      {isAgent ? (
                        <AgentTxItem
                          tx={tx}
                          userUid={account?.uid}
                          txLink={true}
                          loading={localLoading.txLoading}
                        />
                      ) : (
                        <UserTxItem
                          tx={tx}
                          userUid={account?.uid}
                          txLink={true}
                          loading={localLoading.txLoading}
                        />
                      )}
                    </>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {userTransactions.length === 0 && localLoading.txLoading && (
          <div className='dashboard-transactions-content'>
            <div className='tx-block'>
              <div className='tx-table'>
                {[...new Array(5)].map((_) => (
                  <>
                    {isAgent ? (
                      <AgentTxItem loading={localLoading.txLoading} key={uuidv4()} />
                    ) : (
                      <UserTxItem loading={localLoading.txLoading} key={uuidv4()} />
                    )}
                  </>
                ))}
              </div>
            </div>
          </div>
        )}

        {userTransactions?.length === 0 && !localLoading.txLoading && (
          <>
            <div className='dashboard-contacts-empty el-text-m'>
              <span className='icon'>💵</span>
              No recent transactions yet. Please,
              <Link to={'/user/deposit/system'} className='el-link-m'>
                {' '}
                deposit some money{' '}
              </Link>
              to start using Pesabase.
            </div>
          </>
        )}
      </div>

      <div className='dashboard-invite'>
        <div className='dashboard-invite-wrap'>
          <div className='dashboard-invite-content'>
            <div className='dashboard-invite-notifications'>
              <div className='dashboard-invite-notifications-icon' onClick={toggleInvitePopup}>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M22.8 12C22.8 17.9647 17.9647 22.8 12 22.8C6.03532 22.8 1.2 17.9647 1.2 12C1.2 6.03532 6.03532 1.2 12 1.2C17.9647 1.2 22.8 6.03532 22.8 12Z'
                    stroke='#C4C8CB'
                    strokeWidth='2.4'
                  />
                  <path
                    d='M13.5 16.5C13.5 17.3284 12.8284 18 12 18C11.1716 18 10.5 17.3284 10.5 16.5C10.5 15.6716 11.1716 15 12 15C12.8284 15 13.5 15.6716 13.5 16.5Z'
                    fill='#C4C8CB'
                  />
                  <path
                    d='M9 9.75V9.75C9 8.50736 10.0074 7.5 11.25 7.5H13.1459C14.1699 7.5 15 8.33011 15 9.3541V9.3541C15 10.0564 14.6032 10.6984 13.9751 11.0125L12.6708 11.6646C12.2597 11.8702 12 12.2904 12 12.75V12.75'
                    stroke='#C4C8CB'
                    strokeWidth='2.1'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
            </div>

            <div className='dashboard-invite-title'>
              <div className='dashboard-invite-title--title'>Refer a friend and receive</div>
              <div className='dashboard-invite-title--color'>50 PESA to your balance!</div>
            </div>

            <div className='dashboard-invite-copy'>
              <div className='dashboard-invite-copy--title'>Share your referral link:</div>
              <div className='dashboard-invite-copy-block'>
                <div className='dashboard-invite-copy-ref'>{referralLink}</div>
                <Button
                  className='dashboard-invite-copy-btn el-button orange'
                  variant='contained'
                  onClick={() => copyToclipboard(referralLink)}
                >
                  Copy
                </Button>
              </div>
            </div>
          </div>
          <div className='dashboard-invite-bg'>
            <img src={imgs['bannerInvite_bg.png']} alt='' />
          </div>
        </div>

        <Popup open={invitePopup} togglePopUp={toggleInvitePopup}>
          <div className='modal-content'>
            <div className='modal-img'>
              <ReactPlayer
                className='player'
                controls={false}
                playing={true}
                loop={true}
                muted={true}
                playsinline={true}
                url={inviteImg}
                width='100%'
                height='100%'
              />
            </div>

            <div className='modal-desc'>
              <div className='modal-desc-title'>
                Refer a friend and receive bonus to your balance!
              </div>

              <div className='el-text-m modal-desc-text'>
                We pay you 50 PESA each time users register by your link and make 100 USD of
                transactions. Your referral bonus will be added to your PESA balance after the
                transaction is processed. You can also find it in your Activity history and Rewards.
              </div>
            </div>

            <div className='modal-btns'>
              <Button
                className='el-button orange el-button-continue'
                variant='contained'
                onClick={toggleInvitePopup}
              >
                Ok, got it
              </Button>
            </div>
          </div>
        </Popup>
      </div>
    </>
  );
};

DashboardUser.propTypes = {
  localLoading: PropTypes.object,
};

export default DashboardUser;
