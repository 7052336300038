import React from 'react';
import PropTypes from 'prop-types';
import usePictures from '../../../hooks/usePictures';
import Amount from '../..//Amount';
import ArrowBack from '../..//ArrowBack';

const SendTCAmount = ({ changeStep, transferData, setData }) => {
  return (
    <div className='send-step send-step-2'>
      <ArrowBack handle={() => changeStep(-1)} />

      <div className='payment-title'>
        <h6 className='el-title-h6 med'>
          Enter the amount to <span className='orange'>send</span> to
        </h6>
      </div>

      <Amount changeStep={changeStep} transferData={transferData} setData={setData} />
    </div>
  );
};

SendTCAmount.propTypes = {
  changeStep: PropTypes.func.isRequired,
  transferData: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
};

export default SendTCAmount;
