import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import closeFlow from '../../../../utils/closeFlow';
import { isAddress } from 'ethers';

const WithdrawReceiver = ({ setData }) => {
  const [wallet, setWallet] = useState('');
  const [isValidAddress, setIsValidAddress] = useState(false);
  
  useEffect(() => {
    setIsValidAddress(isAddress(wallet));
  }, [wallet]);

  return (
    <div className='send-step send-step-1'>
      <div className='payment-title'>
        <h6 className='el-title-h6 med'>
          Enter the receiving wallet address to
          <span className='orange'> withdraw PESA</span>
        </h6>
      </div>

      <div className='send-step-content'>
        <TextField
          className='el-input'
          label='Wallet address'
          type='text'
          name='Wallet address'
          variant='outlined'
          onChange={(e) => setWallet(e.target.value)}
          value={wallet}
        />

        <span className='el-text-s send-subtitle'>
          Withdrawals are only possible through the BSC (Binance Smart Chain) network
        </span>

        <div className='send-btns'>
          <button
            disabled={!isValidAddress}
            className='el-button orange'
            type='button'
            onClick={() => setData({ userReceiver: { address: wallet } })}
          >
            Continue
          </button>

          <div className='send-btns-cancel' onClick={closeFlow}>
            <span className='el-link-m el-button-link' variant='contained'>
              Cancel
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

WithdrawReceiver.propTypes = {
  setData: PropTypes.func.isRequired,
  transferData: PropTypes.object.isRequired,
};

export default WithdrawReceiver;
