import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { ReactComponent as CustomIcon } from '../../assets/icons/icon-arrow-right.svg';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

const AccFaq = ({ items }) => {
  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  return (
    <div>
      {items.map((item, index) => (
        <Accordion key={uuidv4()} expanded={expanded === index} onChange={handleChange(index)}>
          <AccordionSummary>
            <CustomIcon />
            <span className='el-text-m med'>{item.title}</span>
          </AccordionSummary>
          <AccordionDetails>
            <div className='c-content-editer'>
              <Typography>{item.content}</Typography>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

AccFaq.propTypes = {
  items: PropTypes.array.isRequired,
};

export default AccFaq;
