import React, { useState, useEffect } from 'react';
import AgentTxItemSocket from '../../components/AgentTxItemSocket';
import AgentSearchField from '../../components/AgentSearchField';
import AgentFilter from '../../components/AgentFilter/AgentFilter';
import dayjs from 'dayjs';

const OutgoingOrders = ({ outgoingTxData }) => {
  const [selectedPeriod, setSelectedPeriod] = useState(-1);
  const [selectedStatus, setSelectedStatus] = useState('All statuses');
  const [selectedReceiver, setSelectedReceiver] = useState('All agent receivers');
  const [searchValue, setSearchValue] = useState('');
  const [statusOptions, setStatusOptions] = useState(['All statuses']);
  const [receiverOptions, setReceiverOptions] = useState(['All agent receivers']);
  const periodOptions = [7, 30, 90, 120,-1];

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
  };

  const handlePeriodChange = (period) => {
    setSelectedPeriod(period);
    setSelectedReceiver('All agent receivers');
    setSelectedStatus('All statuses');
    if (period === -1) {
      setSelectedReceiver('All agent receivers');
      setSelectedStatus('All statuses');
    }
  };

  const handleReceiverChange = (receiver) => {
    setSelectedReceiver(receiver);
    setSelectedStatus('All statuses');
  };

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const handleClear = () => {
    setSearchValue('');
  };

  const filterBySearch = (transactions, search) => {
    const normalizedSearch = search.toLowerCase().trim();
    return transactions.filter(tx => {
      const isValidSearch = normalizedSearch === '' ||
        (tx?.receiver?.phoneNumber && tx?.receiver?.phoneNumber.includes(normalizedSearch)) ||
        (tx?.sender?.phoneNumber && tx?.sender?.phoneNumber.includes(normalizedSearch)) ||
        (tx?.agentReceiver?.phoneNumber && tx?.agentReceiver?.phoneNumber.includes(normalizedSearch)) ||
        (tx?.agentSender?.phoneNumber && tx?.agentSender?.phoneNumber.includes(normalizedSearch)) ||
        (tx?.receiver?.name && tx?.receiver?.name.toLowerCase().includes(normalizedSearch)) ||
        (tx?.sender?.name && tx?.sender?.name.toLowerCase().includes(normalizedSearch)) ||
        (tx?.agentReceiver?.name && tx?.agentReceiver?.name.toLowerCase().includes(normalizedSearch)) ||
        (tx?.agentSender?.name && tx?.agentSender?.name.toLowerCase().includes(normalizedSearch));

      return isValidSearch;
    });
  };

  const filterByCriteria = (transactions, status, period, receiver) => {
    const now = dayjs();

    return transactions.filter(tx => {
      const isValidStatus = status === 'All statuses' || tx.status === status;
      const isValidPeriod = period === -1 || now.diff(dayjs(parseInt(tx.timestamp)), 'day') <= period;
      const isValidReceiver = receiver === 'All agent receivers' || tx.agentReceiver?.name === receiver;
      return isValidStatus && isValidPeriod && isValidReceiver;
    });
  };

  useEffect(() => {
    const newStatuses = ['All statuses'];
    const newReceiverOptions = ['All agent receivers'];
    const allTxData = Object.values(outgoingTxData || {}).flat();
    const now = dayjs();

    allTxData.forEach(tx => {
      if (selectedPeriod === -1 || now.diff(dayjs(parseInt(tx.timestamp)), 'day') <= selectedPeriod) {
        if (selectedReceiver === 'All agent receivers' || tx.agentReceiver?.name === selectedReceiver) {
          if (!newStatuses.includes(tx?.status) && tx?.status !== ' ' && tx?.status) {
            newStatuses.push(tx?.status);
          }
        if (tx?.agentReceiver?.name && !newReceiverOptions.includes(tx.agentReceiver.name)) {
          newReceiverOptions.push(tx.agentReceiver.name);
        }
        }

      }
    });

    setStatusOptions(newStatuses);
    setReceiverOptions(newReceiverOptions);
  }, [outgoingTxData, selectedPeriod, selectedReceiver]);

  const getFilteredData = (data) => {
    const searchFilteredData = filterBySearch(data, searchValue);
    return filterByCriteria(searchFilteredData, selectedStatus, selectedPeriod, selectedReceiver);
  };

  const attentionTxData = Object.entries(outgoingTxData || {}).flatMap(([key, value]) => {
    if (key === 'Needs your attention') {
      return value;
    }
    return [];
  });

  const filteredAttentionTxData = getFilteredData(attentionTxData);

  const txsByKey = Object.entries(outgoingTxData).reduce((acc, [key, value]) => {
    if (key !== 'Needs your attention') {
      const filteredData = getFilteredData(value);
      if (filteredData.length > 0) {
        acc[key] = filteredData;
      }
    }
    return acc;
  }, {});

  return (
    <div className='tx'>
      <div className='tx-search'>
        <div className='tx-search-wrap'>
          <h6 className='el-title-h6-med'>Browse your transactions:</h6>
          <AgentSearchField
            className='tx-search-input'
            onSearch={handleSearch}
            onClear={handleClear}
            placeholderText='Search by the name or phone number of an agent or client'
            toggleLoading={() => {}}
          />
        </div>
      </div>
      <AgentFilter
        onStatusChange={handleStatusChange}
        onPeriodChange={handlePeriodChange}
        onReceiverChange={handleReceiverChange}
        statusOptions={statusOptions}
        periodOptions={periodOptions}
        receiverOptions={receiverOptions}
        defaultStatus={selectedStatus}
        defaultPeriod={selectedPeriod}
        defaultReceiver={selectedReceiver}
      />
      {filteredAttentionTxData.length > 0 && (
        <div className='tx-attention'>
          <div className='tx-title el-text-s med'>Need your attention</div>
          <div className='tx-table'>
            {filteredAttentionTxData.map((tx, index) => (
              <AgentTxItemSocket
                tx={tx}
                key={index}
                txOutgoing={true}
                txAttention={true}
                txChat={true}
              />
            ))}
          </div>
        </div>
      )}

      {txsByKey && Object.keys(txsByKey).length > 0 && (
        <div className='tx-list'>
          {Object.entries(txsByKey).map(([key, txs]) => (
            <div className='tx-block' key={key}>
              <div className='tx-date el-text-s med'>{key}</div>
              <div className='tx-table'>
                {txs.map((tx, index) => (
                  <AgentTxItemSocket tx={tx} key={index} txOutgoing={true} txChat={true} />
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
      {(filteredAttentionTxData.length === 0 && Object.keys(txsByKey).length === 0) && (
        <div className='tx-no-results el-text-m' >
         🔍 No transactions yet.
        </div>
      )}
    </div>
  );
};

export default OutgoingOrders;
