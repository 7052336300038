import React, { useState, useEffect } from 'react';
import AgentTxItemSocket from '../../components/AgentTxItemSocket';
import AgentSearchField from '../../components/AgentSearchField';
import AgentFilter from '../../components/AgentFilter/AgentFilter';
import dayjs from 'dayjs';

const TransactionPool = ({ poolTxData }) => {
  const txsByDate = {};

  const [selectedAmount, setSelectedAmount] = useState('All amounts');
  const [amountOptions, setAmountOptions] = useState(['All amounts']);
  const [searchValue, setSearchValue] = useState('');

  const handleAmountChange = (amount) => {
    setSelectedAmount(amount);
  };

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const handleClear = () => {
    setSearchValue('');
  };

  const filterBySearch = (transactions, search) => {
    const normalizedSearch = search.toLowerCase().trim();
    return transactions.filter((tx) => {
      const isValidSearch =
        normalizedSearch === '' ||
        (tx?.receiver?.phoneNumber && tx?.receiver?.phoneNumber.includes(normalizedSearch)) ||
        (tx?.sender?.phoneNumber && tx?.sender?.phoneNumber.includes(normalizedSearch)) ||
        (tx?.agentReceiver?.phoneNumber &&
          tx?.agentReceiver?.phoneNumber.includes(normalizedSearch)) ||
        (tx?.agentSender?.phoneNumber && tx?.agentSender?.phoneNumber.includes(normalizedSearch)) ||
        (tx?.receiver?.name && tx?.receiver?.name.toLowerCase().includes(normalizedSearch)) ||
        (tx?.sender?.name && tx?.sender?.name.toLowerCase().includes(normalizedSearch)) ||
        (tx?.agentReceiver?.name &&
          tx?.agentReceiver?.name.toLowerCase().includes(normalizedSearch)) ||
        (tx?.agentSender?.name && tx?.agentSender?.name.toLowerCase().includes(normalizedSearch));

      return isValidSearch;
    });
  };

  const filterByAmount = (transactions, amount) => {
    return transactions.filter((tx) => {
      const isValidAmount =
        amount === 'All amounts' ||
        (amount === 'up to 100 USD' && tx.amount <= 100) ||
        (amount === 'up to 500 USD' && tx.amount <= 500) ||
        (amount === 'up to 1,000 USD' && tx.amount <= 1000) ||
        (amount === 'up to 5,000 USD' && tx.amount <= 5000) ||
        (amount === 'up to 10,000 USD' && tx.amount <= 10000);
      return isValidAmount;
    });
  };

  useEffect(() => {
    const newAmounts = ['All amounts'];
    const allTxData = Object.values(poolTxData || {}).flat();
    allTxData.forEach((tx) => {
      if (tx?.amount) {
        if (tx.amount <= 100 && !newAmounts.includes('up to 100 USD'))
          newAmounts.push('up to 100 USD');
        if (tx.amount <= 500 && !newAmounts.includes('up to 500 USD'))
          newAmounts.push('up to 500 USD');
        if (tx.amount <= 1000 && !newAmounts.includes('up to 1,000 USD'))
          newAmounts.push('up to 1,000 USD');
        if (tx.amount <= 5000 && !newAmounts.includes('up to 5,000 USD'))
          newAmounts.push('up to 5,000 USD');
        if (tx.amount <= 10000 && !newAmounts.includes('up to 10,000 USD'))
          newAmounts.push('up to 10,000 USD');
      }
    });
    setAmountOptions(newAmounts);
  }, [poolTxData]);

  const getFilteredData = (data) => {
    const searchFilteredData = filterBySearch(data, searchValue);
    return filterByAmount(searchFilteredData, selectedAmount);
  };

  const fulfilledTxData = Object.entries(poolTxData).flatMap(([key, value]) => {
    if (key !== 'Needs your attention') {
      return value;
    }
    return [];
  });

  getFilteredData(fulfilledTxData).forEach((tx) => {
    const formattedDate = dayjs(parseInt(tx.timestamp)).format('MMM-YYYY');
    if (!txsByDate[formattedDate]) {
      txsByDate[formattedDate] = [];
    }
    txsByDate[formattedDate].push(tx);
  });

  return (
    <div className='tx'>
      <div className='tx-search'>
        <div className='tx-search-wrap'>
          <h6 className='el-title-h6-med'>Browse your transactions:</h6>
          <AgentSearchField
            className='tx-search-input'
            onSearch={handleSearch}
            onClear={handleClear}
            toggleLoading={() => {}}
          />
        </div>
      </div>
      <AgentFilter
        onAmountChange={handleAmountChange}
        amountOptions={amountOptions}
        defaultAmount={selectedAmount}
      />
      {txsByDate && Object.keys(txsByDate).length > 0 && (
        <div className='tx-list'>
          {Object.entries(txsByDate).map(([date, txs]) => (
            <div className='tx-block' key={date}>
              <div className='tx-date el-text-s med'>{date}</div>
              <div className='tx-table'>
                {txs.map((tx, index) => (
                  <AgentTxItemSocket tx={tx} key={index} txPool={true} />
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
      {Object.keys(txsByDate).length === 0 && (
        <div className='tx-no-results el-text-m'>🔍 No transactions yet.</div>
      )}
    </div>
  );
};

export default TransactionPool;
