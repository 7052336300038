const Initials = ({ fullName, className }) => {
  if (!fullName || fullName === ' ') fullName = 'Pesabase User';

  const words = fullName.split(' ');
  let initials = '';

  for (const word of words) {
    initials += word.charAt(0);
  }

  return <span className={className}>{initials}</span>;
};

export default Initials;
