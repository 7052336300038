import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { ReactComponent as iconSelect } from '../../../../../assets/icons/icon-select.svg';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useDispatch } from 'react-redux';
import {
  setRemitReceiver,
  setRemitSender,
} from '../../../../../redux/features/transaction/remittanceSlice';
import ArrowBack from '../../../../../components/ArrowBack';
import Rewards from '../../../../../components/Rewards/Rewards';
import closeFlow from '../../../../../utils/closeFlow';
import Estimate from '../../../../../components/Estimate';

const NewUserGeneral = ({ changeStep, remitUser, sender }) => {
  const dispatch = useDispatch();

  const [currentUser, setCurrentSender] = useState({
    title: remitUser?.personalInfo?.title?.toLowerCase() || '',
    firstName: remitUser?.personalInfo?.firstName || '',
    middleName: remitUser?.personalInfo?.middleName || '',
    lastName: remitUser?.personalInfo?.lastName || '',
    createAccount: remitUser?.sender?.personalInfo?.createAccount || false,
  });
  const { title, firstName, middleName, lastName, createAccount } = currentUser;

  const isContinueButtonDisabled = () => {
    const { middleName, ...newObj } = currentUser || {};
    return Object.values(newObj).every((value) => value !== '');
  };

  const setTextFields = (e) => {
    setCurrentSender({ ...currentUser, [e.target.name]: e.target.value });
  };

  const setTitle = (e) => {
    setCurrentSender({ ...currentUser, title: e.target.value });
  };

  const setNewAccount = (value) => {
    setCurrentSender({ ...currentUser, createAccount: value });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    const setFunction = sender ? setRemitSender : setRemitReceiver;
    dispatch(setFunction({ personalInfo: currentUser }));
    changeStep('newUserSteps', 1);
  };

  return (
    <div className='send-step'>
      <ArrowBack handle={() => changeStep('newUserSteps', -1)} />

      <div className='payment-title'>
        <h6 className='el-title-h6 med'>
          Enter <span className='orange'>{sender ? "Sender's" : "Receiver's"} </span> details
        </h6>
      </div>

      <div className='send-step-content'>
        <form onSubmit={submitForm}>
          <div className='auth-content-row'>
            <FormControl className='el-input-select'>
              <InputLabel id='select-label'>Title</InputLabel>
              
              <Select
                labelId='select-label'
                id='select'
                value={title}
                label='Title'
                onChange={setTitle}
                IconComponent={iconSelect}
              >
                <MenuItem className='capitalize' value={'ms'}>Ms</MenuItem>
                <MenuItem className='capitalize' value={'mr'}>Mr</MenuItem>
                <MenuItem className='capitalize' value={'mx'}>Mx</MenuItem>
              </Select>
            </FormControl>

            <TextField
              className='el-input'
              label='First name'
              type='text'
              name='firstName'
              value={firstName}
              variant='outlined'
              onChange={setTextFields}
            />
          </div>

          <TextField
            className='el-input'
            label='Middle name (optional)'
            name='middleName'
            type='text'
            variant='outlined'
            value={middleName}
            onChange={setTextFields}
          />

          <TextField
            className='el-input'
            label='Last name'
            type='text'
            name='lastName'
            variant='outlined'
            onChange={setTextFields}
            value={lastName}
          />

          {!remitUser?.kycState?.account && <Rewards handle={setNewAccount} />}

          {createAccount && (
            <div className='send-step-content-estimate'>
            <Estimate count={50} text={'Your estimated reward'} />
            </div>
          )}



          <div className='send-btns'>
            <div className='send-btns-continue'>
              <Button
                disabled={!isContinueButtonDisabled()}
                className='el-button orange'
                variant='contained'
                type='submit'
              >
                Continue
              </Button>
            </div>

            <div className='send-btns-cancel'>
              <span className='el-link-m el-button-link' variant='contained' onClick={closeFlow}>
                Cancel
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

NewUserGeneral.propTypes = {
  changeStep: PropTypes.func.isRequired,
};

export default NewUserGeneral;
