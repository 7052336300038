import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import usePictures from '../../../../hooks/usePictures';
import Estimate from '../../../../components/Estimate';
import { useSelector } from 'react-redux';
import {
  selectAccount,
  selectFirebaseJWT,
  selectJwtToken,
} from '../../../../redux/features/user/userSlice';
import Popup from '../../../../components/Popup';
import { completeRemit } from '../../../../api';
import copyToclipboard from '../../../../utils/copyToclipboard';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import iconEyeHidden from '../../../../assets/icons/icon-textfield-eye.svg';
import iconEye from '../../../../assets/icons/icon-textfield-eye-hidden.svg';
import ReactPlayer from 'react-player';
import sendWarnVideo from '../../../../assets/anims/videos/popups/send_warn_ff.mp4';

const ChatSideBar = ({ order }) => {
  const userUID = useSelector(selectAccount).uid;
  const userToken = useSelector(selectFirebaseJWT);
  const userDeviceToken = useSelector(selectJwtToken);
  const [localLoading, setLocalLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [completeDialog, setCompleteDialog] = useState(false);
  // const [cancelDialog, setCancelDialog] = useState(false);
  const [visible, setVisible] = useState(false);

  const toggleVisibleVerification = () => setVisible(!visible);

  const navigate = useNavigate();
  const imgs = usePictures(
    require.context('../../../../assets/img/p-dashboard', false, /\.(png|jpe?g|svg|webp)$/)
  );
  const icons = usePictures(
    require.context('../../../../assets/icons', false, /\.(png|jpe?g|svg|webp)$/)
  );

  // check if the agentReceiver is chatting
  const isAgentReceiver = order?.agentReceiver?.uid === userUID;

  const handleCompleteDialog = () => setCompleteDialog(!completeDialog);
  // const handleCancelDialog = () => setCancelDialog(!cancelDialog);
  const toggleDetails = () => setIsOpen(!isOpen);

  const handleProcess = () => {
    const cancelDecline = !isAgentReceiver ? true : false;
    navigate('/agent/chat/declined', { state: { txId: order.txId, cancel: cancelDecline } });
  };

  const formattedOrderDate = (timestamp) =>
    dayjs(parseInt(timestamp)).format('DD/MM/YYYY HH:mm:ss');

  const handleComplete = async () => {
    try {
      setLocalLoading(true);
      const code = await completeRemit(order.txId, userToken, userDeviceToken);
      if (code.status === 200) {
        navigate('/agent/chat/completed');
      } else {
        throw new Error(code.message);
      }
    } catch (error) {
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      console.log(error);
    } finally {
      setLocalLoading(false);
    }
  };

  const {
    amount,
    fee,
    totalAmount,
    currency,
    paymentSystem,
    txAmountInUSD,
    comment,
    operation,
    status,
    timestamp,
    txId,
    receiver,
    sender,
    agentSender,
    agentReceiver,
    type,
  } = order || {};

  return (
    <div className='c-agent-sidebar'>
      <div
        className={`c-agent-sidebar-box ${isAgentReceiver && order?.status === 'pending' ? 'notmore' : ''}`}
      >
        <div className='c-agent-sidebar-top'>
          <div className='c-agent-transaction'>
            <div className='c-agent-transaction-copy'>
              <div className='left'>
                <span className='label'>Transaction</span>
                <span className='text'>{order?.txId}</span>
              </div>
              <img
                className='copy'
                onClick={() => copyToclipboard(order?.txId, true)}
                src={icons['icon-copy.svg']}
                alt=''
              />
            </div>
            <div className='c-agent-transaction-st'>
              <div className='quantity'>
                {order?.type === 'outgoing' ? (
                  <div className='quantity-wrap sent-quan'>
                    <div className='icon'>
                      <img src={icons['icon-sent.svg']} alt='' />
                    </div>
                    <div className='text'>Outgoing</div>
                  </div>
                ) : (
                  <div className='quantity-wrap received-quan'>
                    <div className='icon'>
                      <img src={icons['icon-received.svg']} alt='' />
                    </div>
                    <div className='text'>Incoming</div>
                  </div>
                )}
              </div>
              {order?.status && <div className='status'>{order?.status}</div>}
            </div>
          </div>
          <div className='c-agent-description'>
            {/* show reassign to agent sender only */}
            {!isAgentReceiver && (
              <div className='c-agent-transaction-btns'>
                <Button
                  component={Link}
                  to='/agent/chat/reassign'
                  variant='outlined'
                  className='el-button account'
                >
                  Reassign transaction
                </Button>
              </div>
            )}

            {/* when agent reciever and clicked confirm show complete */}
            {isAgentReceiver && order?.status === 'processing' && (
              <div className='c-agent-transaction-btns'>
                <Button
                  className='el-button orange'
                  variant='contained'
                  onClick={handleCompleteDialog}
                >
                  Complete
                </Button>
              </div>
            )}

            {!isAgentReceiver ? (
              <>
                <div className={`block-content ${isOpen ? 'open' : ''}`}>
                  {order?.timestamp && (
                    <div className='block-content-wrap'>
                      {order?.timestamp && (
                        <div className='block-content-item'>
                          <div className='block-content-label el-text-s'>Initiation date</div>
                          <div className='block-content-text el-text-m'>
                            {formattedOrderDate(order?.timestamp)}
                          </div>
                        </div>
                      )}

                      {isAgentReceiver ? (
                        <>
                          {order?.agentSender?.name && (
                            <div className='block-content-item'>
                              <div className='block-content-label el-text-s'>Sending Agent</div>
                              <div className='block-content-text el-text-m'>
                                {order?.agentSender.name}
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          <div className='block-content-item'>
                            <div className='block-content-label el-text-s'>Receiving Agent</div>
                            {order?.agentReceiver ? (
                              <div className='block-content-text el-text-m'>
                                {order?.agentReceiver?.name}
                              </div>
                            ) : (
                              <div className='block-content-text red el-text-m'>
                                Not yet assigned
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  )}

                  {(order?.amount || order?.fee || order?.totalAmount || order?.comment) && (
                    <div className='block-content-wrap'>
                      {order?.amount && (
                        <div className='block-content-item'>
                          <div className='block-content-label el-text-s'>Transaction amount</div>
                          <div className='block-content-text el-text-m semi'>
                            <b>
                              {order?.amount} {order?.currency}
                            </b>
                          </div>
                        </div>
                      )}

                      {order?.fee && (
                        <div className='block-content-item'>
                          <div className='block-content-label el-text-s'>Transaction fee</div>
                          <div className='block-content-text el-text-m'>
                            {order?.fee} {order?.feeCurrency}
                          </div>
                        </div>
                      )}

                      {order?.type != 'incoming' && order?.totalAmount && (
                        <div className='block-content-item'>
                          <div className='block-content-label el-text-s'>Total</div>
                          <div className='block-content-text el-text-m semi'>
                            <b>
                              {order?.totalAmount} {order?.currency}{' '}
                            </b>
                          </div>
                        </div>
                      )}

                      {order?.comment && (
                        <div className='block-content-item'>
                          <div className='block-content-label el-text-s'>Comment</div>
                          <div className='block-content-text comment el-text-m'>
                            {order?.comment}
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {(order?.sender?.name ||
                    order?.sender?.phoneNumber ||
                    order?.sender?.location) && (
                    <div className='block-content-wrap'>
                      {order?.sender?.name && (
                        <div className='block-content-item'>
                          <div className='block-content-label el-text-s'>Sender</div>
                          <div className='block-content-text el-text-m'>{order?.sender?.name}</div>
                        </div>
                      )}

                      {order?.sender?.phoneNumber && (
                        <div className='block-content-item'>
                          <div className='block-content-label el-text-s'>Sender’s phone</div>
                          <div className='block-content-text el-text-m'>
                            {order?.sender?.phoneNumber}
                          </div>
                        </div>
                      )}

                      {order?.sender?.location && (
                        <div className='block-content-item'>
                          <div className='block-content-label el-text-s'>Sender’s location</div>
                          <div className='block-content-text el-text-m'>
                            {order?.sender?.location?.country}, {order?.sender?.location?.city}
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {(order?.receiver?.name ||
                    order?.receiver?.phoneNumber ||
                    order?.receiver?.dateBirth ||
                    order?.receiver?.gender) && (
                    <div className='block-content-wrap'>
                      {order?.receiver?.name && (
                        <div className='block-content-item'>
                          <div className='block-content-label el-text-s'>Receiver</div>
                          <div className='block-content-text el-text-m'>
                            {order?.receiver?.name}
                          </div>
                        </div>
                      )}

                      {order?.receiver?.phoneNumber && (
                        <div className='block-content-item'>
                          <div className='block-content-label el-text-s'>Receiver’s phone</div>
                          <div className='block-content-text el-text-m'>
                            {order?.receiver?.phoneNumber}
                          </div>
                        </div>
                      )}

                      {/* todo add in object */}
                      {order?.receiver?.dateBirth && (
                        <div className='block-content-item'>
                          <div className='block-content-label el-text-s'>Date of birth</div>
                          <div className='block-content-text el-text-m'>
                            {order?.receiver?.dateBirth}
                          </div>
                        </div>
                      )}

                      {/* todo add in object */}
                      {order?.receiver?.gender && (
                        <div className='block-content-item'>
                          <div className='block-content-label el-text-s'>Gender</div>
                          <div className='block-content-text el-text-m'>
                            {order?.receiver?.gender}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className='block-more'>
                  <button
                    className={`block-more-btn ${isOpen ? 'open' : ''}`}
                    onClick={toggleDetails}
                  >
                    <span>{isOpen ? 'Hide details' : 'See details'}</span>
                    <img className='icon' src={icons['icon-drop-down.svg']} alt='' />
                  </button>
                </div>
              </>
            ) : isAgentReceiver && order?.status === 'processing' ? (
              <>
                <div className={`block-content ${isOpen ? 'open' : ''}`}>
                  {order?.timestamp && (
                    <div className='block-content-wrap'>
                      {order?.timestamp && (
                        <div className='block-content-item'>
                          <div className='block-content-label el-text-s'>Initiation date</div>
                          <div className='block-content-text el-text-m'>
                            {formattedOrderDate(order?.timestamp)}
                          </div>
                        </div>
                      )}

                      {isAgentReceiver ? (
                        <>
                          {order?.agentSender?.name && (
                            <div className='block-content-item'>
                              <div className='block-content-label el-text-s'>Sending Agent</div>
                              <div className='block-content-text el-text-m'>
                                {order?.agentSender.name}
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          <div className='block-content-item'>
                            <div className='block-content-label el-text-s'>Receiving Agent</div>
                            {order?.agentReceiver ? (
                              <div className='block-content-text el-text-m'>
                                {order?.agentReceiver?.name}
                              </div>
                            ) : (
                              <div className='block-content-text red el-text-m'>
                                Not yet assigned
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  )}

                  {(order?.amount || order?.comment) && (
                    <div className='block-content-wrap'>
                      {order?.amount && (
                        <div className='block-content-item'>
                          <div className='block-content-label el-text-s'>Transaction amount</div>
                          <div className='block-content-text el-text-m'>
                            <b>
                              {order?.amount} {order?.currency}
                            </b>
                          </div>
                        </div>
                      )}

                      {order?.comment && (
                        <div className='block-content-item'>
                          <div className='block-content-label el-text-s'>Comment</div>
                          <div className='block-content-text comment el-text-m'>
                            {order?.comment}
                          </div>
                        </div>
                      )}

                        {order?.verification && (
                        <div className='block-content-item'>
                          <div className='block-content-label el-text-s'>Verification</div>
                          <div className='block-content-text el-text-m'>
                            {' '}
                            {visible ? order?.verification : '****'}
                          </div>
                          <span className='verification-icon' onClick={toggleVisibleVerification}>
                            <img src={visible ? iconEye : iconEyeHidden} alt='Toggle visibility' />
                          </span>
                        </div>
                      )}
                    </div>
                  )}

                  {(order?.sender?.name ||
                    order?.sender?.phoneNumber ||
                    order?.sender?.location) && (
                    <div className='block-content-wrap'>
                      {order?.sender?.name && (
                        <div className='block-content-item'>
                          <div className='block-content-label el-text-s'>Sender</div>
                          <div className='block-content-text el-text-m'>{order?.sender.name}</div>
                        </div>
                      )}

                      {order?.sender?.phoneNumber && (
                        <div className='block-content-item'>
                          <div className='block-content-label el-text-s'>Sender’s phone</div>
                          <div className='block-content-text el-text-m'>
                            {order?.sender.phoneNumber}
                          </div>
                        </div>
                      )}

                      {order?.sender?.location && (
                        <div className='block-content-item'>
                          <div className='block-content-label el-text-s'>Sender’s location</div>
                          <div className='block-content-text el-text-m'>
                            {order?.sender?.location?.country}, {order?.sender?.location?.city}
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {(order?.pickupLocation?.country ||
                    order?.pickupLocation?.suburb ||
                    order?.receiver?.name ||
                    order?.receiver?.phoneNumber ||
                    order?.receiver?.dateBirth ||
                    order?.receiver?.gender) && (
                    <div className='block-content-wrap'>
                      {(order?.pickupLocation?.country || order?.pickupLocation?.suburb) && (
                        <div className='block-content-item'>
                          <div className='block-content-label el-text-s'>
                            Receiver’s cash pickup location
                          </div>
                          <div className='block-content-text el-text-m'>
                            {order?.pickupLocation?.country}, {order?.pickupLocation?.suburb}
                          </div>
                        </div>
                      )}

                      {order?.receiver?.name && (
                        <div className='block-content-item'>
                          <div className='block-content-label el-text-s'>Receiver</div>
                          <div className='block-content-text el-text-m'>
                            {order?.receiver?.name}
                          </div>
                        </div>
                      )}

                      {order?.receiver?.phoneNumber && (
                        <div className='block-content-item'>
                          <div className='block-content-label el-text-s'>Receiver’s phone</div>
                          <div className='block-content-text el-text-m'>
                            {order?.receiver?.phoneNumber}
                          </div>
                        </div>
                      )}

                      {/* todo add in object */}
                      {order?.Receiver?.dateBirth && (
                        <div className='block-content-item'>
                          <div className='block-content-label el-text-s'>Date of birth</div>
                          <div className='block-content-text el-text-m'>
                            {order?.receiver?.dateBirth}
                          </div>
                        </div>
                      )}

                      {/* todo add in object */}
                      {order?.receiver?.gender && (
                        <div className='block-content-item'>
                          <div className='block-content-label el-text-s'>Gender</div>
                          <div className='block-content-text el-text-m'>
                            {order?.receiver?.gender}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className='block-more'>
                  <button
                    className={`block-more-btn ${isOpen ? 'open' : ''}`}
                    onClick={toggleDetails}
                  >
                    <span>{isOpen ? 'Hide details' : 'See details'}</span>
                    <img className='icon' src={icons['icon-drop-down.svg']} alt='' />
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className={`block-content open`}>
                  {order?.timestamp && (
                    <div className='block-content-wrap'>
                      {order?.timestamp && (
                        <div className='block-content-item'>
                          <div className='block-content-label el-text-s'>Initiation date</div>
                          <div className='block-content-text el-text-m'>
                            {formattedOrderDate(order?.timestamp)}
                          </div>
                        </div>
                      )}
                      {order?.agentSender?.name && (
                        <div className='block-content-item'>
                          <div className='block-content-label el-text-s'>Sending Agent</div>
                          <div className='block-content-text el-text-m'>
                            {order?.agentSender?.name}
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {order?.amount && (
                    <div className='block-content-wrap'>
                      {order?.amount && (
                        <div className='block-content-item'>
                          <div className='block-content-label el-text-s'>Transaction amount</div>
                          <div className='block-content-text el-text-m semi'>
                            {order?.amount} {order?.currency}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <div className='c-agent-sidebar-bottom'>
          <div className='c-agent-note'>
            {!isAgentReceiver && order?.estimated_user_RewardsInPesa && (
              <Estimate
                type={'small'}
                count={order?.estimated_user_RewardsInPesa}
                text={'Reward for a newly registered user'}
                registration='true'
              />
            )}
            {order?.estimatedTxRewardsInPesa && (
              <Estimate
                type={'small'}
                count={order?.estimatedTxRewardsInPesa}
                text={'Reward for transaction'}
              />
            )}
          </div>

          {!isAgentReceiver && (
            <div className='c-agent-btns'>
              <div className='c-agent-btns-btn'>
                <Button className='el-button-link' onClick={() => handleProcess()}variant='contained'>
                  Cancel transaction
                </Button>
              </div>
            </div>
          )}
          {isAgentReceiver && order?.status === 'processing' && (
            <div className='c-agent-btns'>
              <div className='c-agent-btns-btn'>
                <Button className='el-button-link' onClick={() => handleProcess()} variant='contained'>
                  Decline transaction
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>

      <Popup open={completeDialog} togglePopUp={handleCompleteDialog}>
        <div className='modal-content'>
          <div className='modal-img'>
            {/* <img src={imgs['send-cancel.png']} alt='' /> */}
            <ReactPlayer
              className='player'
              controls={false}
              playing={true}
              loop={true}
              muted={true}
              playsinline={true}
              url={sendWarnVideo}
              width='100%'
              height='100%'
            />
          </div>
          <div className='modal-desc'>
            <div className='modal-desc-title'>Are you sure want to complete the transaction?</div>
            <div className='modal-desc-text'>
              Please, be sure that you have verified the receiver's identity and given the right
              amount of money.
            </div>
          </div>
          <div className='modal-btns'>
            <LoadingButton
              disabled={localLoading}
              loading={localLoading}
              onClick={handleComplete}
              className='el-button orange'
            >
              <span>{localLoading ? 'Processing' : 'Yes, I want to complete the transaction'}</span>
            </LoadingButton>

            <Button className='el-button' onClick={handleCompleteDialog}>
              Cancel
            </Button>
          </div>
        </div>
      </Popup>

    </div>
  );
};

ChatSideBar.propTypes = {
  order: PropTypes.object,
};

export default ChatSideBar;
