import React from 'react';
import PropTypes from 'prop-types';
import Amount from '../../../components/Amount';
import ArrowBack from '../../../components/ArrowBack';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectRemitReceiver,
  setRemitDetails,
} from '../../../redux/features/transaction/remittanceSlice';

const RemitAmount = ({ changeMainStep }) => {
  const receiver = useSelector(selectRemitReceiver);
  const dispatch = useDispatch();

  const setAmountData = (data) => {
    dispatch(setRemitDetails(data.userReview));
    changeMainStep(1);
  };

  return (
    <div className='send-step'>
      <ArrowBack handle={() => changeMainStep(-1)} />

      <div className='payment-title'>
        <h6 className='el-title-h6 med'>
          Enter the amount to <span className='orange'>send</span> to
        </h6>
      </div>

      <Amount
        changeStep={changeMainStep}
        setData={setAmountData}
        transferData={{ userReview: {}, userReceiver: receiver, paymentSystem: 'pesabase' }}
        remit={true}
      />
    </div>
  );
};

RemitAmount.propTypes = {
  changeMainStep: PropTypes.func.isRequired,
};

export default RemitAmount;
