import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAccount, selectFirebaseJWT } from '../../redux/features/user/userSlice';
import { toast } from 'react-toastify';
import { ReactComponent as iconSelect } from '../../assets/icons/icon-select.svg';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { updateUserProfile } from '../../api';
import UserContext from '../../UserContext/UserContext';
import closeFlow from '../../utils/closeFlow';
import { useLocation, useNavigate } from 'react-router-dom';
import { allCountries } from 'country-region-data';
import { v4 as uuidv4 } from 'uuid';
import useUserRole from '../../utils/useUserRole';
import transformString from '../../utils/transformString';
import DOBPicker from '../../components/DOBPicker';

const AccountEdits = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const userRole = useUserRole();
  const userToken = useSelector(selectFirebaseJWT);
  const { loading, setLoading } = useContext(UserContext);

  const infoEdits = pathname === `/${userRole}/account/edits/information`;
  const locationEdits = pathname === `/${userRole}/account/edits/location`;
  const userProfile = useSelector(selectAccount);
  const neededCountries = ['AU', 'UG', 'SS', 'KE'];

  const defaultCountries = allCountries.filter((country) => neededCountries.includes(country[1]));
  const [allRegions, setAllRegions] = useState(
    defaultCountries.filter(
      (country) => transformString(country[0]) === transformString(userProfile?.location?.country)
    )?.[0]?.[2]
  );

  const [edits, setEdits] = useState({
    error: false,
    userInfo: userProfile?.personalInfo,
    userLocation: userProfile?.location,
    localLoading: false,
    disabledSubmit: true,
  });

  const { error, userInfo, userLocation, localLoading, disabledSubmit } = edits;
  console.log(userLocation);

  const updateState = (name, value) => {
    setEdits((prevState) => ({ ...prevState, [name]: value }));
  };

  const setTitle = (e) => {
    updateState('userInfo', { ...userInfo, title: e.target.value });
  };

  const setGender = (e) => {
    updateState('userInfo', { ...userInfo, gender: e.target.value });
  };

  const setDoB = (e) => {
    updateState('userInfo', { ...userInfo, dateOfBirth: Date.parse(e) });
  };

  const setTextFields = (e) => {
    updateState('userInfo', { ...userInfo, [e.target.name]: e.target.value });
  };

  const handleCountryChange = (event) => {
    updateState('userLocation', {
      ...userLocation,
      country: event.target.value,
      city: '',
      suburb: null,
    });
    const selectedCountryData = defaultCountries.find(
      (country) => transformString(country[0]) === transformString(event.target.value)
    );
    setAllRegions(selectedCountryData[2]);
  };

  const handleRegionChange = (event) =>
    updateState('userLocation', { ...userLocation, suburb: event.target.value });

  const isValidData = (data) => {
    const { email, middleName, ...newObj } = data || {};
    const values = Object.values(newObj).map((field) => {
      if (typeof field === 'object') {
        return field !== null && field.$y > 0;
      } else {
        return field !== '' && field !== null;
      }
    });
    const result =
      values.every((value) => value === true) &&
      Object.keys(newObj).length >= (locationEdits ? 3 : 5);
    return result;
  };

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      updateState('localLoading', true);
      const formatedDoB = { ...userInfo, dateOfBirth: `${userInfo.dateOfBirth}` };
      const updateUser = locationEdits ? userLocation : formatedDoB;
      const response = await updateUserProfile(updateUser, userToken);

      if (response.status === 200) {
        navigate(`/${userRole}/account`);
        toast.success(
          'Your request has been sent! You’ll get an email when your information is confirmed and updated.'
        );
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.log('submitForm', error);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
    } finally {
      updateState('localLoading', false);
    }
  };

  useEffect(() => {
    updateState('disabledSubmit', !isValidData(locationEdits ? userLocation : userInfo));
  }, [userInfo, userLocation]);

  return (
    <>
      {userInfo && (
        <form className='account-edits' onSubmit={submitForm}>
          <h6 className='el-title-h6 med'>
            You are about to request a change of your personal information
          </h6>

          <div className='el-text-s account-edits--subtitle'>
            Change the information on this page and send a request. Our manager will approve it and
            update your account in 3 days.{' '}
          </div>

          {infoEdits && (
            <div className='account-edits-form'>
              <FormControl className='el-input-select'>
                <InputLabel id='select-label'>Title</InputLabel>
                <Select
                  labelId='select-label'
                  id='select'
                  value={userInfo?.title || ''}
                  label='Title'
                  onChange={setTitle}
                  IconComponent={iconSelect}
                >
                  <MenuItem className='capitalize' value={'ms'}>
                    Ms
                  </MenuItem>
                  <MenuItem className='capitalize' value={'mr'}>
                    Mr
                  </MenuItem>
                  <MenuItem className='capitalize' value={'mx'}>
                    Mx
                  </MenuItem>
                </Select>
              </FormControl>

              <TextField
                className='el-input'
                label='First name'
                type='text'
                name='firstName'
                value={userInfo?.firstName || ''}
                variant='outlined'
                onChange={setTextFields}
              />

              <TextField
                className='el-input'
                label='Middle name (optional)'
                name='middleName'
                type='text'
                variant='outlined'
                value={userInfo?.middleName || ''}
                onChange={setTextFields}
              />

              <TextField
                className='el-input'
                label='Last name'
                type='text'
                name='lastName'
                variant='outlined'
                onChange={setTextFields}
                value={userInfo?.lastName || ''}
              />

              <FormControl className='el-input-select'>
                <InputLabel id='select-label'>Gender</InputLabel>
                <Select
                  labelId='select-label'
                  id='select'
                  value={userInfo?.gender || ''}
                  label='Gender'
                  onChange={setGender}
                  IconComponent={iconSelect}
                >
                  <MenuItem className='capitalize' value={'male'}>
                    Male
                  </MenuItem>
                  <MenuItem className='capitalize' value={'female'}>
                    Female
                  </MenuItem>
                  <MenuItem className='capitalize' value={'other'}>
                    Other
                  </MenuItem>
                </Select>
              </FormControl>

              <DOBPicker dob={userInfo.dateOfBirth || null} setDoB={setDoB} />

              <div className='account-edits-form-btns'>
                <LoadingButton
                  disabled={disabledSubmit}
                  loading={localLoading}
                  type='submit'
                  className='el-button orange'
                >
                  <span>{localLoading ? 'Processing' : 'Send a request'}</span>
                </LoadingButton>

                <span className='el-link-m' onClick={closeFlow}>
                  Cancel
                </span>
              </div>
            </div>
          )}

          {locationEdits && (
            <div className='account-edits-form'>
              <FormControl className='el-input-select'>
                <InputLabel id='select-label-1'>Select country</InputLabel>
                <Select
                  labelId='select-label-1'
                  id='select'
                  value={transformString(userLocation.country) || ''}
                  label='Select country'
                  onChange={handleCountryChange}
                  IconComponent={iconSelect}
                >
                  {defaultCountries.map((country) => (
                    <MenuItem
                      className='capitalize'
                      key={uuidv4()}
                      value={transformString(country[0])}
                    >
                      {country[0]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl className='el-input-select'>
                <InputLabel id='select-label-2'>Select region</InputLabel>
                <Select
                  disabled={!userLocation.country}
                  labelId='select-label-2'
                  id='select-region'
                  value={transformString(userLocation.suburb) || ''}
                  label='Select region'
                  onChange={handleRegionChange}
                  IconComponent={iconSelect}
                >
                  {allRegions?.length > 0 &&
                    allRegions.map((region) => (
                      <MenuItem
                        className='capitalize'
                        key={uuidv4()}
                        value={transformString(region[0])}
                      >
                        {region[0]}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <TextField
                disabled={!userLocation.suburb}
                className='el-input'
                label='City'
                type='text'
                name='city'
                variant='outlined'
                value={userLocation.city || ''}
                onChange={(e) =>
                  updateState('userLocation', { ...userLocation, city: e.target.value })
                }
              />

              <div className='account-edits-form-btns'>
                <LoadingButton
                  disabled={disabledSubmit || userLocation.city.length < 2}
                  loading={localLoading}
                  type='submit'
                  className='el-button orange'
                >
                  <span>{localLoading ? 'Processing' : 'Send a request'}</span>
                </LoadingButton>

                <span className='el-link-m' onClick={closeFlow}>
                  Cancel
                </span>
              </div>
            </div>
          )}
        </form>
      )}

      {!loading && error && <h6 className='el-title-error'>{error}</h6>}
    </>
  );
};

export default AccountEdits;
