import React, { useContext, useEffect, useState } from 'react';
import Steps from '../../../components/Steps';
import KycLocation from '../../../components/Kyc/KycLocation/ KycLocation';
import KycVerify from '../../../components/Kyc/KycVerify';
import KycInfo from '../../../components/Kyc/KycInfo';
import { useNavigate } from 'react-router';
import useBodyClass from '../../../hooks/useBodyClass';
import KycIndetify from '../../../components/Kyc/KycIndetify/KycIndetify';
import ArrowBack from '../../../components/ArrowBack';
import { checkUserAccount, submitKYC, updateKYC } from '../../../api';
import { useSelector } from 'react-redux';
import { selectFirebaseJWT } from '../../../redux/features/user/userSlice';
import { toast } from 'react-toastify';
import UserContext from '../../../UserContext/UserContext';

const KYC = () => {
  useBodyClass('p-auth-flow');
  const navigate = useNavigate();
  const [docType, setDocType] = useState(false);
  const [userSteps, setUserSteps] = useState({});
  const userToken = useSelector(selectFirebaseJWT);
  const { setLoading } = useContext(UserContext);

  const [steps, setSteps] = useState({
    current: 0,
    total: [{ name: 'Personal info' }, { name: 'Location' }, { name: 'Documents' }],
  });
  const { current } = steps;

  const getBack = () => changeStep(-1);
  const changeStep = (step) => setSteps({ ...steps, current: current + step });

  const changeDocsType = (type) => {
    setDocType(type);
    changeStep(1);
  };

  const submitStepData = async (data) => {
    try {
      if (data) {
        setLoading(true);
        const response = await submitKYC(data, userToken);
        setLoading(false);

        if (response.status === 200) {
          changeStep(1);
        } else {
          setLoading(false);
          throw new Error(response.message);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
    } finally {
      setLoading(false);
    }
  };

  const updateStepData = async (step, data) => {
    try {
      if (data) {
        setLoading(true);

        const response = await updateKYC(step, data, userToken);

        if (response.status === 200) {
          setLoading(false);
          changeStep(1);
        } else {
          throw new Error(response.message);
        }
      }
    } catch (error) {
      console.log('updateStepData', error);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userSteps.personalInfo && userSteps.location && userSteps.documents) {
      navigate('/user/dashboard');
    }

    if (userSteps.personalInfo && !userSteps.location) changeStep(1);
    if (userSteps.personalInfo && userSteps.location) changeStep(2);
  }, [userSteps]);

  useEffect(() => {
    (async () => {
      if (current === 0) {
        try {
          setLoading(true);
          const response = await checkUserAccount(userToken);
          setUserSteps(response);
        } catch (error) {
          setError(error);
          toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, []);

  return (
    <>
      <Steps steps={steps} />

      {current > 0 && <ArrowBack handle={getBack} />}

      {current === 0 && (
        <KycInfo
          personalInfo={userSteps.personalInfo}
          submitStepData={submitStepData}
          updateStepData={updateStepData}
        />
      )}

      {current === 1 && (
        <KycLocation
          location={userSteps.location}
          submitStepData={submitStepData}
          updateStepData={updateStepData}
        />
      )}

      {current === 2 && <KycVerify changeDocsType={changeDocsType} />}

      {current === 3 && <KycIndetify docType={docType} submitStepData={submitStepData} />}
    </>
  );
};

export default KYC;
