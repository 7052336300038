import React from 'react';
import { Link } from 'react-router-dom';
import useBodyClass from '../../hooks/useBodyClass';
import notFoundImagVideo from '../../assets/anims/videos/fullscreen/p404.mp4';
import ReactPlayer from 'react-player';
const PostPage = () => {
  useBodyClass('p-404');

  return (
    <section className='p-404-box'>
      <span className='p-404-box-image'>
        <ReactPlayer
            className='player'
            controls={false}
            playing={true}
            loop={true}
            muted={true}
            playsinline={true}
            url={notFoundImagVideo}
            width='100%'
            height='100%'
          />
      </span>

      <h4>This page couldn’t be found</h4>

      <Link to='/' className='el-button orange p-404-box--link'>
        Go to Home page
      </Link>
    </section>
  );
};

export default PostPage;
