import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import SearchField from '../..//SearchField';
import { useSelector } from 'react-redux';
import { selectFirebaseJWT } from '../../../redux/features/user/userSlice';
import SingleContact from '../../SingleContact';
import NoResult from '../..//NoResult';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getContacts, getUsers } from '../../../api';
import UserContext from '../../../UserContext/UserContext';
import getDataLength from '../../../utils/getDataLength';
import { v4 as uuidv4 } from 'uuid';
import ArrowBack from '../../ArrowBack';

const SendTCReceiver = ({ setData, transferData, changeStep }) => {
  // const refPhoneWrap = useRef(null);
  // const [phone, setPhone] = useState('');
  const refPhoneField = useRef(null);
  const token = useSelector(selectFirebaseJWT);
  const [originalData, setOriginalData] = useState({});
  const [contactsData, setContactsData] = useState({});
  const [contactsDataLength, setContactsDataLength] = useState(0);
  const [recentActivity, setRecentActivity] = useState([]);
  const [localLoading, setLocalLoading] = useState(false);

  // infinity scroll
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const { loading, setLoading } = useContext(UserContext);
  const limit = 5;
  const { paymentSystem } = transferData;

  // helpers functions for below main funcs
  const checkHasMore = (data) => setHasMore(getDataLength(data) === limit);

  const toggleLoading = (value) => setLocalLoading(value);

  const mergeContacts = (contacts1, contacts2) => {
    const mergedContacts = {};

    // Merge contacts from the first object
    Object.keys(contacts1).forEach((letter) => {
      if (!mergedContacts[letter]) {
        mergedContacts[letter] = [];
      }
      mergedContacts[letter] = [...mergedContacts[letter], ...contacts1[letter]];
    });

    // Merge contacts from the second object
    Object.keys(contacts2).forEach((letter) => {
      if (!mergedContacts[letter]) {
        mergedContacts[letter] = [];
      }
      mergedContacts[letter] = [...mergedContacts[letter], ...contacts2[letter]];
    });

    return mergedContacts;
  };

  // UI func for interactive
  const handleSearch = async (response, searchValue) => {
    setSearchValue(searchValue);
    setCurrentPage(1);
    setContactsData(response?.data?.contact || {});
    checkHasMore(response?.data?.contact || {});
  };

  const handleClearSearch = () => {
    setCurrentPage(1);
    setSearchValue('');
    setContactsData(originalData);
    checkHasMore(originalData);
    setLocalLoading(false);
  };

  // getting data from API
  const fetchContactsData = async (page) => {
    try {
      const result = await (searchValue
        ? getUsers(token, page, limit, searchValue)
        : getContacts(token, page, limit));
      return result;
    } catch (error) {
      console.error('Error fetching contacts:', error);
      return {};
    }
  };

  const loadMoreContacts = async () => {
    if (!hasMore) return;

    try {
      const result = await fetchContactsData(currentPage + 1);

      if (result.status === 200) {
        const contacts = result?.data?.contact;

        if (contacts) {
          setContactsData((prevState) => mergeContacts(prevState, contacts));
          setCurrentPage((prevPage) => prevPage + 1);
          checkHasMore(contacts);
        } else {
          setHasMore(false);
        }
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      console.log(error);
    }
  };

  const getContactsFunc = async () => {
    try {
      setLoading(true);
      const response = await getContacts(token, currentPage, limit, 'all');

      if (response.status === 200) {
        const contact = response?.data?.contact;
        const recentActivity = response?.data?.recentActivity;

        if (contact) {
          setOriginalData(contact);
          setContactsData(contact);
          setRecentActivity(recentActivity);
          checkHasMore(contact);
        }
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      console.log('SendTCReceiver', error);
    } finally {
      setLoading(false);
    }
  };

  const skeleton = (
    <div className='c-contact-box-el flow'>
      <div className='c-contact-box-el-symbol'></div>
      {[...new Array(5)].map(() => (
        <div className='contact-wrap' key={uuidv4()}>
          <SingleContact loading={true} />
        </div>
      ))}
    </div>
  );

  useEffect(() => {
    setContactsDataLength(getDataLength(contactsData));
  }, [contactsData]);

  useEffect(() => {
    getContactsFunc();

    if (refPhoneField.current) {
      refPhoneField.current.numberInputRef.focus();
    }
  }, []);

  return (
    <>
      <div className='send-step send-step-1'>
        <ArrowBack handle={() => changeStep(-1)} />

        <div className='payment-title'>
          <h6 className='el-title-h6 med'>
            Search <span className='orange'>Pesabase</span> user
          </h6>
        </div>

        <SearchField
          className='contacts-search-field'
          contactType='contact'
          token={token}
          onClear={handleClearSearch}
          onSearch={(response, searchValue) => handleSearch(response, searchValue)}
          placeholderText='Enter name, address, email, or phone number'
          flow={true}
          toggleLoading={toggleLoading}
        />

        {recentActivity?.length > 0 && !loading && !localLoading && !searchValue && (
          <div className='c-contact-recent'>
            <div className='c-contact-box-wrap'>
              <div className='el-text-m med'>Recent activity:</div>
              <div className='c-contact-box-el recent'>
                {recentActivity.map((contact) => {
                  return (
                    <>
                      <div className='contact-wrap' key={uuidv4()}>
                        <SingleContact contact={contact} setData={setData} flow={true} />
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        )}

        <div className='c-contact-box'>
          {contactsDataLength >= 0 && (
            <InfiniteScroll
              dataLength={contactsDataLength}
              next={loadMoreContacts}
              hasMore={hasMore}
              loader={skeleton}
            >
              {localLoading && skeleton}

              {!localLoading && contactsDataLength > 0 && (
                <>
                  <div className='c-contact-box-wrap'>
                    {!searchValue && <div className='el-text-m med'>Contacts:</div>}

                    {Object.entries(contactsData).map(([letter, contactsGroup]) => (
                      <div className='c-contact-box-el flow' key={uuidv4()}>
                        <div className='c-contact-box-el-symbol el-text-s med'>{letter}</div>
                        {contactsGroup.map((contact, index) => (
                          <div className='contact-wrap' key={uuidv4()}>
                            <SingleContact contact={contact} setData={setData} flow={true} />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </>
              )}

              {contactsDataLength === 0 && searchValue && !localLoading && (
                <NoResult>{'No user found. Try entering the email or mobile number.'}</NoResult>
              )}
            </InfiniteScroll>
          )}

          {contactsDataLength === 0 && !searchValue && !localLoading && (
            <NoResult>{'No contacts yet.'}</NoResult>
          )}
        </div>
      </div>
    </>
  );
};

SendTCReceiver.propTypes = {
  setData: PropTypes.func.isRequired,
  transferData: PropTypes.object.isRequired,
};

export default SendTCReceiver;
