import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import usePictures from '../../../hooks/usePictures';
import useLowerCase from '../../../hooks/useLowerCase';
import dayjs from 'dayjs';
import transformNumbers from '../../../utils/transformNumbers';
import ShortenedAddress from '../../../components/ShortenedAddress';
import copyToclipboard from '../../../utils/copyToclipboard';

import { Button } from '@mui/material';
import Popup from '../../../components/Popup';
import ReactPlayer from 'react-player';
import rewardsbanner from '../../../assets/anims/videos/popups/rewards_ff.mp4';

import { Drawer } from '@mui/material';
import Avatar from '../../../components/Avatar';
import transformString from '../../../utils/transformString';

const UserTxItemPopup = ({ tx, userUid, open, toggleTxDetails }) => {
  const [popup, setPopup] = useState(false);

  const icons = usePictures(
    require.context('../../../assets/icons', false, /\.(png|jpe?g|svg|webp)$/)
  );

  const imgs = usePictures(
    require.context('../../../assets/img/p-dashboard', false, /\.(png|jpe?g|svg|webp)$/)
  );

  const [visible, setVisible] = useState(false);
  const togglePopup = (tx) => setPopup(!popup);

  const {
    amount,
    fee,
    feeCurrency,
    feeInUSD,
    totalAmount,
    currency,
    paymentSystem,
    rewardInPesa,
    swapAmount,
    txAmountInUSD,
    comment,
    operation,
    receiver = {},
    sender = {},
    status,
    timestamp,
    tokenPrice,
    txId,
    attention,
    uid,
  } = tx;


  const {
    phoneNumber: senderPhoneNumber,
    name: senderName,
    email: senderEmail,
    uid: senderUid,
    avatar: senderAvatarTx,
    contact: senderContact,
    favourite: senderFavourite,
    agent: senderAgent,
    address: senderAddress,
  } = sender;
  const {
    phoneNumber: receiverPhoneNumber,
    name: receiverName,
    email: receiverEmail,
    uid: receiverUid,
    avatar: receiverAvatarTx,
    contact: receiverContact,
    favourite: receiverFavourite,
    agent: receiverAgent,
    address: receiverAddress,
  } = receiver;

  const amountNumber = Number(amount);
  const feeNumber = Number(fee);
  const totalAmountNumber = Number(totalAmount);
  const rewardInPesaNumber = Number(rewardInPesa);
  const txAmountInUSDNumber = Number(txAmountInUSD);
  const tokenPriceNumber = Number(tokenPrice);
  const swapAmountNumber = Number(swapAmount);
  const txPaymentSystem =
  tx && paymentSystem !== undefined ? useLowerCase(paymentSystem) : '';
  const txOperation = operation !== undefined ? useLowerCase(operation) : '';

  const selectRepeatPath = () => {
    switch (transformString(txOperation)) {
      case 'transfer':
        return '/user/send/system/user';
      case 'swap':
        return '/user/deposit/buy-pesa';
      case 'withdrawal':
        return '/user/withdraw';
      default:
        return '';
    }
  };
  return (
    <Drawer className='modal-right' anchor={'right'} open={open} onClose={toggleTxDetails}>
      <div className='modal-top'>
        <h6 className='el-title-h6 med'>Transaction details:</h6>
        <DialogActions>
          <Button onClick={toggleTxDetails} className='modal-close' color='primary'>
            <svg
              width='100%'
              height='100%'
              viewBox='0 0 40 40'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g id='icons/X'>
                <path
                  id='Union'
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M8.68645 8.68547C8.29593 9.076 8.29593 9.70916 8.68645 10.0997L18.586 19.9993L8.68645 29.8988C8.29592 30.2894 8.29592 30.9225 8.68645 31.313C9.07697 31.7036 9.71014 31.7036 10.1007 31.313L20.0002 21.4135L29.8997 31.3129C30.2902 31.7034 30.9233 31.7034 31.3139 31.3129C31.7044 30.9224 31.7044 30.2892 31.3139 29.8987L21.4144 19.9993L31.3139 10.0998C31.7044 9.70931 31.7044 9.07615 31.3139 8.68562C30.9233 8.2951 30.2902 8.2951 29.8997 8.68562L20.0002 18.585L10.1007 8.68547C9.71014 8.29495 9.07698 8.29495 8.68645 8.68547Z'
                />
              </g>
            </svg>
          </Button>
        </DialogActions>
      </div>

      <DialogContent>
        <div className='modal-wrap'>
          <div className='activity-modal'>
            <div className='item'>
              <div className='item-wrap'>
                <div className='item-info'>
                  <div className='item-info-avatar'>
                    <>
                      {txOperation === 'crypto deposit' ? (
                        <>
                          <img src={imgs['avatar-cripto@2x.png']} alt='' />
                        </>
                      ) : txOperation === 'swap' ? (
                        <>
                          <img src={imgs['avatar-cripto@2x.png']} alt='' />
                        </>
                      ) : txOperation === 'transfer' ? (
                        <Avatar data={senderUid !== userUid ? tx?.sender : tx?.receiver} />
                      ) : txOperation === 'withdrawal' ? (
                        <>
                          {txPaymentSystem === 'm-pesa' ? (
                            <img src={imgs['avatar-mpesa-agent@2x.png']} alt='' />
                          ) : (
                            <img src={imgs['avatar-cripto@2x.png']} alt='' />
                          )}
                        </>
                      ) : txOperation === 'top up' ? (
                        <Avatar data={tx?.sender} />
                      ) : txOperation === 'referral reward' ? (
                        <>
                          <img src={imgs['avatar-pesa-ref@2x.png']} alt='' />
                        </>
                      ) : operation === 'signup reward' ? (
                        <>
                          <img src={imgs['avatar-pesa-ref@2x.png']} alt='' />
                        </>
                      ) : (
                        <Avatar data={tx?.sender} />
                      )}
                    </>
                  </div>
                  <div className='item-info-desc'>
                    <div className='item-info-name'>
                      <div className='item-info-name-title el-text-m semi'>
                        {txOperation === 'crypto deposit' ? (
                          <>Crypto deposit</>
                        ) : txOperation === 'swap' ? (
                          <>
                            {tx?.sender?.name && transformString(tx?.sender?.name) !== ' '
                              ? tx?.sender?.name
                              : 'Buy PESA'}
                          </>
                        ) : txOperation === 'transfer' ? (
                          <>
                            {senderUid !== userUid ? (
                              <>
                                {tx?.sender?.name && tx?.sender?.name !== ' '
                                  ? tx?.sender.name
                                  : 'Pesabase user'}
                              </>
                            ) : (
                              <>
                                {tx?.sender?.name && tx?.receiver?.name !== ' '
                                  ? tx?.receiver.name
                                  : 'Pesabase user'}
                              </>
                            )}
                          </>
                        ) : txOperation === 'withdrawal' ? (
                          txPaymentSystem === 'm-pesa' ? (
                            <>
                              {tx?.receiver?.name && transformString(tx?.receiver?.name) !== ' '
                                ? tx?.receiver.name
                                : tx?.receiver?.phoneNumber}
                            </>
                          ) : (
                            <>Withdrawal</>
                          )
                        ) : txOperation === 'top up' ? (
                          <>
                            {tx?.sender?.name && transformString(tx?.sender?.name) !== ' '
                              ? tx?.sender.name
                              : 'Pesabase user'}
                          </>
                        ) : txOperation === 'referral reward' ? (
                          <>Referral reward</>
                        ) : txOperation === 'signup reward' ? (
                          <>Welcome reward</>
                        ) : txOperation === 'remit' ? (
                          <>Remit</>
                        ) : (
                          <></>
                        )}
                      </div>
                      {tx?.sender?.uid !== userUid ? (
                        <>
                          {txOperation !== 'swap' ? (
                            <>
                              {tx?.sender?.agent && (
                                <span className='item-info-agent'>Agent</span>
                              )}
                              {tx?.sender?.favourite && (
                                <img
                                  className='item-info-star'
                                  src={icons['icon-star.svg']}
                                  alt=''
                                />
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <>
                          {txOperation !== 'swap' ? (
                            <>
                              {tx?.receiver?.agent && (
                                <span className='item-info-agent'>Agent</span>
                              )}
                              {tx?.receiver?.favourite && (
                                <img
                                  className='item-info-star'
                                  src={icons['icon-star.svg']}
                                  alt=''
                                />
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </div>
                    {timestamp && (
                      <div className='item-info-date'>
                        {dayjs(parseInt(tx?.timestamp)).format('DD MMM, HH:mm')}
                      </div>
                    )}
                  </div>
                </div>
                <div className='item-right'>
                  <div className='item-quantity'>
                    <div
                      className={`item-quantity-wrap ${
                        senderAgent
                          ? 'wallet-quan'
                          : txOperation === 'crypto deposit'
                            ? 'wallet-quan'
                            : txOperation === 'swap'
                              ? 'buy-quan'
                              : txOperation === 'top up'
                                ? 'wallet-quan'
                                : txOperation === 'withdrawal' && txPaymentSystem !== 'm-pesa'
                                  ? 'withdrawal-quan'
                                  : senderUid === userUid
                                    ? 'sent-quan'
                                    : 'received-quan'
                      }`}
                    >
                      <div className='icon'>
                        {senderUid === userUid ? (
                          <>
                            {txOperation === 'swap' ? (
                              <>
                                <img src={icons['icon-type-buy.svg']} alt='' />
                              </>
                            ) : txOperation === 'withdrawal' ? (
                              <>
                                {txPaymentSystem === 'm-pesa' ? (
                                  <img src={icons['icon-sent.svg']} alt='' />
                                ) : (
                                  <img src={icons['icon-type-withdrawal.svg']} alt='' />
                                )}
                              </>
                            ) : (
                              <img src={icons['icon-sent.svg']} alt='' />
                            )}
                          </>
                        ) : senderAgent ? (
                          <>
                            <img src={icons['icon-wallet.svg']} alt='' />
                          </>
                        ) : txOperation === 'crypto deposit' ? (
                          <>
                            <img src={icons['icon-wallet.svg']} alt='' />
                          </>
                        ) : txOperation === 'swap' ? (
                          <>
                            <img src={icons['icon-type-buy.svg']} alt='' />
                          </>
                        ) : txOperation === 'transfer' ? (
                          <>
                            <img src={icons['icon-received.svg']} alt='' />
                          </>
                        ) : txOperation === 'withdrawal' ? (
                          <>
                            {txPaymentSystem === 'm-pesa' ? (
                              <img src={icons['icon-sent.svg']} alt='' />
                            ) : (
                              <img src={icons['icon-type-withdrawal.svg']} alt='' />
                            )}
                          </>
                        ) : txOperation === 'top up' ? (
                          <>
                            <img src={icons['icon-wallet.svg']} alt='' />
                          </>
                        ) : txOperation === 'referral reward' ? (
                          <>
                            <img src={icons['icon-received.svg']} alt='' />
                          </>
                        ) : txOperation === 'signup reward' ? (
                          <>
                            <img src={icons['icon-received.svg']} alt='' />
                          </>
                        ) : (
                          <>
                            <img src={icons['icon-sent.svg']} alt='' />
                          </>
                        )}
                      </div>

                      {currency === 'USD' ? (
                        <div className='text'>$ {transformNumbers(amountNumber, 2)}</div>
                      ) : (
                        <div className='text'>
                          {transformNumbers(amountNumber, 2)} {currency}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='item-status'>
                    {status === 'completed' ? (
                      <div className='item-status-wrap success'>
                        <div className='icon'>
                          <img src={icons['icon-success.svg']} alt='' />
                        </div>
                        <div className='text'>completed</div>
                      </div>
                    ) : status === 'failed' ? (
                      <div className='item-status-wrap failed'>
                        <div className='icon'>
                          <img src={icons['icon-failed.svg']} alt='' />
                        </div>
                        <div className='text'>failed</div>
                      </div>
                    ) : status === 'initiated' ? (
                      <div className='item-status-wrap initiated'>
                        <div className='icon'>
                          <img src={icons['icon-time.svg']} alt='' />
                        </div>
                        <div className='text'>initiated</div>
                      </div>
                    ) : status === 'assigned' ? (
                      <div className='item-status-wrap assigned'>
                        <div className='icon'>
                          <img src={icons['icon-time.svg']} alt='' />
                        </div>
                        <div className='text'>assigned</div>
                      </div>
                    ) : status === 'pending ' ? (
                      <div className='item-status-wrap pending'>
                        <div className='icon'>
                          <img src={icons['icon-time.svg']} alt='' />
                        </div>
                        <div className='text'>pending</div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-desc'>
            <div className='modal-desc-list'>
              {/* txId for all transaction */}
              {txId && (
                <div className='modal-desc-item'>
                  <div className='el-text-m modal-desc-label'>Transaction ID</div>
                  <div className='el-text-m modal-desc-text'>{txId}</div>
                </div>
              )}

              {/* date for all transaction */}
              {timestamp && (
                <div className='modal-desc-item'>
                  <div className='el-text-m modal-desc-label'>Initiation date</div>
                  <div className='el-text-m modal-desc-text'>
                    {dayjs(parseInt(timestamp)).format('MM/DD/YYYY HH:mm:ss')}
                  </div>
                </div>
              )}
              {/* address for crypto deposit  */}
              {(txOperation === 'crypto deposit' || txOperation === 'swap') ? (
                <>
                  {tx?.sender?.address && (
                    <div className='modal-desc-item'>
                      <div className='el-text-m modal-desc-label'>
                        <>Sender wallet address</>
                      </div>
                      <div className='el-text-m modal-desc-text name'>
                        <span
                          className='address'
                          onClick={() => copyToclipboard(tx?.sender?.address)}
                        >
                          <ShortenedAddress address={tx?.sender?.address} />
                          <img className='address-copy' src={icons['icon-copy.svg']} alt='' />
                        </span>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
              {/* address for  withdrawal */}
              {(txOperation === 'withdrawal' && txPaymentSystem !== 'm-pesa') ? (
                <>
                  {tx?.receiver?.address && (
                    <div className='modal-desc-item'>
                      <div className='el-text-m modal-desc-label'>
                        <>Receiving wallet address</>
                      </div>
                      <div className='el-text-m modal-desc-text name'>
                        <span
                          className='address'
                          onClick={() => copyToclipboard(tx?.receiver?.address)}
                        >
                          <ShortenedAddress address={tx?.receiver?.address} />
                          <img className='address-copy' src={icons['icon-copy.svg']} alt='' />
                        </span>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}

              {/* paymentSystem operation transfer  */}
              {(txOperation === 'transfer' || txOperation === 'top up'|| (txOperation === 'withdrawal'&& txPaymentSystem === 'm-pesa')) && (
                  <>
                    {txPaymentSystem && (
                      <div className='modal-desc-item'>
                        <div className='el-text-m modal-desc-label'>Payment system</div>
                        <div className='el-text-m modal-desc-text name'>
                          {txPaymentSystem}
                        </div>
                      </div>
                    )}
                  </>
                )
              }
              {/* sender or receiver */}
              {(txOperation === 'transfer' || txOperation === 'top up'|| (txOperation === 'withdrawal'&& txPaymentSystem === 'm-pesa')) && (
                <>
                  {senderUid === userUid ? (
                    <>
                      {receiverName && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Receiver</div>
                          <div className='el-text-m modal-desc-text name'>
                            {receiverName.trim() !== '' ? receiverName : 'Pesabase user'}{' '}
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {senderName && (
                        <div className='modal-desc-item'>
                          <div className='el-text-m modal-desc-label'>Sender</div>
                          <div className='el-text-m modal-desc-text name'>
                            {senderName.trim() !== '' ? senderName : 'Pesabase user'}{' '}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}

              <div className='modal-desc-bottom'>
                {/* you paid*/}
                {txOperation === 'swap' && (
                  <>
                    {swapAmount && (
                      <div className='modal-desc-item'>
                        <div className='el-text-m modal-desc-label'>You paid</div>
                        <div className='el-text-m modal-desc-text'>
                          <span className='el-text-l semi'>
                            {transformNumbers(swapAmountNumber, 2)} USD
                          </span>
                        </div>
                      </div>
                    )}
                  </>
                )}

                {/* amount*/}
                {amount && (
                  <div className='modal-desc-item'>
                    <div className='el-text-m modal-desc-label'>
                      {txOperation === 'crypto deposit' ? (
                        <>You deposited</>
                      ) : txOperation === 'swap' ? (
                        <>You bought</>
                      ) : txOperation === 'transfer' ? (
                        <>{senderUid === userUid ? <>You send exactly</> : <>You received</>}</>
                      ) : txOperation === 'withdrawal' ? (
                        <>You send exactly</>
                      ) : txOperation === 'top up' ? (
                        <>You received</>
                      ) : txOperation === 'referral reward' ? (
                        <>You received</>
                      ) : txOperation === 'signup reward' ? (
                        <>You received</>
                      ) : (
                        <>You send exactly</>
                      )}
                    </div>
                    <div className='el-text-m modal-desc-text'>
                      <span className='el-text-l semi modal-desc-text-reward'>
                        {transformNumbers(amount, 2)} {currency}
                        {(txOperation === 'referral reward' || txOperation === 'signup reward') && (
                          <span className=' modal-desc-text-icon' onClick={toggleRewards}>
                            <svg
                              width='16'
                              height='16'
                              viewBox='0 0 16 16'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 0.999999 8 0.999999C11.866 1 15 4.13401 15 8Z'
                                stroke='#6D757D'
                                strokeWidth='2'
                              />
                              <path
                                d='M9 11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55228 10 9 10.4477 9 11Z'
                                fill='#6D757D'
                              />
                              <path
                                d='M6 6.5V6.5C6 5.67157 6.67157 5 7.5 5H8.76393C9.44659 5 10 5.55341 10 6.23607V6.23607C10 6.70426 9.73548 7.13226 9.31672 7.34164L8.44721 7.77639C8.17313 7.91343 8 8.19357 8 8.5V8.5'
                                stroke='#6D757D'
                                strokeWidth='1.75'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                )}

                {/* fee*/}
                {senderUid === userUid ? (
                  <>
                    {(txOperation === 'transfer' || txOperation === 'top up' || txOperation === 'withdrawal') && (
                      <>
                        {fee && (
                          <div className='modal-desc-item'>
                            <div className='el-text-m modal-desc-label'>Transaction fee</div>
                            <div className='el-text-m modal-desc-text'>
                              {transformNumbers(feeNumber, 3)} {feeCurrency}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {txOperation === 'withdrawal' && (
                      <>
                        {fee && (
                          <div className='modal-desc-item'>
                            <div className='el-text-m modal-desc-label'>Transaction fee</div>
                            <div className='el-text-m modal-desc-text'>
                              {transformNumbers(feeNumber, 3)} {feeCurrency}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}

                {/* total*/}
                {senderUid === userUid ? (
                  <>
                    {(txOperation === 'transfer' || txOperation === 'top up'|| txOperation === 'withdrawal') && (
                      <>
                        {totalAmount && (
                          <div className='modal-desc-item'>
                            <div className='el-text-m modal-desc-label'>Total</div>
                            <div className='el-text-m modal-desc-text'>
                              <span className='el-text-l semi'>
                                {transformNumbers(totalAmountNumber, 2)} {currency}
                              </span>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {txOperation === 'withdrawal' && (
                      <>
                        {totalAmount && (
                          <div className='modal-desc-item'>
                            <div className='el-text-m modal-desc-label'>Total</div>
                            <div className='el-text-m modal-desc-text'>
                              <span className='el-text-l semi'>
                                {transformNumbers(totalAmountNumber, 2)} {currency}
                              </span>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
                {/* USD Equivalent*/}
                {senderUid === userUid ? (
                  <>
                    {(txOperation === 'transfer' || txOperation === 'top up'|| txOperation === 'withdrawal') && (
                      <>
                        {txAmountInUSD && (
                          <div className='modal-desc-item'>
                            <div className='el-text-m modal-desc-label'>USD Equivalent</div>
                            <div className='el-text-m modal-desc-text'>
                              {transformNumbers(txAmountInUSDNumber, 2)} USD
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {txOperation === 'withdrawal' && (
                      <>
                        {txAmountInUSD && (
                          <div className='modal-desc-item'>
                            <div className='el-text-m modal-desc-label'>USD Equivalent</div>
                            <div className='el-text-m modal-desc-text'>
                              {transformNumbers(txAmountInUSDNumber, 2)} USD
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
                {/* comment for several operation */}
                {(txOperation === 'transfer' ||
                  txOperation === 'top up' ||
                  txOperation === 'referral reward'||(txOperation === 'withdrawal'&& txPaymentSystem === 'm-pesa')) && (
                  <>
                    {comment && (
                      <div className='modal-desc-item'>
                        <div className='el-text-m modal-desc-label'>Comment</div>
                        <div className='el-text-m modal-desc-text'>{comment}</div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          {/* Your reward banner */}

          {senderUid === userUid && rewardInPesa && rewardInPesa !== 0 && (
            <>
              <div className='modal-reward'>
                <div className='modal-reward-wrap'>
                  <div className='modal-reward-left'>
                    <div className='modal-reward-left-img'>
                      <img src={imgs['icon-reward-no-bg.png']} alt='reward image' />
                    </div>
                    <div className='modal-reward-label'>{rewardInPesaNumber.toFixed(6)} PESA</div>
                  </div>
                  <div className='modal-reward-text'>
                    Your reward
                    <span className='modal-reward-text-icon' onClick={togglePopup}>
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 0.999999 8 0.999999C11.866 1 15 4.13401 15 8Z'
                          stroke='#6D757D'
                          strokeWidth='2'
                        />
                        <path
                          d='M9 11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55228 10 9 10.4477 9 11Z'
                          fill='#6D757D'
                        />
                        <path
                          d='M6 6.5V6.5C6 5.67157 6.67157 5 7.5 5H8.76393C9.44659 5 10 5.55341 10 6.23607V6.23607C10 6.70426 9.73548 7.13226 9.31672 7.34164L8.44721 7.77639C8.17313 7.91343 8 8.19357 8 8.5V8.5'
                          stroke='#6D757D'
                          strokeWidth='1.75'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

      </DialogContent>
      <div className='modal-btn'>
        {/* Repeat transaction button */}
        {senderUid === userUid && (
          <div className='modal-repeat'>
            <Link
              variant='outlined'
              to={
                transformString(tx?.paymentSystem) === 'm-pesa' && txOperation === 'withdrawal'
                  ? '/user/send/system/mpesa'
                  : selectRepeatPath()
              }
              className='el-button orange'
              state={{
                transferData: {
                  paymentSystem: transformString(tx?.paymentSystem),
                  userReceiver: receiver,
                  userReview: {
                    sendValue: amount || totalAmount,
                    currency: currency.toLowerCase(),
                  },
                },
              }}
            >
              Repeat transaction
            </Link>
          </div>
        )}
      </div>

      <Popup open={popup} togglePopUp={togglePopup}>
        <div className='modal-content'>
          <div className='modal-img'>
            <ReactPlayer
              className='player'
              controls={false}
              playing={true}
              loop={true}
              muted={true}
              playsinline={true}
              url={rewardsbanner}
              width='100%'
              height='100%'
            />
          </div>

          <div className='modal-desc'>
            <div className='modal-desc-title'>Rewards for you!</div>

            <div className='el-text-m modal-desc-text'>
              We pay you rewards each time you use our platform sending money to your friends and
              family. Your reward will be added to your PESA balance after transaction is processed.
              You can also find it in your Activity history.
            </div>
          </div>

          <div className='modal-btns'>
            <Button
              className='el-button orange el-button-continue'
              variant='contained'
              onClick={togglePopup}
            >
              Ok, got it
            </Button>
          </div>
        </div>
      </Popup>
    </Drawer>
  );
};

UserTxItemPopup.propTypes = {
  tx: PropTypes.object,
  toggleTxDetails: PropTypes.func,
};

export default UserTxItemPopup;
