import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ShortenedAddress from '../../components/ShortenedAddress';
import { Link } from 'react-router-dom';
import usePictures from '../../hooks/usePictures';
import { Button } from '@mui/material';
import copyToclipboard from '../../utils/copyToclipboard';
import { updateFavouriteStatus } from '../../api';
import { selectFirebaseJWT } from '../../redux/features/user/userSlice';
import Skeleton from '@mui/material/Skeleton';
import { v4 as uuidv4 } from 'uuid';
import useUserRole from '../../utils/useUserRole';
import Avatar from '../Avatar';
import { LoadingButton } from '@mui/lab';

const SingleContact = ({
  contact,
  setData,
  flow = false,
  agentFlow = false,
  agentSearch = false,
  loading,
  btnLoading,
}) => {
  const [isFavourite, setIsFavourite] = useState(contact?.favourite);
  const token = useSelector(selectFirebaseJWT);
  const userRole = useUserRole();

  const icons = usePictures(
    require.context('../../assets/icons', false, /\.(png|jpe?g|svg|webp)$/)
  );

  const handleUpdateFavourites = async (contactId, action) => {
    try {
      await updateFavouriteStatus(token, contactId, action);
      setIsFavourite(action === 'add' ? true : false);
    } catch (error) {
      console.error('Error updating favorites:', error);
    }
  };

  useEffect(() => {
    setIsFavourite(contact?.favourite);
  }, [contact?.favourite]);

  return (
    <div className='contact'>
      {loading ? (
        <Skeleton animation='wave' variant='rectangular' />
      ) : (
        <>
          <div className='contact-info'>
            <div className='contact-info-img'>
              <Avatar data={contact} />
            </div>

            <div className='contact-info-details'>
              <div className='contact-info-name'>
                {contact?.name && contact.name.trim() !== '' ? (
                  <span className='name'>{contact.name}</span>
                ) : (
                  <span className='name'>Pesabase user</span>
                )}

                {contact?.agent && <span className='contact-info-name-agent'>Agent</span>}

                {isFavourite && (
                  <span className='contact-info-name-star'>
                    <img src={icons['icon-star.svg']} alt='' />
                  </span>
                )}

                {agentFlow && (
                  <>
                    {contact?.kycState?.account &&
                      contact?.kycState.verificationState !== 'verified' && (
                        <span className='el-text-xs med contact-info-name-kyc'>
                          <img src={icons['icon-notification.svg']} alt='' />
                          Unverified
                        </span>
                      )}

                    {!contact?.kycState?.account && (
                      <span className='el-text-xs med contact-info-name-kyc yellow'>
                        <img src={icons['icon-warning.png']} alt='' />
                        Unregistered
                      </span>
                    )}
                  </>
                )}
              </div>

              {contact?.mail && <Link to={`mailto:${contact.mail}`}>{contact.mail}</Link>}

              {agentFlow && (
                <>
                  {contact?.kycState?.verificationState !== 'verified' && contact?.phoneNumber && (
                    <Link >{contact.phoneNumber}</Link>
                  )}

                  {contact?.kycState?.verificationState === 'verified' && contact?.address && (
                    <span className='address' onClick={() => copyToclipboard(contact.address)}>
                      <ShortenedAddress address={contact.address} />
                      <img className='address-copy' src={icons['icon-copy.svg']} alt='' />
                    </span>
                  )}
                </>
              )}

              {agentSearch && (
                <>
                  {contact?.phoneNumber &&
                    contact?.phoneNumber.map((el) => (
                      <Link key={uuidv4()}>
                        {el}
                      </Link>
                    ))}

                  {contact?.address && (
                    <span className='address' onClick={() => copyToclipboard(contact.address)}>
                      <ShortenedAddress address={contact.address} />
                      <img className='address-copy' src={icons['icon-copy.svg']} alt='' />
                    </span>
                  )}
                </>
              )}
            </div>
          </div>

          <div className='contact-info-right'>
            {!flow && (
              <Button
                className='contact-info-link'
                onClick={() => handleUpdateFavourites(contact.uid, isFavourite ? 'remove' : 'add')}
              >
                <img className='icon' src={icons['icon-star-default.svg']} alt='' />
                {isFavourite ? <span>Remove from favorites</span> : <span>Add to favourites</span>}
              </Button>
            )}

            {flow ? (
              (agentFlow ? (
                <LoadingButton
                disabled={btnLoading && btnLoading !== contact.uid}
                loading={btnLoading === contact.uid}
                onClick={() => {
                  setData({ ...contact });
                }}
                variant='outlined'
                className='el-button orange'
              >
                  <span>{loading ? 'Processing' : 'Choose'}</span>
              </LoadingButton>
              ) : (
                <Button
                disabled={contact?.isOnline === 'false'}
                onClick={() => {
                  setData({ userReceiver: { ...contact } });
                }}
                variant='outlined'
                className='el-button orange'
              >
                Send
              </Button>
              ))
            ) : (
              <Link
                variant='outlined'
                to={`/${userRole}/send/system/${userRole}`}
                className='el-button orange'
                state={{
                  transferData: {
                    paymentSystem: 'pesabase',
                    userReceiver: contact,
                  },
                }}
              >
                Send
              </Link>
            )}
          </div>
        </>
      )}
    </div>
  );
};

SingleContact.propTypes = {
  contact: PropTypes.object,
  setContactToData: PropTypes.func,
  flow: PropTypes.bool,
  agentFlow: PropTypes.bool,
  loading: PropTypes.bool,
};

export default SingleContact;
