import { combineReducers } from 'redux';
import userReducer from '../features/user/userSlice';
import remittanceReducer from '../features/transaction/remittanceSlice';
import socketReducer from '../features/sockets/socketslice';

const rootReducer = combineReducers({
  user: userReducer,
  remittance: remittanceReducer,
  socket: socketReducer,
});

export default rootReducer;
