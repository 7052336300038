import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import iconUpload from '../../../../../assets/icons/icon-upload.svg';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { getAgentOTP } from '../../../../../api';
import LoadingButton from '@mui/lab/LoadingButton';
import { selectFirebaseJWT } from '../../../../../redux/features/user/userSlice';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import ArrowBack from '../../../../../components/ArrowBack';
import {
  selectRemitSender,
  setRemitSender,
} from '../../../../../redux/features/transaction/remittanceSlice';
import closeFlow from '../../../../../utils/closeFlow';
import DOBPicker from '../../../../../components/DOBPicker';

const KycIndetify = ({ docType, changeStep }) => {
  const userDocs = useSelector(selectRemitSender)?.documents;

  const [filesFace, setFilesFace] = useState([
    {
      preview: userDocs?.front || null,
      raw: userDocs?.front || null,
    },
  ]);

  const [filesFront, setFilesFront] = useState([
    {
      preview: userDocs?.type !== 'passport' ? userDocs?.front : null,
      raw: userDocs?.type !== 'passport' ? userDocs?.front : null,
    },
  ]);

  const [filesBack, setFilesBack] = useState([
    {
      preview: userDocs?.back || null,
      raw: userDocs?.back || null,
    },
  ]);

  const expDateShowRules = () => {
    if (docType === 0 && userDocs?.type === 'passport') {
      return userDocs?.expirationDate;
    }
    if ((docType === 1 || docType === 2) && userDocs?.type === 'id') {
      return userDocs?.expirationDate;
    }
  };

  const [expirationDate, setExpirationDate] = useState(expDateShowRules());
  const [localLoading, setLocalLoading] = useState(false);
  const userToken = useSelector(selectFirebaseJWT);
  const remitSender = useSelector(selectRemitSender);
  const dispatch = useDispatch();

  const docTypeMapping = {
    0: 'passport',
    1: 'id',
    2: 'driver-licence',
  };

  const setDate = (newValue) => setExpirationDate(Date.parse(newValue));

  const handleDrop = (acceptedFiles, setter) => {
    const file = acceptedFiles[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64String = e.target.result;

        setter(
          acceptedFiles.map((file) => ({
            ...file,
            preview: URL.createObjectURL(file),
            raw: base64String,
          }))
        );
      };
      reader.readAsDataURL(file);
    }
  };

  const {
    getRootProps: getRootPropsFront,
    getInputProps: getInputPropsFront,
    open: openFront,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => handleDrop(acceptedFiles, setFilesFront),
    maxFiles: 1,
    maxSize: 5242880,
    accept: {
      'image/*': [],
    },
  });

  const {
    getRootProps: getRootPropsBack,
    getInputProps: getInputPropsBack,
    open: openBack,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => handleDrop(acceptedFiles, setFilesBack),
    maxFiles: 1,
    maxSize: 5242880,
    accept: {
      'image/*': [],
    },
  });

  const {
    getRootProps: getRootPropsFace,
    getInputProps: getInputPropsFace,
    open: openFace,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => handleDrop(acceptedFiles, setFilesFace),
    maxFiles: 1,
    maxSize: 5242880,
    accept: {
      'image/*': [],
    },
  });

  const submitDocs = async (e) => {
    e.preventDefault();
    try {
      setLocalLoading(true);

      dispatch(
        setRemitSender({
          documents: {
            type: docTypeMapping[docType] || null,
            front: (docType === 0 ? filesFace[0]?.raw : filesFront[0]?.raw) || null,
            back: filesBack[0]?.raw || null,
            expirationDate: `${expirationDate}` || null,
          },
        })
      );
      await getAgentOTP(userToken, `${remitSender?.phoneNumber}`);
      changeStep('newUserSteps', 1);
    } catch (error) {
      console.log('submitDocs', error);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
    } finally {
      setLocalLoading(false);
    }
  };

  return (
    <div className='send-step'>
      <ArrowBack handle={() => changeStep('newUserSteps', -1)} />

      <div className='payment-title'>
        <h6 className='el-title-h6 med'>
          Verify <span className='orange'>Sender’s</span> identity
        </h6>
      </div>

      <div className='send-step-content'>
        <form>
          <div className='auth-content-indetify'>
            <div className='auth-content-indetify-box'>
              {docType === 0 && (
                <div {...getRootPropsFace({ className: 'dropzone' })}>
                  {filesFace.length === 0 && (
                    <div className='dropzone-img'>
                      <img src={iconUpload} alt='dropzone img' />
                    </div>
                  )}
                  <div className='dropzone-description'>
                    <input {...getInputPropsFace()} />
                    {filesFace?.[0]?.raw ? (
                      <>
                        <span className='el-text-m dropzone-description--title'>
                          Front side of your document
                        </span>

                        {filesFace.map((file) => (
                          <div className='dropzone-preview' key={uuidv4()}>
                            <img src={file.preview} onLoad={() => file.preview} />
                          </div>
                        ))}

                        <span className='el-text-xs'>{filesFace[0].path}</span>

                        <a className='el-link-s dropzone-description--upload' onClick={openFace}>
                          Upload another file
                        </a>
                      </>
                    ) : (
                      <>
                        <span className='el-text-m dropzone-description--title'>
                          Face photo page of you passport
                        </span>

                        <span className='el-text-xs dropzone-description--subtitle'>
                          Upload a photo or scan
                        </span>

                        <ul>
                          <li className='el-text-xs'>Supported formats: JPG; PNG; WEBP</li>
                          <li className='el-text-xs'>Max file weight 5 MB</li>
                        </ul>

                        <Button onClick={openFace} className='el-button orange' variant='outlined'>
                          Choose a file
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              )}

              {docType !== 0 && (
                <>
                  <div {...getRootPropsFront({ className: 'dropzone' })}>
                    {filesFront.length === 0 && (
                      <div className='dropzone-img'>
                        <img src={iconUpload} alt='dropzone img' />
                      </div>
                    )}

                    <div className='dropzone-description'>
                      <input {...getInputPropsFront()} />

                      {filesFront?.[0]?.raw ? (
                        <>
                          <span className='el-text-m dropzone-description--title'>
                            {docType === 1 && 'Front side of your ID card'}
                            {docType === 2 && 'Front side of your driving license'}
                          </span>

                          {filesFront.map((file) => (
                            <div className='dropzone-preview' key={uuidv4()}>
                              <img src={file.preview} onLoad={() => file.preview} />
                            </div>
                          ))}

                          <span className='el-text-xs'>{filesFront[0].path}</span>

                          <a className='el-link-s dropzone-description--upload' onClick={openFront}>
                            Upload another file
                          </a>
                        </>
                      ) : (
                        <>
                          <span className='el-text-m dropzone-description--title'>
                            {docType === 1 && 'Front side of your ID card'}
                            {docType === 2 && 'Front side of your driving license'}
                          </span>

                          <span className='el-text-xs dropzone-description--subtitle'>
                            Upload a photo or scan
                          </span>

                          <ul>
                            <li className='el-text-xs'>Supported formats: JPEG; JPG; PNG</li>
                            <li className='el-text-xs'>Max file weight 5 MB</li>
                          </ul>

                          <Button
                            onClick={openFront}
                            className='el-button orange'
                            variant='outlined'
                          >
                            Choose a file
                          </Button>
                        </>
                      )}
                    </div>
                  </div>

                  <div {...getRootPropsBack({ className: 'dropzone' })}>
                    {filesBack.length === 0 && (
                      <div className='dropzone-img'>
                        <img src={iconUpload} alt='dropzone img' />
                      </div>
                    )}

                    <div className='dropzone-description'>
                      <input {...getInputPropsBack()} />

                      {filesBack?.[0]?.raw ? (
                        <>
                          <span className='el-text-m dropzone-description--title'>
                            {docType === 1 && 'Back side of your ID card'}
                            {docType === 2 && 'Back side of your driving license'}
                          </span>

                          {filesBack.map((file) => (
                            <div className='dropzone-preview' key={uuidv4()}>
                              <img src={file.preview} onLoad={() => file.preview} />
                            </div>
                          ))}

                          <span className='el-text-xs'>{filesBack[0].path}</span>

                          <a className='el-link-s dropzone-description--upload' onClick={openBack}>
                            Upload another file
                          </a>
                        </>
                      ) : (
                        <>
                          <span className='el-text-m dropzone-description--title'>
                            {docType === 1 && 'Back side of your ID card'}
                            {docType === 2 && 'Back side of your driving license'}
                          </span>

                          <span className='el-text-xs dropzone-description--subtitle'>
                            Upload a photo or scan
                          </span>

                          <ul>
                            <li className='el-text-xs'>Supported formats: JPEG; JPG; PNG</li>
                            <li className='el-text-xs'>Max file weight 5 MB</li>
                          </ul>

                          <Button
                            onClick={openBack}
                            className='el-button orange'
                            variant='outlined'
                          >
                            Choose a file
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className='auth-content-indetify-date'>
              <DOBPicker dob={null || expirationDate} setDoB={setDate} expDate={true} />
            </div>

            <div className='send-btns'>
              <LoadingButton
                disabled={
                  (docType === 0 && filesFace.length === 0) ||
                  ((docType === 1 || docType === 2) &&
                    (filesFront.length === 0 || filesBack.length === 0)) ||
                  !expirationDate
                }
                loading={localLoading}
                className='el-button orange dropzone-submit'
                onClick={(e) => submitDocs(e)}
              >
                <span>{localLoading ? 'Processing' : 'Continue'}</span>
              </LoadingButton>

              <div className='send-btns-cancel'>
                <span className='el-link-m el-button-link' variant='contained' onClick={closeFlow}>
                  Cancel
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

KycIndetify.propTypes = {
  docType: PropTypes.number.isRequired,
};

export default KycIndetify;
