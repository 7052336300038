import dayjs from 'dayjs';
import Avatar from '../../../../components/Avatar';

const ChatReceiverMSG = ({ from, text, timestamp,isMyMessageReceiver, receiverAvatar }) => {
  const chatUnixTimestamp = Math.floor(Number(timestamp) / 1000);
  const date = dayjs.unix(chatUnixTimestamp);
  return (
    <div className='chat-message'>
      <div className='avatar'>
        <Avatar data={receiverAvatar} />
      </div>

      <div className={`message ${isMyMessageReceiver ? 'message-my' : ''}`}>
        <div className='message-name'>{from.name}</div>
        <div className='message-text' dangerouslySetInnerHTML={{ __html: text }}/>

        <div className='message-right'>
          <div className='el-text-xs message-timestamp'>{dayjs(date).format('DD/MM/YYYY HH:mm:ss')}</div>
        </div>
      </div>
    </div>
  );
};

export default ChatReceiverMSG;
