import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {useSelector} from 'react-redux';
import dayjs from 'dayjs';
import Avatar from '../../../../components/Avatar';
import { selectOrder } from '../../../../redux/features/transaction/remittanceSlice';

const ChatSenderMSG = ({ from, text,type, timestamp, isMyMessageSender, senderAvatar, orderTxId, }) => {
  const order = useSelector(selectOrder);
  const chatUnixTimestamp = Math.floor(Number(timestamp) / 1000);
  const date = dayjs.unix(chatUnixTimestamp);
  const messageRef = useRef(null);
  const navigate = useNavigate();

  const handleProcess = (path) => {
    navigate(path, { state: { txId: orderTxId } });
  };
  useEffect(() => {
    if (messageRef.current) {
      const confirmButton = messageRef.current.querySelector('#confirm');
      const declineButton = messageRef.current.querySelector('#decline');

      if (confirmButton) {
        confirmButton.addEventListener('click', () => handleProcess('/agent/chat/accepted'));
      }
      if (declineButton) {
        declineButton.addEventListener('click', () => handleProcess('/agent/chat/declined'));
      }

      return () => {
        if (confirmButton) {
          confirmButton.removeEventListener('click', () => handleProcess('/agent/chat/accepted'));
        }
        if (declineButton) {
          declineButton.removeEventListener('click', () => handleProcess('/agent/chat/declined'));
        }
      };
    }
  }, [messageRef]);

  return (
      <>
        { type === 'message' ? (
          <>
            <div className='chat-message' >
              <div className='avatar'>
                <Avatar data={senderAvatar} />
              </div>

              <div className={`message ${isMyMessageSender ? 'message-my' : ''}`}>
                <div className='message-name'>{from.name}</div>
                <div className='message-text' dangerouslySetInnerHTML={{ __html: text }} />

                <div className='message-right'>
                  <div className='el-text-xs message-timestamp'>{dayjs(date).format('DD/MM/YYYY HH:mm:ss')}</div>
                </div>
              </div>
            </div>
          </>
        ):(
          <>
          {( type === 'button' && order.status !== "processing" ) && (
            <div className='chat-message message-btns'  ref={messageRef} dangerouslySetInnerHTML={{ __html: text }}/>
          )}

          </>
        )}
      </>
  );
};

export default ChatSenderMSG;
