import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import OtpInput from 'react-otp-input';
import LoadingButton from '@mui/lab/LoadingButton';
import { getAgentOTP, getOTP } from '../../api';
import { useSelector } from 'react-redux';
import { selectFirebaseJWT } from '../../redux/features/user/userSlice';
import { toast } from 'react-toastify';
import closeFlow from '../../utils/closeFlow';

const TFA = ({
  loading,
  number,
  handle,
  resendFn,
  resendAgentFn = false,
  setError,
  error,
  confirmBtnText = 'Confirm',
  closeFlowFn,
}) => {
  const [otp, setOtp] = useState('');
  const [countdown, setCountdown] = useState(60);
  const [isResendAllowed, setIsResendAllowed] = useState(true);
  const userToken = useSelector(selectFirebaseJWT);

  const submitForm = (e) => {
    e.preventDefault();
    if (otp.length === 6) handle(otp, e);
  };

  const setInputValue = (value) => {
    if (otp.length === 6 && error) {
      setError(false);
    }
    setOtp(value);
  };

  const getOTPcode = async () => {
    try {
      await getOTP(userToken);
    } catch (error) {
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      console.log(error);
    }
  };

  const handleResend = () => {
    setOtp('');
    setError(false);
    setIsResendAllowed(false);
    setCountdown(60);
    resendFn ? resendFn() : resendAgentFn ? getAgentOTP(userToken, number) : getOTPcode();
  };

  useEffect(() => {
    let timer;

    if (countdown > 0 && !isResendAllowed) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else {
      setIsResendAllowed(true);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [countdown, isResendAllowed]);

  return (
    <form onSubmit={(e) => submitForm(e)}>
      {closeFlowFn ? (
        <h6 className='el-title-h6 med'>
          Enter the confirmation code sent to <span className='orange'>{number}</span>
        </h6>
      ) : (
        <>
          <h4>Enter the confirmation code</h4>

          <span className='c-tfa-box-text el-text-m auth-content--title'>
            The confirmation code was sent by SMS to your number: <b> {number}</b>
          </span>
        </>
      )}
      <div className={`c-tfa-box-code ${error ? 'error' : ''}`}>
        <OtpInput
          shouldAutoFocus={true}
          value={otp}
          onChange={setInputValue}
          numInputs={6}
          renderSeparator={false}
          inputType={'tel'}
          skipDefaultStyles={true}
          containerStyle={'input-box'}
          inputStyle={'input-box--code'}
          renderInput={(props) => <input {...props} />}
        />
      </div>

      <span className='c-tfa-box-notes el-text-s' disabled={!isResendAllowed}>
        Didn’t receive the code?{' '}
        <span
          className={`el-link-s semi ${isResendAllowed ? '' : 'disabled'}`}
          onClick={handleResend}
        >
          {isResendAllowed ? 'Resend' : `Resend in ${countdown} seconds`}
        </span>
      </span>

      <div className='c-tfa-box-btns'>
        <LoadingButton
          loading={loading}
          disabled={otp.length !== 6 || loading}
          type='submit'
          className='el-button orange'
          onClick={(e) => submitForm(e)}
          id='auth-button'
        >
          <span>{loading ? 'Processing' : confirmBtnText}</span>
        </LoadingButton>

        {closeFlowFn && (
          <span className='el-link-m el-button-link' variant='contained' onClick={closeFlow}>
            Cancel
          </span>
        )}
      </div>

      <div id='recaptcha-container' className='resend'></div>
    </form>
  );
};

TFA.propTypes = {
  number: PropTypes.string.isRequired,
  handle: PropTypes.func.isRequired,
  resend: PropTypes.func,
  loading: PropTypes.bool.isRequired,
};

export default TFA;
