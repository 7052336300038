import React from 'react';

const ChatLogMSG = ({ text, logType }) => {
  const classNameEnd = logType === 'invite' ? 'invited' : 'invited';
  return (
    <div className={`chat-agent-${classNameEnd}`} dangerouslySetInnerHTML={{ __html: text }} />
  );
};

export default ChatLogMSG;
