import React, { useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import Dashboard from '../../pages/Dashboard/Dashboard';
import AgentPaymentSystem from '../pages/AgentPaymentSystem';
import Layout from '../../components/Layout';
import OutgoingOrders from '../pages/OutgoingOrders';
import IncomingOrders from '../pages/IncomingOrders';
import TransactionPool from '../pages/TransactionPool';
import Transactions from '../../pages/Transactions';
import TransactionPoolDetails from '../pages/TransactionPoolDetails';
import Account from '../../pages/Account';
import Settings from '../../pages/Settings/Settings';
import AgentChat from '../pages/AgentChat';
import AgentRemitFlow from '../pages/AgentRemitFlow';
import AgentTransactionDetail from '../pages/AgentTransactionDetail';
import { useDispatch, useSelector } from 'react-redux';
import UserSendToUser from '../../user/pages/UserSendToUser';
import AgentRemitDecline from '../pages/AgentRemitDecline';
import { selectOrder } from '../../redux/features/transaction/remittanceSlice';
import { selectmessages, selectOrders, selectSocket } from '../../redux/features/sockets/socketslice';
import { initializeSocketConnection } from '../../redux/socketEvents';
import AccountEdits from '../../pages/AccountEdits';
import AccountDelete from '../../pages/AccountDelete';
import AgentAgentFlow from '../pages/AgentAgentFlow/AgentAgentFlow';
import AgentReassign from '../pages/AgentReassign';
import AgentReassignSearch from '../pages/AgentReassignSearch';
import MPesaFlow from '../../pages/MPesaFlow';
import useAuthentication from '../../components/Authentication/Authentication';
import useUserRole from '../../utils/useUserRole';
import AgentRemitAccept from '../pages/AgentRemitAccept';
import AgentRemitComplete from '../pages/AgentRemitComplete';

const AgentRoutes = () => {
  // Fetching necessary state from Redux store
  const socket = useSelector(selectSocket);
  const { pool, incoming, outgoing } = useSelector(selectOrders);
  const order = useSelector(selectOrder);
  const isAgent = useUserRole('agent');
  const navigate = useNavigate();
  const messages = useSelector(selectmessages(order?.txId));
  const user = useAuthentication();

  // Dispatching action to initialize socket connection
  const dispatch = useDispatch();

  useEffect(() => {
    if (user && !socket.isConnected) dispatch(initializeSocketConnection());
  }, [user]);

  useEffect(() => {
    !isAgent && navigate('/not-found');
  }, []);

  return (
    <Routes>
      {/* Layout for account related routes */}
      <Route element={<Layout type={'acc'} agent={true} user={false} />}>
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='outgoing-orders' element={<OutgoingOrders outgoingTxData={outgoing} />} />
        <Route path='transactions-pool' element={<TransactionPool poolTxData={pool} />} />
        <Route path='incoming-orders' element={<IncomingOrders incomingTxData={incoming} />} />
        <Route path='activity' element={<Transactions />} />
        <Route path='account' element={<Account />} />
        <Route path='settings' element={<Settings />} />
        <Route path='*' element={<Navigate to='/not-found' />} />
      </Route>

      {/* Layout for transaction related routes */}
      <Route element={<Layout type={'tx'} agent={true} user={false} />}>
        <Route path='account/edits'>
          <Route path='information' element={<AccountEdits />} />
          <Route path='location' element={<AccountEdits />} />
          <Route path='delete' element={<AccountDelete />} />
          <Route path='*' element={<Navigate to='/not-found' />} />
        </Route>
      </Route>

      {/* Layout for chat related routes */}
      <Route element={<Layout type={'chat'} agent={true} user={false} />}>
        <Route path='chat' element={<AgentChat messages={messages || []} />} />
      </Route>

      {/* Layout for transaction related routes */}
      <Route path='chat' element={<Layout type={'tx'} agent={true} user={false} />}>
        <Route path='accepted' element={<AgentRemitAccept />} />
        <Route path='declined' element={<AgentRemitDecline />} />
        <Route path='completed' element={<AgentRemitComplete />} />

        <Route path='reassign'>
          <Route path='' element={<AgentReassign />} />
          <Route path='search-agent' element={<AgentReassignSearch />} />
          <Route path='*' element={<Navigate to='/not-found' />} />
        </Route>

        <Route path='*' element={<Navigate to='/not-found' />} />
      </Route>

      {/* Layout for transaction pool related routes */}
      <Route element={<Layout type={'pool'} agent={true} user={false} />}>
        <Route path='transaction-pool-details' element={<TransactionPoolDetails />} />
      </Route>

      {/* Layout for transaction related routes */}
      <Route path='send' element={<Layout type={'tx'} agent={true} user={false} />}>
        <Route path='system'>
          <Route path='' element={<AgentPaymentSystem />} />
          <Route path='remit' element={<AgentRemitFlow />} />
          <Route path='user' element={<UserSendToUser />} />
          <Route path='agent' element={<AgentAgentFlow />} />
          <Route path='mpesa' element={<MPesaFlow />} />
          <Route path='*' element={<Navigate to='/not-found' />} />
        </Route>

        <Route path='*' element={<Navigate to='/not-found' />} />
      </Route>

      {/* Layout for transaction details related routes */}
      <Route element={<Layout type={'tx-single'} agent={true} user={false} />}>
        <Route path='activity/:txId' element={<AgentTransactionDetail />} />
      </Route>
    </Routes>
  );
};

export default AgentRoutes;
