import React from 'react';
import PropTypes from 'prop-types';

const NoResult = ({ children }) => {
  return (
    <div className='flow-not-found'>
      <span className='flow-not-found-icon'>🔍</span>

      <span className='flow-not-found-text'>{children}</span>
    </div>
  );
};

NoResult.propTypes = {
  children: PropTypes.string,
};

export default NoResult;
