import React, { useState } from 'react';
import useBodyClass from '../../hooks/useBodyClass';
import Grid from '@mui/material/Unstable_Grid2';
import Pagination from '@mui/material/Pagination';
import ResponsiveImage from '../../components/ResponsiveImage';
import usePictures from '../../hooks/usePictures';
import useSWR from 'swr';
import { getPosts } from '../../client';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import ArticleCard from '../../components/Post/Post';
import { Container } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

const Blog = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  useBodyClass('p-blog');

  const imgs = usePictures(
    require.context('../../assets/img/p-blog', false, /\.(png|jpe?g|svg|webp)$/)
  );

  // getting posts
  const { data } = useSWR(['posts', pageSize, currentPage], () => getPosts(pageSize, currentPage));

  // function to handle page change
  const handlePageChange = (value) => {
    console.log('Clicked page number:', value);
    setCurrentPage(value);
  };

  return (
    <main>
      <Header />
      <section className='s-hero'>
        <Container disableGutters>
          <div className='s-hero-top'>
            <span className='s-hero-top-bg bg-parent'>
              <span className='s-hero-top-bg--layout'></span>

              <ResponsiveImage
                desktop={imgs['s-hero@desktop.jpg']}
                tablet={imgs['s-hero@tablet.jpg']}
                mobile={imgs['s-hero@mobile.jpg']}
                type={'jpg'}
                alt='section team'
              />
            </span>

            <h1>Blog</h1>
          </div>
        </Container>
      </section>

      <section className='s-grid'>
        <Container disableGutters>
          <Grid
            container
            className='s-grid-list'
            columnSpacing={{
              mobile: 0,
              tablet: 2,
              desktop: 3,
              desktopLarge: 4,
            }}
            rowSpacing={{
              mobile: 3,
              tablet: 3.6,
              desktop: 5,
              desktopLarge: 5.8,
            }}
          >
            {data?.posts?.map((data) => (
              <ArticleCard
                key={uuidv4()}
                author={data.authorName}
                title={data.title}
                datePublished={data._createdAt}
                slug={data.slug.current}
                image={data.mainImage.asset.url}
              />
            ))}
          </Grid>
          <Pagination
            onChange={handlePageChange}
            count={Math.ceil(data?.totalPostCount / pageSize)}
            variant='outlined'
            shape='rounded'
            hideNextButton={true}
            hidePrevButton={true}
          />
        </Container>
      </section>
      <Footer />
    </main>
  );
};

export default Blog;
