import React, { useState, useEffect  } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import { ReactComponent as iconDropDown } from '../../assets/icons/icon-drop-down.svg';
import { v4 as uuidv4 } from 'uuid';
const Filter = ({
  onPeriodChange,
  onTypeChange,
  onPersonsChange,
  onStatusChange,
  periodOptions,
  typeOptions,
  personsOptions,
  statusOptions,
  defaultPeriod,
  defaultType,
  defaultPersons,
  defaultStatus,

}) => {
  const [selectedPeriod, setSelectedPeriod] = useState(defaultPeriod);
  const [selectedType, setSelectedType] = useState(defaultType);
  const [selectedPersons, setSelectedPersons] = useState(defaultPersons);
  const [selectedStatus, setSelectedStatus] = useState(defaultStatus);

  useEffect(() => {
    setSelectedPeriod(defaultPeriod);
  }, [defaultPeriod]);

  useEffect(() => {
    setSelectedType(defaultType);
  }, [defaultType]);

  useEffect(() => {
    setSelectedPersons(defaultPersons);
  }, [defaultPersons]);

  useEffect(() => {
    setSelectedStatus(defaultStatus);
  }, [defaultStatus]);



  const handlePeriodChange = (event) => {
    const selectedPeriod = event.target.value;
    // console.log('selectedPeriod ',selectedPeriod);
    setSelectedPeriod(selectedPeriod);
    onPeriodChange(selectedPeriod);
  };

  const handleTypeChange = (event) => {
    const selectedType = event.target.value;
    // console.log('selectedType ',selectedType);
    setSelectedType(selectedType);
    onTypeChange(selectedType);
  };

  const handlePersonsChange = (event) => {
    const selectedPersons = event.target.value;
    // console.log('selectedPersons ',selectedPersons);
    setSelectedPersons(selectedPersons);
    onPersonsChange(selectedPersons);
  };

  const handleStatusChange = (event) => {
    const selectedStatus = event.target.value;
    // console.log('selectedStatus ',selectedStatus);
    setSelectedStatus(selectedStatus);
    onStatusChange(selectedStatus);
  };




  return (
    <div className='filter'>
      <div className='filter-select'>
        {periodOptions && (
          <div className='filter-select-item'>
            <Select
              variant='outlined'
              value={selectedPeriod}
              onChange={handlePeriodChange}
              fullWidth
              className='el-input-select'
              IconComponent={iconDropDown}
            >
              {periodOptions.map((period) => (
                <MenuItem key={uuidv4()} value={period}>
                  {period === 365 ? (
                    <>1 year</>
                  ) :period === 180 ? (
                    <>6 months</>
                  ) : period === 90 ? (
                    <>3 months</>
                  ) : period === 7 ? (
                    <>Last {period} days</>
                  ) : (
                    <>{period} days</>
                  )}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}
        {typeOptions && (
          <div className='filter-select-item'>
            <Select
              variant='outlined'
              value={selectedType}
              onChange={handleTypeChange}
              fullWidth
              className='el-input-select'
              IconComponent={iconDropDown}
            >
              {typeOptions.map((type) => (
                <MenuItem key={uuidv4()} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}

        {personsOptions && (
          <div className='filter-select-item'>
            <Select
              variant='outlined'
              value={selectedPersons}
              onChange={handlePersonsChange}
              fullWidth
              className='el-input-select'
              IconComponent={iconDropDown}
            >
              {personsOptions.map((persons) => (
                <MenuItem key={uuidv4()} value={persons}>
                  {persons}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}

        {statusOptions && (
          <div className='filter-select-item'>
            <Select
              variant='outlined'
              value={selectedStatus}
              onChange={handleStatusChange}
              fullWidth
              className='el-input-select'
              IconComponent={iconDropDown}
            >
              {statusOptions.map((status) => (
                <MenuItem key={uuidv4()} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}
      </div>
    </div>
  );
};

Filter.propTypes = {
  onPeriodChange: PropTypes.func,
  onTypeChange: PropTypes.func,
  onPersonsChange: PropTypes.func,
  onStatusChange: PropTypes.func,
  periodOptions: PropTypes.array,
  typeOptions: PropTypes.array,
  personsOptions: PropTypes.array,
  statusOptions: PropTypes.array,
  defaultPeriod: PropTypes.number,
  defaultType: PropTypes.string,
  defaultPersons: PropTypes.string,
  defaultStatus: PropTypes.string,
};
export default Filter;

// import React, { useState } from 'react';
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
// import PropTypes from 'prop-types';
// import { ReactComponent as iconDropDown } from '../../assets/icons/icon-drop-down.svg';
// import { v4 as uuidv4 } from 'uuid';

// const Filter = ({
//   onPeriodChange,
//   onTypeChange,
//   onPersonssChange,
//   onStatusChange,
//   defaultPeriod,
//   type,
//   status,
//   senderNames,
//   selectedTypeLink,
// }) => {
//   const [selectedPeriod, setSelectedPeriod] = useState(defaultPeriod);
//   const [selectedType, setSelectedType] = useState(selectedTypeLink || 'All types');
//   const [selectedPersonss, setSelectedPersonss] = useState('All people');
//   const [selectedStatus, setSelectedStatus] = useState('All statuses');

//   const handleTypeChange = (event) => {
//     const selectedType = event.target.value;
//     setSelectedType(selectedType);
//     onTypeChange(selectedType);
//   };

//   const handlePersonssChange = (event) => {
//     const selectedPersonss = event.target.value;
//     setSelectedPersonss(selectedPersonss);
//     onPersonssChange(selectedPersonss);
//   };

//   const handleStatusChange = (event) => {
//     const selectedStatus = event.target.value;
//     setSelectedStatus(selectedStatus);
//     onStatusChange(selectedStatus);
//   };

//   const handlePeriodChange = (event) => {
//     const selectedPeriod = event.target.value;
//     setSelectedPeriod(selectedPeriod);
//     onPeriodChange(selectedPeriod);
//   };
//   const period = ['7', '30', '60', '90', '180', '365'];
//   return (
//     <div className='filter'>
//       <div className='filter-select'>
//         {period && (
//           <div className='filter-select-item'>
//             <Select
//               variant='outlined'
//               value={selectedPeriod}
//               onChange={handlePeriodChange}
//               fullWidth
//               className='el-input-select'
//               IconComponent={iconDropDown}
//             >
//               {period.map((period) => (
//                 <MenuItem key={uuidv4()} value={period}>
//                   {period === '180' ? (
//                     <>6 months</>
//                   ) : period === '365' ? (
//                     <>1 year</>
//                   ) : (
//                     <>{period} days</>
//                   )}
//                 </MenuItem>
//               ))}
//             </Select>
//           </div>
//         )}
//         {type && (
//           <div className='filter-select-item'>
//             <Select
//               variant='outlined'
//               value={selectedType}
//               onChange={handleTypeChange}
//               fullWidth
//               className='el-input-select'
//               IconComponent={iconDropDown}
//             >
//               <MenuItem value='All types'>All types</MenuItem>
//               {type.map((type) => (
//                 <MenuItem key={uuidv4()} value={type} className='item-type'>
//                   {type}
//                 </MenuItem>
//               ))}
//             </Select>
//           </div>
//         )}
//         {senderNames && (
//           <div className='filter-select-item'>
//             <Select
//               variant='outlined'
//               value={selectedPersonss}
//               onChange={handlePersonssChange}
//               fullWidth
//               className='el-input-select'
//               IconComponent={iconDropDown}
//             >
//               <MenuItem value='All people'>All people</MenuItem>
//               {senderNames.map((name) => (
//                 <MenuItem key={uuidv4()} value={name}>
//                   {name}
//                 </MenuItem>
//               ))}
//             </Select>
//           </div>
//         )}

//         {status && (
//           <div className='filter-select-item'>
//             <Select
//               variant='outlined'
//               value={selectedStatus}
//               onChange={handleStatusChange}
//               fullWidth
//               className='el-input-select'
//               IconComponent={iconDropDown}
//             >
//               <MenuItem value='All statuses'>All statuses</MenuItem>
//               {status.map((status) => (
//                 <MenuItem key={uuidv4()} value={status}>
//                   {status}
//                 </MenuItem>
//               ))}
//             </Select>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// Filter.propTypes = {
//   onPeriodChange: PropTypes.func.isRequired,
//   ononTypeChangChange: PropTypes.func,
//   onPersonssChange: PropTypes.func,
//   onStatusChange: PropTypes.func,
//   senderNames: PropTypes.array,
//   selectedTypeLink: PropTypes.string,
// };
// export default Filter;
