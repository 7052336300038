import React, { useState } from 'react';
import { Button } from '@mui/material';
import usePictures from '../../../hooks/usePictures';
import useLowerCase from '../../../hooks/useLowerCase';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/en'; // Import the English locale or the locale you need
import relativeTime from 'dayjs/plugin/relativeTime';
import Avatar from '../../../components/Avatar';
import transformString from '../../../utils/transformString';
dayjs.extend(relativeTime);

const AgentTxItem = ({ tx, userUid, txLink = false }) => {
  const [txDetails, setTxDetails] = useState(false);

  const icons = usePictures(
    require.context('../../../assets/icons', false, /\.(png|jpe?g|svg|webp)$/)
  );

  const imgs = usePictures(
    require.context('../../../assets/img/p-dashboard', false, /\.(png|jpe?g|svg|webp)$/)
  );

  const toggleTxDetails = () => setTxDetails(!txDetails);

  const txPaymentSystem =
    tx && tx?.paymentSystem !== undefined ? useLowerCase(tx?.paymentSystem) : '';
  const txOperation = tx && tx?.operation !== undefined ? useLowerCase(tx?.operation) : '';

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber || phoneNumber.length < 11) return phoneNumber;
    return `${phoneNumber.slice(0, 4)}*****${phoneNumber.slice(-4)}`;
  };

  const txItemContent = (
    <>
      <div className='txagent-item-info'>
        <div className='txagent-item-info-avatar'>
          <>
            {txOperation === 'crypto deposit' ? (
              <>
                <img src={imgs['avatar-cripto@2x.png']} alt='' />
              </>
            ) : txOperation === 'remit' ? (
              <img src={imgs['avatar-pesa-agent@2x.png']} alt='' />
            ) : txOperation === 'mpesa' ||
              (txOperation === 'withdrawal' && txPaymentSystem === 'm-pesa') ? (
              <img src={imgs['avatar-mpesa-agent@2x.png']} alt='' />
            ) : txOperation === 'top up' ? (
              <Avatar data={tx?.sender?.uid === userUid ? tx?.receiver : tx?.sender} />
            ) : txOperation === 'referral reward' || txOperation === 'signup reward' ? (
              <>
                <img src={imgs['avatar-pesa-ref@2x.png']} alt='' />
              </>
            ) : (
              <Avatar data={tx?.sender?.uid !== userUid ? tx?.sender : tx?.receiver} />
            )}
          </>
        </div>
        <div className='txagent-item-info-desc'>
            {txOperation === 'crypto deposit' ? (
              <div className='txagent-item-name'>Crypto deposit</div>
              ) : (txPaymentSystem === 'm-pesa' && txOperation === 'withdrawal') ? (
            <>
              {tx?.receiver?.name && transformString(tx?.receiver?.name) !== ' ' ? (
                <div className='txagent-item-phone-num'>{tx?.receiver?.name}</div>
              ) : (
                <div className='txagent-item-phone-num'>{tx?.receiver?.phoneNumber}</div>
              )}
            </>
          ) : txOperation === 'signup reward' ? (
            <div className='txagent-item-rew'>{tx?.sender?.name}</div>
          ) : tx?.agentSender || tx?.agentReceiver ? (
            <>
              {(tx?.sender?.phoneNumber || tx?.receiver?.phoneNumber) && (
                <div className='txagent-item-phone'>
                  {tx?.sender?.phoneNumber && (
                    <div className='txagent-item-phone-num'>
                      {formatPhoneNumber(tx?.sender?.phoneNumber)}
                    </div>
                  )}
                  <img src={icons['icon-arrow-trans.svg']} alt='' />
                  {tx?.receiver?.phoneNumber && (
                    <div className='txagent-item-phone-num'>
                      {formatPhoneNumber(tx?.receiver?.phoneNumber)}
                    </div>
                  )}
                </div>
              )}
            </>
          ) : tx?.sender?.uid === userUid &&
            tx?.receiver?.name &&
            transformString(tx?.receiver?.name) !== ' ' ? (
            <>
              <div className='txagent-item-name'>{tx?.receiver?.name}</div>
            </>
          ) : tx?.receiver?.uid === userUid &&
            tx?.sender?.name &&
            transformString(tx?.sender?.name) !== ' ' ? (
            <>
              <div className='txagent-item-name'>{tx?.sender?.name}</div>
            </>
          ) : (
            <></>
          )}
          <div className='txagent-item-info-bottom'>
            {txOperation === 'referral reward' ? (
              <div className='txagent-item-info-ag'>
                {tx?.agentSender?.name}. has registered using your referral link
              </div>
            ) : tx?.status === 'cancel' ? (
              <div className='txagent-item-info-ag'>
                <div className='avatar'>
                  <img src={icons['icon-dec-cancel.svg']} alt='' />
                </div>
                <div className='text'>Canceled by sender</div>
              </div>
            ) : txOperation === 'remit' ? (
              <div className='txagent-item-info-ag'>
                <Avatar
                  data={userUid === tx?.agentSender?.uid ? tx?.agentReceiver : tx?.agentSender}
                />
              </div>
            ) : (
              <></>
            )}
            {tx?.timestamp && (
              <div className='txagent-item-info-date'>
                {dayjs(parseInt(tx?.timestamp)).format('DD MMM, HH:mm')}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className='txagent-item-quantity'>
        {txOperation === 'crypto deposit' ? (
          <>
          <div className='txagent-item-quantity-wrap wallet-quan'>
            <div className='icon'>
            <img src={icons['icon-wallet.svg']} alt='' />
            </div>
            <div className='text'>
              {tx?.amount} {tx?.currency}
            </div>
          </div>
        </>
        ) : (userUid === tx?.agentSender?.uid ||
        userUid === tx?.sender?.uid ||
        tx?.type === 'outgoing') ? (
          <>
            <div className='txagent-item-quantity-wrap sent-quan'>
              <div className='icon'>
                <img src={icons['icon-sent.svg']} alt='' />
              </div>
              <div className='text'>
                {tx?.amount} {tx?.currency}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='txagent-item-quantity-wrap received-quan'>
              <div className='icon'>
                <img src={icons['icon-received.svg']} alt='' />
              </div>
              <div className='text'>
                {tx?.amount} {tx?.currency}
              </div>
            </div>
          </>
        )}
      </div>

      <div className='txagent-item-status'>
        {tx?.status === 'completed' ? (
          <div className='txagent-item-status-wrap success'>
            <div className='icon'>
              <img src={icons['icon-success.svg']} alt='' />
            </div>
            <div className='text'>completed</div>
          </div>
        ) : tx?.status === 'cancelled' ? (
          <div className='txagent-item-status-wrap failed'>
            <div className='icon'>
              <img src={icons['icon-failed.svg']} alt='' />
            </div>
            <div className='text'>cancelled</div>
          </div>
        ) : tx?.status === 'failed' ? (
          <div className='txagent-item-status-wrap failed'>
            <div className='icon'>
              <img src={icons['icon-failed.svg']} alt='' />
            </div>
            <div className='text'>failed</div>
          </div>
        ) : tx?.status === 'processing' || tx?.status === 'initiated' ? (
          <div className='txagent-item-status-wrap pending'>
            <div className='icon'>
              <img src={icons['icon-time.svg']} alt='' />
            </div>
            <div className='text'>processing</div>
          </div>
        ) : tx?.status === 'pending' ? (
          <div className='txagent-item-status-wrap pending'>
            <div className='icon'>
              <img src={icons['icon-time.svg']} alt='' />
            </div>
            <div className='text'>pending</div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className='txagent-item-details'>
        <span className='txagent-item-details-btn'>
          <img src={icons['icon-arrow-right.svg']} alt='' />
        </span>
      </div>
    </>
  );

  return (
    <div className='txagent-item'>
      <>
        {txLink ? (
          <>
            <Link className={`txagent-item-wrap link`}>{txItemContent}</Link>
          </>
        ) : (
          <>
            <div className={`txagent-item-wrap`} onClick={toggleTxDetails}>
              {txItemContent}
            </div>
          </>
        )}
      </>
    </div>
  );
};

AgentTxItem.propTypes = {
  tx: PropTypes.object,
  userUid: PropTypes.string,
  txLink: PropTypes.bool,
  loading: PropTypes.bool,
};

export default AgentTxItem;
