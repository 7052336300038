import React from 'react';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';

const Popup = ({ className = '', open, togglePopUp, children }) => {
  return (
    <Dialog
      open={open}
      onClose={togglePopUp}
      maxWidth='sm'
      className={`modal-center${' ' + className}`}
      classes={{ paper: 'custom-paper' }}
    >
      <div className='modal-top'>
        <DialogActions>
          <Button className='modal-close' onClick={togglePopUp} color='primary'>
            <svg
              width='100%'
              height='100%'
              viewBox='0 0 40 40'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g id='icons/X'>
                <path
                  id='Union'
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M8.68645 8.68547C8.29593 9.076 8.29593 9.70916 8.68645 10.0997L18.586 19.9993L8.68645 29.8988C8.29592 30.2894 8.29592 30.9225 8.68645 31.313C9.07697 31.7036 9.71014 31.7036 10.1007 31.313L20.0002 21.4135L29.8997 31.3129C30.2902 31.7034 30.9233 31.7034 31.3139 31.3129C31.7044 30.9224 31.7044 30.2892 31.3139 29.8987L21.4144 19.9993L31.3139 10.0998C31.7044 9.70931 31.7044 9.07615 31.3139 8.68562C30.9233 8.2951 30.2902 8.2951 29.8997 8.68562L20.0002 18.585L10.1007 8.68547C9.71014 8.29495 9.07698 8.29495 8.68645 8.68547Z'
                />
              </g>
            </svg>
          </Button>
        </DialogActions>
      </div>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

Popup.propTypes = {
  open: PropTypes.bool.isRequired,
  togglePopUp: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Popup;
