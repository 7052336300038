import React, { useState } from 'react';
import rewardImg from '../../assets/img/p-account/rewards.png';
import { FormControlLabel, Checkbox, Button } from '@mui/material';
import checboxIcon from '../../assets/icons/icon-checbox-filled.svg';
import Popup from '../Popup';
import ReactPlayer from 'react-player';
import rewardsbanner from '../../assets/anims/videos/popups/rewards_ff.mp4';
import { Link } from 'react-router-dom';

const Rewards = ({ handle }) => {
  const [checked, setChecked] = useState(false);
  const [popup, setPopup] = useState(false);
  const togglePopup = () => setPopup(!popup);

  const handleCheckbox = (value) => {
    setChecked(value);
    handle(value);
  };

  return (
    <div className='flow-rewards'>
      <span className='flow-rewards-text'>

        Get a<b>50 PESA reward</b> to your balance for every <b>newly registered user</b>
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          onClick={togglePopup}
        >
          <path
            d='M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 0.999999 8 0.999999C11.866 1 15 4.13401 15 8Z'
            stroke='#6D757D'
            strokeWidth='2'
          />
          <path
            d='M9 11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55228 10 9 10.4477 9 11Z'
            fill='#6D757D'
          />
          <path
            d='M6 6.5V6.5C6 5.67157 6.67157 5 7.5 5H8.76393C9.44659 5 10 5.55341 10 6.23607V6.23607C10 6.70426 9.73548 7.13226 9.31672 7.34164L8.44721 7.77639C8.17313 7.91343 8 8.19357 8 8.5V8.5'
            stroke='#6D757D'
            strokeWidth='1.75'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </span>

      <FormControlLabel
        className='flow-rewards-checkbox'
        control={
          <Checkbox
            checked={checked}
            checkedIcon={<img src={checboxIcon} alt='checboxIcon' />}
            onChange={(event) => handleCheckbox(event.target.checked)}
          />
        }
        label={'Create account (optional)'}
      />

      <span className='flow-rewards-img'>
        <img src={rewardImg} alt='reward img for element' />
      </span>

      <Popup open={popup} togglePopUp={togglePopup}>
        <div className='modal-content'>
          <div className='modal-img'>
            <ReactPlayer
              className='player'
              controls={false}
              playing={true}
              loop={true}
              muted={true}
              playsinline={true}
              url={rewardsbanner}
              width='100%'
              height='100%'
            />
          </div>

          <div className='modal-desc'>
            <div className='modal-desc-title'>Rewards for you!</div>

            <div className='el-text-m modal-desc-text'>
              {`We pay you 50 PESA rewards each time you use our platform sending money. Your reward will be added to your PESA balance after the transaction is processed. You can find it in transaction details in your Activity history. `}
              <Link target='_blank' to={'/rewards'} className='el-button-link'>
                More details here
              </Link>
            </div>
          </div>

          <div className='modal-btns'>
            <Button
              className='el-button orange el-button-continue'
              variant='contained'
              onClick={togglePopup}
            >
              Ok, got it
            </Button>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default Rewards;
