import React, { useContext, useEffect, useState } from 'react';
import useBodyClass from '../../../hooks/useBodyClass';
import { Container, FormControlLabel, Checkbox } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import checboxIcon from '../../../assets/icons/icon-checbox-filled.svg';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';
import UserContext from '../../../UserContext/UserContext';
import { selectAccount, setAccount } from '../../../redux/features/user/userSlice';
import PhoneField from '../../../components/PhoneField';

const Auth = () => {
  useBodyClass('p-auth');
  const { pathname, search } = useLocation();
  const userProfile = useSelector(selectAccount);
  const dispatch = useDispatch();
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const { signUpandLogin, loading, setLoading } = useContext(UserContext);
  const location = useLocation();

  const [searchPhone, setSearchPhone] = useState({
    phoneNumber: '',
    dialCode: '',
    isValid: false,
  });

  const { phoneNumber, isValid } = searchPhone;

  const login = pathname === '/auth/login';
  const signup = pathname === '/auth/signup';
  const invite = search && new URLSearchParams(search).get('invite');

  const signUpFunction = async (e) => {
    e.preventDefault();
    try {
      signUpandLogin(phoneNumber, signup, location.state?.linkBack);
    } catch (error) {
      console.error('signUpFunction', error);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      setLoading(false);
    }
  };

  const handleSetNumber = ({ phoneNumber, isValid, dialCode }) =>
    setSearchPhone((prevState) => {
      return {
        ...prevState,
        phoneNumber,
        isValid,
        dialCode,
      };
    });

  const isDisableBtn = signup ? !(isValid && agreedToTerms) : !isValid;

  useEffect(() => {
    document
      .querySelector("meta[name='viewport']")
      .setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1');
    invite && dispatch(setAccount({ ...userProfile, inviteRFCode: invite }));
  }, [dispatch, invite]);

  useEffect(() => {
    document
      .querySelector("meta[name='viewport']")
      .setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1');
  }, []);

  return (
    <>
      <Header />

      <section className='s-hero'>
        <Container disableGutters>
          <div className='s-hero-top top-inner'>
            <div className='s-hero-top-desc login-inner'>
              {login && <h1>login</h1>}
              {signup && <h1>sign up</h1>}
            </div>

            <form className='s-hero-top-form login-form' onSubmit={(e) => signUpFunction(e)}>
              <PhoneField
                searchPhone={phoneNumber}
                setSearchPhone={handleSetNumber}
                submitFn={signUpFunction}
              />
              <div id='recaptcha-container'></div>

              {signup && (
                <FormControlLabel
                  id='user_rules'
                  name='user_rules'
                  control={
                    <Checkbox
                      checked={agreedToTerms}
                      checkedIcon={<img src={checboxIcon} alt='checboxIcon' />}
                      onChange={(event) => setAgreedToTerms(event.target.checked)}
                    />
                  }
                  label={
                    <>
                      I have read the{' '}
                      <a href='/terms' target='_blank' rel='noopener'>
                        Terms and Conditions
                      </a>{' '}
                      and{' '}
                      <a href='/privacy' target='_blank' rel='noopener'>
                        Privacy Policy
                      </a>
                    </>
                  }
                />
              )}

              <LoadingButton
                id='login-button'
                disabled={isDisableBtn}
                loading={loading}
                type='submit'
                className='el-button orange'
              >
                <span>{loading ? 'Processing' : login ? 'Log in' : 'Continue'}</span>
              </LoadingButton>

              <div className='notes'>
                {login && (
                  <p className='el-text-s'>
                    Don’t have an account?{' '}
                    <a className='el-link-s semi' href='/auth/signup'>
                      Sign up
                    </a>
                  </p>
                )}

                {signup && (
                  <p className='el-text-s'>
                    Have an account?{' '}
                    <a className='el-link-s semi' href='/auth/login'>
                      Log in
                    </a>
                  </p>
                )}
              </div>
            </form>
          </div>
        </Container>
      </section>

      <Footer />
    </>
  );
};

export default Auth;
