import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import usePictures from '../../hooks/usePictures';
import QRmodal from '../QRmodal';

const AccountFooter = () => {
  const imgs = usePictures(
    require.context('../../assets/img/p-dashboard', false, /\.(png|jpe?g|svg|webp)$/)
  );

  const [modal, setOpenModal] = useState({ open: false, store: false });

  const toggleModal = (openModal, store) =>
    setOpenModal({ ...modal, open: openModal, store: store });

  return (
    <div className='c-acc-footer'>
      <div className='c-acc-footer-wrap'>
        <div className='c-acc-footer-app'>
          <div className='title'>Stay connected wherever you are with the mobile app!</div>
          <div className='list'>
            <span className='list-btn play' onClick={() => toggleModal(true)}>
              <img src={imgs['google-play.png']} alt='' />
            </span>

            <span className='list-btn store' onClick={() => toggleModal(true, true)}>
              <img src={imgs['app-store.png']} alt='' />
            </span>
          </div>
        </div>
        <div className='c-acc-footer-get'>
          <div className='title'>Get help</div>
          <Link className='link' to={'/account'}>
            <img src={imgs['icon-faq.png']} alt='' />
          </Link>
        </div>
      </div>
      <div className='c-acc-footer-bg'>
        <img src={imgs['account-footer.jpg']} alt='' />
      </div>

      <QRmodal openModal={modal.open} toggleModal={toggleModal} store={modal.store} />
    </div>
  );
};

export default AccountFooter;
