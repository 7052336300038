import React, { useContext, useEffect, useState } from 'react';
import ArrowBack from '../../../../components/ArrowBack';
import { toast } from 'react-toastify';
import { getAgentsList } from '../../../../api';
import { useSelector } from 'react-redux';
import { selectFirebaseJWT } from '../../../../redux/features/user/userSlice';
import PhoneInput from 'react-phone-input-2';
import UserContext from '../../../../UserContext/UserContext';
import { v4 as uuidv4 } from 'uuid';

const DepositToSSP = () => {
  const [userAgents, setUserAgents] = useState(null);
  const [error, setError] = useState(false);
  const userToken = useSelector(selectFirebaseJWT);
  const { loading, setLoading } = useContext(UserContext);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await getAgentsList(userToken);
        const agentsData = Object.values(response.data).flat();
        setUserAgents(agentsData);
      } catch (error) {
        console.log('DepositToSSP', error);
        setError(true);
        toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <>
      {userAgents && !loading && !error && (
        <div className='deposit-wrap'>
          <ArrowBack />

          <div className='deposit-office'>
            <div className='title el-title-h6 med'>
              Visit one of our agents to deposit SSP to your account
            </div>
            <div className='deposit-office-list'>
              {userAgents &&
                userAgents.map((agent, index) => (
                  <div className='item' key={uuidv4()}>
                    <div className='item-wrap'>
                      <h6 className='title el-title-h6 med'>Office {index + 1}</h6>

                      <div className='item-list'>
                        <div className='item-list-block'>
                          <div className='item-list-row'>
                            <div className='item-list-title el-text-s'>Location</div>
                            {(agent?.location.country || agent?.location.city) && (
                              <div className='item-list-value el-text-m'>
                                {agent.location.country}, {agent.location.city}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className='item-list-block'>
                          <div className='item-list-row'>
                            <div className='item-list-title el-text-s'>Agent</div>
                            <div className='item-list-value el-text-m'>{agent?.name}</div>
                          </div>
                        </div>

                        <div className='item-list-block'>
                          <div className='item-list-row'>
                            <div className='item-list-title  el-text-s'>Contact</div>
                            <div className='item-list-value el-text-m'>
                              {agent?.phoneNumber &&
                                agent.phoneNumber.map((tel) => {
                                  return <PhoneInput value={tel} disabled disableDropdown />;
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}

      {!loading && error && (
        <div className='deposit-wrap'>
          <ArrowBack />
          <h6 className='el-title-error'>Error</h6>
        </div>
      )}
    </>
  );
};

export default DepositToSSP;
