import React, { useEffect, useState } from 'react';
import iconSuccess from '../../../assets/icons/icon-n-success.svg';
import iconAttention from '../../../assets/icons/icon-n-attention.svg';
import iconInfo from '../../../assets/icons/icon-n-info.svg';
import iconError from '../../../assets/icons/icon-n-error.svg';
import { ReactComponent as IconQ } from '../../../assets/icons/icon_question.svg';
import transformNumbers from '../../../utils/transformNumbers';
import { Button, Menu, MenuItem } from '@mui/material';
import rewardImg from '../../../assets/img/p-dashboard/icon-reward-no-bg.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectFirebaseJWT } from '../../../redux/features/user/userSlice';
import { selectOrders } from '../../../redux/features/sockets/socketslice';
import { updatecurrentOrder } from '../../../redux/features/transaction/remittanceSlice';
import ReactPlayer from 'react-player';
import rewardsbanner from '../../../assets/anims/videos/popups/rewards_ff.mp4';
import Popup from '../../../components/Popup';
import useUserRole from '../../../utils/useUserRole';
import findOrderById from '../../../utils/findOrderById';
import { getTXlist } from '../../../api';

const NotificationMenu = ({
  data,
  togglePopUp,
  parseTime,
  updateNotificationStatus,
  delNotification,
}) => {
  const { id, icon, message, read, timestamp, txId, reward } = data;
  const { pool, incoming, outgoing } = useSelector(selectOrders);
  const userToken = useSelector(selectFirebaseJWT);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchor, setAnchor] = useState(null);
  const [popupReward, setPopupReward] = useState(false);
  const userRole = useUserRole();
  const isAgent = useUserRole('agent');

  const handleMenuOpen = (e) => setAnchor(e.target);

  const handleMenuClose = () => setAnchor(null);

  const toggleRewardPopup = () => setPopupReward(!popupReward);

  const fetchOrderFromApi = async (txId) => {
    try {
      const dataTx = await getTXlist(userToken, 180);
      let txLink = false;
      Object.entries(dataTx.data.activity).forEach(([date, txs]) => {
        const orderId = txs.find((tx) => tx.txId === txId);
        if (orderId) {
          txLink = true;
        }
      });
      return txLink;
    } catch (error) {
      console.error('Error fetching order from API:', error);
    }
  };

  const navigateTo = async (e, txId, id) => {
    e.preventDefault();

    if (popupReward || anchor) return;

    if (
      e.target.parentElement.classList.contains('el-bottom-icon') ||
      e.target.parentElement.classList.contains('el-bottom-icon-svg')
    ) {
      toggleRewardPopup();
    } else if (e.target.classList.contains('el-btn')) {
      handleMenuOpen(e, id);
    } else if (
      !e.target.classList.contains('MuiModal-backdrop') ||
      !e.target.parentElement.classList.contains('el-btn')
    ) {

      if (txId ) {
        const transactionLink = fetchOrderFromApi(txId);
           if (isAgent) {
          const order = findOrderById({ pool, incoming, outgoing }, txId);

          if (order) {
            dispatch(updatecurrentOrder(order));
            navigate(`/agent/chat`);
          } else if (transactionLink) {
            navigate(`/agent/activity/${txId}`);

          } else {
            console.error('Order and tx not found');
            togglePopUp(false);
            await updateNotificationStatus(id, false, false);
          }
        } else if (transactionLink) {
          navigate(`/user/transactions/${txId}`);
        } else {
          console.error('Order and tx not found');
          togglePopUp(false);
          await updateNotificationStatus(id, false, false);
        }
      }
      togglePopUp(false);
      await updateNotificationStatus(id, false, false);

    } else return;
  };

  return (
    <div className={`el ${read === true ? '' : 'unread'}`} onClick={(e) => navigateTo(e, txId, id)}>
      <div className='el-top'>
        <div className='el--title' dangerouslySetInnerHTML={{ __html: message }} />

        {parseTime(timestamp)}

        <div className='el-text-m med el--status'>
          {icon === 'success' ? (
            <img src={iconSuccess} alt='' />
          ) : icon === 'attention' ? (
            <img src={iconAttention} alt='' />
          ) : icon === 'information' ? (
            <img src={iconInfo} alt='' />
          ) : icon === 'error' ? (
            <img src={iconError} alt='' />
          ) : (
            <> </>
          )}
        </div>

        <div className={`el-btn ${anchor ? 'active' : ''}`}>
          <div className='el-btn-circle'></div>
          <div className='el-btn-circle'></div>
          <div className='el-btn-circle'></div>
        </div>

        <Menu
          className='el-btn-menu'
          anchorEl={anchor}
          open={Boolean(anchor)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem
            className='el-text-s el-btn-menu--item'
            onClick={async () => {
              await updateNotificationStatus(id, read);
              handleMenuClose();
            }}
          >
            {read === 'false' || !read ? 'Mark as read' : 'Mark as unread'}
          </MenuItem>

          <MenuItem
            className='el-text-s el-btn-menu--item'
            onClick={async () => {
              await delNotification(id);
              handleMenuClose();
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </div>

      {reward && (
        <div className='el-bottom'>
          <span className='el-bottom-img'>
            <img src={rewardImg} alt='rewards icon' />
          </span>

          <span className='el-text-s el-bottom--text'>
            You received a reward of <span className='el-text-s semi'>{transformNumbers(parseFloat(reward))} PESA.</span>
          </span>

          <span className='el-bottom-icon' onClick={toggleRewardPopup}>
            <IconQ className='el-bottom-icon-svg' />
          </span>
        </div>
      )}

      <Popup open={popupReward} togglePopUp={toggleRewardPopup}>
        <div className='modal-content'>
          <div className='modal-img'>
            <ReactPlayer
              className='player'
              controls={false}
              playing={true}
              loop={true}
              muted={true}
              playsinline={true}
              url={rewardsbanner}
              width='100%'
              height='100%'
            />
          </div>

          <div className='modal-desc'>
            <div className='modal-desc-title'>Rewards for you!</div>

            <div className='el-text-m modal-desc-text'>
              We pay you rewards each time you use our platform sending money to your friends and
              family. Your reward will be added to your PESA balance after transaction is processed.
              You can also find it in your Activity history.
            </div>
          </div>

          <div className='modal-btns'>
            <Button
              className='el-button orange el-button-continue'
              variant='contained'
              onClick={toggleRewardPopup}
            >
              Ok, got it
            </Button>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default NotificationMenu;
