import { useSelector } from 'react-redux';
import { selectAccount } from '../redux/features/user/userSlice';

const useUserRole = (role) => {
  const user = useSelector(selectAccount);
  if (user) {
    if (role) {
      return user?.role === role;
    } else {
      return user?.role === 'agent' ? 'agent' : 'user';
    }
  }
  return null;
};

export default useUserRole;
