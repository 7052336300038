import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ArrowBack from '../../../components/ArrowBack';
import { useSelector } from 'react-redux';
import { selectFirebaseJWT } from '../../../redux/features/user/userSlice';
import SingleContact from '../../../components/SingleContact';
import { toast } from 'react-toastify';
import { getAgentsList } from '../../../api';
import { v4 as uuidv4 } from 'uuid';
import SearchField from '../../../components/SearchField';
import getDataLength from '../../../utils/getDataLength';
import NoResult from '../../../components/NoResult';

const AgentReceiver = ({ flowData, setData, changeStep }) => {
  const userToken = useSelector(selectFirebaseJWT);
  const [localLoading, setLocalLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(flowData?.userReceiver?.name?.split(' ')[0].toLowerCase() || '');
  const [originalData, setOriginalData] = useState([]);
  const [agentsData, setAgentsData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const limit = 5;

  const skeleton = (
    <div className='c-contact-box-el'>
      <div className='c-contact-box-el-symbol'></div>
      {[...new Array(5)].map(() => (
        <div className='contact-wrap' key={uuidv4()}>
          <SingleContact loading={true} />
        </div>
      ))}
    </div>
  );
  const checkHasMore = (data) => setHasMore(getDataLength(data) === limit);

  const handleSearch = async (response, searchValue) => {
    setCurrentPage(1);
    setSearchValue(searchValue);
    setAgentsData(response?.data?.contact || {});
    checkHasMore(response?.data?.contact || {});
  };

  const handleClearSearch = async () => {
    setSearchValue('');
    setCurrentPage(1);
    setAgentsData({});
    setAgentsData(originalData);
    checkHasMore(originalData);
    setLocalLoading(false);
  };

  const setReceiver = ({ userReceiver }) => setData({ userReceiver });

  const renderContactGroup = (data) => {
    if (data) {
      return (
        <div className='c-contact-box-el' key={uuidv4()}>
          <div className='c-contact-box-el-symbol'>{data[0]}</div>
          {data[1].map((contact) => (
            <div className='contact-wrap' key={uuidv4()}>
              <SingleContact
                contact={contact}
                setData={setReceiver}
                flow={true}
                agentSearch={true}
                btnLoading={localLoading}
                agentToAgent={true}
              />
            </div>
          ))}
        </div>
      );
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLocalLoading(true);
        const response = await getAgentsList(userToken);

        if (response.status === 200) {
          const agents = response?.data;
          setOriginalData(agents);
          setAgentsData(agents);
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        console.log(error);
        toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      } finally {
        setLocalLoading(false);
      }
    })();
  }, [userToken]);

  return (
    <>
      <ArrowBack handle={() => changeStep(-1)} />

      <div className='payment-title'>
        <h6 className='el-title-h6 med'>
          Search <span className='orange'>Pesabase</span> agent
        </h6>
      </div>

      <div className='send-step-content'>
        <SearchField
          className='agents-search-field'
          contactType='contact'
          token={userToken}
          onClear={handleClearSearch}
          onSearch={(response, searchValue) => handleSearch(response, searchValue)}
          placeholderText='Enter name, email, or phone number'
          toggleLoading={setLocalLoading}
          agents={true}
        />

        <div className='c-contact-box'>
          {localLoading && skeleton}

          {!localLoading && Object.entries(agentsData).length > 0 && Object.entries(agentsData).map(renderContactGroup)}

          {Object.entries(agentsData).length === 0 && searchValue !== '' && !localLoading && (
            <div className='c-contact-box-el'>
              <NoResult>{'No agents found. Try entering the email or mobile number.'}</NoResult>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

AgentReceiver.propTypes = {
  changeStep: PropTypes.func.isRequired,
};

export default AgentReceiver;
