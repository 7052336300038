import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import Popup from '../Popup';
import { Button } from '@mui/material';
import UserContext from '../../UserContext/UserContext';
import { selectFirebaseJWT } from '../../redux/features/user/userSlice';
import Loader from '../Loader';
import stillHereVideo from '../../assets/anims/videos/popups/still-here-loop.mp4';
import ReactPlayer from 'react-player';
import LoadingButton from '@mui/lab/LoadingButton';

const PrivateRoute = () => {
  const firebaseJWT = useSelector(selectFirebaseJWT);
  const { logout, refreshTokens } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpenPopUp, setIsOpenPopUp] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const [expired, setExpired] = useState(false);
  const { loading } = useContext(UserContext);
  const [activeLoader, setActiveLoader] = useState(false);

  const togglePopUp = () => setIsOpenPopUp(!isOpenPopUp);

  const checkTokenExpiration = (token) => {
    if (token) {
      const decodedToken = jwt_decode(token);
      const expirationTime = decodedToken.exp * 1000;
      const currentTime = Date.now();

      // Calculate the remaining time until token expiration
      const remainingTime = expirationTime - currentTime;
      console.log((remainingTime / 1000 / 60).toFixed(2) + ' minutes left');
      return remainingTime;
    } else {
      console.log('No token provided');
      return 0;
    }
  };

  const handleTokenExpiration = () => {
    // Check the remaining time until token expiration
    const remainingTime = checkTokenExpiration(firebaseJWT);
    if (firebaseJWT) {
      if (remainingTime > 0) {
        // Create a timer with the remaining time
        const timer = setTimeout(() => {
          // Timer expired (refresh token, log out)
          setExpired(true);
          togglePopUp();
          console.log('Token expired. Perform necessary actions.');
        }, remainingTime);

        // rest of the code
      } else {
        logout();
      }
    }
  };

  const refreshToken = async () => {
    if (expired) {
      setLocalLoading(true);
      await refreshTokens();
      togglePopUp();
      window.location.reload();
    } else {
      togglePopUp();
    }
  };

  useEffect(() => {
    let timer;

    if (isOpenPopUp) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isOpenPopUp]);

  useEffect(() => {
    if (countdown === 0) logout();
  }, [countdown]);

  // toggle main global loader (coins anims)
  useEffect(() => {
    setActiveLoader(loading ? true : false);
  }, [loading]);

  useEffect(() => {
    if (!firebaseJWT) {
      navigate('/auth/login', { state: { linkBack: location.pathname } });
    }

    let inactivityTimer;

    const resetTimer = () => {
      clearTimeout(inactivityTimer);

      inactivityTimer = setTimeout(() => {
        togglePopUp();
      }, 900000);
    };

    const handleActivity = () => resetTimer();

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    resetTimer();

    handleTokenExpiration();

    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      clearTimeout(inactivityTimer);
    };
  }, []);

  return (
    firebaseJWT && (
      <>
        <Outlet />

        <Popup open={isOpenPopUp} togglePopUp={togglePopUp}>
          <div className='modal-content'>
            <div className='modal-img'>
              <ReactPlayer
                className='player'
                controls={false}
                playing={true}
                loop={true}
                muted={true}
                playsinline={true}
                url={stillHereVideo}
                width='100%'
                height='100%'
              />
            </div>

            <div className='modal-desc'>
              <div className='modal-desc-title'>
                {expired ? 'Are you still using the app?' : 'Are you still there?'}
              </div>
              <div className='el-text-m modal-desc-text'>
                {expired ? (
                  <>
                    For account security, click 'Stay Logged In' to verify your presence. Otherwise,
                    for your protection, you'll be logged out in <b>{countdown}</b>.
                  </>
                ) : (
                  <>
                    There has been no activity on this page for a long time. Click 'I'm still here'
                    to keep this session going. If not, we'll log you out in <b>{countdown}</b>.
                  </>
                )}
              </div>
            </div>

            <div className='modal-btns'>
              <LoadingButton
                onClick={() => refreshToken()}
                className='el-button el-button-btn orange'
                loading={localLoading}
              >
                <span>
                  {localLoading ? 'Processing' : expired ? 'Stay Logged In' : "I'm still here"}
                </span>
              </LoadingButton>

              <Button
                disabled={localLoading}
                className='el-button el-button-continue'
                variant='contained'
                onClick={logout}
              >
                Log out
              </Button>
            </div>
          </div>
        </Popup>

        <Loader active={activeLoader} />
      </>
    )
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func,
};

export default PrivateRoute;
