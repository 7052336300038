import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  selectRemitReceiver,
  selectRemitSender,
} from '../../../../../redux/features/transaction/remittanceSlice';
import TFA from '../../../../../components/TFA';
import ArrowBack from '../../../../../components/ArrowBack';
import {
  NewUserPersonal,
  NewUserGeneral,
  NewUserLocation,
  NewUserVerify,
  NewUserIndetify,
} from '../index';

const NewUserFlow = ({ sender, changeStep, step, localLoading, submitUser, setError, error }) => {
  const remitUser = useSelector(sender ? selectRemitSender : selectRemitReceiver);
  const userDocs = useSelector(selectRemitSender)?.documents;
  const [docType, setDocType] = useState(
    userDocs?.type ? (userDocs.type === 'passport' ? 0 : 1) : ''
  );
  /**
   * Sets the document type and advances to the next sub-step.
   * @param {string} type - The document type selected by the user.
   */

  const changeDocType = (type) => {
    setDocType(type);
    changeStep('newUserSteps', 1);
  };

  return (
    <>
      {step === 0 && (
        <NewUserGeneral changeStep={changeStep} remitUser={remitUser} sender={sender} />
      )}

      {step === 1 && (
        <NewUserPersonal changeStep={changeStep} remitUser={remitUser} sender={sender} />
      )}

      {step === 2 && (
        <NewUserLocation
          changeStep={changeStep}
          remitUser={remitUser}
          sender={sender}
          submitUser={submitUser}
        />
      )}

      {step === 3 && <NewUserVerify changeDocType={changeDocType} changeStep={changeStep} />}

      {step === 4 && <NewUserIndetify docType={docType} changeStep={changeStep} />}

      {step === 5 && (
        <div className='send-step'>
          <ArrowBack handle={() => changeStep('newUserSteps', -1)} />

          <TFA
            loading={localLoading}
            number={remitUser.phoneNumber}
            changeStep={changeStep}
            handle={submitUser}
            resendAgentFn={true}
            setError={setError}
            error={error}
            closeFlowFn={true}
          />
        </div>
      )}
    </>
  );
};

NewUserFlow.propTypes = {
  changeStep: PropTypes.func.isRequired,
};

export default NewUserFlow;
