import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { ReactComponent as iconSelect } from '../../../../../assets/icons/icon-select.svg';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useDispatch } from 'react-redux';
import {
  setRemitReceiver,
  setRemitSender,
} from '../../../../../redux/features/transaction/remittanceSlice';
import ArrowBack from '../../../../../components/ArrowBack';
import closeFlow from '../../../../../utils/closeFlow';
import DOBPicker from '../../../../../components/DOBPicker';
import transformString from '../../../../../utils/transformString';

const NewUserPersonal = ({ changeStep, remitUser, sender }) => {
  const dispatch = useDispatch();

  const [currentUser, setCurrentUser] = useState({
    email: remitUser?.personalInfo?.email || '',
    dateOfBirth: remitUser?.personalInfo?.dateOfBirth,
    gender: transformString(remitUser?.personalInfo?.gender) || '',
    occupation: remitUser?.personalInfo?.occupation || '',
  });

  const { email, dateOfBirth, occupation, gender } = currentUser;

  const isValidData = () => {
    const { email, occupation, ...newObj } = currentUser;
    const values = Object.values(newObj).map((field) => {
      if (typeof field === 'object') {
        return field !== null && field.$y > 0;
      } else {
        return field !== '' && field !== null && field !== 'Invalid Date';
      }
    });

    const result = values.every((value) => value === true);
    return result;
  };

  const setTextFields = (e) => {
    setCurrentUser({ ...currentUser, [e.target.name]: e.target.value });
  };

  const setDoB = (e) => {
    setCurrentUser({ ...currentUser, dateOfBirth: Date.parse(e) });
  };

  const setGender = (e) => {
    setCurrentUser({ ...currentUser, gender: e.target.value });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    const setFunction = sender ? setRemitSender : setRemitReceiver;
    dispatch(setFunction({ personalInfo: currentUser }));
    changeStep('newUserSteps', 1);
  };

  return (
    <div className='send-step'>
      <ArrowBack handle={() => changeStep('newUserSteps', -1)} />

      <div className='payment-title'>
        <h6 className='el-title-h6 med'>
          Enter <span className='orange'>{sender ? "Sender's" : "Receiver's"} </span> details
        </h6>
      </div>

      <div className='send-step-content'>
        <form onSubmit={submitForm}>
          <TextField
            className='el-input'
            label='Email (optional)'
            type='email'
            name='email'
            value={email}
            variant='outlined'
            onChange={setTextFields}
            inputProps={{
              pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$',
            }}
          />

          <DOBPicker dob={dateOfBirth || null} setDoB={setDoB} />

          <FormControl className='el-input-select'>
            <InputLabel id='select-label'>Gender</InputLabel>
            <Select
              labelId='select-label'
              id='select'
              value={gender}
              label='Gender'
              onChange={setGender}
              IconComponent={iconSelect}
            >
              <MenuItem className='capitalize' value={'male'}>
                Male
              </MenuItem>
              <MenuItem className='capitalize' value={'female'}>
                Female
              </MenuItem>
              <MenuItem className='capitalize' value={'other'}>
                Other
              </MenuItem>
            </Select>
          </FormControl>

          <TextField
            className='el-input'
            label='Occupation (optional)'
            type='text'
            name='occupation'
            value={occupation}
            variant='outlined'
            onChange={setTextFields}
            inputProps={{ maxLength: 100 }}
          />

          <div className='send-btns'>
            <div className='send-btns-continue'>
              <Button
                disabled={!isValidData()}
                className='el-button orange'
                variant='contained'
                type='submit'
              >
                Continue
              </Button>
            </div>

            <div className='send-btns-cancel'>
              <span className='el-link-m el-button-link' variant='contained' onClick={closeFlow}>
                Cancel
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

NewUserPersonal.propTypes = {
  changeStep: PropTypes.func.isRequired,
};

export default NewUserPersonal;
