import { useEffect } from 'react';

const useHideBtn = (currentStep, successStep) => {
  useEffect(() => {
    const closeBtn = document.querySelector('.close-flow');

    if (closeBtn && currentStep === successStep) {
      closeBtn.classList.add('hide');
    } else {
      closeBtn.classList.remove('hide');
    }
  }, [currentStep]);
};

export default useHideBtn;
