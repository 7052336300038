import React, { useContext, useRef, useState } from 'react';
import { Button } from '@mui/material';
import ArrowBack from '../../../../components/ArrowBack';
import { useSelector } from 'react-redux';
import { selectAccount } from '../../../../redux/features/user/userSlice';
import { Link } from 'react-router-dom';
import copyToclipboard from '../../../../utils/copyToclipboard';
import UserContext from '../../../../UserContext/UserContext';
import Faq from '../../../../components/Faq';
// import QRCode from 'qrcode.react';

const DepositPesabase = () => {
  const userAddress = useRef(null);
  const { loading, setLoading } = useContext(UserContext);
  const userData = useSelector(selectAccount);

  // const generateQRCode = (address) => <QRCode value={`${address}`} />;

  const accordionItems = [
    {
      title: 'How to send crypto via most popular crypto platforms',
      content: 'Content for Accordion 1',
    },
    {
      title: 'How to buy PESA',
      content: 'Content for Accordion 2',
    },
    {
      title: 'How to use PESA',
      content: 'Content for Accordion 3',
    },
    {
      title: 'How my Pesabase wallet address works',
      content: 'Content for Accordion 3',
    },
  ];

  return (
    <>
      {userData && !loading && (
        <div className='deposit-wrap'>
          <ArrowBack />

          <div className='deposit-address-copy'>
            <div className='title el-title-h6 med'>
              Copy your account address to deposit crypto:
            </div>
            <div className='deposit-address-copy-block'>
              {userData?.address && (
                <div className='deposit-address-copy-ref el-text-m' ref={userAddress}>
                  {userData.address}
                </div>
              )}

              <Button
                className='deposit-address-copy-btn el-button orange'
                variant='contained'
                onClick={() => copyToclipboard(userData.address)}
              >
                Copy
              </Button>
            </div>
          </div>

          {/* <div className='deposit-scan'>
            <div className='title el-title-h6 med'>Or scan with your phone:</div>
            <div className='deposit-scan-wrap'>
              <div className='deposit-scan-code'>{generateQRCode(userData.address)}</div>
              <div className='deposit-scan-desc'>
                <ul className='deposit-scan-list'>
                  <li className='deposit-scan-li'>
                    Open <b>Pesabase app</b> on your phone.
                  </li>
                  <li className='deposit-scan-li'>
                    Tap <b>Menu</b> and select <b>QR code scanner</b>.
                  </li>
                  <li className='deposit-scan-li'>
                    Scan the QR code to copy your account address.
                  </li>
                  <li className='deposit-scan-li'>
                    Send crypto to this address using your favourite crypto platform.
                  </li>
                </ul>
              </div>
            </div>
          </div> */}

          <div className='deposit-note'>
            <div className='deposit-note-wrap'>
              <div className='title el-title-h6 med'>Please note!</div>
              <ul className='deposit-note-list'>
                <li className='deposit-scan-li'>Send only PESA, USDT, BUSD to this address.</li>
                <li className='deposit-scan-li'>
                  This address only supports deposits on Binance Smart Chain and Ethereum Mainnet.
                </li>
              </ul>
            </div>
          </div>

          <div className='deposit-pesa'>
            <div className='deposit-pesa-wrap'>
              <div className='title  el-title-h6 med'>
                If you have USD in your account, you can buy PESA right here
              </div>
              <ul className='deposit-pesa-list'>
                <li className='deposit-scan-li'>Easiest way</li>
                <li className='deposit-scan-li'>No fees</li>
                <li className='deposit-scan-li'>Transaction takes only 2 seconds</li>
                <li className='deposit-scan-li'>No extra apps needed</li>
              </ul>
              <div className='deposit-pesa-btn'>
                <Link
                  to={'/user/deposit/buy-pesa'}
                  className='el-button orange'
                  variant='contained'
                >
                  Buy PESA now
                </Link>
              </div>
            </div>
          </div>

          <div className='deposit-accordion'>
            <Faq items={accordionItems} />
          </div>
        </div>
      )}
    </>
  );
};

export default DepositPesabase;
