import React from 'react';
import iconPassport from '../../../assets/icons/icon-passport@2x.png';
import iconID from '../../../assets/icons/icon-id@2x.png';
import iconLicence from '../../../assets/icons/icon-licence@2x.png';
import VerifyOption from '../../VerifyOption';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

const KycVerify = ({ changeDocsType }) => {
  const docs = [
    {
      image: iconPassport,
      title: 'Passport',
      subtitle: 'Face photo page',
    },
    {
      image: iconID,
      title: 'National ID',
      subtitle: 'Front and back',
    },
    {
      image: iconLicence,
      title: 'Driver licence',
      subtitle: 'Front and back',
    },
  ];

  return (
    <>
      <div className='form'>
        <div className='auth-title-block'>
          <h6 className='el-title-h6 med'>Verify your identity</h6>
          <span className='el-text-s auth-content--subtitle'>
            Select the type of document you would like to upload
          </span>
        </div>
        <div className='c-option-verify'>
          {docs.map(({ image, title, subtitle }, index) => {
            return (
              <div key={uuidv4()} onClick={() => changeDocsType(index)}>
                <VerifyOption image={image} title={title} subtitle={subtitle} />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

KycVerify.propTypes = {
  changeDocsType: PropTypes.func.isRequired,
};

export default KycVerify;
