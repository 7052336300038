import React, { Fragment } from 'react';
import useBodyClass from '../../hooks/useBodyClass';
import { Container } from '@mui/material';
import Accordion from '../../components/Accordion/Accordion';
import ResponsiveImage from '../../components/ResponsiveImage';
import usePictures from '../../hooks/usePictures';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import useSWR from 'swr';
import { getVacancy } from '../../client';

const Careers = () => {
  useBodyClass('p-careers');

  const imgs = usePictures(
    require.context('../../assets/img/p-careers', false, /\.(png|jpe?g|svg|webp)$/)
  );

  // getting vacancy
  const { data } = useSWR('vacancys', () => getVacancy());

  return (
    <main>
      <Header />
      <section className='s-hero'>
        <Container disableGutters>
          <div className='s-hero-top'>
            <div className='s-hero-top-desc'>
              <span className='bg bg-parent'>
                <span className='bg--layout'></span>

                <ResponsiveImage
                  desktop={imgs['s-hero@desktop.jpg']}
                  tablet={imgs['s-hero@tablet.jpg']}
                  mobile={imgs['s-hero@mobile.jpg']}
                  type={'jpg'}
                  alt='section hero'
                />
              </span>

              <h1>Careers</h1>

              {data?.vacancys?.length > 0 ? (
                <Fragment>
                  <h4>VACANCIES AVAILABLE !</h4>
                  <span className='subtitle--count'>Read about the job posting</span>
                </Fragment>
              ) : (
                <Fragment>
                  <h4>No VACANCIES AVAILABLE </h4>
                </Fragment>
              )}
            </div>

            <div className='s-hero-top-accordion'>
              <Accordion vacancies={data} />

              <div className='s-hero-top-accordion--subtitle'>
                If you didn't find the appropriate vacancy but feel like you could fit our team,
                feel free to contact us on <a href='mailto:team@pesabase.com'>team@pesabase.com</a>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <Footer />
    </main>
  );
};

export default Careers;
