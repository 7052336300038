import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, Select } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectRemitSender,
  setRemitReceiver,
  setRemitSender,
} from '../../../../../redux/features/transaction/remittanceSlice';
import ArrowBack from '../../../../../components/ArrowBack';
import { ReactComponent as iconSelect } from '../../../../../assets/icons/icon-select.svg';
import { allCountries } from 'country-region-data';
import { LoadingButton } from '@mui/lab';
import closeFlow from '../../../../../utils/closeFlow';
import { toast } from 'react-toastify';

const NewUserLocation = ({ changeStep, remitUser, sender, submitUser }) => {
  const dispatch = useDispatch();
  const neededCountries = ['AU', 'UG', 'SS', 'KE'];
  const userDocs = useSelector(selectRemitSender)?.documents;
  const [localLoading, setLocalLoading] = useState(false);
  const transformName = (string) => string?.trim()?.toLowerCase();
  const defaultCountries = allCountries.filter((country) => neededCountries.includes(country?.[1]));
  const userCountry = defaultCountries.find((country) => {
    const countryName = transformName(country[0]);
    const countryABR = transformName(country[1]);
    const transformedRemitCountry = transformName(remitUser?.location?.country);

    return countryABR === transformedRemitCountry || countryName === transformedRemitCountry;
  });
  const userRegion = userCountry?.[2].find((region) => {
    const suburbName = transformName(region[0]);
    const suburbABR = transformName(region[1]);

    return (
      suburbName === transformName(remitUser?.location?.suburb) ||
      suburbABR === transformName(remitUser?.location?.suburb)
    );
  });

  const [userLocation, setUserLocation] = useState({
    country: userCountry?.[1] || '',
    suburb: userRegion?.[1] || '',
  });
  const { country, suburb } = userLocation;
  const [allRegions, setAllRegions] = useState(userCountry?.[2] || []);

  const handleCountryChange = (event) => {
    console.log(event.target.value);
    // Update user's location with the selected country
    setUserLocation({
      ...userLocation,
      country: event.target.value,
      suburb: '',
    });

    // Find the selected country's data
    const selectedCountryData = defaultCountries.find(
      (country) => country[1] === event.target.value
    );

    // Set all regions for the selected country
    setAllRegions(selectedCountryData[2]);
  };

  const handleRegionChange = (event) =>
    setUserLocation({ ...userLocation, suburb: event.target.value });

  const submitForm = async (e) => {
    e.preventDefault();

    try {
      setLocalLoading(true);
      const locationObj = {
        country: allCountries.find((el) => el[1] === country)?.[0] || '',
        suburb: allRegions.find((region) => region[1] === suburb)?.[0] || '',
      };
      const setFunction = sender ? setRemitSender : setRemitReceiver;
      dispatch(setFunction({ location: locationObj }));

      if (!sender) {
        await submitUser();
      } else {
        changeStep('newUserSteps', userDocs?.type ? 2 : 1);
      }
    } catch (error) {
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
    } finally {
      setLocalLoading(false);
    }
  };

  return (
    <div className='send-step'>
      <ArrowBack handle={() => changeStep('newUserSteps', -1)} />

      <div className='payment-title'>
        <h6 className='el-title-h6 med'>
          Enter <span className='orange'>{sender ? "Sender's" : "Receiver's"} </span> details
        </h6>
      </div>

      <div className='send-step-content'>
        <form onSubmit={(e) => submitForm(e)}>
          <div className='location-select'>
            <FormControl className='el-input-select'>
              <InputLabel id='select-label-2'>Select country</InputLabel>

              <Select
                labelId='select-label-2'
                id='select-region'
                label='Select region'
                value={country}
                onChange={handleCountryChange}
                IconComponent={iconSelect}
              >
                {defaultCountries.map((item) => (
                  <MenuItem className='capitalize' key={item[0]} value={item[1]}>
                    {item[0]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className='el-input-select'>
              <InputLabel id='select-label-2'>Select region</InputLabel>

              <Select
                disabled={!country}
                labelId='select-label-2'
                id='select-region'
                label='Select region'
                value={suburb}
                onChange={handleRegionChange}
                IconComponent={iconSelect}
              >
                {allRegions.map((item) => (
                  <MenuItem key={item[0]} value={item[1]}>
                    {item[0]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className='send-btns'>
            <div className='send-btns-continue'>
              <LoadingButton
                type='submit'
                disabled={!suburb}
                loading={localLoading}
                className='el-button orange'
              >
                <span>{localLoading ? 'Processing' : 'Continue'}</span>
              </LoadingButton>
            </div>

            <div className='send-btns-cancel'>
              <span className='el-link-m el-button-link' variant='contained' onClick={closeFlow}>
                Cancel
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

NewUserLocation.propTypes = {
  changeStep: PropTypes.func.isRequired,
};

export default NewUserLocation;
