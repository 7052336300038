import React, { useState, useEffect } from 'react';
import { Link, useFetcher, useLocation, useNavigate } from 'react-router-dom';
import { SendTCReceiver, SendTCAmount, SendTCReview } from '../../../components/Send/SendTC';
import Success from '../../../components/Success';
import Steps from '../../../components/Steps';
import {
  selectFirebaseJWT,
  selectJwtToken,
  selectPhoneNumber,
  selectTransferData,
  setBalance,
  selectWhatsAppCode,
} from '../../../redux/features/user/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getUserBalance, sendCurrency } from '../../../api';
import successVideo from '../../../assets/anims/videos/fullscreen/receipt-success-loop.mp4';
import { toast } from 'react-toastify';
import ArrowBack from '../../../components/ArrowBack';
import TFA from '../../../components/TFA';
import useHideBtn from '../../../utils/useHideBtn';
import whatsAppLogo from '../../../assets/img/whatsAppLogo.png';
import { setWhatsAppCodeVerificationStatus } from '../../../redux/features/sockets/socketslice';
import useUserRole from '../../../utils/useUserRole';

const UserSendToUser = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const userToken = useSelector(selectFirebaseJWT);
  const navigate = useNavigate();
  const userDeviceToken = useSelector(selectJwtToken);
  const userTransferData = useSelector(selectTransferData);
  const userPhoneNumber = useSelector(selectPhoneNumber);
  const [localLocading, setLocalLoading] = useState(false);
  const [error, setError] = useState(false);
  const linkTransferData = location?.state?.transferData;
  const code = useSelector(selectWhatsAppCode);
  const [isCodeVerified, setIsCodeVerified] = useState(false);
  const whatsappVerificationStatus = useSelector(
    (state) => state.socket.whatsAppCodeVerificationStatus
  );
  const userRole = useUserRole();

  console.log('location state:', location?.state);
  console.log('whatsapp verification is from user send first:', whatsappVerificationStatus);
  console.log('user device token:', userDeviceToken);

  // set some fields if it exist or we navigated from other pages with some states
  const [transferData, setUserTransferData] = useState(
    userTransferData || linkTransferData || { paymentSystem: location?.state?.paymentSystem }
  );

  const [steps, setSteps] = useState({
    current:
      transferData?.userReview?.sendValue || transferData?.userReceiver
        ? location.state?.jumpToNextStep
          ? 2
          : 1
        : 0,
    total: [{ name: 'Receiver' }, { name: 'Amount' }, { name: 'Review' }, { name: 'Send' }],
  });

  const { current } = steps;

  useHideBtn(current, 4);

  const changeStep = (step) => {
    console.log(current, step);
    if (current === 0 && step === -1) {
      navigate('/user/send/system');
    }
    setSteps((prevSteps) => ({ ...prevSteps, current: prevSteps.current + step }));
  };

  const setData = (data) => {
    setUserTransferData({ ...transferData, ...data });
    changeStep(1);
  };

  const updateCurrentBalance = async () => {
    if (userTransferData?.userReview) {
      const balanceResponse = await getUserBalance(userToken);
      const dataArray = Object.entries(balanceResponse?.data).map(([currency, info]) => ({
        currency,
        ...info,
      }));
      balanceResponse?.data && dispatch(setBalance(dataArray));
    }
  };
  updateCurrentBalance();

  const submitSend = async (otp) => {
    try {
      setLocalLoading(true);
      const { userReceiver, userReview } = transferData;

      const data = {
        amount: userReview?.sendValue,
        receiver: userReceiver?.uid,
        feeCurrency: userReview?.transactionFeeCurrency?.toUpperCase(),
        comment: userReview?.comment,
        code: otp,
      };

      dispatch(setWhatsAppCodeVerificationStatus(false));

      const response = await sendCurrency(data, userToken, userDeviceToken, userReview?.currency);

      if (response?.status !== 200) {
        throw new Error(response.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      setError(true);
    } finally {
      setLocalLoading(false);
      changeStep(1);
    }
  };

  useEffect(() => {
    if (whatsappVerificationStatus) {
      setIsCodeVerified(true);
    }
  }, [whatsappVerificationStatus]);

  useEffect(() => {
    console.log('useEffect hook executed');
    if (isCodeVerified) {
      console.log(
        'whatsapp verification is from inside useEffect hook from send to user:',
        whatsappVerificationStatus
      );
      const handleSend = async () => {
        try {
          await submitSend(code);
        } catch (err) {
          console.error('Error in sending currency:', err);
        }
      };
      handleSend();
    }
  }, [isCodeVerified]);

  return (
    <div className='send'>
      <Steps steps={steps} />

      {current === 0 && (
        <SendTCReceiver changeStep={changeStep} setData={setData} transferData={transferData} />
      )}

      {current === 1 && (
        <SendTCAmount changeStep={changeStep} transferData={transferData} setData={setData} />
      )}

      {current === 2 && (
        <SendTCReview changeStep={changeStep} transferData={transferData} userToken={userToken} />
      )}

      {current === 3 && (
        <div className='send-step'>
          <ArrowBack handle={() => changeStep(-1)} />
          <form>
            <>
              <h4>
                Code: <span className='orange'>{code}</span>
              </h4>
              <span className='c-tfa-box-text el-text-m auth-content--title'>
                Send this code to our whatsapp number for verification
              </span>
            </>

            <span className='c-tfa-box-notes el-text-s'>
              <a
                aria-label='Chat on WhatsApp'
                href='https://wa.me/+18189183281'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img alt='Chat on WhatsApp' src={whatsAppLogo} style={{ width: '50%' }} />
              </a>
            </span>

            <div id='recaptcha-container' className='resend'></div>
          </form>

          {/* <TFA
            loading={localLocading}
            number={userPhoneNumber}
            changeStep={changeStep}
            handle={submitSend}
            closeFlowFn={true}
            setError={setError}
            error={error}
          /> */}
        </div>
      )}

      {current === 4 && (
        <Success
          video={successVideo}
          title={'Your send request has been placed'}
          subtitle={
            'You can check the details in <a class="el-button-link" href="/user/transactions"><span>Activity</span></a>'
          }
          buttons={
            <>
              <Link
                className='el-button orange'
                state={{
                  transferData: transferData,
                }}
                onClick={() => changeStep(-3)}
              >
                Send more
              </Link>
            </>
          }
        />
      )}
    </div>
  );
};

export default UserSendToUser;
