import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { Link } from 'react-router-dom';
import useBodyClass from '../../hooks/useBodyClass';
import usePictures from '../../hooks/usePictures';
import ResponsiveImage from '../../components/ResponsiveImage';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Container } from '@mui/material';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import useIsTablet from '../../hooks/useIsTablet';

gsap.registerPlugin(ScrollTrigger);

const Rewards = () => {
  useBodyClass('p-rewards');

  const [isTablet, setIsTablet] = useState(useIsTablet());

  const imgsGlobal = usePictures(
    require.context('../../assets/img/p-home', false, /\.(png|jpe?g|svg|webp)$/)
  );
  const imgs = usePictures(
    require.context('../../assets/img/p-rewards', false, /\.(png|jpe?g|svg|webp)$/)
  );
  const phoneImgRef = useRef(null);
  const phoneAnchorRef = useRef(null);
  const screenWidth = window.innerWidth;
  let startValue, endValue, fixPhoneImg;

  if (screenWidth >= 1920) {
    (startValue = '+=20% 40%'), (endValue = () => `+=${phoneImgRef.current.offsetHeight / 5}`);
  } else if (screenWidth >= 1440) {
    (startValue = '+=20% 40%'), (endValue = () => `+=${phoneImgRef.current.offsetHeight / 2.75}`);
  } else if (screenWidth >= 1024) {
    (startValue = '+=0% 30%'), (endValue = () => `+=${phoneImgRef.current.offsetHeight / 2.5}`);
  }

  useEffect(() => {
    if (!isTablet) {
      fixPhoneImg = ScrollTrigger.create({
        trigger: phoneImgRef.current,
        scrub: true,
        start: startValue,
        end: endValue,
        markers: false,
        pin: true,
        toggleActions: 'play reverse none reverse',
      });
    }

    return () => {
      fixPhoneImg && fixPhoneImg.kill();
    };
  }, [isTablet]);

  return (
    <>
      <Header />
      <Container className='p-referral-container' disableGutters>
        <section className='s-hero'>
          <span className='s-hero-bg bg-parent'>
            <ResponsiveImage
              desktop={imgs['s-hero@desktop.jpg']}
              tablet={imgs['s-hero@tablet.jpg']}
              mobile={imgs['s-hero@mobile.jpg']}
              type={'jpg'}
              alt='section hero'
            />
          </span>
          <div className='s-hero-content'>
            <div className='s-hero-title'>
              <h1>Explore our Rewards Program!</h1>
              <h6 className='s-hero-title-desc'>
                Welcome to the PESA community, where your connections can lead to exciting rewards!{' '}
              </h6>
            </div>
            <div className='s-hero-bottom'>
              <div className='links'>
                <Link to={'/auth/signup'} className='links-btn el-button orange'>
                  sign up
                </Link>
                <a
                  href='https://play.google.com/store/apps/details?id=com.pesabase.app&hl=en'
                  target='_blank'
                  className='links-btn links-btn-app'
                  rel='noopener noreferrer'
                >
                  <img src={imgsGlobal['google.png']} alt='' />
                </a>

                <a
                  href='https://apps.apple.com/us/app/pesabase/id1557420579'
                  target='_blank'
                  className='links-btn links-btn-app '
                  rel='noopener noreferrer'
                >
                  <img src={imgsGlobal['apple.png']} alt='' />
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className='s-how'>
          <div className='s-how-left'>
            <h2>How It Works</h2>
            <div className='s-how-left-list' ref={phoneAnchorRef}>
              <ul>
                <li>
                  <span className='li-count'>1</span>
                  <div className='li-content'>
                    <h6>Send Money, Earn Rewards</h6>
                    <p className='el-text-m'>
                      Simply use PESA to send money to your friends and family, and watch the
                      rewards roll in! Every transaction you pay in PESA puts you one step closer to
                      exciting benefits.
                    </p>
                  </div>
                </li>
                <li>
                  <span className='li-count'>2</span>
                  <div className='li-content'>
                    <h6>Your Rewards, Your Way</h6>
                    <p className='el-text-m'>
                      Your earned rewards will be seamlessly added to your PESA balance after each
                      transaction is processed. It's our way of saying thank you for choosing PESA
                      for your money transfers.
                    </p>
                  </div>
                </li>
                <li>
                  <span className='li-count'>3</span>
                  <div className='li-content'>
                    <h6>Keep Track of Your Earnings</h6>
                    <p className='el-text-m'>
                      Stay informed about your rewards journey by checking your Activity history.
                      See how your transactions translate into valuable rewards, all in one
                      convenient place.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className='s-how-right'>
            <div className='s-how-right-wrap' ref={phoneImgRef}>
              <div className='phone-img'>
                <img src={imgs['phone-rewards@2x.png']} alt='' />
              </div>
            </div>
          </div>
        </section>
        <section className='s-pay'>
          <div className='s-pay-title'>
            <h2>Rewards we pay</h2>
          </div>
          <div className='s-pay-content'>
            <div className='s-pay-left'>
              <div className='s-pay-left-wrap'>
                <div className='s-pay-left-img'>
                  <img src={imgs['pay@desktop.jpg']} alt='' />
                </div>
              </div>
            </div>
            <div className='s-pay-right'>
              <div className='s-pay-list'>
                <div className='item'>
                  <div className='item-wrap'>
                    <div className='item-img'>
                      <img src={imgs['rewards-1.png']} alt='' />
                    </div>
                    <div className='item-content'>
                      <h6>Welcome reward</h6>
                      <p className='el-text-m'>
                        After the first transaction, you receive a welcome bonus of 10 PESA,
                        regardless of the amount.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='item'>
                  <div className='item-wrap'>
                    <div className='item-img'>
                      <img src={imgs['rewards-2.png']} alt='' />
                    </div>
                    <div className='item-content'>
                      <h6>Transaction reward</h6>
                      <p className='el-text-m'>
                        We pay you rewards each time you use our platform sending money to your
                        friends and family.{' '}
                      </p>
                    </div>
                  </div>
                </div>
                <div className='item'>
                  <div className='item-wrap dark'>
                    <div className='item-img'>
                      <img src={imgs['rewards-3.png']} alt='' />
                    </div>
                    <div className='item-content'>
                      <h6>Referral program</h6>
                      <p className='el-text-m'>
                        We pay you 50 PESA each time users register by your link and make 100 USD of
                        transactions. Your referral bonus will be added to your PESA balance after
                        the transaction is processed.
                      </p>
                      <div className='item-content-btn'>
                        <Link to={'/referral'} className='el-button orange'>
                          learn more
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='s-why'>
          <div className='s-why-wrap'>
            <h2>Why choose PESA?</h2>
            <div className='s-why-list'>
              <div className='item'>
                <div className='item-wrap'>
                  <div className='item-img'>
                    <img src={imgs['rewards-4.png']} alt='' />
                  </div>
                  <div className='item-bottom'>
                    <h6>Earn with Every Transaction Paid in PESA</h6>
                    <p className='el-text-m'>
                      Your loyalty deserves to be rewarded, and we make sure it happens with every
                      transaction you pay in PESA.
                    </p>
                  </div>
                </div>
              </div>
              <div className='item'>
                <div className='item-wrap'>
                  <div className='item-img'>
                    <img src={imgs['rewards-5.png']} alt='' />
                  </div>
                  <div className='item-bottom'>
                    <h6>Simple and Transparent</h6>
                    <p className='el-text-m'>
                      No hidden fees or complicated processes – just straightforward rewards for
                      your trust in PESA.{' '}
                    </p>
                  </div>
                </div>
              </div>
              <div className='item'>
                <div className='item-wrap'>
                  <div className='item-img'>
                    <img src={imgs['rewards-6.png']} alt='' />
                  </div>
                  <div className='item-bottom'>
                    <h6>Share the Wealth</h6>
                    <p className='el-text-m'>
                      Invite your friends to join, and you'll both enjoy the benefits when they make
                      their first 100 USD in transactions.{' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='s-ready'>
          <div className='s-ready-wrap'>
            <div className='s-ready-content'>
              <div className='s-ready-title'>
                <h2>Ready to turn your transactions into rewards?</h2>
                <h6 className='s-ready-title-desc'>
                  Embrace the PESA Rewards Program today and experience a new level of joy with
                  every money transfer!
                </h6>
              </div>
              <div className='s-ready-bottom'>
                <div className='links'>
                  <Link to={'/auth/signup'} className='links-btn el-button black'>
                    sign up
                    <br /> on the web
                  </Link>
                  <a
                    href='https://play.google.com/store/apps/details?id=com.pesabase.app&hl=en'
                    target='_blank'
                    className='links-btn links-btn-app'
                    rel='noopener noreferrer'
                  >
                    <img src={imgsGlobal['google.png']} alt='' />
                  </a>

                  <a
                    href='https://apps.apple.com/us/app/pesabase/id1557420579'
                    target='_blank'
                    className='links-btn links-btn-app '
                    rel='noopener noreferrer'
                  >
                    <img src={imgsGlobal['apple.png']} alt='' />
                  </a>
                </div>
                <div className='s-ready-img'>
                  <div className='s-ready-img-block'>
                    <img src={imgs['rewards-ready.png']} alt='' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Container>
      <Footer />
    </>
  );
};

export default Rewards;
