import React from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const PaymentSystem = ({ data }) => {
  return (
    <div className='c-pay-system'>
      <h6 className='c-pay-system--title el-title-h6 med'>Choose payment system</h6>

      <div className='c-pay-system-list'>
        {data.map((item) => (
          <Link
            key={uuidv4()}
            to={`${item.link}`}
            state={{ paymentSystem: item.system }}
            variant='contained'
            className='c-pay-system-list-btn'
          >
            <div className='img'>
              <img src={item.icon} alt={item.title} />
            </div>

            <div className='el-text-m title'>{item.title}</div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default PaymentSystem;
