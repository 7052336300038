import React from 'react';
import PropTypes from 'prop-types';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
// import { ReactComponent as IconStep } from '../../assets/icons/icon-step.svg';
import { ReactComponent as IconStepSVG } from '../../assets/icons/icon-step.svg';
import { v4 as uuidv4 } from 'uuid';

const IconStep = () => {
  return <IconStepSVG />;
};

const Steps = ({ steps, className }) => {
  const { current, total } = steps;

  return (
    <Stepper activeStep={current} alternativeLabel className={className}>
      {total.map(({ name, completed }, index) => {
        return (
          <Step
            active={current === index}
            key={uuidv4()}
            className={`${current === index ? 'current' : ''}`}
            // completed={completed}
            // completed={completed ? 'true' : 'false'}
            // active={current === index ? 'true' : '' }
          >
            <StepLabel
              completed={completed ? 'true' : 'false'}
              error={false}
              StepIconComponent={IconStep}
              // active={current === index ? 'true' : '' }
              // active={current === index}
            >
              {name}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

Steps.propTypes = {
  steps: PropTypes.object.isRequired,
};

export default Steps;
