import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ArrowBack from '../../../../../components/ArrowBack';
import { useDispatch, useSelector } from 'react-redux';
import { List, ListItem } from '@mui/material';
import { selectFirebaseJWT, selectJwtToken } from '../../../../../redux/features/user/userSlice';
import SingleContact from '../../../../../components/SingleContact';
import { toast } from 'react-toastify';
import { assignAgent, getAgentsList, getAgentsSearch } from '../../../../../api';
import { v4 as uuidv4 } from 'uuid';
import SearchField from '../../../../../components/SearchField';
import { selectRemitDetails } from '../../../../../redux/features/transaction/remittanceSlice';
import getDataLength from '../../../../../utils/getDataLength';
import NoResult from '../../../../../components/NoResult';
import UserContext from '../../../../../UserContext/UserContext';

const SendStepAgents = ({ changeStep, setAgent }) => {
  const userToken = useSelector(selectFirebaseJWT);
  const userDeviceJwt = useSelector(selectJwtToken);
  const remmitDetails = useSelector(selectRemitDetails);
  const tabs = ['all', 'available'];
  const [error, setError] = useState(false);
  const [activeTab, setActiveTab] = useState('all');
  const [localLoading, setLocalLoading] = useState(false);
  const { setLoading } = useContext(UserContext);
  const [searchValue, setSearchValue] = useState('');
  const [originalData, setOriginalData] = useState([]);
  const [agentsData, setAgentsData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const limit = 5;

  const skeleton = (
    <div className='c-contact-box-el'>
      <div className='c-contact-box-el-symbol'></div>
      {[...new Array(5)].map(() => (
        <div className='contact-wrap' key={uuidv4()}>
          <SingleContact loading={true} />
        </div>
      ))}
    </div>
  );

  const setData = async ({ userReceiver }) => {
    try {
      setLoading(true);
      const response = await assignAgent(
        userToken,
        userDeviceJwt,
        remmitDetails.remitID,
        userReceiver.uid
      );

      if (response.status === 200) {
        setAgent(userReceiver);
        changeStep('sendSteps', 1);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      setError(true);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const checkHasMore = (data) => setHasMore(getDataLength(data) === limit);

  const handleSearch = async (response, searchValue) => {
    setCurrentPage(1);
    setSearchValue(searchValue);
    setAgentsData(response?.data?.contact || {});
    checkHasMore(response?.data || {});
  };

  const handleTabClick = async (tab) => {
    try {
      setLocalLoading(true);
      setActiveTab(tab);
      setCurrentPage(1);
      setHasMore(false);
      let response;

      // If the 'available' tab is clicked and there is a search value, fetch agents based on the search
      if (searchValue) {
        response = await getAgentsSearch(
          userToken,
          searchValue,
          tab === 'all' ? false : true,
          1,
          limit
        );
      } else if (tab === 'available') {
        // If the 'available' tab is clicked or there is no search value, fetch available agents
        response = await getAgentsList(userToken, true);
      } else {
        // If the 'available' tab is not clicked or there is no search value, fetch all agents
        response = await getAgentsList(userToken, false);
      }

      // Check if the API call was successful
      if (response.status === 200) {
        // Extract the agents data from the API response and update the component's state
        const agents = response?.data?.contact || response?.data;
        setAgentsData(agents || {});

        // Check if there are more data to fetch for pagination
        checkHasMore(agents || {});
      } else {
        // If the API call was not successful, throw an error
        throw new Error(response.message);
      }
    } catch (error) {
      console.error('Error fetching agents:', error);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
    } finally {
      setLocalLoading(false);
    }
  };

  const handleClearSearch = async () => {
    setSearchValue('');
    setActiveTab('all');
    setCurrentPage(1);
    setAgentsData({});
    setAgentsData(originalData);
    checkHasMore(originalData);
    setLocalLoading(false);
  };

  const renderContactGroup = (data) => {
    if (data) {
      return (
        <div className='c-contact-box-el' key={uuidv4()}>
          <div className='c-contact-box-el-symbol'>{data[0]}</div>
          {data[1].map((contact) => (
            <div className='contact-wrap' key={uuidv4()}>
              <SingleContact
                contact={contact}
                setData={setData}
                flow={true}
                agentSearch={true}
              />
            </div>
          ))}
        </div>
      );
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLocalLoading(true);
        const response = await getAgentsList(userToken);

        if (response.status === 200) {
          const agents = response?.data;
          setOriginalData(agents);
          setAgentsData(agents);
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        console.log(error);
        toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      } finally {
        setLocalLoading(false);
      }
    })();
  }, [userToken]);

  return (
    <>
      <ArrowBack handle={() => changeStep('sendSteps', -1)} />

      <div className='payment-title'>
        <h6 className='el-title-h6 med'>
          Choose an <span className='orange'>Agent</span> to send
        </h6>
      </div>

      <div className='send-step-content'>
        <SearchField
          className='agents-search-field'
          contactType='contact'
          token={userToken}
          onClear={handleClearSearch}
          onSearch={(response, searchValue) => handleSearch(response, searchValue)}
          placeholderText='Enter name, address, or phone number'
          toggleLoading={setLocalLoading}
          agents={true}
        />

        <div className='contacts-filters agents-flow'>
          <List>
            {tabs.map((tab) => (
              <ListItem
                key={uuidv4()}
                className={`el-text-m ${activeTab === tab ? 'active' : ''}`}
                onClick={() => {
                  handleTabClick(tab);
                }}
              >
                {tab}
              </ListItem>
            ))}
          </List>
        </div>

        <div className='c-contact-box'>
          {localLoading && skeleton}

          {!localLoading && agentsData && Object.entries(agentsData).map(renderContactGroup)}

          {Object.entries(agentsData).length === 0 && searchValue !== '' && !localLoading && (
            <div className='c-contact-box-el'>
              <NoResult>{'No agents found. Try entering the email or mobile number.'}</NoResult>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

SendStepAgents.propTypes = {
  changeStep: PropTypes.func.isRequired,
};

export default SendStepAgents;
