import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  selectAccount,
  selectFirebaseJWT,
  setAccount,
  setJwtToken,
} from '../../redux/features/user/userSlice';
import { getSettings, logOutAllDevices, updateAgentStatus } from '../../api';
import UserContext from '../../UserContext/UserContext';
import deviceIcon from '../../assets/icons/icon_pc.svg';
import { v4 as uuidv4 } from 'uuid';
import Popup from '../../components/Popup';
import logOutVideo from '../../assets/anims/videos/popups/logout_ff.mp4';
import ReactPlayer from 'react-player';
import { Button } from '@mui/material';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import useUserRole from '../../utils/useUserRole';
import useSWR from 'swr';

const Settings = () => {
  const isAgent = useUserRole('agent');
  const dispatch = useDispatch();
  const userToken = useSelector(selectFirebaseJWT);
  const userProfile = useSelector(selectAccount);
  const [clicked, setClicked] = useState(false);
  const { setLoading } = useContext(UserContext);

  const [settings, setSettings] = useState({
    logOutPopUp: false,
    devices: null,
    checked: userProfile?.availability,
  });

  const { logOutPopUp, checked, devices } = settings;

  const fetcher = (userToken) => getSettings(userToken).then((res) => res);
  const { data, error, isLoading } = useSWR(userToken, fetcher);

  if (error) {
    toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
    console.log(error);
  }

  /**
   * Function to log out all trusted devices.
   *
   * @returns {void}
   */

  const logOutDevices = async () => {
    try {
      // Send a request to the server to log out all devices
      const response = await logOutAllDevices(userToken);

      // Check if the request was successful
      if (response?.status === 200) {
        // Dispatch action to remove JWT token from the Redux store
        dispatch(setJwtToken(null));

        // Show success message
        toast.success('All trusted devices were removed');

        // Update the state to remove the devices list
        setSettings((prevState) => ({ ...prevState, devices: null }));
      } else {
        // If the request was not successful, throw an error
        throw new Error(response.message);
      }
    } catch (error) {
      // Show error message
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);

      // Log the error to the console
      console.log(error);
    } finally {
      // Close the log out pop-up
      setSettings((prevState) => ({ ...prevState, logOutPopUp: false }));
    }
  };

  const handleSetChecked = async () => {
    try {
      setClicked(true);
      const response = await updateAgentStatus(userToken, !checked);

      if (response?.status === 200) {
        toast.success('Agent availability updated');
        dispatch(setAccount({ availability: !checked }));
        setSettings((prevState) => ({ ...prevState, checked: !checked }));
      } else {
        throw new Error(response.message);
      }
      setClicked(false);
    } catch (error) {
      console.log(error);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
    }
  };

  useEffect(() => {
    data?.data && setSettings((prevState) => ({ ...prevState, ...data.data }));
  }, [data]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  return (
    <>
      {(!error && !isLoading) && (
        <div className='settings'>
          {isAgent && (
            <div className='settings-box'>
              <h6 className='el-title-h6'>Agent:</h6>

              <FormGroup className={`${clicked ? 'processing' : ''}`}>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={clicked}
                      checked={checked}
                      onChange={(e) => handleSetChecked(e)}
                    />
                  }
                  label={
                    <>
                      <div className='el-text-m med title'>
                        Available for receiving transactions
                      </div>

                      <span className='el-text-s text'>
                        When set to "Available", other agents can find you and assign transactions
                        to you. Set your availability to "Not available" if you're not ready to do
                        withdraws.
                      </span>
                    </>
                  }
                />
              </FormGroup>
            </div>
          )}

          <div className='settings-box'>
            <h6 className='el-title-h6 med settings-box--title'>Trusted devices:</h6>

            {devices?.length > 0 && (
              <>
                {devices.map(({ os, model, browser }) => (
                  <div className='settings-box-el' key={uuidv4()}>
                    <div className='settings-box-el--icon'>
                      <img src={deviceIcon} alt='' />
                    </div>

                    {(model.vendor || model.model) && (
                      <div className='el-text-m med settings-box-el--model'>
                        {model.vendor} {model.model}
                      </div>
                    )}

                    {!model.vendor && !model.model && os.name.toLowerCase().includes('windows') && (
                      <div className='el-text-m med settings-box-el--model'>PC</div>
                    )}

                    {(os.name || os.version) && (
                      <div className='el-text-s settings-box-el--os'>
                        {os.name} {os.version}
                      </div>
                    )}

                    {(browser.name || browser.version) && (
                      <div className='el-text-s settings-box-el--app'>
                        {browser.name} {browser.version}
                      </div>
                    )}
                  </div>
                ))}

                <span
                  className='el-link-m semi settings-box-logout'
                  onClick={() => setSettings((prevState) => ({ ...prevState, logOutPopUp: true }))}
                >
                  Deauthorize All Devices
                </span>
              </>
            )}

            {!devices && (
              <div className='settings-box-el'>
                🖥️ <span className='el-text-m settings-box-el--empty'>No trusted devices yet.</span>
              </div>
            )}
          </div>

          <div className='settings-box'>
            <h6 className='el-title-h6'>Email notifications:</h6>

            <FormGroup>
              <FormControlLabel
                control={<Switch />}
                label={
                  <>
                    <div className='el-text-m title'>Transaction Updates</div>

                    <span className='el-text-s text'>
                      You'll receive notifications about the status of your transactions, such as
                      successful, pending, or failed.
                    </span>
                  </>
                }
              />
              <FormControlLabel
                control={<Switch />}
                label={
                  <>
                    <div className='el-text-m title'>Receiving Money</div>

                    <span className='el-text-s text'>
                      Whenever someone sends you money, we'll promptly notify you about the received
                      funds.
                    </span>
                  </>
                }
              />
            </FormGroup>
          </div>

          <Popup
            open={logOutPopUp}
            togglePopUp={() =>
              setSettings((prevState) => ({ ...prevState, logOutPopUp: !logOutPopUp }))
            }
          >
            <div className='modal-content'>
              <div className='modal-img'>
                <ReactPlayer
                  className='player'
                  controls={false}
                  playing={true}
                  loop={true}
                  muted={true}
                  playsinline={true}
                  url={logOutVideo}
                  width='100%'
                  height='100%'
                />
              </div>

              <div className='modal-desc'>
                <div className='modal-desc-title'>
                  Are you sure you want to deauthorize all devices?
                </div>

                <div className='el-text-m modal-desc-text'>
                  By clicking 'Deauthorize All Devices,' you will revoke access to your account from
                  all previously trusted devices. This action will require re-authentication on each
                  device to regain access.
                </div>
              </div>

              <div className='modal-btns'>
                <Button className='el-button' variant='contained' onClick={logOutDevices}>
                  Yes, I want to deauthorize
                </Button>

                <Button
                  className='el-button orange'
                  variant='contained'
                  onClick={() => setSettings((prevState) => ({ ...prevState, logOutPopUp: false }))}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Popup>
        </div>
      )}

      {!isLoading && error && <h6 className='el-title-error'>{error}</h6>}
    </>
  );
};

export default Settings;
