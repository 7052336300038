import React from 'react';
import PropTypes from 'prop-types';
import Initials from '../Initials';
import transformString from '../../utils/transformString';
import { Skeleton } from '@mui/material';
import { toast } from 'react-toastify';
import usePictures from '../../hooks/usePictures';
import { getAvatar } from '../../api';
import useSWR from 'swr';
import useUserRole from '../../utils/useUserRole';

const Avatar = ({ data }) => {
  const fetcher = (url) => getAvatar(url).then((res) => res);
  const { data: avatar, error, isLoading } = useSWR(data?.avatar ? data.avatar : null, fetcher);
  const isAgent = useUserRole('agent');

  const imgs = usePictures(
    require.context('../../assets/img/p-dashboard', false, /\.(png|jpe?g|svg|webp)$/)
  );

  if (error) {
    toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
    console.log(error);
  }

  return (
    <div className='c-avatar'>
      {!isAgent && (
        <>
          {data?.agent && (
            <img className='c-avatar-label' src={imgs['avatar-agent@2x.png']} alt='' />
          )}
        </>
      )}

      {data?.avatar ? (
        <div className='c-avatar-img'>
          {isLoading ? <Skeleton animation='wave' variant='circle' /> : <img src={avatar} alt='' />}
        </div>
      ) : (
        <div className='c-avatar-init'>
          <Initials
            className={'c-avatar-init-text'}
            fullName={transformString(data?.name) || 'Pesabase user'}
          />
        </div>
      )}
    </div>
  );
};

Avatar.propTypes = {
  data: PropTypes.object,
};

export default Avatar;
