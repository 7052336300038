import { createSlice } from '@reduxjs/toolkit';
import { tx_messages, update_current_order } from '../sockets/socketslice';

const initialState = {
  sender: {},
  receiver: {},
  remitDetails: {},
  order: null,
};

const remittanceSlice = createSlice({
  name: 'remittance',
  initialState,
  reducers: {
    setRemitReceiver: (state, action) => {
      state.receiver = {
        ...state.receiver,
        ...action.payload,
        personalInfo: {
          ...state.receiver.personalInfo,
          ...action.payload.personalInfo,
        },
      };
    },
    setRemitSender: (state, action) => {
      state.sender = {
        ...state.sender,
        ...action.payload,
        personalInfo: {
          ...state.sender.personalInfo,
          ...action.payload.personalInfo,
        },
      };
    },
    setRemitDetails: (state, action) => {
      state.remitDetails = {
        ...state.remitDetails,
        ...action.payload,
      };
    },
    setOrder: (state, action) => {
      state.order = action.payload;
    },
    setConfirmOrder: (state, action) => {
      state.order = {
        ...state.order,
        ...action.payload,
      };
    },
    updateOrder: (state, action) => {
      state.order = action.payload;
    },
    resetSenderUser:  (state) => {
      state.sender = {};
    },
    resetReceiverUser:  (state) => {
      state.receiver = {};
    },
    resetCurrentRemit:  (state) => {
      state.sender = {};
      state.receiver = {};
      state.remitDetails = {};
    },
    resetRemittance: () => initialState,
  },
});

export const updatecurrentOrder = (params) => {
  return async (dispatch) => {
    await dispatch(setOrder(params));
    await dispatch(update_current_order(params.txId));
    await dispatch(tx_messages(params.txId));
  };
};

export const {
  setOrder,
  updateOrder,
  setConfirmOrder,
  setRemitReceiver,
  setRemitSender,
  setRemitDetails,
  resetSenderUser,
  resetReceiverUser,
  resetCurrentRemit,
  resetRemittance,
} = remittanceSlice.actions;

export const selectRemitSender = (state) => state.remittance.sender;
export const selectRemitReceiver = (state) => state.remittance.receiver;
export const selectRemitDetails = (state) => state.remittance.remitDetails;
export const selectOrder = (state) => state.remittance.order;
export const selectConfirmOrder = (state) => state.remittance.order.confirm;

export default remittanceSlice.reducer;
