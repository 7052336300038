import React, { useContext, useEffect, useState } from 'react';
import BuyAmount from '../../../components/Buy/BuyAmount';
import BuyReview from '../../../components/Buy/BuyReview';
import Buy from '../../../components/Buy/Buy';
import Success from '../../../components/Success';
import Steps from '../../../components/Steps';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectBalance,
  selectFirebaseJWT,
  selectJwtToken,
  selectTransferData,
  setTransferData,
} from '../../../redux/features/user/userSlice';
import { swapCurrency } from '../../../api';
import Attention from '../../../components/Attention/Attention';
import successBuyPesaVideo from '../../../assets/anims/videos/fullscreen/buy_pesa_success_ff.mp4';
import errorBuyPesaVideo from '../../../assets/anims/videos/popups/buy_pesa_fail_ff.mp4';
import { toast } from 'react-toastify';
import useHideBtn from '../../../utils/useHideBtn';
import { LoadingButton } from '@mui/lab';

const BuyPesa = () => {
  const transferData = useSelector(selectTransferData);
  const fromSendFlow = transferData?.userReview;
  const userBalance = useSelector(selectBalance);
  const userDeviceToken = useSelector(selectJwtToken);
  const userToken = useSelector(selectFirebaseJWT);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [error, setError] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);

  const [steps, setSteps] = useState({
    current: transferData?.userBuy ? 1 : 0,
    total: [
      { name: 'Amount', completed: false },
      { name: 'Review', completed: false },
      { name: 'Buy', completed: false },
    ],
  });

  const { current } = steps;
  const changeStep = (step) => setSteps({ ...steps, current: current + step });

  const [currencyValue, setCurrencyValue] = useState({
    usd: transferData?.userBuy?.usd || '',
    pesa: transferData?.userBuy?.pesa || '',
  });

  const checkBalance = () => {
    const currentUSDBalance = userBalance.find((item) => item.currency === 'USD');
    setError(currencyValue.usd > currentUSDBalance.amount);
  };

  const changeCurrencyValue = (event, value) => {
    const currentField = event?.target.name;
    const currentValue = parseFloat(value);

    if (!isNaN(currentValue)) {
      if (currentField === 'usd') {
        setCurrencyValue((prevCurrency) => {
          return {
            ...prevCurrency,
            pesa: parseFloat((currentValue * userBalance[0].rate).toFixed(2)),
            usd: currentValue,
          };
        });
      }

      if (currentField === 'pesa') {
        setCurrencyValue((prevCurrency) => {
          return {
            ...prevCurrency,
            pesa: currentValue,
            usd: parseFloat((currentValue / userBalance[0].rate).toFixed(2)),
          };
        });
      }
    } else {
      setCurrencyValue({
        usd: '',
        pesa: '',
      });
    }
  };

  const saveTransferData = () => {
    const flow = {
      state: {
        buyFlow: true,
        linkBack: location.pathname,
        jumpToNextStep: true,
      },
    };

    return (dispatch) => {
      dispatch(
        setTransferData({
          ...transferData,
          userBuy: currencyValue,
        })
      );

      navigate('/auth/trust-device', flow);
    };
  };

  const submitSwap = async (otp) => {
    try {
      setLocalLoading(true);

      const swap = {
        sellCurrency: 'USD',
        amount: `${currencyValue.usd}`,
        code: `${otp}`,
      };

      const response = await swapCurrency(swap, userToken, userDeviceToken);

      if (response.status === 200) {
        dispatch(
          setTransferData({
            ...transferData,
            userBuy: currencyValue,
          })
        );
        changeStep(1);
      } else {
        throw new Error(response.message);
      }

      console.log('submitSwap', swap, response);
    } catch (error) {
      setError(true);
      console.log(error);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
    } finally {
      setLocalLoading(false);
    }
  };

  useHideBtn(current, 3);

  useEffect(() => {
    checkBalance();
  }, [currencyValue]);

  useEffect(() => {
    if (current === 3 && !error) {
      setLocalLoading(true);

      setTimeout(() => {
        setLocalLoading(false);
      }, 20000);
    }
    if (current === 3 && !error && !fromSendFlow) {
      dispatch(setTransferData(null));
    }
  }, [current]);

  return (
    <div className='buy'>
      <Steps steps={steps} />

      {current === 0 && (
        <BuyAmount
          error={error}
          pesaBalance={userBalance[0]?.amount}
          usdBalance={userBalance[1]?.amount}
          currencyValue={currencyValue}
          changeStep={changeStep}
          changeCurrencyValue={changeCurrencyValue}
          pesaRate={userBalance[0]?.rate}
          saveTransferData={saveTransferData}
          userDeviceToken={userDeviceToken}
          dispatch={dispatch}
        />
      )}

      {current === 1 && <BuyReview currencyValue={currencyValue} changeStep={changeStep} />}

      {current === 2 && (
        <Buy
          changeStep={changeStep}
          submitSwap={submitSwap}
          loading={localLoading}
          buy={true}
          error={error}
          setError={setError}
        />
      )}

      {current === 3 && (
        <Success
          error={error}
          video={error ? errorBuyPesaVideo : successBuyPesaVideo }
          changeStep={changeStep}
          title={'Your PESA purchase has been processed'}
          subtitle={
            'You can check the details in <a class="el-link-m semi" href="/user/transactions">Activity</a>'
          }
          buttons={
            localLoading ? (
              <LoadingButton
                disabled={localLoading}
                loading={localLoading}
                type='submit'
                className='el-button orange'
              >
                <span>Processing</span>
              </LoadingButton>
            ) : (
              <Link
                className='el-button orange'
                to={fromSendFlow ? '/user/send/system/user' : '/user/dashboard'}
              >
                {fromSendFlow && 'Continue send money'}
                {(error || !fromSendFlow) && 'Go to Dashboard'}
              </Link>
            )
          }
        />
      )}

      {fromSendFlow && current !== 3 && (
        <Attention type='info'>
          Your progress is saved. You’ll be returned back to sending after purchasing PESA.
        </Attention>
      )}
    </div>
  );
};

export default BuyPesa;
