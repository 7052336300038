import React from 'react';
import pesaLogo from '../../../assets/img/p-dashboard/deposit-to-pesa.png';
import mpesaLogo from '../../../assets/img/p-dashboard/send-pesa.png';
import pesaLogoAgent from '../../../assets/img/p-dashboard/send-pesa-agent.png';
import PaymentSystem from '../../../components/PaymentSystem';

const AgentPaymentSystem = () => {
  const agentLinks = [
    {
      title: 'Send a Pesabase remittance',
      icon: pesaLogo,
      link: '/agent/send/system/remit',
      system: 'pesabase',
    },
    {
      title: 'Send via M-PESA',
      icon: mpesaLogo,
      link: '/agent/send/system/mpesa',
      system: 'm-pesa',
    },
    {
      title: 'Send to a Pesabase agent',
      icon: pesaLogoAgent,
      link: '/agent/send/system/agent',
      system: 'pesabase',
    }
  ];

  return (
    <div className='deposit'>
      <PaymentSystem data={agentLinks} />
    </div>
  );
};

export default AgentPaymentSystem;
