import React from 'react';
import PropTypes from 'prop-types';
import TFA from '..//TFA';
import ArrowBack from '..//ArrowBack';
import { useSelector } from 'react-redux';
import { selectPhoneNumber } from '../../redux/features/user/userSlice';

const Buy = ({ changeStep, submitSwap, loading, buy, error, setError }) => {
  const userPhone = useSelector(selectPhoneNumber);

  return (
    <div className='buy-step buy-step-3'>
      <ArrowBack handle={() => changeStep(-1)} />

      <div className='buy-step-wrap'>
        <div className='buy-step-content'>
          <TFA
            number={userPhone}
            handle={submitSwap}
            loading={loading}
            closeFlowFn={true}
            setError={setError}
            error={error}
          />
        </div>
      </div>
    </div>
  );
};

Buy.propTypes = {
  changeStep: PropTypes.func.isRequired,
  currencyValue: PropTypes.number.isRequired,
};
export default Buy;
