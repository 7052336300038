import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ArrowBack from '../../../../../components/ArrowBack';
import VerifyOption from '../../../../../components/VerifyOption';
import iconAgent from '../../../../../assets/icons/icon-agent.png';
import iconPool from '../../../../../assets/icons/icon-pool.png';
import { toast } from 'react-toastify';
import { addRemitToPool } from '../../../../../api';
import { useSelector } from 'react-redux';
import { selectRemitDetails } from '../../../../../redux/features/transaction/remittanceSlice';
import { selectFirebaseJWT, selectJwtToken } from '../../../../../redux/features/user/userSlice';
import UserContext from '../../../../../UserContext/UserContext';

const SendStepDestination = ({ changeStep }) => {
  const remitID = useSelector(selectRemitDetails)?.remitID;
  const token = useSelector(selectFirebaseJWT);
  const deviceJwt = useSelector(selectJwtToken);
  const { setLoading } = useContext(UserContext);

  const addToPool = async () => {
    try {
      setLoading(true);
      const response = await addRemitToPool(remitID, token, deviceJwt);

      if (response.status === 200) {
        changeStep('sendSteps', 2);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ArrowBack handle={() => changeStep('sendSteps', -1)} />

      <div className='payment-title'>
        <h6 className='el-title-h6 med'>Choose the destination of the transaction</h6>

        <span className='el-text-m payment-title--subtitle'>
          Select the way the transaction will be processed
        </span>
      </div>

      <div className='send-step-content'>
        <div className='c-option-verify'>
          <div onClick={() => changeStep('sendSteps', 1)}>
            <VerifyOption
              image={iconAgent}
              title={'Send to a specific agent'}
              subtitle={
                'You choose the agent to receive the transaction. They can confirm or decline your request.'
              }
            />
          </div>

          <div onClick={addToPool}>
            <VerifyOption
              image={iconPool}
              title={'Add to transactions pool'}
              subtitle={
                'Other agents will be able to choose and process your transaction if they’re available.'
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

SendStepDestination.propTypes = {
  changeStep: PropTypes.func.isRequired,
};

export default SendStepDestination;
