import { useState, useEffect } from 'react';

const useIsTablet = () => {
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 1023);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth <= 1023);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isTablet;
};

export default useIsTablet;
