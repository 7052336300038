import React, { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import usePictures from '../../../hooks/usePictures';
import Popup from '../../../components/Popup';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFirebaseJWT,
  selectJwtToken,
  selectPhoneNumber,
} from '../../../redux/features/user/userSlice';
import { selectOrder } from '../../../redux/features/transaction/remittanceSlice';
import { cancelRemit, declineRemit, getAgentOTP } from '../../../api';
import { toast } from 'react-toastify';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import Success from '../../../components/Success';
import ReactPlayer from 'react-player';
// import animVideo from '../../../assets/anims/videos/fullscreen/account-success-loop.mp4';
import sendSuccessVideo from '../../../assets/anims/videos/fullscreen/send_success_ff.mp4';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { setOrder } from '../../../redux/features/transaction/remittanceSlice';
import TFA from '../../../components/TFA';
import sendWarnVideo from '../../../assets/anims/videos/popups/send_warn_ff.mp4';
import useHideBtn from '../../../utils/useHideBtn';

const AgentRemitDecline = () => {
  const userToken = useSelector(selectFirebaseJWT);
  const userPhone = useSelector(selectPhoneNumber);
  const deviceToken = useSelector(selectJwtToken);
  const order = useSelector(selectOrder);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [completeDialog, setCompleteDialog] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [remit, setRemit] = useState({
    localLoading: false,
    reason: '',
    showTextArea: false,
    tfa: false,
    success: false,
    error: false,
  });

  const handleCancelDialog = () => setCancelDialog(!cancelDialog);
  const { localLoading, reason, showTextArea, tfa, success, error } = remit;

  const setError = (error) => {
    setRemit((prevState) => ({
      ...prevState,
      error,
    }));
  };
  const imgs = usePictures(
    require.context('../../../assets/img/p-dashboard', false, /\.(png|jpe?g|svg|webp)$/)
  );

  const setReason = (e) => {
    const isTextArea = e.target.nodeName === 'TEXTAREA';

    setRemit((prevState) => ({
      ...prevState,
      reason: e.target.value,
      showTextArea: isTextArea,
    }));
  };

  const submitCancelRemit = async (otp) => {
    try {
      setRemit((prevState) => ({
        ...prevState,
        localLoading: true,
      }));

      const response = await cancelRemit(state.txId, userToken, deviceToken, otp, reason);

      if (response.status === 200) {
        dispatch(setOrder(null));
        setRemit((prevState) => ({
          ...prevState,
          success: true,
          tfa: false,
        }));
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.log('remitount', error);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
    } finally {
      setRemit((prevState) => ({
        ...prevState,
        localLoading: false,
      }));
    }
  };

  const handleProcessCancel = (path) => {
    navigate(path);
  };
  const submitForm = async (e) => {
    e.preventDefault();
    try {
      if (state.cancel && !tfa) {
        await getAgentOTP(userToken, userPhone);

        setRemit((prevState) => ({
          ...prevState,
          tfa: true,
          localLoading: true,
        }));
      } else {
        setRemit((prevState) => ({
          ...prevState,
          localLoading: true,
        }));

        const response = await declineRemit(state.txId, userToken, deviceToken, reason);

        if (response.status === 200) {
          dispatch(setOrder(null));
          setRemit((prevState) => ({
            ...prevState,
            success: true,
            tfa: false,
          }));
        } else {
          throw new Error(response.message);
        }
      }
    } catch (error) {
      console.log('remitount', error);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
    } finally {
      setRemit((prevState) => ({
        ...prevState,
        localLoading: false,
      }));
    }
  };

  useHideBtn(success, true);

  return (
    <>
      {!success && !tfa && (
        <form className='account-edits'>
          <h6 className='el-title-h6 med'>
            {state?.cancel
              ? 'Please select the reason for the cancel'
              : 'Please select the reason for the decline'}
          </h6>

          <div className='account-edits-variants'>
            {state?.cancel ? (
              <FormControl>
                <RadioGroup defaultValue='female' name='radio-buttons-group'>
                  <FormControlLabel
                    className='label'
                    value='Client asked to do cancel'
                    control={<Radio />}
                    label='Client asked to do cancel'
                    onClick={setReason}
                  />

                  <FormControlLabel
                    className='label'
                    value='Created by mistake'
                    control={<Radio />}
                    label='Created by mistake'
                    onClick={setReason}
                  />

                  <FormControlLabel
                    className='label'
                    value='Technical issue'
                    control={<Radio />}
                    label='Technical issue'
                    onClick={setReason}
                  />

                  <FormControlLabel
                    className='label'
                    value={'other'}
                    control={<Radio />}
                    label='Other reasons'
                    onClick={() =>
                      setRemit((prevState) => ({ ...prevState, showTextArea: true, reason: '' }))
                    }
                  />
                </RadioGroup>

                {showTextArea && (
                  <BaseTextareaAutosize
                    onChange={setReason}
                    minRows={3}
                    maxRows={6}
                    placeholder='Please share with us the reasons'
                  />
                )}
              </FormControl>
            ) : (
              <FormControl>
                <RadioGroup defaultValue='female' name='radio-buttons-group'>
                  <FormControlLabel
                    className='label'
                    value='I don’t have enough cash'
                    control={<Radio />}
                    label='I don’t have enough cash'
                    onClick={setReason}
                  />

                  <FormControlLabel
                    className='label'
                    value='I’m too busy'
                    control={<Radio />}
                    label='I’m too busy'
                    onClick={setReason}
                  />

                  {order?.status === 'processing' ? (
                    <FormControlLabel
                      className='label'
                      value='Client didn’t show up'
                      control={<Radio />}
                      label='Client didn’t show up'
                      onClick={setReason}
                    />
                  ):(
                    <></>
                  )}

                  <FormControlLabel
                    className='label'
                    value={'other'}
                    control={<Radio />}
                    label='Other reasons'
                    onClick={() =>
                      setRemit((prevState) => ({ ...prevState, showTextArea: true, reason: '' }))
                    }
                  />
                </RadioGroup>

                {showTextArea && (
                  <BaseTextareaAutosize
                    onChange={setReason}
                    minRows={3}
                    maxRows={6}
                    placeholder='Please share with us the reasons'
                  />
                )}
              </FormControl>
            )}
          </div>

          <div className='account-edits-form-btns'>
            <LoadingButton
              disabled={reason?.length < 6}
              loading={localLoading}
              onClick={(e) => submitForm(e)}
              className='el-button orange'
            >
              <span>
                {localLoading ? 'Processing' : state?.cancel ? 'Continue' : 'Send answer'}
              </span>
            </LoadingButton>

            <span className='el-link-m' onClick={handleCancelDialog}>
              Cancel
            </span>
          </div>
        </form>
      )}

      {tfa && (
        <TFA
          number={userPhone}
          handle={submitCancelRemit}
          resendAgentFn={true}
          setError={setError}
          error={error}
          loading={localLoading}
          closeFlowFn={true}
        />
      )}

      {success && (
        <Success
          video={sendSuccessVideo}
          title={
            state?.cancel
              ? 'You canceled the outgoing transaction'
              : 'You declined the incoming transaction'
          }
          subtitle={
            state?.cancel
              ? 'You can no longer do anything with this transaction. It was archived in <a class="el-button-link" href="/agent/activity"><span>Activity history</span></a>'
              : ''
          }
          buttons={
            state?.cancel
            ? (
              <Link to={'/agent/outgoing-orders'} className='el-button orange'>
              Go to Outgoing orders
            </Link>
            )
            : (
              <Link to={'/agent/incoming-orders'} className='el-button orange'>
              Go to Incoming orders
            </Link>
            )
          }
        />
      )}

      <Popup open={cancelDialog} togglePopUp={handleCancelDialog}>
        <div className='modal-content'>
          <div className='modal-img'>
            <ReactPlayer
              className='player'
              controls={false}
              playing={true}
              loop={true}
              muted={true}
              playsinline={true}
              url={sendWarnVideo}
              width='100%'
              height='100%'
            />
          </div>
          <div className='modal-desc'>
            <div className='modal-desc-title'>
              {(order?.type === "outgoing")
                ? 'Are you sure want to cancel destination change?'
                : 'Are you sure want to cancel decline?'}
            </div>
          </div>
          <div className='modal-btns'>
            <LoadingButton
              disabled={localLoading}
              loading={localLoading}
              onClick={() => handleProcessCancel('/agent/chat')}
              className='el-button'
            >
              <span>{localLoading ? 'Processing' : 'Yes, I want to cancel'}</span>
            </LoadingButton>

            <Button className='el-button orange' onClick={handleCancelDialog}>
              No, I want to continue
            </Button>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default AgentRemitDecline;
