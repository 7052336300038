import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
const ChatMSG = ({ sendMessage }) => {
  const [comment, setComment] = useState('');

  const sendMSG = (e) => {
    if (e.keyCode === 13 || e.type === 'click') {
      e.preventDefault();
      sendMessage(comment);
      setComment('');
    }
  };

  return (
    <div className='chat-editer'>
      <div className='chat-editer-top'>
        <div className='comment'>
          <TextField
            className='el-text'
            label='Type your message'
            variant='outlined'
            fullWidth
            multiline
            // rows={4}
            rows={3}
            value={comment}
            onKeyDown={sendMSG}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
      </div> 
      <div className='chat-editer-bottom'>
        <div className='chat-editer-left'>
          {/* <Button className='el-button-icon' variant='contained'>
          <img src={icons['icon-adding.svg']} alt='' />
        </Button>
        <Button className='el-button-icon' variant='contained'>
          <img src={icons['icon-emoji.svg']} alt='' />
        </Button> */}
        </div>
        <div className='chat-editer-btn'>
          <Button
            className='el-button orange'
            variant='contained'
            onClick={sendMSG}
            disabled={comment.length === 0}
          >
            Send
          </Button>
        </div>
      </div>
    </div>
  );
};
export default ChatMSG;
