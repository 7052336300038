import React from 'react';
import { ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';
import { ReactComponent as IconCloseBtn } from '../../assets/icons/icon-close-toast.svg';

const Toast = () => {
  const closeToast = () => {
    return;
  };

  const IconClose = ({ closeToast }) => (
    <IconCloseBtn className='Toastify__toast-close' onClick={closeToast} />
  );

  return (
    <ToastContainer
      closeButton={<IconClose closeToast={closeToast} />}
      position='bottom-center'
      autoClose={4000}
      newestOnTop={false}
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
      hideProgressBar
    />
  );
};

export default Toast;
