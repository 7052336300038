import React from 'react';
import PropTypes from 'prop-types';

const ResponsiveImage = ({ desktop, tablet, mobile, type, alt }) => {
  return (
    <picture>
      <source type={`image/${type}`} media='(min-width: 1024px)' srcSet={desktop} />
      <source type={`image/${type}`} media='(min-width: 768px)' srcSet={tablet} />
      <source type={`image/${type}`} media='(min-width: 320px)' srcSet={mobile} />
      <img src={desktop} alt={alt} />
    </picture>
  );
};

ResponsiveImage.propTypes = {
  desktop: PropTypes.string,
  tablet: PropTypes.string,
  mobile: PropTypes.string,
  type: PropTypes.string,
  alt: PropTypes.string,
};

export default ResponsiveImage;
