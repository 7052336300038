import React, { useState, useEffect  } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import { ReactComponent as iconDropDown } from '../../../assets/icons/icon-drop-down.svg';
import { v4 as uuidv4 } from 'uuid';
const AgentFilter = ({
  onStatusChange,
  onPeriodChange,
  onSenderChange,
  onReceiverChange,
  onAmountChange,
  statusOptions,
  periodOptions,
  senderOptions,
  receiverOptions,
  amountOptions,
  defaultStatus,
  defaultPeriod,
  defaultSender,
  defaultReceiver,
  defaultAmount,
}) => {
  const [selectedStatus, setSelectedStatus] = useState(defaultStatus);
  const [selectedPeriod, setSelectedPeriod] = useState(defaultPeriod);
  const [selectedSender, setSelectedSender] = useState(defaultSender);
  const [selectedReceiver, setSelectedReceiver] = useState(defaultReceiver);
  const [selectedAmount, setSelectedAmount] = useState(defaultAmount);

  useEffect(() => {
    setSelectedPeriod(defaultPeriod);
  }, [defaultPeriod]);

  useEffect(() => {
    setSelectedStatus(defaultStatus);
  }, [defaultStatus]);

  useEffect(() => {
    setSelectedSender(defaultSender);
  }, [defaultSender]);

  useEffect(() => {
    setSelectedReceiver(defaultReceiver);
  }, [defaultReceiver]);


  useEffect(() => {
    setSelectedAmount(defaultAmount);
  }, [defaultAmount]);


  const handlePeriodChange = (event) => {
    const selectedPeriod = event.target.value;
    setSelectedPeriod(selectedPeriod);
    onPeriodChange(selectedPeriod);
  };

  const handleSenderChange = (event) => {
    const selectedSender = event.target.value;
    setSelectedSender(selectedSender);
    onSenderChange(selectedSender);
  };

  const handleReceiverChange = (event) => {
    const selectedReceiver = event.target.value;
    setSelectedReceiver(selectedReceiver);
    onReceiverChange(selectedReceiver);
  };

  const handleStatusChange = (event) => {
    const selectedStatus = event.target.value;
    setSelectedStatus(selectedStatus);
    onStatusChange(selectedStatus);
  };



  const handleAmountChange = (event) => {
    const selectedAmount = event.target.value;
    setSelectedAmount(selectedAmount);
    onAmountChange(selectedAmount);
  };


  return (
    <div className='filter'>
      <div className='filter-select'>
        {periodOptions && (
          <div className='filter-select-item'>
            <Select
              variant='outlined'
              value={selectedPeriod}
              onChange={handlePeriodChange}
              fullWidth
              className='el-input-select'
              IconComponent={iconDropDown}
            >
              {periodOptions.map((period) => (
                <MenuItem key={uuidv4()} value={period}>
                  {period === -1 ? (
                    <>All times</>
                  ) :period === 120 ? (
                    <>4 months</>
                  ) : period === 90 ? (
                    <>3 months</>
                  ) : period === 7 ? (
                    <>Last {period} days</>
                  ) : (
                    <>{period} days</>
                  )}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}
        {senderOptions && (
          <div className='filter-select-item'>
            <Select
              variant='outlined'
              value={selectedSender}
              onChange={handleSenderChange}
              fullWidth
              className='el-input-select'
              IconComponent={iconDropDown}
            >
              {senderOptions.map((sender) => (
                <MenuItem key={uuidv4()} value={sender}>
                  {sender}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}

        {receiverOptions && (
          <div className='filter-select-item'>
            <Select
              variant='outlined'
              value={selectedReceiver}
              onChange={handleReceiverChange}
              fullWidth
              className='el-input-select'
              IconComponent={iconDropDown}
            >
              {receiverOptions.map((receiver) => (
                <MenuItem key={uuidv4()} value={receiver}>
                  {receiver}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}
        {statusOptions && (
          <div className='filter-select-item'>
            <Select
              variant='outlined'
              value={selectedStatus}
              onChange={handleStatusChange}
              fullWidth
              className='el-input-select'
              IconComponent={iconDropDown}
            >
              {statusOptions.map((status) => (
                <MenuItem key={uuidv4()} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}
        {amountOptions && (
          <div className='filter-select-item'>
            <Select
              variant='outlined'
              value={selectedAmount}
              onChange={handleAmountChange}
              fullWidth
              className='el-input-select'
              IconComponent={iconDropDown}
            >
              {amountOptions.map((amount) => (
                <MenuItem key={uuidv4()} value={amount}>
                  {amount}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}
      </div>
    </div>
  );
};

AgentFilter.propTypes = {
  onStatusChange: PropTypes.func,
  onPeriodChange: PropTypes.func,
  onSenderChange: PropTypes.func,
  onReceiverChange: PropTypes.func,
  statusOptions: PropTypes.array,
  periodOptions: PropTypes.array,
  senderOptions: PropTypes.array,
  receiverOptions: PropTypes.array,
  defaultStatus: PropTypes.string,
  defaultPeriod: PropTypes.number,
  defaultSender: PropTypes.string,
  defaultReceiver: PropTypes.string,
};
export default AgentFilter;
