import React from 'react';
import PropTypes from 'prop-types';
import Amount from '../../../../components/Amount';
import ArrowBack from '../../../../components/ArrowBack';

const WithdrawAmount = ({ changeStep, flowData, setData }) => {
  return (
    <div className='send-step send-step-2'>
      <ArrowBack handle={() => changeStep(-1)} />

      <div className='payment-title'>
        <h6 className='el-title-h6 med'>
          Enter the amount to <span className='orange'>withdraw</span> to
        </h6>
      </div>

      <Amount
        changeStep={changeStep}
        setData={setData}
        transferData={flowData}
        withdraw={true}
      />
    </div>
  );
};

WithdrawAmount.propTypes = {
  changeStep: PropTypes.func.isRequired,
  flowData: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
};

export default WithdrawAmount;
