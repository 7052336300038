const useUpperCase = (text) => {
  const words = text.toLowerCase().split(' ');
  for (let i = 0; i < words.length; i++) {
    if (i === 0) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    } else {
      words[i] = words[i].toLowerCase();
    }
  }
  return words.join(' ');
};

export default useUpperCase;
