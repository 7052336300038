import React, { useRef } from 'react';
import useBodyClass from '../../hooks/useBodyClass';
import Icon from '../../components/Icon';
import { Container } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText.js';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { TextPlugin } from 'gsap/TextPlugin';
import usePictures from '../../hooks/usePictures';
import HomeAnims from './HomeAnims';
import ResponsiveImage from '../../components/ResponsiveImage';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import phoneImg1 from '../../assets/img/p-home/iphone-1.png';
import phoneImg2 from '../../assets/img/p-home/iphone-2.png';
import { Helmet } from 'react-helmet-async';
import { Grid } from '@mantine/core';
// import Grid2 from '@mui/material/Unstable_Grid2';

gsap.registerPlugin(ScrollTrigger, SplitText, TextPlugin);

const Home = () => {
  useBodyClass('p-home');

  const imgs = usePictures(
    require.context('../../assets/img/p-home', false, /\.(png|jpe?g|svg|webp)$/)
  );

  const swiperRef = useRef();
  const coloredText = useRef(null);
  const heroSection = useRef(null);
  const customersSection = useRef(null);

  HomeAnims(coloredText, heroSection, customersSection);

  return (
    <main>
      <Helmet>
        <meta
          name='description'
          content=" Experience the cheapest, fastest, and most secure way to send money to South Sudan with Pesabase, South Sudan's premier remittance platform."
        />
        <meta name='viewport' content='width=device-width, initial-scale=1, maximum-scale=1' />

        <meta
          name='keywords'
          content='pesabase, startup, $PESA,  PESA, crypto, money, remittance, bitcoin'
        />

        <meta name='title' content='Send money to South Sudan – Pesabase' />

        <title>Send Money to South Sudan - Pesabase</title>
      </Helmet>
      <Header />
      <section className='s-hero' ref={heroSection}>
        <Container disableGutters>
          <div className='s-hero-desc'>
            <h1>
              SENDING MONEY HAS NEVER BEEN <br />
              <span></span>
              <i></i>
            </h1>

            <div className='links'>
              <a
                href='https://play.google.com/store/apps/details?id=com.pesabase.app&hl=en'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={imgs['google.png']} alt='' />
              </a>

              <a
                href='https://apps.apple.com/us/app/pesabase/id1557420579'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={imgs['apple.png']} alt='' />
              </a>
            </div>
          </div>

          <div className='s-hero-phone'>
            <div className='phone-img'>
              <img src={phoneImg1} alt='' />
            </div>

            <div className='phone-img'>
              <img src={phoneImg2} alt='' />
            </div>
          </div>
        </Container>

        <div className='s-hero-bottom'>
          <div className='s-hero-bottom-bg bg-parent'>
            <span className='s-hero-bottom-bg--layout'></span>

            <ResponsiveImage
              desktop={imgs['hero-bg@desktop.jpg']}
              tablet={imgs['hero-bg@tablet.jpg']}
              mobile={imgs['hero-bg@mobile.jpg']}
              type={'jpg'}
              alt='sending money to south sudan'
            />
          </div>
          <Container disableGutters>
            <div className='links'>
              <a
                href='https://play.google.com/store/apps/details?id=com.pesabase.app&hl=en'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={imgs['google.png']} alt='' />
              </a>

              <a
                href='https://apps.apple.com/us/app/pesabase/id1557420579'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={imgs['apple.png']} alt='' />
              </a>
            </div>

            <div className='text'>
              <h3 ref={coloredText}>
                Pesabase is the easiest way for you to make payments and send money to South Sudan.
                We make transfers to your friends and family instantly.
              </h3>
            </div>
          </Container>
        </div>
      </section>

      <section className='s-why'>
        <Container disableGutters>
          <h2>Why use Pesabase</h2>

          <div className='s-why-image'>
            <ResponsiveImage
              desktop={imgs['s-why@desktop.jpg']}
              tablet={imgs['s-why@tablet.jpg']}
              mobile={imgs['s-why@mobile.jpg']}
              type={'jpg'}
              alt='Finacially connecting Africa'
            />
          </div>

          <div className='s-why-list'>
            <Grid>
              <Grid.Col xs={12} md={4}>
                <span className='el-title--h8 s-why-list--title'>Safe & Secure</span>
                <span className='s-why-list--subtitle'>
                  We take the security of your transactions seriously; that's why we use the latest
                  encryption and blockchain technology.
                </span>
              </Grid.Col>

              <Grid.Col xs={12} md={4}>
                <span className='el-title--h8 s-why-list--title'>SWIFT</span>
                <span className='s-why-list--subtitle'>
                  Send and receive money instantly at the click of a button; it's fast and simple!
                </span>
              </Grid.Col>

              <Grid.Col xs={12} md={4}>
                <span className='el-title--h8 s-why-list--title'>Great Exchange Rate</span>
                <span className='s-why-list--subtitle'>
                  We strive to offer the best market rates; give it a try today!
                </span>
              </Grid.Col>
            </Grid>
          </div>
        </Container>
      </section>

      <section className='s-customers' ref={customersSection}>
        <Container disableGutters>
          <div className='s-customers-top'>
            <h2>Our Happy Customers</h2>

            <div className='s-customers-top-btns'>
              <span className='arrow' onClick={() => swiperRef.current?.slidePrev()}>
                <Icon name='arrow-slider' />
              </span>

              <span className='arrow right' onClick={() => swiperRef.current?.slideNext()}>
                <Icon name='arrow-slider-right' />
              </span>
            </div>
          </div>
        </Container>

        <Swiper
          className='s-customers-slider'
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          slidesPerView={'auto'}
          updateOnWindowResize
          observer
          loop={true}
          observeParents
          grabCursor={true}
          breakpoints={{
            1920: {
              slidesPerView: 2,
              spaceBetween: 50,
            },
            1440: {
              spaceBetween: 40,
            },
            1025: {
              spaceBetween: 30,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 26,
            },
            320: {
              spaceBetween: 20,
            },
          }}
        >
          <SwiperSlide>
            <h5 className='swiper-slide--text'>
              " It's a great app and very fast. I love the fact that it's easy to use and sends
              money in seconds. "
            </h5>

            <span className='swiper-slide--author'>Bevoline Wangechi</span>
          </SwiperSlide>

          <SwiperSlide>
            <h5 className='swiper-slide--text'>
              " Easy to use, great app which helps to send money accross borders. "
            </h5>

            <span className='swiper-slide--author'>Rogers Ngere</span>
          </SwiperSlide>

          <SwiperSlide>
            <h5 className='swiper-slide--text'>
              " Cheapest app for sending money from juba to Nairobi. "
            </h5>

            <span className='swiper-slide--author'>Cyrus Githii</span>
          </SwiperSlide>

          <SwiperSlide>
            <h5 className='swiper-slide--text'>
              " The best and easiest way to cash to your loved ones. "
            </h5>

            <span className='swiper-slide--author'>Jebel Raja</span>
          </SwiperSlide>

          <SwiperSlide>
            <h5 className='swiper-slide--text'>
              " It's easy to send and receive money via this app; I'm delighted to see the
              blockchain revolution being simplified through such apps. "
            </h5>

            <span className='swiper-slide--author'>Roselyne Wanjiru</span>
          </SwiperSlide>

          <SwiperSlide>
            <h5 className='swiper-slide--text'>
              " Very reliable app. It is secure and swift way of sending money to family and friends
              around the world. "
            </h5>

            <span className='swiper-slide--author'>Faith Mwaniki</span>
          </SwiperSlide>
        </Swiper>
      </section>
      <Footer />
    </main>
  );
};

export default Home;
