import { createClient } from '@sanity/client';
import groq from 'groq';

export const client = createClient({
  projectId: '286u5fwe',
  dataset: 'production',
  useCdn: true,
  apiVersion: '2023-04-25',
});

// fetcher function
const fetcher = async (query) => {
  const result = await client.fetch(query);
  return result;
};

// a function to get posts
export const getPosts = async (pageSize, currentPage) => {
  const start = (currentPage - 1) * pageSize;
  const end = start + pageSize;
  const query = groq`{
      "posts": *[_type == "post"] | order(_createdAt desc){
        title,
        slug,
        body,
        _id,
        "authorName": author -> name,
        _createdAt,
        mainImage{
          asset->{
            _id,
            url,
            alt
          }
        }
      }[${start}...${end}],
      "totalPostCount": count(*[_type == "post"])
    }`;
  const data = await fetcher(query);
  return data;
};

// a function to get vacancy
export const getVacancy = async () => {
  const query = groq`{
    "vacancys": *[_type == "vacancy"] | order(_createdAt desc){
      title,
      body,
      _id,
      _createdAt,
    },
  }`;
  const data = await fetcher(query);
  return data;
};

// getting single post
export const getPostBySlug = async (slug) => {
  const query = groq`*[slug.current == "${slug}"]{
      title,
      body,
      mainImage{
        asset->{
          _id,
          url,
          alt
        }
      },
      "authorName": author -> name,
      _createdAt,
    }`;
  const params = { slug };
  const data = await fetcher(query, params);
  return data;
};

// count total number of posts
export const getPostCount = async () => {
  const query = '*[_type == "post"] | count';
  const result = await client.fetch(query);
  return result;
};

//  fucntion to truncate words
export const truncateWords = (str, numWords) => {
  const words = str.split(' ');
  if (words.length <= numWords) {
    return str;
  }
  return words.slice(0, numWords).join(' ') + '...';
};
