import React, { useEffect, useState } from 'react';
import Steps from '../../../components/Steps';
import AgentReceiver from '../../components/AgentAgent/AgentReceiver';
import AgentAmount from '../../components/AgentAgent/AgentAmount';
import AgentReview from '../../components/AgentAgent/AgentReview';
import TFA from '../../../components/TFA';
import { useSelector } from 'react-redux';
import {
  selectFirebaseJWT,
  selectJwtToken,
  selectPhoneNumber,
  selectTransferData,
} from '../../../redux/features/user/userSlice';
import { sendCurrency } from '../../../api';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ArrowBack from '../../../components/ArrowBack';
import Success from '../../../components/Success';
import sendSuccessVideo from '../../../assets/anims/videos/fullscreen/send_success_ff.mp4';
import { toast } from 'react-toastify';
import useHideBtn from '../../../utils/useHideBtn';

const AgentAgentFlow = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const transferData = useSelector(selectTransferData);
  const linkTransferData = location?.state?.transferData;
  const [flowData, setFlowData] = useState(
    transferData ||
      linkTransferData || {
        paymentSystem: location?.state?.paymentSystem,
      }
  );
  const userToken = useSelector(selectFirebaseJWT);
  const userDeviceToken = useSelector(selectJwtToken);
  const [localLoading, setLocalLoading] = useState(false);
  const currentUserPhone = useSelector(selectPhoneNumber);
  const [error, setError] = useState(false);
  const [steps, setSteps] = useState({
    current: flowData?.userReview?.sendValue ? (location.state?.jumpToNextStep ? 2 : 1) : 0,
    total: [{ name: 'Receiver' }, { name: 'Amount' }, { name: 'Review' }, { name: 'Send' }],
  });
  const { current } = steps;
  useHideBtn(current, 4);

  const changeStep = (step) => {
    if (current === 0 && step === -1) {
      navigate(-1);
    }
    setSteps({ ...steps, current: current + step });
  };

  const setData = (data) => {
    if (!error) {
      setFlowData((prev) => ({ ...prev, ...data }));
      changeStep(1);
    }
  };

  const submitSend = async (otp) => {
    try {
      setLocalLoading(true);
      const { userReceiver, userReview } = flowData;

      const data = {
        amount: userReview.sendValue,
        receiver: userReceiver.uid,
        feeCurrency: userReview.transactionFeeCurrency.toUpperCase(),
        comment: userReview.comment,
        code: otp,
      };

      const response = await sendCurrency(data, userToken, userDeviceToken, userReview.currency);

      if (response.status === 200) {
        changeStep(1);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      setError(true);
    } finally {
      setLocalLoading(false);
    }
  };

  console.log(flowData);

  return (
    <div className='send'>
      <Steps steps={steps} className='steps-agent' />

      {current === 0 && (
        <AgentReceiver flowData={flowData} setData={setData} changeStep={changeStep} />
      )}

      {current === 1 && (
        <AgentAmount
          flowData={flowData}
          changeStep={changeStep}
          setData={setData}
          data={flowData}
        />
      )}

      {current === 2 && <AgentReview changeStep={changeStep} data={flowData} />}

      {current === 3 && (
        <div className='send-step'>
          <ArrowBack handle={() => changeStep(-1)} />

          <TFA
            loading={localLoading}
            number={currentUserPhone}
            changeStep={changeStep}
            handle={submitSend}
            resendAgentFn={true}
            setError={setError}
            error={error}
            closeFlowFn={true}
            confirmBtnText='Confirm send'
          />
        </div>
      )}

      {current === 4 && (
        <Success
          video={sendSuccessVideo}
          title={`Your send request has been sent to an ${flowData?.userReceiver?.name}`}
          subtitle={
            'You can find the transaction in <a class="el-button-link" href="/agent/activity"><span>Activity history</span></a>'
          }
          buttons={
            <>
              <Link
                className='el-button orange'
                state={{
                  transferData: flowData,
                }}
                onClick={() => changeStep(-3)}
              >
                Send more
              </Link>
            </>
          }
        />
      )}
    </div>
  );
};

export default AgentAgentFlow;
