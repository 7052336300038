import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import usePictures from '../../hooks/usePictures';
import useLowerCase from '../../hooks/useLowerCase';
import { getAvatar, getTransactionDetails } from '../../api';
import { useSelector } from 'react-redux';
import Initials from '../../components/Initials';
import dayjs from 'dayjs';
import transformNumbers from '../../utils/transformNumbers';
import { selectPhoneNumber, selectAccount } from '../../redux/features/user/userSlice';
import copyToclipboard from '../../utils/copyToclipboard';
import { Button } from '@mui/material';
import { ReactComponent as IconArrowBack } from '../../assets/icons/icon-arrow-back.svg';
import UserContext from '../../UserContext/UserContext';
import ShortenedAddress from '../../components/ShortenedAddress';
import Popup from '../../components/Popup';
import { selectFirebaseJWT } from '../../redux/features/user/userSlice';
import { toast } from 'react-toastify';
import ReactPlayer from 'react-player';
import rewardsbanner from '../../assets/anims/videos/popups/rewards_ff.mp4';
import Avatar from '../../components/Avatar';
import transformString from '../../utils/transformString';

const TransactionDetail = () => {
  const { loading, setLoading } = useContext(UserContext);
  const { txId } = useParams();
  const [tx, setTx] = useState(null);
  const userUid = useSelector(selectAccount).uid;
  const userPhoneNumber = useSelector(selectPhoneNumber);
  const [error, setError] = useState(false);
  const [senderAvatar, setSenderAvatar] = useState('');
  const [receiverAvatar, setReceiverAvatar] = useState('');
  const [rewardsPopUp, setRewardsPopUp] = useState(false);
  const icons = usePictures(
    require.context('../../assets/icons', false, /\.(png|jpe?g|svg|webp)$/)
  );

  const imgs = usePictures(
    require.context('../../assets/img/p-dashboard', false, /\.(png|jpe?g|svg|webp)$/)
  );

  const { userToken } = useSelector((state) => ({
    userToken: selectFirebaseJWT(state),
  }));

  const toggleRewards = () => setRewardsPopUp(!rewardsPopUp);

  const selectRepeatPath = () => {
    switch (transformString(txOperation)) {
      case 'transfer':
        return '/user/send/system/user';
      case 'swap':
        return '/user/deposit/buy-pesa';
      case 'withdrawal':
        return '/user/withdraw';
      default:
        return '';
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const transactionData = await getTransactionDetails(userToken, txId);
        if (transactionData?.data && transactionData.data.length > 0) {
          setTx(transactionData.data[0]);
          const avatar = await getAvatar(transactionData.data[0].sender?.avatar);
          const avatarReceiver = await getAvatar(transactionData.data[0].receiver?.avatar);
          setSenderAvatar(avatar);
          setReceiverAvatar(avatarReceiver);
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        console.error('Failed to fetch transaction data.', error);
        setError(true);
        toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      } finally {
        setLoading(false);
      }
    })();
  }, [txId]);

  const txPaymentSystem =
  tx && tx?.paymentSystem !== undefined ? useLowerCase(tx?.paymentSystem) : '';
  const txOperation = tx?.operation !== undefined ? useLowerCase(tx?.operation) : '';

  return (
    <>
      {!loading && !error && tx && (
        <div className='tx-detail'>
          <Link className='c-arrow-back' to='/user/transactions'>
            <span className='c-arrow-back-icon'>
              <IconArrowBack />
            </span>
            Activity
          </Link>
          <div className='tx-detail-top'>
          <h6 className='med'> Transaction {txId}</h6>
            <Button className='el-button' variant='contained' onClick={() => copyToclipboard(txId)}>
              <span className='el-button-icon'>
                <svg
                  width='100%'
                  height='100%'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <g id='icons/copy'>
                    <path
                      id='Vector'
                      d='M10 13.333H3.33333V4.66634C3.33333 4.29967 3.03333 3.99967 2.66667 3.99967C2.3 3.99967 2 4.29967 2 4.66634V13.333C2 14.0663 2.6 14.6663 3.33333 14.6663H10C10.3667 14.6663 10.6667 14.3663 10.6667 13.9997C10.6667 13.633 10.3667 13.333 10 13.333ZM13.3333 10.6663V2.66634C13.3333 1.93301 12.7333 1.33301 12 1.33301H6C5.26667 1.33301 4.66667 1.93301 4.66667 2.66634V10.6663C4.66667 11.3997 5.26667 11.9997 6 11.9997H12C12.7333 11.9997 13.3333 11.3997 13.3333 10.6663ZM12 10.6663H6V2.66634H12V10.6663Z'
                      fill='#6D757D'
                    />
                  </g>
                </svg>
              </span>
              <span>Copy number</span>
            </Button>
          </div>

          <div className='tx-info'>
            <div className='item'>
              <div className='item-wrap'>
                <div className='item-info'>
                  <div className='item-info-avatar'>
                    {txOperation === 'crypto deposit' ? (
                      <>
                        <img src={imgs['avatar-cripto@2x.png']} alt='' />
                      </>
                    ) : txOperation === 'swap' ? (
                      <>
                        <img src={imgs['avatar-cripto@2x.png']} alt='' />
                      </>
                    ) : txOperation === 'transfer' ? (
                      <>
                        <Avatar data={tx?.sender?.uid !== userUid ? tx?.sender : tx?.receiver} />
                      </>
                    ) : txOperation === 'withdrawal' ? (
                      <>
                        {txPaymentSystem === 'm-pesa' ? (
                          <img src={imgs['avatar-mpesa-agent@2x.png']} alt='' />
                        ):(
                          <img src={imgs['avatar-cripto@2x.png']} alt='' />
                        )}
                      </>
                    ) : txOperation === 'top up' ? (
                      <>
                        <Avatar data={tx?.sender} />
                      </>
                    ) : txOperation === 'referral reward' ? (
                      <>
                        <img src={imgs['avatar-pesa-ref@2x.png']} alt='' />
                      </>
                    ) : txOperation === 'signup reward' ? (
                      <>
                        <img src={imgs['avatar-pesa-ref@2x.png']} alt='' />
                      </>
                    ) : txOperation === 'remit' ? (
                      <>
                        <img src={imgs['avatar-pesa-ref@2x.png']} alt='' />
                      </>
                    ) : (
                      <Avatar data={tx?.sender} />
                    )}
                  </div>
                  <div className='item-info-desc'>
                    <div className='item-info-name'>
                      <div className='item-info-name-title el-text-m semi'>
                        {txOperation === 'crypto deposit' ? (
                          <>Crypto deposit</>
                        ) : txOperation === 'swap' ? (
                          <>{(tx?.sender?.name && transformString(tx?.sender?.name) !== ' ') ? tx?.sender?.name : 'Buy PESA'}</>
                        ) : txOperation === 'transfer' ? (
                          <>
                            {tx?.sender?.uid !== userUid ? (
                              <>{(tx?.sender?.name && transformString(tx?.sender?.name) !== ' ') ? tx?.sender?.name : 'Pesabase user'}</>
                            ) : (
                              <>{(tx?.receiver?.name && transformString(tx?.receiver?.name) !== ' ') ? tx?.receiver?.name : 'Pesabase user'}</>
                            )}
                          </>
                        ) : txOperation === 'withdrawal' ? (
                              txPaymentSystem === 'm-pesa' ? (
                                <>{(tx?.receiver?.name && transformString(tx?.receiver?.name) !== ' ') ? tx?.receiver.name : tx?.receiver?.phoneNumber}</>
                            ):(
                            <>Withdrawal</>
                            )
                        ) : txOperation === 'top up' ? (
                          <>{(tx?.sender?.name && transformString(tx?.sender?.name) !== ' ') ? tx?.sender.name : 'Pesabase user'}</>
                        ) : txOperation === 'referral reward' ? (
                          <>Referral reward</>
                        ) : txOperation === 'signup reward' ? (
                          <>Welcome reward</>
                        ) : txOperation === 'remit' ? (
                          <>Remit</>
                        ) : (
                          <></>
                        )}
                      </div>
                      {tx?.sender?.uid !== userUid ? (
                        <>
                          {txOperation !== 'swap' ? (
                            <>
                              {tx?.sender?.agent && (
                                <span className='item-info-agent'>Agent</span>
                              )}
                              {tx?.sender?.favourite && (
                                <img
                                  className='item-info-star'
                                  src={icons['icon-star.svg']}
                                  alt=''
                                />
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <>
                          {txOperation !== 'swap' ? (
                            <>
                              {tx?.receiver?.agent && (
                                <span className='item-info-agent'>Agent</span>
                              )}
                              {tx?.receiver?.favourite && (
                                <img
                                  className='item-info-star'
                                  src={icons['icon-star.svg']}
                                  alt=''
                                />
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </div>
                    {tx?.timestamp && (
                      <div className='item-info-date'>
                        {dayjs(parseInt(tx?.timestamp)).format('DD MMM, HH:mm')}
                      </div>
                    )}
                  </div>
                </div>
                <div className='item-right'>
                  <div className='item-quantity'>
                    <div
                      className={`item-quantity-wrap ${
                        tx?.sender?.agent
                          ? 'wallet-quan'
                          : txOperation === 'crypto deposit'
                            ? 'wallet-quan'
                            : txOperation === 'swap'
                              ? 'buy-quan'
                              : txOperation === 'top up'
                                ? 'wallet-quan'
                                : (txOperation === 'withdrawal' && txPaymentSystem !== 'm-pesa')
                                  ? 'withdrawal-quan'
                                  : tx?.sender?.uid === userUid
                                    ? 'sent-quan'
                                    : 'received-quan'
                      }`}
                    >
                      <div className='icon'>
                        {tx?.sender?.uid === userUid ? (
                          <>
                            {txOperation === 'swap' ? (
                              <>
                                <img src={icons['icon-type-buy.svg']} alt='' />
                              </>
                            ) : txOperation === 'withdrawal' ? (
                              <>
                                {txPaymentSystem === 'm-pesa' ? (
                                  <img src={icons['icon-sent.svg']} alt='' />
                                ):(
                                  <img src={icons['icon-type-withdrawal.svg']} alt='' />
                                )}
                              </>
                            ): (
                              <img src={icons['icon-sent.svg']} alt='' />
                            )}
                          </>
                        ) : tx?.sender?.agent ? (
                          <>
                            <img src={icons['icon-wallet.svg']} alt='' />
                          </>
                        ) : txOperation === 'crypto deposit' ? (
                          <>
                            <img src={icons['icon-wallet.svg']} alt='' />
                          </>
                        ) : txOperation === 'swap' ? (
                          <>
                            <img src={icons['icon-type-buy.svg']} alt='' />
                          </>
                        ) : txOperation === 'transfer' ? (
                          <>
                            <img src={icons['icon-received.svg']} alt='' />
                          </>
                        ) : txOperation === 'withdrawal' ? (
                          <>
                            {txPaymentSystem === 'm-pesa' ? (
                              <img src={icons['icon-sent.svg']} alt='' />
                            ):(
                              <img src={icons['icon-type-withdrawal.svg']} alt='' />
                            )}
                          </>
                        ) : txOperation === 'top up' ? (
                          <>
                            <img src={icons['icon-wallet.svg']} alt='' />
                          </>
                        ) : txOperation === 'referral reward' ? (
                          <>
                            <img src={icons['icon-received.svg']} alt='' />
                          </>
                        ) : txOperation === 'signup reward' ? (
                          <>
                            <img src={icons['icon-received.svg']} alt='' />
                          </>
                        ) : (
                          <>
                            <img src={icons['icon-sent.svg']} alt='' />
                          </>
                        )}
                      </div>

                      {tx?.currency === 'USD' ? (
                        <div className='text'>$ {parseFloat(tx?.amount).toFixed(2)}</div>
                      ) : (
                        <div className='text'>
                          {parseFloat(tx?.amount).toFixed(2)} {tx?.currency}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='item-status'>
                    {tx?.status === 'completed' ? (
                      <div className='item-status-wrap success'>
                        <div className='icon'>
                          <img src={icons['icon-success.svg']} alt='' />
                        </div>
                        <div className='text'>completed</div>
                      </div>
                    ) : tx?.status === 'failed' ? (
                      <div className='item-status-wrap failed'>
                        <div className='icon'>
                          <img src={icons['icon-failed.svg']} alt='' />
                        </div>
                        <div className='text'>failed</div>
                      </div>
                    ) : tx?.status === 'initiated' ? (
                      <div className='item-status-wrap initiated'>
                        <div className='icon'>
                          <img src={icons['icon-time.svg']} alt='' />
                        </div>
                        <div className='text'>initiated</div>
                      </div>
                    ) : tx?.status === 'assigned' ? (
                      <div className='item-status-wrap assigned'>
                        <div className='icon'>
                          <img src={icons['icon-time.svg']} alt='' />
                        </div>
                        <div className='text'>assigned</div>
                      </div>
                    ) : tx?.status === 'pending ' ? (
                      <div className='item-status-wrap pending'>
                        <div className='icon'>
                          <img src={icons['icon-time.svg']} alt='' />
                        </div>
                        <div className='text'>pending</div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='tx-desc'>
            <div className='tx-desc-list'>
              {/* txId for all transaction */}
              {tx?.txId && (
                <div className='tx-desc-item'>
                  <div className='el-text-m tx-desc-label'>Transaction ID</div>
                  <div className='el-text-m tx-desc-text'>{tx?.txId}</div>
                </div>
              )}


              {/* date for all transaction */}
              {tx?.timestamp && (
                <div className='tx-desc-item'>
                  <div className='el-text-m tx-desc-label'>Initiation date</div>
                  <div className='el-text-m tx-desc-text'>
                    {dayjs(parseInt(tx?.timestamp)).format('MM/DD/YYYY HH:mm:ss')}
                  </div>
                </div>
              )}
              {/* address for crypto deposit  */}
              {(txOperation === 'crypto deposit' || txOperation === 'swap') ? (
                <>
                  {tx?.sender?.address && (
                    <div className='tx-desc-item'>
                      <div className='el-text-m tx-desc-label'>
                        <>Sender wallet address</>
                      </div>
                      <div className='el-text-m tx-desc-text name'>
                        <span
                          className='address'
                          onClick={() => copyToclipboard(tx?.sender?.address)}
                        >
                          <ShortenedAddress address={tx?.sender?.address} />
                          <img className='address-copy' src={icons['icon-copy.svg']} alt='' />
                        </span>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
              {/* address for  withdrawal */}
              {(txOperation === 'withdrawal' && txPaymentSystem !== 'm-pesa') ? (
                <>
                  {tx?.receiver?.address && (
                    <div className='tx-desc-item'>
                      <div className='el-text-m tx-desc-label'>
                        <>Receiving wallet address</>
                      </div>
                      <div className='el-text-m tx-desc-text name'>
                        <span
                          className='address'
                          onClick={() => copyToclipboard(tx?.receiver?.address)}
                        >
                          <ShortenedAddress address={tx?.receiver?.address} />
                          <img className='address-copy' src={icons['icon-copy.svg']} alt='' />
                        </span>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
              {/* paymentSystem operation transfer  */}
              {(txOperation === 'transfer' || txOperation === 'top up'|| (txOperation === 'withdrawal'&& txPaymentSystem === 'm-pesa')) && (
                  <>
                    {txPaymentSystem && (
                      <div className='tx-desc-item'>
                        <div className='el-text-m tx-desc-label'>Payment system</div>
                        <div className='el-text-m tx-desc-text name'>
                          {txPaymentSystem}
                        </div>
                      </div>
                    )}
                  </>
                )
              }

              {/* sender or receiver */}
              {(txOperation === 'transfer' || txOperation === 'top up' || (txOperation === 'withdrawal'&& txPaymentSystem === 'm-pesa')) && (
                <>
                  {tx?.sender?.uid === userUid ? (
                    <>
                      {tx?.receiver.name && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Receiver</div>
                          <div className='el-text-m tx-desc-text name'>
                            {tx?.receiver.name.trim() !== '' ? tx?.receiver.name : 'Pesabase user'}{' '}
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {tx?.sender.name && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Sender</div>
                          <div className='el-text-m tx-desc-text name'>
                            {tx?.sender.name.trim() !== '' ? tx?.sender.name : 'Pesabase user'}{' '}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}

              <div className='tx-desc-bottom'>
                {/* you paid*/}
                {txOperation === 'swap' && (
                  <>
                    {tx?.swapAmount && (
                      <div className='tx-desc-item'>
                        <div className='el-text-m tx-desc-label'>You paid</div>
                        <div className='el-text-m tx-desc-text'>
                          <span className='el-text-l semi'>
                            {transformNumbers(tx?.swapAmount, 2)} USD
                          </span>
                        </div>
                      </div>
                    )}
                  </>
                )}

                {/* amount*/}
                {tx?.amount && (
                  <div className='tx-desc-item'>
                    <div className='el-text-m tx-desc-label'>
                      {txOperation === 'crypto deposit' ? (
                        <>You deposited</>
                      ) : txOperation === 'swap' ? (
                        <>You bought</>
                      ) : txOperation === 'transfer' ? (
                        <>
                          {tx?.sender?.uid === userUid ? <>You send exactly</> : <>You received</>}
                        </>
                      ) : txOperation === 'withdrawal' ? (
                        <>You send exactly</>
                      ) : txOperation === 'top up' ? (
                        <>You received</>
                      ) : txOperation === 'referral reward' ? (
                        <>You received</>
                      ) : txOperation === 'signup reward' ? (
                        <>You received</>
                      ) : (
                        <>You send exactly</>
                      )}
                    </div>
                    <div className='el-text-m tx-desc-text tx-desc-text-reward'>
                      <span className='el-text-l semi'>
                        {transformNumbers(tx?.amount, 2)} {tx?.currency}
                      </span>
                      {(txOperation === 'referral reward' || txOperation === 'signup reward') && (
                        <span className=' tx-desc-text-icon' onClick={toggleRewards}>
                          <svg
                            width='16'
                            height='16'
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 0.999999 8 0.999999C11.866 1 15 4.13401 15 8Z'
                              stroke='#6D757D'
                              strokeWidth='2'
                            />
                            <path
                              d='M9 11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55228 10 9 10.4477 9 11Z'
                              fill='#6D757D'
                            />
                            <path
                              d='M6 6.5V6.5C6 5.67157 6.67157 5 7.5 5H8.76393C9.44659 5 10 5.55341 10 6.23607V6.23607C10 6.70426 9.73548 7.13226 9.31672 7.34164L8.44721 7.77639C8.17313 7.91343 8 8.19357 8 8.5V8.5'
                              stroke='#6D757D'
                              strokeWidth='1.75'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </span>
                      )}
                    </div>
                  </div>
                )}

                {/* fee*/}
                {tx?.sender?.uid === userUid ? (
                  <>
                    {(txOperation === 'transfer' || txOperation === 'top up' || txOperation === 'withdrawal') && (
                      <>
                        {tx?.fee && (
                          <div className='tx-desc-item'>
                            <div className='el-text-m tx-desc-label'>Transaction fee</div>
                            <div className='el-text-m tx-desc-text'>
                              {transformNumbers(Number(tx?.fee), 3)} {tx?.feeCurrency}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {txOperation === 'withdrawal' && (
                      <>
                        {tx?.fee && (
                          <div className='tx-desc-item'>
                            <div className='el-text-m tx-desc-label'>Transaction fee</div>
                            <div className='el-text-m tx-desc-text'>
                              {transformNumbers(Number(tx?.fee), 3)} {tx?.feeCurrency}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}

                {/* total*/}
                {tx?.sender?.uid === userUid ? (
                  <>
                    {(txOperation === 'transfer' || txOperation === 'top up' || txOperation === 'withdrawal') && (
                      <>
                        {tx?.totalAmount && (
                          <div className='tx-desc-item'>
                            <div className='el-text-m tx-desc-label'>Total</div>
                            <div className='el-text-m tx-desc-text'>
                              <span className='el-text-l semi'>
                                {transformNumbers(tx?.totalAmount, 2)} {tx?.currency}
                              </span>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {txOperation === 'withdrawal' && (
                      <>
                        {tx?.totalAmount && (
                          <div className='tx-desc-item'>
                            <div className='el-text-m tx-desc-label'>Total</div>
                            <div className='el-text-m tx-desc-text'>
                              <span className='el-text-l semi'>
                                {transformNumbers(tx?.totalAmount, 2)} {tx?.currency}
                              </span>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}

                {/* USD Equivalent*/}
                {tx?.sender?.uid === userUid ? (
                  <>
                    {(txOperation === 'transfer' || txOperation === 'top up' || txOperation === 'withdrawal') && (
                      <>
                        {tx?.txAmountInUSD && (
                          <div className='tx-desc-item'>
                            <div className='el-text-m tx-desc-label'>USD Equivalent</div>
                            <div className='el-text-m tx-desc-text'>
                              {transformNumbers(Number(tx?.txAmountInUSD), 2)} USD
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {txOperation === 'withdrawal' && (
                      <>
                        {tx?.txAmountInUSD && (
                          <div className='tx-desc-item'>
                            <div className='el-text-m tx-desc-label'>USD Equivalent</div>
                            <div className='el-text-m tx-desc-text'>
                              {transformNumbers(Number(tx?.txAmountInUSD), 2)} USD
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}

                {/* comment for several operation */}
                {(txOperation === 'transfer' ||
                  txOperation === 'top up' ||
                  txOperation === 'referral reward'||(txOperation === 'withdrawal'&& txPaymentSystem === 'm-pesa')) && (
                  <>
                    {tx?.comment && (
                      <div className='tx-desc-item'>
                        <div className='el-text-m tx-desc-label'>Comment</div>
                        <div className='el-text-m tx-desc-text'>{tx?.comment}</div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          {tx?.sender?.uid === userUid && tx?.rewardInPesa && tx?.rewardInPesa !== 0 && (
            <div className='tx-reward'>
              <div className='tx-reward-wrap'>
                <div className='tx-reward-left'>
                  <div className='tx-reward-left-img'>
                    <img src={imgs['icon-reward-no-bg.png']} alt='reward image' />
                  </div>
                  <div className='tx-reward-label'>{Number(tx?.rewardInPesa).toFixed(6)} PESA</div>
                </div>
                <div className='tx-reward-text'>
                  Your reward
                  <span className='tx-reward-text-icon' onClick={toggleRewards}>
                    <svg
                      width='16'
                      height='16'
                      viewBox='0 0 16 16'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 0.999999 8 0.999999C11.866 1 15 4.13401 15 8Z'
                        stroke='#6D757D'
                        strokeWidth='2'
                      />
                      <path
                        d='M9 11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55228 10 9 10.4477 9 11Z'
                        fill='#6D757D'
                      />
                      <path
                        d='M6 6.5V6.5C6 5.67157 6.67157 5 7.5 5H8.76393C9.44659 5 10 5.55341 10 6.23607V6.23607C10 6.70426 9.73548 7.13226 9.31672 7.34164L8.44721 7.77639C8.17313 7.91343 8 8.19357 8 8.5V8.5'
                        stroke='#6D757D'
                        strokeWidth='1.75'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          )}

          {/* Repeat transaction button */}
          {tx?.sender?.uid === userUid && (
            <div className='tx-btn'>
              <Link
                variant='outlined'
                to={(transformString(tx?.paymentSystem) === 'm-pesa' && txOperation === 'withdrawal') ? '/user/send/system/mpesa' : selectRepeatPath()}
                className='el-button orange'
                state={{
                  transferData: {
                    paymentSystem: transformString(tx?.paymentSystem),
                    userReceiver: tx?.receiver,
                    userReview: {
                      sendValue: tx?.amount || tx?.totalAmount,
                      currency: tx?.currency.toLowerCase(),
                    },
                  },
                }}
              >
                Repeat transaction
              </Link>
            </div>
          )}

          <Popup open={rewardsPopUp} togglePopUp={toggleRewards}>
            <div className='modal-content'>
              <div className='modal-img'>
                <ReactPlayer
                  className='player'
                  controls={false}
                  playing={true}
                  loop={true}
                  muted={true}
                  playsinline={true}
                  url={rewardsbanner}
                  width='100%'
                  height='100%'
                />
              </div>

              <div className='modal-desc'>
                <div className='modal-desc-title'>Rewards for you!</div>

                <div className='el-text-m modal-desc-text'>
                  We pay you rewards each time you use our platform sending money to your friends
                  and family. Your reward will be added to your PESA balance after transaction is
                  processed. You can also find it in your Activity history.
                </div>
              </div>

              <div className='modal-btns'>
                <Button
                  className='el-button orange el-button-continue'
                  variant='contained'
                  onClick={toggleRewards}
                >
                  Ok, got it
                </Button>
              </div>
            </div>
          </Popup>
        </div>
      )}

      {!loading && error && <h6 className='el-title-error'>Error</h6>}
    </>
  );
};

export default TransactionDetail;
