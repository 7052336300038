import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import Popup from '../../../components/Popup';
import usePictures from '../../../hooks/usePictures';
import { ReactComponent as IconArrowBack } from '../../../assets/icons/icon-arrow-back.svg';
import dayjs from 'dayjs';
import ReactPlayer from 'react-player';
import rewardsbanner from '../../../assets/anims/videos/popups/rewards_ff.mp4';
import { useSelector } from 'react-redux';
import { selectOrder } from '../../../redux/features/transaction/remittanceSlice';
import Avatar from '../../../components/Avatar';
const TransactionPoolDetails = () => {
  const order = useSelector(selectOrder);
  const navigate = useNavigate();
  const icons = usePictures(
    require.context('../../../assets/icons', false, /\.(png|jpe?g|svg|webp)$/)
  );

  const imgs = usePictures(
    require.context('../../../assets/img/p-dashboard', false, /\.(png|jpe?g|svg|webp)$/)
  );
  const toggleRewards = () => setRewardsPopUp(!rewardsPopUp);
  const handleProcess = (path) => {
    console.log('path', path);
    navigate(path, { state: { txId: order.txId} });
  };
  return (
    <>
      <div className='tx-detail pool'>
        <Link className='c-arrow-back' to='/agent/transactions-pool'>
          <span className='c-arrow-back-icon'>
            <IconArrowBack />
          </span>
          Back to pool
        </Link>
        <div className='txagent-item'>
          <div className='txagent-item-wrap'>
            <div className='txagent-item-info'>
              <div className='txagent-item-info-avatar'>
                <img src={imgs['avatar-pesa-agent@2x.png']} alt='' />
              </div>
              <div className='txagent-item-info-desc'>
                {order?.sender?.phoneNumber && (
                  <div className='txagent-item-phone'>
                    <div className='txagent-item-phone-num'>
                      {order?.sender?.phoneNumber}
                    </div>
                    <img src={icons['icon-arrow-trans.svg']} alt='' />
                    {order?.receiver?.phoneNumber && (
                      <div className='txagent-item-phone-num'>
                        {order?.receiver?.phoneNumber}
                      </div>
                    )}
                  </div>
                )}
                <div className='txagent-item-info-bottom'>
                  {order?.agentSender && (
                    <>
                      <div className='txagent-item-info-ag'>
                        <Avatar data={order?.agentSender} />
                        <div className='name'>{order?.agentSender?.name}</div>
                      </div>
                    </>
                  )}
                  {order?.timestamp && (
                    <div className='txagent-item-info-date'>
                      {dayjs(parseInt(order?.timestamp)).format('DD MMM, HH:mm')}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='txagent-item-quantity'>
              {order?.agentReceiver ? (
                <div className='txagent-item-quantity-wrap sent-quan'>
                  <div className='icon'>
                    <img src={icons['icon-sent.svg']} alt='' />
                  </div>
                  <div className='text'>${order?.amount}</div>
                </div>
              ) : (
                <div className='txagent-item-quantity-wrap received-quan'>
                  <div className='icon'>
                    <img src={icons['icon-received.svg']} alt='' />
                  </div>
                  <div className='text'>${order?.amount}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='tx-desc'>
          <div className='tx-desc-list'>
            {/* date for all transaction */}
            {order?.timestamp && (
              <div className='tx-desc-item'>
                <div className='el-text-m txagent-desc-label'>Initiation date</div>
                <div className='el-text-m txagent-desc-text'>
                  {dayjs(parseInt(order?.timestamp)).format('MM/DD/YYYY HH:mm:ss')}
                </div>
              </div>
            )}

            {/* sender */}
            {order?.agentSender && (
              <div className='tx-desc-item'>
                <div className='el-text-m tx-desc-label'>Sending Agent</div>
                <div className='el-text-m tx-desc-text name'>
                  {order?.agentSender?.name ? order?.agentSender?.name : 'Agent Sender'}{' '}
                </div>
              </div>
            )}

            {/* amount*/}
            {order?.amount && (
              <div className='tx-desc-item'>
                <div className='el-text-m tx-desc-label'>Transaction amount</div>
                <div className='el-text-m tx-desc-text tx-desc-text-reward'>
                  <span className='el-text-l semi'>
                    <b>
                      {order?.amount} {order?.currency.trim() !== '' ? order?.currency : 'USD'}
                    </b>
                  </span>
                </div>
              </div>
            )}

            {/* comment for several operation */}
            {order?.comment && (
              <div className='tx-desc-item'>
                <div className='el-text-m tx-desc-label'>Comment</div>
                <div className='el-text-m tx-desc-text'>{order?.comment}</div>
              </div>
            )}
          </div>
        </div>
        {order?.estimatedRewardsInPesa && (
          <>
            <div className='tx-reward'>
              <div className='tx-reward-wrap'>
                <div className='tx-reward-left'>
                  <div className='tx-reward-left-img'>
                    <img src={imgs['icon-reward-no-bg.png']} alt='reward image' />
                  </div>
                  <div className='tx-reward-label'>{Number(order?.estimatedRewardsInPesa)} PESA</div>
                </div>
                <div className='tx-reward-text'>
                  Your estimated reward
                  <span className='tx-reward-text-icon' onClick={toggleRewards}>
                    <svg
                      width='16'
                      height='16'
                      viewBox='0 0 16 16'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 0.999999 8 0.999999C11.866 1 15 4.13401 15 8Z'
                        stroke='#6D757D'
                        strokeWidth='2'
                      />
                      <path
                        d='M9 11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55228 10 9 10.4477 9 11Z'
                        fill='#6D757D'
                      />
                      <path
                        d='M6 6.5V6.5C6 5.67157 6.67157 5 7.5 5H8.76393C9.44659 5 10 5.55341 10 6.23607V6.23607C10 6.70426 9.73548 7.13226 9.31672 7.34164L8.44721 7.77639C8.17313 7.91343 8 8.19357 8 8.5V8.5'
                        stroke='#6D757D'
                        strokeWidth='1.75'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <Popup open={rewardsPopUp} togglePopUp={toggleRewards}>
              <div className='modal-content'>
                <div className='modal-img'>
                  <ReactPlayer
                    className='player'
                    controls={false}
                    playing={true}
                    loop={true}
                    muted={true}
                    playsinline={true}
                    url={rewardsbanner}
                    width='100%'
                    height='100%'
                  />
                </div>

                <div className='modal-desc'>
                  <div className='modal-desc-title'>Rewards for you!</div>

                  <div className='el-text-m modal-desc-text'>
                    We pay you rewards each time you use our platform sending money to your friends
                    and family. Your reward will be added to your PESA balance after transaction is
                    processed. You can also find it in your Activity history.
                  </div>
                </div>

                <div className='modal-btns'>
                  <Button
                    className='el-button orange el-button-continue'
                    variant='contained'
                    onClick={toggleRewards}
                  >
                    Ok, got it
                  </Button>
                </div>
              </div>
            </Popup>
          </>
        )}
        {order?.operation === 'remit' && (
          <>
            <div className='tx-btn'>
              <Button
              onClick={() => handleProcess('/agent/chat/accepted')}
              className='el-button orange'
            >
              Confirm ability to receive
            </Button>
              <Button
              onClick={() => handleProcess('/agent/chat/declined')}
              className='el-button el-button-bottom'
            >
              Decline
            </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TransactionPoolDetails;
