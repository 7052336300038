import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ArrowBack from '../../ArrowBack';
import { useSelector } from 'react-redux';
import {
  selectFirebaseJWT,
  selectJwtToken,
  selectPhoneNumber,
} from '../../../redux/features/user/userSlice';
import TFA from '../../TFA';
import { toast } from 'react-toastify';
import { withdrawCurrency } from '../../../api';

const MPesaSend = ({ changeStep, flowData }) => {
  // Redux state selectors
  const userPhone = useSelector(selectPhoneNumber);
  const userToken = useSelector(selectFirebaseJWT);
  const userDeviceJwt = useSelector(selectJwtToken);

  // Local states
  const [error, setError] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);

  // Destructuring flowData state
  const { userReceiver, userReview } = flowData;

  /**
   * Submits the withdrawal request.
   *
   * @param {string} otpCode - The OTP code entered by the user.
   * @returns {void}
   */
  const submitRequest = async (otpCode) => {
    try {
      setLocalLoading(true);

      const data = {
        amount: userReview?.sendValue,
        wallet: userReceiver?.phoneNumber || userReceiver?.address,
        channel: 'm-pesa',
        comment: userReview?.comment,
        code: otpCode,
        feeCurrency: userReview?.transactionFeeCurrency?.toUpperCase(),
      };

      // API call to withdraw currency
      const response = await withdrawCurrency(data, userToken, userDeviceJwt, userReview.currency);

      if (response.status === 200) {
        changeStep(1);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      console.log(error);
      setError(true);
    } finally {
      setLocalLoading(false);
    }
  };

  return (
    <div className='send-step'>
      <>
        <ArrowBack handle={() => changeStep(-1)} />

        <TFA
          closeFlowFn={true}
          loading={localLoading}
          number={userPhone}
          changeStep={changeStep}
          handle={submitRequest}
          resendAgentFn={true}
          setError={setError}
          error={error}
          confirmBtnText='Confirm send'
        />
      </>
    </div>
  );
};

MPesaSend.propTypes = {
  changeStep: PropTypes.func.isRequired,
};

export default MPesaSend;
