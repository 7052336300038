import React from 'react';
import usePictures from '../../hooks/usePictures';
import PropTypes from 'prop-types';
import { TextField, Button } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import closeFlow from '../../utils/closeFlow';

const BuyAmount = ({
  changeStep,
  currencyValue,
  changeCurrencyValue,
  pesaRate,
  pesaBalance,
  usdBalance,
  error,
  saveTransferData,
  userDeviceToken,
  dispatch,
}) => {
  const icons = usePictures(
    require.context('../../assets/icons', false, /\.(png|jpe?g|svg|webp)$/)
  );

  return (
    <div className='buy-step buy-step-1'>
      <div className='payment-title'>
        <h6 className='el-title-h6 med'>
          Enter the amount to <span className='orange'>buy PESA</span>
        </h6>
      </div>

      <div className='buy-step-wrap'>
        <div className='buy-step-exchange'>
          <div className='buy-step-exchange-block'>
            <div className='buy-step-exchange-note'>
              <img src={icons['icon-notification-gray.svg']} alt='' />
            </div>
            <div className='buy-step-exchange-text'>
              <span className='count'>1.00</span> PESA ={' '}
              <span className='count'>{(1 / pesaRate).toFixed(2)}</span> USD
            </div>
          </div>
        </div>
        <div className='buy-step-content'>
          <div className={`item ${error ? 'error' : ''}`}>
            <div className='item-wrap'>
              <div className='item-left'>
                <div className='item-title'>You pay:</div>
                <div className='item-count'>
                  <NumericFormat
                    name='usd'
                    className={`item-count-input ${currencyValue?.usd ? 'value-entered' : ''}`}
                    customInput={TextField}
                    variant='outlined'
                    placeholder='0.00'
                    value={currencyValue?.usd}
                    onValueChange={(values, sourceInfo) => {
                      const { event } = sourceInfo;
                      const { value } = values;
                      changeCurrencyValue(event, value);
                    }}
                    onFocus={(sourceInfo) => (sourceInfo.target.placeholder = '')}
                    onBlur={(sourceInfo) => (sourceInfo.target.placeholder = '0.00')}
                    // isNumericString
                    valueIsNumericString
                    allowNegative={false}
                    decimalScale={2}
                    thousandSeparator=','
                    fixedDecimalScale
                    inputProps={{ maxLength: 20 }}
                  />
                </div>
                <div className='item-balance el-text-xs'>
                  Your balance:{' '}
                  <span className='count-usd el-text-xs med'>
                    {usdBalance?.toFixed(2)} <span className='currency'>USD</span>
                  </span>
                </div>
              </div>
              <div className='item-right'>
                <div className='item-currency'>
                  <div className='item-currency-icon'>
                    <img src={icons['currency-usd.svg']} alt='' />
                  </div>
                  <span className='item-currency-text'>USD</span>
                </div>
              </div>
            </div>
          </div>

          <div className='buy-step-arrow'>
            <div className='buy-step-arrow-wrap'>
              <div className='buy-step-arrow-icon'>
                <img src={icons['icon-arrow-exchange-green.svg']} alt='' />
              </div>
            </div>
          </div>

          <div className={`item`}>
            <div className='item-wrap'>
              <div className='item-left'>
                <div className='item-title'>You buy:</div>
                <div className='item-count'>
                  <NumericFormat
                    name='pesa'
                    className={`item-count-input ${currencyValue?.pesa ? 'value-entered' : ''}`}
                    customInput={TextField}
                    variant='outlined'
                    placeholder='0.00'
                    value={currencyValue?.pesa}
                    onValueChange={(values, sourceInfo) => {
                      const { event } = sourceInfo;
                      const { value } = values;
                      changeCurrencyValue(event, value);
                    }}
                    onFocus={(sourceInfo) => (sourceInfo.target.placeholder = '')}
                    onBlur={(sourceInfo) => (sourceInfo.target.placeholder = '0.00')}
                    // isNumericString
                    valueIsNumericString
                    allowNegative={false}
                    decimalScale={2}
                    thousandSeparator={','}
                    fixedDecimalScale
                    inputProps={{ maxLength: 20 }}
                  />
                </div>
                <div className='item-balance el-text-xs'>
                  Your balance:{' '}
                  <span className='count-pesa el-text-xs med'>
                    {pesaBalance?.toFixed(2)} <span className='currency'>PESA</span>
                  </span>
                </div>
              </div>
              <div className='item-right'>
                <div className='item-currency'>
                  <div className='item-currency-icon'>
                    <img src={icons['currency-pesa.svg']} alt='' />
                  </div>
                  <span className='item-currency-text'>PESA</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='buy-btns'>
        <div className='buy-btns-continue'>
          <Button
            onClick={() => {
              if (userDeviceToken) {
                changeStep(1);
              } else {
                dispatch(saveTransferData());
              }
            }}
            disabled={
              !currencyValue?.pesa ||
              !currencyValue?.usd ||
              parseFloat(currencyValue?.usd) > usdBalance
            }
            className='el-button orange'
            variant='contained'
          >
            Continue
          </Button>
        </div>
        <div className='buy-btns-cancel'>
          <div className='buy-btns-cancel-wrap'>
            <span className='el-link-m el-button-link' onClick={closeFlow}>
              Cancel
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

BuyAmount.propTypes = {
  changeStep: PropTypes.func.isRequired,
  currencyValue: PropTypes.object.isRequired,
  changeCurrencyValue: PropTypes.func.isRequired,
  pesaRate: PropTypes.number.isRequired,
  pesaBalance: PropTypes.number.isRequired,
  usdBalance: PropTypes.number.isRequired,
  error: PropTypes.bool.isRequired,
};

export default BuyAmount;
