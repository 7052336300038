import React from 'react';
import { ReactComponent as IconSend } from '../../../assets/icons/icon-send.svg';
import { ReactComponent as IconOutgoing } from '../../../assets/icons/icon-outgoing.svg';
import { ReactComponent as IconIncoming } from '../../../assets/icons/icon-incoming.svg';
import { ReactComponent as IconTransactionsPool } from '../../../assets/icons/icon-transactions-pool.svg';
import { ReactComponent as IconActivity } from '../../../assets/icons/icon-activity.svg';
import { ReactComponent as IconAccount } from '../../../assets/icons/icon-account.svg';
import { ReactComponent as IconSettings } from '../../../assets/icons/icon-settings.svg';
import Sidebar from '../../../components/Sidebar';

const AgentSidebar = () => {
  const agentFlowLinks = [
    { link: '/agent/send/system', label: 'Send', icon: IconSend },
    { link: '/agent/outgoing-orders', label: 'Outgoing orders', icon: IconOutgoing },
    { link: '/agent/transactions-pool', label: 'Transactions pool', icon: IconTransactionsPool },
    { link: '/agent/incoming-orders', label: 'Incoming orders', icon: IconIncoming },
  ];

  const agentAccountLinks = [
    { link: '/agent/activity', label: 'Activity history', icon: IconActivity },
    { link: '/agent/account', label: 'Account', icon: IconAccount },
    { link: '/agent/settings', label: 'Settings', icon: IconSettings },
  ];

  return (
    <Sidebar
      flowLinks={agentFlowLinks}
      accountLinks={agentAccountLinks}
      agent={true}
      user={false}
    />
  );
};

export default AgentSidebar;
