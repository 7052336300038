import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Success from '../../../components/Success';
import { Button } from '@mui/base';
import animSuccessEmail from '../../../assets/anims/videos/fullscreen/email-success-loop.mp4';
import animWarningEmail from '../../../assets/anims/videos/fullscreen/email-warn-loop.mp4';
import animSuccess from '../../../assets/anims/videos/fullscreen/account-success-loop.mp4';
import useUserRole from '../../../utils/useUserRole';

const Thanks = () => {
  let { pathname, state } = useLocation();
  const navigate = useNavigate();

  const registration = pathname.includes('/registration');
  const email = pathname.includes('/email');
  const kyc = pathname.includes('/kyc');

  return (
    <>
      {registration && (
        <Success
          video={animSuccess}
          title={'Thanks for registration!'}
          subtitle={`Your account has been created and you can start using it. Note, that you can only deposit and perform 1 send transaction before you verify your identity.  Also, we recommend to connect an email address to your account to improve our communication and safety.`}
          buttons={
            <>
              <Link className='el-button orange' to={`/user/dashboard`}>
                Go to Dashboard
              </Link>

              <Link className='el-button' to={'/auth/add-email'}>
                Add email
              </Link>

              <Link className='el-button' to='/auth/kyc'>
                Verify Identity
              </Link>
            </>
          }
        />
      )}

      {email && (
        <Success
          video={state.error ? animWarningEmail : animSuccessEmail}
          title={
            state.error
              ? 'Something went wrong. Please contact our support'
              : 'Thanks for adding the email! Now we’re waiting for you to verify it.'
          }
          subtitle={
            state.error
              ? ''
              : `We have sent you a verification link on your email <b>${state.userEmail}</b>.`
          }
          subtitlebot={
            state.error
              ? ''
              : `Note, that you can only deposit and perform 1 send transaction before you verify your identity.`
          }
          buttons={
            <>
              {!state.error && (
                <Link className='el-button orange' to='/auth/kyc'>
                  Verify Identity
                </Link>
              )}

              <Button className='el-button' onClick={() => navigate('/user/dashboard')}>
                Go to Dashboard
              </Button>
            </>
          }
        />
      )}

      {kyc && (
        <Success
          video={animSuccess}
          title={'Thank you for submitting your KYC information'}
          subtitle={
            'Your account is now being reviewed and we will be in touch shortly to confirm your identity. In the meantime, you can learn more about our services by visiting our website or by contacting our Customer Support Team. We appreciate your patience and understanding.'
          }
          buttons={
            <>
              <Link className='el-button orange' to={`/user/dashboard`}>
                Go to Dashboard
              </Link>
            </>
          }
        />
      )}
    </>
  );
};

export default Thanks;
