import React, { Fragment } from 'react';
// import Icon from '../Icon';
// import Grid from '@mui/material/Unstable_Grid2';
// import Item from '@mui/material/Unstable_Grid2';
// import { Link } from 'react-router-dom';
import { getPosts } from '../../client';
// import {truncateWords } from '../../client';
import useSWR from 'swr';

const RecentPosts = () => {
  const pageSize = 4;
  const currentPage = 1;
  const { data } = useSWR(['posts', pageSize, currentPage], () => getPosts(pageSize, currentPage));

  return (
    <Fragment>
      {data?.posts?.slice(0, 3).map((post) => (
        <></>
      ))}
    </Fragment>
  );
};

export default RecentPosts;
