import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TFA from '../../../../components/TFA';
import ArrowBack from '../../../../components/ArrowBack';
import { useSelector } from 'react-redux';
import { selectPhoneNumber, selectWhatsAppCode } from '../../../../redux/features/user/userSlice';
import whatsAppLogo from '../../../../assets/img/whatsAppLogo.png';

const WithdrawOTP = ({ loading, changeStep, submitSend, flow, error, setError }) => {
  const userPhoneNumber = useSelector(selectPhoneNumber);
  const code = useSelector(selectWhatsAppCode);
  const whatsappVerificationStatus = useSelector(
    (state) => state.socket.whatsAppCodeVerificationStatus
  );

  console.log('whats verificaton status from withdraw otp:', whatsappVerificationStatus);
  console.log('code from withdraw otp:', code);

  useEffect(() => {
    console.log('useEffect hook executed from withdrawOTP');
    if (whatsappVerificationStatus) {
      const handleSend = async () => {
        try {
          await submitSend(code);
        } catch (err) {
          console.error('Error in sending currency:', err);
        }
      };
      handleSend();
    }
  }, [whatsappVerificationStatus]);

  return (
    <div className='send-step send-step-3'>
      <ArrowBack handle={() => changeStep(-1)} />
      <form>
        <>
          <h4>
            Code: <span className='orange'>{code}</span>
          </h4>
          <span className='c-tfa-box-text el-text-m auth-content--title'>
            Send this code to our whatsapp number for verification
          </span>
        </>

        <span className='c-tfa-box-notes el-text-s'>
          <a
            aria-label='Chat on WhatsApp'
            href='https://wa.me/+18189183281'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img alt='Chat on WhatsApp' src={whatsAppLogo} style={{ width: '50%' }} />
          </a>
        </span>

        <div id='recaptcha-container' className='resend'></div>
      </form>

      {/* <TFA
        loading={loading}
        number={userPhoneNumber}
        changeStep={changeStep}
        handle={submitSend}
        closeFlowFn={true}
        setError={setError}
        error={error}
        withdraw={true}
        confirmBtnText='Confirm withdrawal'
      /> */}
    </div>
  );
};

WithdrawOTP.propTypes = {
  changeStep: PropTypes.func.isRequired,
  submitSend: PropTypes.func.isRequired,
};
export default WithdrawOTP;
