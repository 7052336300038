import React, { useState } from 'react';
import AccordionMUI from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import SanityBlockContent from '@sanity/block-content-to-react';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

const Accordion = ({ vacancies }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleButtonClick = () => {
    const encodedEmail = encodeURIComponent('team@pesabase.com');
    const mailtoURL = `mailto:${encodedEmail}`;
    window.location.href = mailtoURL;
  };

  return (
    <div className='c-accordion'>
      {vacancies?.vacancys?.map(({ title, body }, index) => {
        return (
          <AccordionMUI key={nanoid()} expanded={expanded === index} onChange={handleChange(index)}>
            <AccordionSummary aria-controls={`panel${index}d-content`} id={`panel${index}d-header`}>
              <h6 className='position'>{title}</h6>
              <div className='location'>Nairobi, Kenya</div>
              <div className='work'>Full Time</div>
              <div className='icon'>
                <svg
                  width='100%'
                  height='100%'
                  viewBox='0 0 36 36'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect id='bg' width='36' height='36' rx='5' fill='#ED7E41' />
                  <rect id='plus' x='10' y='17' width='16' height='2' fill='white' />
                  <rect x='17' y='10' width='2' height='16' fill='white' id='plus'></rect>
                </svg>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <SanityBlockContent blocks={body} projectId='286u5fwe' dataset='production' />
              </div>

              <button onClick={handleButtonClick} className='el-button orange'>
                apply now
              </button>
            </AccordionDetails>
          </AccordionMUI>
        );
      })}
    </div>
  );
};

Accordion.propTypes = {
  vacancies: PropTypes.object,
};

export default Accordion;
