import React from 'react';
import { useSelector } from 'react-redux';
import { selectAccount } from '../../../redux/features/user/userSlice';
import { ReactComponent as IconSend } from '../../../assets/icons/icon-send.svg';
import { ReactComponent as IconActivity } from '../../../assets/icons/icon-activity.svg';
import { ReactComponent as IconAccount } from '../../../assets/icons/icon-account.svg';
import { ReactComponent as IconSettings } from '../../../assets/icons/icon-settings.svg';
import { ReactComponent as IconDeposit } from '../../../assets/icons/icon-deposit.svg';
import { ReactComponent as IconBuy } from '../../../assets/icons/icon-buy.svg';
import { ReactComponent as IconWithdraw } from '../../../assets/icons/icon-withdraw.svg';
import { ReactComponent as IconContacts } from '../../../assets/icons/icon-contacts.svg';
import Sidebar from '../../../components/Sidebar';

const UserSidebar = () => {
  const userAccount = useSelector(selectAccount);

  const flowLinks = [
    { link: '/user/deposit/system', label: 'Deposit', icon: IconDeposit },
    {
      link: '/user/send/system',
      label: 'Send',
      icon: IconSend,
      disabled: !userAccount?.canTransact,
    },
    {
      link: '/user/deposit/buy-pesa',
      label: 'Buy Pesa',
      icon: IconBuy,
      disabled: !userAccount?.canTransact,
    },
    {
      link: '/user/withdraw',
      label: 'Withdraw PESA',
      icon: IconWithdraw,
      disabled: !userAccount?.canTransact,
    },
  ];

  const accountLinks = [
    {
      link: '/user/transactions',
      label: 'Activity',
      icon: IconActivity,
      disabled: !userAccount?.canTransact,
    },
    {
      link: '/user/contacts',
      label: 'Contacts',
      icon: IconContacts,
      disabled: !userAccount?.canTransact,
    },
    {
      link: '/user/account',
      label: 'Account',
      icon: IconAccount,
      disabled: !userAccount?.canTransact,
    },
    {
      link: '/user/settings',
      label: 'Settings',
      icon: IconSettings,
      disabled: !userAccount?.canTransact,
    },
  ];

  return <Sidebar flowLinks={flowLinks} accountLinks={accountLinks} agent={false} user={true} />;
};

export default UserSidebar;
