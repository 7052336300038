import React, { useState } from 'react';
import AccordionMUI from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import PropTypes from 'prop-types';

const AccordionFaq = ({ faq, faqIndex }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleChange = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };
  return (
    <AccordionMUI expanded={expandedIndex === faqIndex} onChange={() => handleChange(faqIndex)}>
      <AccordionSummary aria-controls={`panel${faqIndex}d-content`} id={`panel${faqIndex}d-header`}>
        <h6 className='position'>{faq.title}</h6>
        <div className='icon'>
          <svg width='100%' height='100%' viewBox='0 0 36 36' xmlns='http://www.w3.org/2000/svg'>
            <rect id='bg' width='36' height='36' rx='5' fill='#ED7E41' />
            <rect id='plus' x='10' y='17' width='16' height='2' fill='black' />
            <rect x='17' y='10' width='2' height='16' fill='black' id='plus'></rect>
          </svg>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className='tab-content'>{faq.content}</div>
      </AccordionDetails>
    </AccordionMUI>
  );
};

AccordionFaq.propTypes = {
  faq: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
  faqIndex: PropTypes.number.isRequired,
};

export default AccordionFaq;
