import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import UserProvider from './UserContext/UseProvider';
import { Routes, Route, Navigate } from 'react-router-dom';
import Toast from './components/Toast';

// auth section
import Auth from './pages/auth/Auth';
import TwoFactorAuth from './pages/auth/TwoFactorAuth';
import { WhatsAppTFA } from './pages/auth/TwoFactorAuth/WhatsAppTFA';
import TrustDevice from './pages/auth/TrustDevice';
import AddEmail from './pages/auth/AddEmail';
import KYC from './pages/auth/KYC';
import Thanks from './pages/auth/Thanks';
import UserNotFound from './pages/auth/UserNotFound';
import VerificationEmail from './pages/auth/VerificationEmail';

// layout
import Layout from './components/Layout';

// static pages
import Home from './pages/Home';
import Faq from './pages/Faq';
import Blog from './pages/Blog';
import PostPage from './pages/Post';
import About from './pages/About';
import Careers from './pages/Careers';
import Contact from './pages/Contact';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import PageNotFound from './pages/404';
import Referral from './pages/Referral';
import Rewards from './pages/Rewards';
import Cookies from './pages/Cookies';

// hide pages from not authenticated users
import PrivateRoute from './components/PrivateRoute';
import AgentRoutes from './agent/routes/AgentRoutes';
import UserRoutes from './user/routes/UserRoutes';

const App = () => {
  // set default breakpoints
  const theme = createTheme({
    breakpoints: {
      values: {
        desktopLarge: 1920,
        desktop: 1440,
        laptop: 1024,
        tablet: 640,
        mobile: 0,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <Toast />

        <Routes>
          {/* Static pages */}
          <Route path='/' element={<Home />} />
          <Route path='/blog' element={<Blog />} />
          <Route exact path='/about' element={<About />} />
          <Route exact path='/careers' element={<Careers />} />
          <Route exact path='/contact' element={<Contact />} />
          <Route exact path='/terms' element={<Terms />} />
          <Route exact path='/privacy' element={<Privacy />} />
          <Route exact path='/cookies' element={<Cookies />} />
          <Route exact path='/post/:slug' element={<PostPage />} />
          <Route exact path='/faq' element={<Faq />} />
          <Route exact path='/referral' element={<Referral />} />
          <Route exact path='/rewards' element={<Rewards />} />

          {/* Auth pages */}
          <Route exact path='/auth/login' element={<Auth />} />
          <Route exact path='/auth/signup' element={<Auth />} />

          <Route path='/auth' element={<Layout type={'auth'} agent={false} user={false} />}>
            <Route exact path='two-factor-auth' element={<TwoFactorAuth />} />
            <Route exact path='two-factor-auth-whatsapp' element={<WhatsAppTFA />} />
            <Route exact path='verification-email/:token' element={<VerificationEmail />} />
          </Route>

          {/* Account pages */}
          <Route element={<PrivateRoute />}>
            <Route path='/auth' element={<Layout type={'auth'} agent={false} user={false} />}>
              <Route exact path='add-email' element={<AddEmail />} />
              <Route exact path='user-not-found' element={<UserNotFound />} />
              <Route exact path='trust-device' element={<TrustDevice />} />
              <Route exact path='kyc' element={<KYC />} />
              <Route exact path='thanks/:reason' element={<Thanks />} />
              <Route path='*' element={<Navigate to='/not-found' />} />
            </Route>

            <Route path='/user/*' element={<UserRoutes />} />
            <Route path='/agent/*' element={<AgentRoutes />} />
          </Route>

          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </UserProvider>
    </ThemeProvider>
  );
};

export default App;
