import React from 'react';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import qrGoogle from '../../assets/img/c-footer/qr-google.svg';
import qrApp from '../../assets/img/c-footer/qr-app.svg';

const QRmodal = ({ openModal, toggleModal, store }) => {
  return (
    <Dialog open={openModal} onClose={() => toggleModal(false)} maxWidth='sm' className='qr-modal'>
      <DialogContent>
        <span className='el-title-h6'>Scan with the camera on your phone</span>
        <span className='el-text-m'>
          You will be redirected to the Google Play to download the app
        </span>

        <div className='qr-modal-img'>
          <img src={!store ? qrGoogle : qrApp} alt='qr google image' />
        </div>

        <Button className='el-button' onClick={() => toggleModal(false)} color='primary'>
          Close
        </Button>
      </DialogContent>
    </Dialog>
  );
};

QRmodal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  store: PropTypes.bool.isRequired,
};

export default QRmodal;
