import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import coin from '../../assets/anims/videos/loader.mp4';

const Loader = ({ active }) => {
  return (
    <div className={`c-loader ${active ? 'active' : ''}`}>
      <div className='c-loader-anim'>
        <ReactPlayer
          className='player'
          controls={false}
          playing={true}
          loop={true}
          muted={true}
          playsinline={true}
          url={coin}
          width='100%'
          height='100%'
        />
      </div>
    </div>
  );
};

Loader.propTypes = {
  active: PropTypes.bool,
};

export default Loader;
